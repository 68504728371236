import { Typography, Tooltip, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PermissionPopover = ({ permissions, permissionsList }: { permissions: string[]; permissionsList: any[] }) => {
  return (
    <Tooltip
      title={
        <Box p={1} style={{ overflow: "auto", maxHeight: "100px" }}>
          <Typography style={{ fontSize: "12px", fontWeight: "800", color: "#ffffff" }}>Permissions:</Typography>
          {permissions?.map((permission) => {
            const permissionItem = permissionsList?.find((item) => item?.id === permission);
            return <div key={permission}>{permissionItem?.name || permission}</div>;
          })}
        </Box>
      }
      arrow
    >
      <span style={{ marginLeft: "5px" }}>
        <InfoOutlinedIcon style={{ width: "18px", height: "18px", cursor: "pointer", verticalAlign: "middle" }} />
      </span>
    </Tooltip>
  );
};

export default PermissionPopover;
