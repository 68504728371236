import { memo } from "react";
import * as React from "react";
import { format } from "date-fns";
import { Box, TextField } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fieldValueEqual, formattedDate } from "../../../utils";
import { appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  baseForm: { sharedStyles, formLabeErrorStyles }
} = appStyles;

const DateField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isDisabledField = true;

  const sxProps = {
    ...sharedStyles,
    marginBottom: 0,
    ...(fieldInfo?.style ?? {})
  };

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={formattedDate(value)} />;
  } else {
    return (
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <div style={{ width: "98%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={error ? formLabeErrorStyles : {}}>
              <DatePicker
                disabled={isDisabledField}
                label={fieldInfo.label}
                value={value ? value : null}
                inputFormat="MM/dd/yyyy"
                onChange={(date: any) => {
                  try {
                    setFieldValue(fieldInfo.name, format(date, "yyyy-MM-dd"));
                  } catch (err) {
                    setFieldValue(fieldInfo.name, date);
                  }
                }}
                aria-invalid={Boolean(error)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={fieldInfo.variant ?? "standard"}
                    name={fieldInfo.name}
                    error={Boolean(error)}
                    helperText={error}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ ...params.inputProps, "aria-describedby": fieldInfo.label }}
                    id={fieldInfo.label}
                    sx={sxProps}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default memo(DateField, fieldValueEqual);
