import { commonStrings } from "@midato-mono/common/src/assets";
import { formattedDate } from "@midato-mono/common/src/utils";

const { appStrings: appstrings_common, defaultErrorStrings } = commonStrings;

const dateString = (date: string) => formattedDate(date, "MMM do, yyyy");

const twoDigitsDateString = (date: string) => formattedDate(date, "MM/dd/yy");

export default {
  uploadConsent: {
    title: " Consent Upload"
  },
  ariaLabels: {
    sideBarNavigation: "sidebar navigation"
  },
  formBuilder: {
    notificationFormStatus: (status: string) => `Status: ${status}.`,
    jsonTextPlaceholder: "Paste or type your JSON here",
    pdfSubTitle: "PDF Template",
    deletedNotification: "Form deleted",
    apiCallError: "Failed to delete form",
    nonDraftError: "Only draft forms can be deleted",
    draft: "draft",
    confirmDeletion: "Are you sure you want to delete this draft?",
    formDeleteSuccess: "Form successfully deleted",
    formDeprecateSuccess: "Form successfully deprecated",
    formPublishSuccess: "Form successfully published",
    formUpdateSuccess: "Form successfully updated",
    formCreateSuccess: "Form successfully created as draft",
    genericFailure: "An error has occured",
    confirmPublish:
      "After publishing the form you will no longer be able to edit it. Are you sure you want to publish the form?",
    tabs: {
      details: "details",
      schema: "schema",
      pdfTemplate: "pdf template",
      preview: "preview"
    },
    buttonText: {
      save: "Save",
      publish: "Publish",
      delete: "Delete",
      deactivate: "Deactivate",
      continue: "Continue",
      saveDraft: "Save Draft"
    }
  },
  notifications: {
    copyRole: "Role was successfully copied",
    changedPassword: "Changed Password.",
    phoneChanged: "Your phone number has been changed.",
    emailChanged: "Your email has been changed.",
    changedName: "Changed Name.",
    changedNameAndEmail: "Changed Name and Email",
    resetConsumerSuccess: "Reset consumer successfully",
    resetConsumerError: "Error resetting Consumer",
    saveSuccess: "Successfully saved",
    saveFailure: "Could not save, please try again",
    createSuccess: "Successfully created",
    createFailure: "Could not create, please try again",
    deleteSuccess: "Successfully deleted",
    deleteFailure: "Could not delete, please try again",
    activateSuccess: "Successfully activated",
    activateFailure: "Could not activate, please try again",
    deactivateSuccess: "Successfully deactivated",
    deactivateFailure: "Could not deactivate, please try again",
    formSubmitError:
      "Form cannot be submitted until all validation errors are fixed and required fields have been completed"
  },
  dates: {
    standardDateString: dateString,
    sentDateString: (date: string) => `Sent ${dateString(date)}`,
    twoDigitsDateString: twoDigitsDateString
  },
  specialChars: {
    greaterThan: ">"
  },
  // specific component strings
  actionRibbon: {
    searchLabel: "Search"
  },
  sidebar: {
    labels: {
      consentMgmt: "Consents",
      admin: "Admin",
      reports: "Reports",
      drawerHeaderTitle: "Share Approve Admin",
      dashboard: "Dashboard",
      forms: "Forms",
      ocrResults: "OCR Results",
      tenants: "Tenants",
      serviceAreas: "Service Areas",
      users: "Users",
      clinicians: "Clinicians",
      consumers: "Consumers",
      consents: "Consents",
      organizations: "Organizations",
      practitioners: "Practitioners",
      selected: "Selected",
      exports: "Exports",
      imports: "Imports",
      logout: "Logout",
      settings: "Settings",
      userGuide: "User Guide",
      securityRoles: "Security Roles",
      activityReport: "User Activity",
      consentReport: "Consents",
      expiryReport: "Expiry",
      usageReport: "Usage Report",
      consentStatisticsReport: "Consent Stats",
      loadTimeReport: "Response Time"
    }
  },
  consentDetail: {
    confirmationModal: {
      cancel: {
        modalConfirmation: "Are you sure you want to leave before you complete this form?",
        modalExplanation:
          "If you exit now, the consent will still appear in the NEW tab, and you can pick up where you left off at any time."
      },
      submit: {
        modalConfirmation: "Submit form?",
        modalExplanation:
          "If you submit this form, it will appear in the ACTIVE tab, where you can review it at any time."
      }
    }
  },
  loginScreen: {
    header: "Login!"
  },
  newPassword: {
    wrapperText: {
      header: "Welcome!",
      textArray: ["Please enter a new password to continue."]
    }
  },
  login: {
    wrapperText: {
      header: "Welcome!",
      textArray: ["Please sign in to continue."]
    },
    forgotPassword: "Forgot password?",
    notYetUserText: "Not yet a ShareApprove member?",
    signUp: "Sign up.",
    signIn: "Sign in.",
    newPasswordConfirm: "Are you sure you would like to set this new password?",
    placeholders: {
      usernamePH: "Your username",
      passwordPH: "Password",
      newPasswordPH: "New Password",
      confirmPasswordPH: "Confirm your new password"
    },
    errors: {
      usernameRequiredError: defaultErrorStrings.usernameRequiredError,
      passwordRequiredError: "Password is required",
      invalidPhoneError: defaultErrorStrings.invalidPhoneError,
      phoneMaxLengthError: defaultErrorStrings.phoneMaxLengthError,
      phoneMinLengthError: defaultErrorStrings.phoneMinLengthError
    }
  },
  signup: {
    labels: {
      phoneLabel: "Phone number",
      emailLabel: "Your email address",
      passwordLabel: "Your password",
      confirmPasswordLabel: "Confirm your password"
    }
  },
  forgotPassword: {
    wrapperTextRequest: {
      header: "Forgot password?",
      textArray: ["No problem!", "We'll send you a reactivation code."]
    },
    wrapperTextSubmit: {
      header: "Forgot password?",
      textArray: ["No problem!", "We'll send you a reactivation code."]
    },
    placeholders: {
      phonePH: "Your phone number",
      passwordPH: "Your new password",
      confirmPasswordPH: "Confirm your new password",
      codePH: "Enter the code we texted you"
    },
    errors: {
      usernameRequiredError: defaultErrorStrings.usernameRequiredError,
      invalidPhoneError: defaultErrorStrings.invalidPhoneError,
      phoneMaxLengthError: defaultErrorStrings.phoneMaxLengthError,
      phoneMinLengthError: defaultErrorStrings.phoneMinLengthError,
      passwordRequiredError: defaultErrorStrings.passwordRequiredError,
      minLengthError: defaultErrorStrings.minLengthError,
      maxLengthError: defaultErrorStrings.maxLengthError,
      hasCapError: defaultErrorStrings.hasCapError,
      hasLowerError: defaultErrorStrings.hasLowerError,
      hasDigitError: defaultErrorStrings.hasDigitError,
      hasSymbolError: defaultErrorStrings.hasSymbolError,
      confirmationRequiredError: defaultErrorStrings.confirmationRequiredError,
      confirmationMismatchError: defaultErrorStrings.confirmationMismatchError,
      dobInvalidError: defaultErrorStrings.dobInvalidError
    }
  },
  profile: {
    profileForm: {
      header: "Profile",
      permissions: "Permissions",
      wrapperText: {
        header: "Profile"
      },
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email"
      }
    },
    passwordForm: {
      header: "Password",
      wrapperText: {
        header: "Password"
      },
      labels: {
        password: "Password",
        newPassword: "New Password",
        confirmPassword: "Confirm New Password",
        currentPassword: "Current password"
      }
    }
  },
  addEditGeneral: {
    newItemTitle: (pluaralName: string) => `New ${pluaralName.slice(0, -1)}`
  },
  addEditServiceArea: {
    labels: {
      deleteButton: "Delete this Service Area"
    },
    notifications: {
      infoChanged: "Service Area Saved",
      confirmDelete: "Service Area Deleted"
    },
    responses: {
      deletedItem: (itemId: string) => {
        return `Service Area ${itemId} deleted`;
      }
    }
  },
  addEditTenant: {
    notifications: {
      infoChanged: "Tenant Saved",
      confirmDelete: "Tenant Deleted"
    },
    responses: {
      confirmDelete: (id: string) => `Registry ${id} deleted`,
      confirmUpdate: "Registry updated successfully"
    }
  },
  wizard: {
    getErrorString: (i: number, message: string) => `Error ${i + 1}: ${message}`
  },
  inviteWizard: {
    sendAppInvite: (name: string) => `Invite ${name} to ${appstrings_common.metaStrings.shareApprove}`,
    recipientOrganizations: (name: string) => `Recipient Organization: ${name}`,
    messageSent: "Consumer will receive the invite shortly",
    noFormSelectedError: "No form(s) selected",
    inviteResponseError: "INVITE PATIENT REQUEST FAILED",
    pageTitles: {
      page1: "Form(s) is being sent on behalf of:",
      page2: "Select Form(s)",
      page3: "Select a Recipient",
      page4: "Review Attached Form(s)",
      page5: ""
    },
    labels: {
      anOrganization: "An Organization >",
      aPractitioner: "A Practitioner >"
    }
  },
  sendConsentFormsWizard: {
    messageSent: "Consumer will receive the invite shortly",
    defaultTitle: "Select a form(s) to send",
    confirmationText: "Consent form(s) have been sent",
    sendConsentErrorText: "Error - unable to send a consent form",
    attached: "Attached",
    noFormSelectedError: "No form(s) selected",
    sendConsentForm: (name: string) => `Send Consent Form to ${name}`,
    recipientOrganizations: (name: string) => `Recipient Organization: ${name}`,
    recipientPractitioner: (name: string) => `Recipient Practitioner: ${name}`,
    recipientName: (type: string, name: string) => `Recipient ${type.charAt(0).toUpperCase() + type.slice(1)}: ${name}`,
    selectRecipientHeading: (name: string) => `Select a Recipient ${name.charAt(0).toUpperCase() + name.slice(1)}`,
    requestConsentFormError: "One or more forms were not requested",
    selectButtonText: "SELECT",
    requestPageTitles: {
      consentForm: "Form",
      requestor: "Requestor",
      dataRecipient: "Data Recipient"
    },
    actionTypes: {
      form: "form",
      requestor: "requestor",
      recipient: "recipient"
    },
    providerTypes: {
      organization: "organization",
      practitioner: "practitioner"
    },
    consentRequestTypes: {
      organization: "organization",
      practitioner: "practitioner"
    },
    pageTitles: {
      // page1: "Form is being sent on behalf of:",
      page1: "",
      page2: "Select Form(s)",
      page3: "Select a Recipient Organization",
      page4: "Review Attached Form(s)"
    },
    labels: {
      anOrganization: "Organization",
      aPractitioner: "Practitioner",
      aForm: "Form"
    }
  },
  formViewerWizard: {
    getWorkerSrc: (pdfjsVersion: string) => `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`,
    getTitle: (firstName: string, lastName: string, formTitle: string) => `${firstName} ${lastName} / ${formTitle}`,
    errors: {
      fetchConsentError: "REQUEST CONSENT ERROR",
      fetchHistoryError: "REQUEST CONSENT HISTORY ERROR",
      viewPdfError: "Unable to view consent form",
      patientIdNotFound: "Error -- Patient ID not found"
    },
    events: {
      DRAFTED: "Sent",
      REJECTED: "Rejected",
      EDITED: "Edited",
      SIGNED: "Signed",
      REVOKED: "Revoked",
      EXPIRED: "Expired",
      ABANDONED: "Abandoned"
    }
  },
  manuallyRecordConsentWizard: {
    getTitle: (firstName: string, lastName: string, formTitle: string) => `${firstName} ${lastName} / ${formTitle}`,
    titles: {
      defaultViewerTitle: "Consumer Consent",
      manuallyCreatingFor: "Manually record a consent for",
      subTitleUploader: "Once you have uploaded the manual consent document, the user's consent will be set to ACTIVE"
    },
    labels: {
      type: "Select the type of consent you wish to record.",
      pdf: "Find and upload a consent document/file",
      consentType: "Consent Type",
      recipient: "Recipient",
      form: "Form"
    },
    pages: {
      page1: "page1",
      page2: "page2",
      page3: "page3"
    }
  },
  runReport: {
    title: "Your report request has been submitted.",
    body: {
      line1: "Your report will be available for download from the ",
      label: "Your Reports ",
      line1b: "page as soon as it's available.",
      line2: "We'll also notify you via email when the report is available."
    },
    dismissButton: "Close"
  },
  listPageButtonText: {
    add: "Add New Item",
    dropDown: "Options"
  },
  organizations: {
    listPageHeader: "Organizations",
    detailPageName: "Organizations",
    labels: {
      listPageHeader: "Organizations",
      newOrganization: "New Organization",
      name: "Name",
      city: "City",
      state: "State",
      phone: "Phone",
      email: "Email",
      status: "Status",
      actions: "",
      level: "",
      searchBar: "Browse and manage organizations.",
      deleteConfirmation: "Are you sure?",
      all: "All"
    },
    webhooksTab: {
      headers: {
        smsInviteSettingsHeader: "SMS Invite Settings",
        webhooksSettingsHeader: "Webhooks Settings",
        webhooksNameHeader: "Webhook",
        webhooksHeader: "Webhook Headers"
      },
      verticalTabLabels: {
        CONSENT_ACTIVATED: "Consent Activated",
        CONSENT_REVOKED: "Consent Revoked"
      },
      labels: {
        subscribed: "Subscribed",
        overrideUrl: "Override URL:",
        authorization: "Authorization",
        topicName: "Topic Name",
        consumerId: "Consumer ID",
        consentId: "Consent ID",
        webhookId: "Webhook ID",
        defaultUrl: "Default URL"
      }
    },
    settingsTab: {
      labels: {
        smsInvite: "Patient Invite SMS Template"
      }
    },
    filterOptions: {
      deleted: "deleted",
      active: "active"
    }
  },
  reports: {
    labels: {
      label: "Label",
      listPageHeader: "Reports",
      runReport: "RUN REPORT",
      reportEditorTitle: "Request a consent report",
      dateRange: "Date Range",
      date: "Report Date",
      empty: "",
      organizations: "Organizations",
      selected: "Selected"
    },
    subTitle: "Complete the form below to request a report."
  },
  dropDownCheckbox: {
    leftString: "Expires in",
    rightString: "months(s)"
  },
  tenants: {
    detailPageName: "Tenants",
    labels: {
      listPageHeader: "Tenants",
      name: "Name",
      id: "ID",
      phone: "Contact Phone",
      createdAt: "Date Added",
      deleteTenant: "Delete this Tenant"
    }
  },
  forms: {
    detailPageName: "Forms",
    labels: {
      listPageHeader: "Forms",
      formName: "Form Name",
      createdAt: "Date Created",
      formDesc: "Form Description",
      formVersionAndCode: "Form Code",
      createdBy: "Created By",
      lastModified: "Last Modified",
      modifiedBy: "Modified By",
      status: "Status",
      active: "Active",
      deprecated: "Deprecated",
      draft: "Draft",
      all: "All"
    },
    viewingStatus: (status: string) => `Viewing: ${status}`,
    radioButtonLabels: {
      validation: "Validation:",
      enabled: "Enabled",
      disabled: "Disabled"
    },
    formPreviewButtonLabels: {
      downloadTemplate: "Download Template",
      preview: "Preview PDF",
      close: "Close Preview",
      returnToFormBuilder: "Return To FormBuilder"
    }
  },
  consentsScreen: {
    listPageHeader: "Consents",
    labels: {
      firstName: "First Name",
      lastName: "Last Name",
      formName: "Form Name",
      dateSent: "Date Sent",
      dateSigned: "Date Signed",
      dateExpiry: "Expiration Date",
      status: "Status",
      all: "All"
    },
    filterOptions: {
      draft: "draft",
      rejected: "rejected",
      active: "active",
      inactive: "inactive",
      expired: "expired",
      abandoned: "abandoned",
      all: ""
    }
  },
  sectionDetails: {
    sectionTitle: (currIdx: string | number, length: string | number | undefined) => {
      return `page ${currIdx} of ${length}`;
    }
  },
  dashboard: {
    pageHeader: "Trends",
    exportsTitle: "Exports",
    importsTitle: "Imports",
    labels: {
      invited: "Invited",
      signed: "Signed",
      revoked: "Revoked"
    }
  },
  bulkImport: {
    list: {
      listPageHeader: "Imports",
      labels: {
        creationDate: "Creation Date",
        importType: "Import Type",
        filename: "File Name",
        state: "State",
        info: "Info",
        importStatus: "Import Status",
        createdAt: "Created at",
        description: "Description"
      }
    },
    item: {
      formFields: {
        description: "Description",
        creationDate: "Creation Date",
        importType: "Import Type",
        filename: "File Name",
        state: "State",
        info: "Info",
        importStatus: "Import Status",
        createdAt: "Created at",
        options: {
          consumers: "CONSUMERS"
        }
      },
      tabs: {}
    }
  },
  bulkExport: {
    list: {
      listPageHeader: "Exports",
      labels: {
        creationDate: "Creation Date",
        startDate: "Start Date",
        endDate: "End Date",
        status: "Status",
        description: "Description"
      }
    },
    item: {
      formFields: {
        organization: "Organization",
        exportType: "Export Type",
        exportFormat: "Export Format",
        filterData: "Filters",
        consentStatus: "Consent Status",
        startDate: "Start Date",
        endDate: "End Date",
        exportStatus: "Export Status",
        results: "Results",
        description: "Description",
        options: {
          consents: "CONSENTS",
          ndjson: "NDJSON"
        },
        consentStatusOptions: {
          DRAFT: { name: "draft", label: "pending" },
          REJECTED: { name: "rejected", label: "rejected" },
          ACTIVE: { name: "active", label: "active" },
          INACTIVE: { name: "inactive", label: "revoked" },
          EXPIRED: { name: "expired", label: "expired" },
          ABANDONED: { name: "abandoned", label: "abandoned" }
        }
      },
      tabs: {}
    }
  },
  ocrResult: {
    ocrResultStatus: {
      ready: "Ready",
      reviewing: "Reviewing",
      validated: "Validated",
      deleted: "Deleted"
    },
    list: {
      listPageHeader: "OCR Result",
      labels: {
        consumer: "Consumer",
        status: "Status",
        dateCreated: "Creation Date",
        formTemplate: "Form Template",
        lastEdited: "Last Edited"
      }
    },
    item: {
      formFields: {},
      tabs: {}
    }
  },
  consent: {
    consentsStatus: {
      draft: "Pending",
      rejected: "Rejected",
      active: "Active",
      inactive: "Revoked",
      expired: "Expired",
      abandoned: "Abandoned",
      in_progress: "Draft",
      deleted: "Deleted",
      ocr_verification: "OCR Verification"
    },
    list: {
      listPageHeader: "Consents",
      labels: {
        disclosingOrganizations: "Disclosing Organization(s)",
        recipientOrganizations: "Recipient Organization(s)",
        name: "Consumer",
        firstName: "First Name",
        lastName: "Last Name",
        formName: "Form",
        dateSent: "Date Sent",
        dateCreated: "Date Created",
        dateSigned: "Date Signed",
        dateExpiry: "Expiration Date",
        status: "Status",
        expirationDate: "Expiration Date",
        deleted: "Deleted",
        all: "All"
      },
      filterOptions: {
        draft: "draft",
        pending: "pending",
        rejected: "rejected",
        active: "active",
        inactive: "inactive",
        expired: "expired",
        abandoned: "abandoned",
        in_progress: "in_progress",
        deleted: "Deleted",
        ocr_verification: "ocr_verification",
        all: "*"
      }
    },
    item: {
      formFields: {},
      tabs: {}
    }
  },
  consumer: {
    list: {
      listPageHeader: "Consumers",
      labels: {
        listPageHeader: "Consumers",
        lastName: "Last Name",
        firstName: "First Name",
        dob: "DOB (Age) Sex",
        consents: "Consents",
        phone: "Mobile Phone",
        email: "Contact Email",
        createdAt: "Date Added",
        form: "Form",
        originatingEntity: "Originating Entity",
        dateSent: "Date Sent",
        dateCreated: "Date Created",
        expirationDate: "Expiration Date",
        status: "Status",
        disclosingOrganizations: "Disclosing Organization(s)",
        recipientOrganizations: "Recipient Organization(s)"
      }
    },
    item: {
      formFields: {
        personalInformation: "Personal Information",
        consumerIdentiryHeader: "Basics",
        consumerExternalIdsHeader: "External IDs",
        consumerContactHeader: "Consumer Contact",
        suffix: "Suffix",
        gender: "Gender",
        sex: "Sex",
        organization: "Organization",
        externalId: "External ID",
        firstName: "First Name",
        lastName: "Last Name",
        middle: "Middle",
        dob: "Date of Birth",
        orgId: "DEV: Organization ID",
        email: "Email",
        mobilePhone: "Mobile Phone",
        landPhone: "Home Phone",
        address1: "Address 1",
        address2: "Address 2",
        city: "City",
        state: "State",
        zip: "Zip",
        expressZip: "Express Zip",
        medicaidId: "ProviderOne Client Number",
        dropDownOptions: {
          // male: "Male",
          // female: "Female",
          // genderOther: "Other",
          dr: "Dr.",
          phd: "PhD",
          jr: "Jr.",
          sr: "Sr.",
          second: "2nd",
          third: "C3rd",
          two: "II",
          three: "III",
          four: "IV",
          five: "V",
          six: "VI"
        },
        genderDropDownOptions: {
          male: "Male",
          female: "Female",
          other: "Other"
        }
      },
      tabs: {
        profile: "profile",
        consents: "consents"
      },
      actionMenuOptions: {
        profileAndSettings: "Profile & Settings",
        logout: "Log out",
        inviteConsumer: "Invite Consumer",
        sendAppInvite: "Send App Invite",
        sendAConsentForm: "Send a consent form",
        sendAConsentFormToGuest: "Send a consent form to guest",
        sendActivationCode: "Send new activation code",
        sendPasswordReset: "Send password reset",
        sendMessage: "Send a message",
        exportConsumersData: "Export consumer's data",
        createConsent: "Create Consent",
        exportConsumersDocsPDF: "Export consumer's consent documents to PDF",
        deleteConsumerOption: "Delete this consumer",
        resetConsumerOption: "Reset consumer"
      }
    }
  },
  form: {
    formsConsentStatus: {
      draft: "Draft",
      active: "Active",
      deprecated: "Deprecated"
    },
    list: {
      listPageHeader: "Forms",
      labels: {
        formName: "Name",
        createdAt: "Date Created",
        formDesc: "Description",
        formVersionAndCode: "Form Code",
        createdBy: "Created By",
        lastModified: "Last Modified",
        modifiedBy: "Modified By",
        status: "Status",
        activeStatus: "Active Status",
        publishedStatus: "Published Status",
        active: "Active",
        deprecated: "Deprecated",
        draft: "Draft",
        all: "All"
      },
      filterOptions: {
        all: "",
        active: "active",
        draft: "draft",
        deprecated: "deprecated"
      }
    },
    item: {
      formFields: {
        title: "Title",
        name: "Name",
        standard: "Standard Form",
        pdf: "PDF-Only Form",
        version: "Version",
        expiry: "Expiry Days",
        expiryOptionOne: "One Year",
        expiryOptionTwo: "Two years",
        description: "Description",
        code: "Code",
        published: "Published",
        tpo: "TPO",
        ocrEnabled: "OCR Enabled",
        generalDesignation: "General Designation",
        active: "Active",
        subTitle: "Form Information/Attributes",
        expiryDays: "Expire automatically after",
        templates: "Templates",
        language: "Language",
        pdfUploadLabel: "PDF File Upload",
        schemaSubTitle: "Schema",
        schemaPlaceholder: `{"sections":[], "title":"", "description":""}`,
        formType: "Form Type",
        formTemplatesOptions: {
          FHIR_CONSENT_PRIVACY: { name: "FHIR_CONSENT_PRIVACY", label: "FHIR_CONSENT_PRIVACY" },
          FHIR_CONDITION_SDOH: { name: "FHIR_CONDITION_SDOH", label: "FHIR_CONDITION_SDOH" }
        }
      },
      tabs: {
        details: "details",
        schema: "schema",
        pdfTemplate: "pdf template",
        preview: "preview"
      }
    }
  },
  organization: {
    organizationsStatus: {
      deleted: "Deactivated",
      active: "Active"
    },
    list: {
      listPageHeader: "Organizations",
      labels: {
        listPageHeader: "Organizations",
        newOrganization: "New Organization",
        name: "Name",
        city: "City",
        state: "State",
        phone: "Phone",
        email: "Email",
        status: "Status",
        actions: "",
        level: "",
        searchBar: "Browse and manage organizations.",
        deleteConfirmation: "Are you sure?",
        all: "All",
        addUserToOrg: "Add a new user",
        addNewSubOrg: "Add a suborganization"
      },
      filterOptions: {
        deleted: "deactivated",
        active: "active",
        all: ""
      }
    },
    item: {
      detailPageHeader: "Organization Detail",
      formFields: {
        activeECMUser: "Active ConsentLink Organization",
        name: "Name",
        email: "Email",
        city: "City",
        state: "State",
        phone: "Phone",
        fax: "Fax",
        npi: "NPI",
        address1: "Address 1",
        address2: "Address 2",
        zip: "Zip",
        expressZip: "Express Zip",
        allowRevocation: "Allow Revocation",
        allowGeneralDesignationConsents: "Allow General Designation Consents",
        active: "Active",
        reviewAfterDays: "Review After Days",
        reviewNotificationLeadDays: "Review Notification Lead Days"
      },
      tabs: {},
      actionMenuOptions: {
        deleteButton: "Deactivate this Organization",
        activateButton: "Activate this Organization"
      }
    }
  },
  practitioner: {
    list: {
      listPageHeader: "Practitioners",
      labels: {
        firstName: "First Name",
        middleName: "Middle Name",
        lastName: "Last Name",
        email: "Email",
        dea: "DEA identifer",
        npi: "NPI number"
      }
    },
    item: {
      detailPageHeader: "Practitioner Detail",
      formFields: {
        firstName: "First Name",
        middleName: "Middle Name",
        lastName: "Last Name",
        email: "Email",
        dea: "DEA identifer",
        npi: "NPI number"
      },
      tabs: {}
    }
  },
  user: {
    list: {
      listPageHeader: "Users",
      labels: {
        listPageHeader: "Users",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Contact Phone",
        email: "Email",
        deleteUser: "Delete this User",
        organizationRoles: "Organizations & Roles",
        status: "Status",
        userOrganizations: "Organizations"
      }
    },
    item: {
      detailPageHeader: "User Detail",
      formFields: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        mobilePhone: "Mobile Phone",
        role: "Role",
        isExternalAuthentication: "External Authentication",
        externalId: "External ID"
      },
      tabs: {}
    }
  },
  securityRole: {
    list: {
      listPageHeader: "Security Roles",
      labels: {
        listPageHeader: "Security Roles",
        name: "Name",
        organization: "Organization",
        status: "Status"
      }
    },
    item: {
      detailPageHeader: "Security Role Detail",
      formFields: {
        name: "Name",
        organization: "Organization",
        active: "Active",
        externalID: "ExternalID",
        permissions: "Permissions"
      },
      tabs: {}
    }
  },
  activityReport: {
    list: {
      listPageHeader: "Activity Report",
      labels: {
        user: "User",
        consumer: "Consumer",
        externalIds: "External ID(s)",
        timestamp: "Timestamp",
        activity: "Activity",
        deviceUsed: "Device Used",
        email: "Email"
      }
    }
  },
  consentReport: {
    list: {
      listPageHeader: "Consents",
      labels: {
        formTitle: "Form",
        consumer: "Consumer",
        dateCreated: "Date Created",
        creatingOrganization: "Creating Organization",
        disclosingOrganizations: "Disclosing Organization(s)",
        recipientOrganizations: "Recipient Organization(s)",
        expirationDate: "Expiration Date"
      }
    }
  },
  consentStatisticsReport: {
    list: {
      listPageHeader: "Consents Statistics",
      labels: {
        organizationName: "Organization",
        isDde: "# DDE Consents Created",
        isOcr: "# OCR Consents Created",
        total: "# Total Consents Created"
      }
    }
  },
  consentRevoke: {
    confirmation: "Are you sure you want to revoke this consent?",
    selectReason: "Revocation Notes",
    reasons: {
      noLongerNeeded: "No Longer Needed",
      inappropriateUse: "Inappropriate Use",
      careCompleted: "Care Completed"
    }
  }
};
