import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;
interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface AddNewPractitionerConfig {
  provider: {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    npi?: string;
    dea?: string;
  };
}

const addNewPractitioner = async ({ auth, variables }: QueryConfig<AddNewPractitionerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($provider: ProviderInput!) {
        addProvider(provider: $provider) {
          providerId
        }
      }
    `,
    variables,
    auth
  );
};

export interface FetchPractitionerConfig {
  providerId: string;
}

const fetchPractitioner = async ({ auth, variables }: QueryConfig<FetchPractitionerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($providerId: ID!) {
        getProvider(providerId: $providerId) {
          ...providerFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdatePractitionerConfig {
  providerId: string;
  provider: {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    npi?: string;
    dea?: string;
  };
}

const updatePractitioner = async ({ auth, variables }: QueryConfig<UpdatePractitionerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($providerId: ID!, $provider: ProviderInput!) {
        updateProvider(providerId: $providerId, provider: $provider) {
          providerId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeletePractitionerConfig {
  providerId: string;
}

const deletePractitioner = async ({ auth, variables }: QueryConfig<DeletePractitionerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($providerId: ID!) {
        deleteProvider(providerId: $providerId) {
          providerId
        }
      }
    `,
    variables,
    auth
  );
};

export { addNewPractitioner, fetchPractitioner, updatePractitioner, deletePractitioner };
