import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

const dailyConsentReport = async ({ auth, variables }: QueryConfig<{}>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        dailyConsentReport {
          labels
          invited
          signed
          revoked
        }
      }
    `,
    variables,
    auth
  );
};

export { dailyConsentReport };
