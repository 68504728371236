import { ConsentFormField } from "./types";
import { validations } from "../../utils";
import assets from "../../../ui/assets";

const {
  appStrings: {
    admin: {
      form: {
        item: { formFields }
      }
    },
    common: {
      appStrings: { languagesOptions }
    }
  },
  appStyles: { languagesChip }
} = assets;

const fields: { [key in ConsentFormField]: any } = {
  formType: {
    name: "formType",
    fieldType: "select",
    label: formFields.formType,
    options: [{ label: "Release of Information", name: "roi" }],
    defaultValue: "ROI",
    validation: validations.requiredText
  },
  name: {
    name: "name",
    label: formFields.name,
    validation: validations.requiredTextMinMax(1, 100)
  },
  description: {
    name: "description",
    label: formFields.description,
    validation: validations.requiredTextMinMax(1, 500)
  },
  active: {
    name: "active",
    fieldType: "switch",
    label: formFields.active,
    defaultValue: true
  },
  generalDesignation: {
    name: "generalDesignation",
    fieldType: "switch",
    label: formFields.generalDesignation,
    defaultValue: false
  },
  tpo: {
    name: "tpo",
    fieldType: "switch",
    label: formFields.tpo,
    defaultValue: false
  },
  ocrEnabled: {
    name: "ocrEnabled",
    fieldType: "switch",
    label: formFields.ocrEnabled,
    defaultValue: false
  },
  published: {
    name: "published",
    fieldType: "switch",
    label: formFields.published,
    defaultValue: false
  },
  version: {
    name: "version",
    label: formFields.version,
    defaultValue: "1",
    validation: validations.requiredTextMinMax(1, 10)
  },
  expiryDays: {
    name: "expiryDays",
    label: formFields.expiryDays,
    psotText: "days",
    fieldType: "checkbox-with-pre-post-text",
    defaultValue: 90,
    uncheckedDefaultValue: 0,
    validation: validations.number
  },
  code: {
    name: "code",
    label: formFields.code,
    validation: validations.requiredText
  },
  templates: {
    name: "templates",
    label: formFields.templates,
    fieldType: "multi-select",
    variant: "outlined",
    width: "70%",
    options: [...Object.values(formFields.formTemplatesOptions)],
    validation: validations.array,
    defaultValue: []
  },

  lang: {
    name: "lang",
    label: formFields.language,
    fieldType: "chips",
    chipStyles: languagesChip,
    width: "80%",
    deleteDisabled: true,
    options: [...Object.values(languagesOptions)],
    validation: validations.nonEmptyArray
  },
  upload: {
    name: "upload",
    label: formFields.pdfUploadLabel,
    fieldType: "pdf",
    validation: validations.requiredFile
  },
  schema: {
    name: "schema",
    label: formFields.schemaSubTitle,
    fieldType: "schema",
    validation: validations.validateSchema
  },
  orgId: {
    name: "orgId",
    label: "Organization",
    showLabel: true,
    fieldType: "select",
    options: []
    // validation: validations.nonEmptyArray
  }
};

export default fields;
