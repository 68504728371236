import ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/redux/store";
import App from "./App";
import "./index.css";

const root = ReactDOMClient.createRoot(document.getElementById("root") as HTMLElement);

if (window.top !== window.self) {
  window.top.location = window.self.location;
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
