import { useHeader } from "../../../app/hooks";
import assets from "../../assets";

const {
  appStyles: {
    HeaderStyle: { HeaderContent, IconContainer, RowBox, NameBox, Text, BoldText },
    styledComponents: { Vertical }
  }
} = assets;

const Header = () => {
  const { firstName, lastName, organization } = useHeader();

  return (
    <HeaderContent>
      <IconContainer />
      <RowBox>
        <NameBox>
          <Text>{`${firstName} ${lastName}`}</Text>
          <Vertical />
          <BoldText>{organization?.name}</BoldText>
        </NameBox>
      </RowBox>
    </HeaderContent>
  );
};

export default Header;
