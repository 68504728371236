import { memo, useEffect, useState } from "react";
import * as React from "react";
import {
  TextField,
  Box,
  Autocomplete,
  FormHelperText,
  TableCell,
  TableRow,
  Popper,
  Table,
  TableBody
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReadonlyTextFieldValueLabel from "../ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../../types";
import { fieldValueEqual, getIsDisabledField } from "../../../../utils";
import { appColors } from "../../../../assets";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../../hooks";
import { graphql } from "../../../../aws";
import AddIcon from "@mui/icons-material/Add";
import CreateOrganizationModal from "./CreateOrganizationModal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";

const { formError, primaryTheme } = appColors;

interface Option {
  name: string;
  label: string;
}

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

interface SearchItemsConfig {
  showAll?: boolean;
  // params: {
  //   type: string;
  //   query: string;
  //   limit: number;
  //   orgIds?: string[];
  //   filter?: string;
  //   orgId?: string;
  //   nextToken?: string;
  //   order?: SortOrderType;
  //   sortBy?: string;
  // };
}

// @ts-expect-error willbe fixed later
const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

const searchItems = async ({ auth, variables }: QueryConfig<SearchItemsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($showAll: Boolean, $query: String) {
        getOrganizations(showAll: $showAll, query: $query) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

const useListStyles = makeStyles({
  customListbox: {
    overflow: "auto",
    display: "inline-block"
  }
});

const useTableStyles = makeStyles({
  tableRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  }
});

const sortByLabel = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

const AutocompleteField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const { token: auth, orgId } = useAuth();
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState<boolean>(false);

  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);

  const tableClasses = useTableStyles();

  const fetchOptions = async (searchValue?: string, orgIds?: string[]) => {
    setLoading(true);
    try {
      const { data } = await searchItems({
        auth,
        variables: {
          showAll: true,
          ...(searchValue?.trim() ? { query: searchValue?.trim() } : {})
          // params: {
          //   type: fieldInfo?.entityName,
          //   limit: 100000,
          //   query: !searchValue?.trim() ? "*" : searchValue?.trim(),
          //   ...(orgIds ? { params: `${orgIds}=${orgIds.join(",")}` } : {}),
          //   orgId
          // }
        }
      });
      if (data) {
        const options = data?.map((item) => ({
          label: item?.[fieldInfo?.entityLabelAttribute],
          name: item?.[fieldInfo?.entityKeyAttribute],
          external: item
        }));
        setOptions(options.sort(sortByLabel));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (value) {
      fetchOptions(null, [value]);
    }
    return () => {
      fieldInfo.clearOnReinitialize && setFieldValue(fieldInfo.name, "");
    };
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const handleInputChange = (value: string) => {
      clearTimeout(timer!);
      timer = setTimeout(() => {
        fetchOptions(value);
      }, 500);
    };

    if (inputValue.length > 2 && !isOrganizationModalOpen) handleInputChange(inputValue);

    return () => {
      clearTimeout(timer!);
    };
  }, [inputValue, isOrganizationModalOpen]);
  const classesList = useListStyles();

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <>
      <CreateOrganizationModal
        isOrganizationModalOpen={isOrganizationModalOpen}
        setIsOrganizationModalOpen={setIsOrganizationModalOpen}
        callback={(id) => {
          setFieldValue(fieldInfo.name, id, true);
        }}
      />
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <Box>
          <Autocomplete
            classes={{ listbox: classesList.customListbox }}
            forcePopupIcon={false}
            noOptionsText="No matching results, type at least 3 characters to search"
            disablePortal
            id="lookup"
            componentsProps={{
              popper: {
                style: {
                  maxWidth: "600px"
                }
              }
            }}
            options={options}
            value={options?.find(({ name }: Option) => name === value) || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event: any, newValue: any | null) => {
              setFieldValue(fieldInfo.name, newValue?.name, true);
            }}
            sx={{ width: 300 }}
            disableClearable
            renderOption={(props, option, state) => (
              // @ts-expect-error willbe fixed later
              <TableRow
                {...props}
                className={tableClasses.tableRow}
                sx={{
                  "td, th": { border: "none", padding: "4px 8px", margin: 0, textAlign: "left", cursor: "pointer" }
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "200px" }}
                >
                  {option.label}
                </TableCell>
                <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "150px" }}>
                  {option?.external?.email ? option?.external?.email : ""}
                </TableCell>
                <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "100px" }}>
                  {option?.external?.address?.address1 ? option?.external?.address?.address1 : ""}
                </TableCell>
                <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "100px" }}>
                  {option?.external?.address?.city ? option?.external?.address?.city : ""}
                </TableCell>
                <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "50px" }}>
                  {option?.external?.address?.state ? option?.external?.address?.state : ""}
                </TableCell>
              </TableRow>
            )}
            PopperComponent={(props) => (
              <Popper {...props}>
                <Table>
                  {/* @ts-expect-error willbe fixed later*/}
                  <TableBody>{props.children}</TableBody>
                </Table>
              </Popper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Type min 3 characters to search"
                required={fieldRequired}
                error={Boolean(error)}
                variant="standard"
                disabled={isDisabledField}
                aria-label={fieldInfo.label}
                aria-required={fieldRequired}
                // aria-invalid={Boolean(error)}
                label={fieldInfo.label}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value || "")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                      {inputValue && (
                        <AddIcon
                          tabIndex={0}
                          style={{ cursor: "pointer", color: primaryTheme }}
                          onClick={() => {
                            setIsOrganizationModalOpen(true);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              setIsOrganizationModalOpen(true);
                            }
                          }}
                        />
                      )}
                      {params.inputProps.value && (
                        <ClearIcon
                          aria-label="clear selected organization"
                          tabIndex={0}
                          style={{ cursor: "pointer" }}
                          role="button"
                          onClick={() => {
                            setFieldValue(fieldInfo.name, "", true);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              setFieldValue(fieldInfo.name, "", true);
                            }
                          }}
                        />
                      )}
                    </>
                  )
                }}
              />
            )}
          />
          {error && (
            <FormHelperText
              sx={{
                color: formError
              }}
            >
              {error}
            </FormHelperText>
          )}
        </Box>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              tabIndex={0}
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              tabIndex={0}
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(AutocompleteField, fieldValueEqual);
