import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Box } from "@mui/material";
import assets from "../assets";
import { clearAllTokens, useAuth } from "@midato-mono/common/src";
import DialogLayout from "./common/DialogLayout";
import { format } from "date-fns";
import { signOut } from "aws-amplify/auth";
import { routes } from "../../Router/constants";
import { useNavigate } from "react-router";

const BaseButton = React.lazy(() => import("@midato-mono/common/src/components/BaseButton"));

const {
  appStyles: {
    ActionModalStyle: { StyledModal },
    TrackUserActivityStyle
  },

  appStrings: {
    common: {
      appStrings: {
        buttonText: { logout, continueSession }
      }
    }
  }
} = assets;

const TrackUserActivity = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [isUserActive, setIsUserActive] = useState(true);
  const [inactivityTime, setInactivityTime] = useState(new Date());
  const [autoCloseTimeoutId, setAutoCloseTimeoutId] = useState<ReturnType<typeof setTimeout>>(undefined);
  const countRef = useRef(inactivityTime);

  const resetTimeout = () => {
    const currentTime = new Date();

    setInactivityTime(currentTime);
    window.sessionStorage.setItem("midato:showInactivityTime", "");
    window.localStorage.setItem("midato:inactivityTime", format(new Date(), "MM/dd/yyyy HH:mm"));
  };

  const onActivity = () => {
    resetTimeout();
  };

  useEffect(() => {
    countRef.current = inactivityTime;
  }, [inactivityTime]);

  useEffect(() => {
    window.addEventListener("mousemove", onActivity);
    window.addEventListener("keypress", onActivity);

    setInterval(() => {
      const currentTime = new Date();
      // @ts-ignore
      if (currentTime - countRef.current > 900000) {
        setIsUserActive(false);
      }
    }, 30000);

    return () => {
      window.removeEventListener("mousemove", onActivity);
      window.removeEventListener("keypress", onActivity);
    };
  }, []);

  useEffect(() => {
    clearTimeout(autoCloseTimeoutId);

    if (!isUserActive) {
      setAutoCloseTimeoutId(
        setTimeout(() => {
          doLogout();
        }, 60000)
      );
    }
  }, [isUserActive]);

  const onClose = (_e: any, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    const currentTime = new Date();
    setInactivityTime(currentTime);
    setIsUserActive(true);
  };

  const doLogout = () => {
    clearAllTokens();
    setAuth(null);
    signOut();
    window.sessionStorage.setItem("midato:showInactivityTime", "true");
    const loginType = window.localStorage.getItem("midato:loginType") || "ALL";
    switch (loginType) {
      case "COGNITO":
        navigate(routes.COGNITO_LOGIN);
        break;
      case "SAW":
        navigate(routes.SAW_LOGIN);
        break;
      case "WATECH":
        navigate(routes.WATECH_LOGIN);
        break;
      default:
        navigate(routes.LOGIN);
        break;
    }
    window.location.reload();
  };

  return (
    <StyledModal open={!isUserActive} disableEscapeKeyDown onClose={onClose} aria-labelledby="modal-modal-title">
      <DialogLayout customStyles={TrackUserActivityStyle}>
        <Grid container>
          <Grid mb={4} xs={12} item>
            <Typography id="modal-modal-title" style={{ textAlign: "center" }} variant="h6">
              The session will be terminated due to user inactivity
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <BaseButton
                color="primary"
                onPress={doLogout}
                id="btn-select"
                style={{ marginRight: "20px" }}
                ariaLabel={logout}
              >
                {logout}
              </BaseButton>
              <BaseButton
                ariaLabel={continueSession}
                color="secondary"
                onPress={onClose}
                id="btn-cancel"
                variant="outlined"
              >
                {continueSession}
              </BaseButton>
            </Box>
          </Grid>
        </Grid>
      </DialogLayout>
    </StyledModal>
  );
};

export default TrackUserActivity;
