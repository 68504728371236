import { memo } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography, Box, FormLabel } from "@mui/material";
import { fieldValueEqual, parseHTMLElements, extractFieldLabel } from "../../../utils";
import { appConstants, appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import PermissionPopover from "../../PermissionPopover";

const { formFieldVerticalMargin } = appConstants;

const {
  baseForm: { errorStyles, sharedStyles }
} = appStyles;

const CheckboxGroupField = ({ fieldInfo, value, setFieldValue, error = "" }: InteractiveFieldProps) => {
  const { options = [], style = {}, label, showLabel, maxHeight } = fieldInfo;
  const sxProps = { ...sharedStyles, marginTop: formFieldVerticalMargin, marginBottom: 0, ...style };
  const isDisabled = fieldInfo.disabled || fieldInfo.readOnly;
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  const sort = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <FormControl component="fieldset" style={sxProps} disabled={fieldInfo.disabled} key={fieldInfo.name}>
        {showLabel && (
          <Box mt={2} mb={2}>
            <FormLabel component="legend">
              {label}
              {fieldRequired ? <span style={{ color: "red" }}>*</span> : ""}
            </FormLabel>
          </Box>
        )}
        <Box sx={{ maxHeight: maxHeight || "200px", overflow: "auto" }}>
          <FormGroup>
            {options.sort(sort).map((option: any) => {
              return isDisabled ? (
                value.includes(option.name) ? (
                  <Typography component="div" key={option.name} mb={1}>
                    {fieldInfo.tooltip ? (
                      <div style={{ display: "flex" }}>
                        {extractFieldLabel(option.label, fieldInfo.componentLabel)}{" "}
                        <PermissionPopover permissionsList={fieldInfo.permissions} permissions={option.permissions} />
                      </div>
                    ) : (
                      extractFieldLabel(option.label, fieldInfo.componentLabel)
                    )}
                  </Typography>
                ) : null
              ) : (
                <FormControlLabel
                  aria-label={label}
                  disabled={isDisabled}
                  key={option.name}
                  control={
                    <Checkbox
                      disabled={fieldInfo.disabled || fieldInfo.readOnly}
                      color="primary"
                      checked={value.includes(option.name)}
                      onChange={(e) => {
                        let newValue = [];
                        if (e.target.checked) {
                          newValue = [...value, option.name];
                        } else {
                          newValue = value.filter((name) => name !== option.name);
                        }
                        setFieldValue(fieldInfo.name, newValue);
                      }}
                      inputProps={{ "aria-label": option.label + " checkbox" }}
                    />
                  }
                  label={
                    fieldInfo.tooltip ? (
                      <div style={{ display: "flex" }}>
                        {extractFieldLabel(option.label, fieldInfo.componentLabel)}{" "}
                        <PermissionPopover permissionsList={fieldInfo.permissions} permissions={option.permissions} />
                      </div>
                    ) : (
                      extractFieldLabel(option.label, fieldInfo.componentLabel)
                    )
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
      </FormControl>
      {error && <Typography style={errorStyles}>{parseHTMLElements(error)}</Typography>}
    </>
  );
};

export default memo(CheckboxGroupField, fieldValueEqual);
