import { BulkImportField } from "./types";
import { validations } from "../../utils";
import assets from "../../../ui/assets";

const {
  appStrings: {
    admin: {
      bulkImport: {
        item: { formFields }
      }
    }
  },
  appConstants: { gutterMargin }
} = assets;

const fields: { [key in BulkImportField]: any } = {
  description: {
    name: "description",
    label: formFields.description,
    width: "70%",
    variant: "outlined",
    validation: validations.text
  },
  type: {
    name: "type",
    label: formFields.importType,
    fieldType: "select",
    variant: "outlined",
    width: "70%",
    options: [{ name: formFields.options.consumers, label: formFields.options.consumers }],
    validation: validations.requiredText
  },
  upload: {
    name: "upload",
    label: formFields.filename,
    fieldType: "file-upload",
    variant: "outlined",
    width: "70%",
    accept: ".ndjson",
    validation: validations.requiredFile
  },
  file: {
    name: "file",
    label: formFields.filename,
    fieldType: "file-upload",
    variant: "outlined",
    disabled: true,
    width: "70%"
  },
  importStatus: {
    name: "importStatus",
    label: formFields.importStatus,
    value: formFields.importStatus,
    fieldType: "readonly_headertext",
    style: { marginTop: gutterMargin }
  },
  rowsProcessed: {
    name: "rowsProcessed",
    fieldType: "progress-bar",
    width: "70%"
  }
};

export default fields;
