import { configureStore } from "@reduxjs/toolkit";
import type { ToasterState } from "@midato-mono/common/src/hooks";
import { authSlice, toasterSlice, dimensionSlice, spinnerSlice } from "@midato-mono/common/src/reduxSlices";
import { commonStrings } from "@midato-mono/common/src/assets";
import { clearAllTokens, getUseAuthDataFromToken } from "@midato-mono/common/src/utils";
import { api } from "./api";
import wizardReducer from "./reducers/wizard";

const {
  appStrings: { loginErrorMessage }
} = commonStrings;

const { reducer: authReducer } = authSlice;
const { reducer: toasterReducer } = toasterSlice;
const { reducer: spinnerReducer } = spinnerSlice;
const { reducer: dimensionsReducer } = dimensionSlice;

const token = window.localStorage.getItem("midato:idToken");

const { permissions, auth } = getUseAuthDataFromToken(token || "");

const toaster: ToasterState =
  token && permissions?.length === 0
    ? { message: loginErrorMessage, messageType: "error" }
    : { message: null, messageType: null };

if (auth === undefined) {
  clearAllTokens();
}

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    spinner: spinnerReducer,
    toaster: toasterReducer,
    dimensions: dimensionsReducer,
    wizard: wizardReducer
  },
  // @ts-ignore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  preloadedState: {
    // @ts-ignore
    auth,
    toaster,
    dimensions: { width: window.innerWidth, height: window.innerHeight }
  }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
