/* eslint-disable */
import React, { FC } from "react";
import * as yup from "yup";
import { CreateActionParams, DecodedToken, FooterButtonMeta, CreateButtonParams } from "./types";
import { filterKeysBy, createTokenToAuthMapCallback } from "@midato-mono/common/src/utils";
import { Grid } from "@mui/material";
import ACTIONS from "./actions";
import assets from "../ui/assets";
import { useMidatoMutation } from "./hooks";
import { routes } from "../Router/constants";
import { format } from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js";

const ButtonBox = React.lazy(() => import("@midato-mono/common/src/components/ButtonBox"));

const {
  appStrings: {
    common: {
      defaultErrorStrings: {
        requiredError,
        invalidEmailError,
        invalidPhoneError,
        phoneMaxLengthError,
        phoneMinLengthError,
        textMinLengthError,
        textMaxLengthError,
        invalidZipError,
        dobInvalidError,
        invalidDateError,
        numberMax,
        numberMin,
        dateMin,
        phoneNumInvalidError,
        formExpiredNumInvalidError
      },
      regex: { isNumber, isValidZip, isValidExpressZip, isValidUsPhone, isValidPhone, isValidAnyPhone }
    },
    admin: {
      notifications: {
        createSuccess,
        createFailure,
        saveSuccess,
        saveFailure,
        deleteSuccess,
        deleteFailure,
        activateSuccess,
        activateFailure,
        deactivateSuccess,
        deactivateFailure,
        resetConsumerSuccess,
        resetConsumerError
      }
    }
  }
} = assets;

export const validateSchemaString = (formSchemaString?: string) => {
  if (!formSchemaString) {
    return false;
  }
  try {
    const schema = JSON.parse(formSchemaString);
    return "sections" in schema;
  } catch (e) {
    return false;
  }
};

export const checkIfUserScopesIsSufficient =
  <T extends { actionId?: keyof typeof ACTIONS }>(permissions: string[] | undefined) =>
  (obj: T) =>
    obj &&
    (!obj.actionId || ACTIONS[obj.actionId].requiredScopes.every((permission) => permissions?.includes(permission)));

export const validateLinkToItem = <T extends { actionId: keyof typeof ACTIONS }>(obj: T, fn: (obj: T) => boolean) =>
  filterKeysBy(obj, (obj) => {
    if ([obj].filter(fn).length > 0) {
      return () => true;
    } else {
      return (key) => key !== "link" && key !== "callback";
    }
  });

export const combineQueryStringAndFilterOption = ({
  queryString,
  field,
  selectedFilterOption
}:
  | {
      queryString: string;
      field?: undefined;
      selectedFilterOption?: undefined;
    }
  | { queryString: string; field: string; selectedFilterOption: string }) => {
  return queryString.trim() === "" && !selectedFilterOption
    ? "*"
    : `${queryString.trim()}${queryString.trim() && selectedFilterOption ? " AND " : ""}${
        selectedFilterOption ? `${field}:${selectedFilterOption}` : ""
      }`;
};

export const phoneAndCountryCodeCombiner = (countryCode: string, phoneNumber: string) => {
  return phoneNumber && countryCode + phoneNumber.replace(/\D/g, "");
};

export const getPhoneNumWithoutCountryCode = (countryCode: string, phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }
  return phoneNumber.trim() !== "" && phoneNumber.startsWith(`${countryCode}`) ? phoneNumber.substring(2) : phoneNumber;
};

export const decodedTokenKeyMap: DecodedToken = {
  given_name: "firstName",
  family_name: "lastName",
  org_id: "orgId",
  user_id: "userId",
  permissions: "permissions"
};

export const tokenToAuthMapCallback = createTokenToAuthMapCallback<DecodedToken>(decodedTokenKeyMap);

export const getValuesForSingleFilter = (filterString: string, filterName: string) => {
  let arr;
  try {
    arr = (
      filterString
        .split("AND")
        .map((a) => a.split("OR").map((a) => a.trim().replaceAll(/[\(|\)]/g, "")))
        .find((singleFilter: any[]) =>
          singleFilter.find((singleValue: string) => singleValue.includes(`${filterName}:`))
        ) ?? []
    ).map((filterString: string) => filterString.replace(`${filterName}:`, ""));
  } catch (error) {
    return [];
  }
  return arr;
};

export const prefixAndValueCombiner = (prefix: string, value: string) => {
  return value && `${prefix}${value}`;
};

export const getValueWithoutPrefix = (prefix: string, value: string) => {
  return value?.replace(prefix, "");
};

export const createOrganizationPageCrumbMeta = (
  organizationData: { orgId: string; name: string },
  navigate: Function,
  includeCurrentOrganization = false
) => {
  // @ts-ignore
  let rawAncestorOrganizations = [];
  if (organizationData) {
    rawAncestorOrganizations = includeCurrentOrganization
      ? [{ orgId: organizationData.orgId, name: organizationData.name }]
      : [];
  }
  // @ts-ignore
  const sortedAncestorOrganization = [...rawAncestorOrganizations].sort(
    (a: any, b: any) => a.orgId.length - b.orgId.length
  );
  return sortedAncestorOrganization.map(({ name, orgId }: any) => ({
    label: name,
    callback: () => {
      navigate(`${routes.ORGANIZATIONS}/${encodeURIComponent(orgId)}`);
    }
  }));
};

export const userCan = ({
  actionId,
  userPermissions
}: {
  actionId?: keyof typeof ACTIONS;
  userPermissions: string[];
}): boolean =>
  actionId === undefined
    ? true
    : ACTIONS[actionId].requiredScopes.every((permission) => userPermissions?.includes(permission));

const baseFieldRules = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\w\s\d.,/\-\'!?]*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

const baseFieldRulesSearch = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\w\s\d.,/\-\']*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

const baseFieldSearchRulesSearch = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\@\w\s\d.,/\-\']*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

// TODO move validation to common
export const validations = {
  date: yup.date().nullable().min("1/1/1900").typeError(invalidDateError),
  text: yup.string().nullable().concat(baseFieldRules),
  textMinMaxSearch: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldSearchRulesSearch)
      .nullable()
      .min(min || 0, textMinLengthError(min || 0))
      .max(max || 150, textMaxLengthError(max || 150)),
  textMinMax: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 0, textMinLengthError(min || 0))
      .max(max || 150, textMaxLengthError(max || 150)),
  textMinMaxCustom: (min: number, max: number, text: string) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 0, text)
      .max(max || 150, text),
  zip: yup.string().max(5).concat(baseFieldRules).nullable().matches(isValidZip, invalidZipError),
  file: yup.mixed(),
  array: yup.array(),
  expressZip: yup.string().concat(baseFieldRules).nullable().matches(isValidExpressZip, invalidZipError),
  number: yup.string().concat(baseFieldRules).matches(isNumber, formExpiredNumInvalidError),
  decimalValue: yup
    .number()
    .nullable()
    .typeError("Must be a number")
    .positive("Must be positive")
    .test("is-decimal", "Invalid decimal", (value: any) => {
      if (value) return /^\d+(\.\d{1,5})?$/.test(String(value));
      return true;
    }),
  requiredNumber: yup
    .string()
    .concat(baseFieldRules)
    .required(requiredError)
    .matches(isNumber, formExpiredNumInvalidError),
  requiredNumberMinMax: (min: number, max: number, text?: string) =>
    yup
      .number()
      .typeError(formExpiredNumInvalidError)
      .required(requiredError)
      .min(min || 1, text || numberMin(min || 1))
      .max(max || 9999999, text || numberMax(max || 9999999)),
  numberMinMax: (min: number, max: number, text?: string) =>
    yup
      .number()
      .typeError(formExpiredNumInvalidError)
      .min(min || 1, text || numberMin(min || 1))
      .max(max || 9999999, text || numberMax(max || 9999999)),
  numberMinMaxInteger: (min: number, max: number, text?: string) =>
    yup
      .string()
      .nullable()
      .matches(/^\d+$/, "Value must be an integer")
      .test("is-valid-number", text || "value should me more then", (value) => {
        if (!value) return true;
        const num = Number(value);
        return num >= min && num <= max;
      }),
  usPhone: yup
    .string()
    // .concat(baseFieldRules)
    .test("usPhone", phoneNumInvalidError, (value?: string) => {
      return value ? isValidPhoneNumber(value, "US") : true;
    }),
  usPhoneRequired: yup
    .string()
    .nullable()
    .required()
    // .concat(baseFieldRules)
    .test("usPhone", phoneNumInvalidError, (value?: string) => {
      return value ? isValidPhoneNumber(value, "US") : true;
    }),
  requiredUsPhone: yup
    .string()
    .concat(baseFieldRules)
    .required(requiredError)
    .matches(isValidUsPhone, invalidPhoneError),
  phone: yup
    .string()
    .nullable()
    .min(10, phoneMinLengthError(10))
    .max(16, phoneMaxLengthError(16))
    .matches(isValidAnyPhone, phoneNumInvalidError),
  requiredPhone: yup
    .string()
    .nullable()
    .min(10, phoneMinLengthError(10))
    .max(16, phoneMaxLengthError(16))
    .matches(isValidAnyPhone, phoneNumInvalidError)
    .required(requiredError),
  requiredText: yup.string().concat(baseFieldRules).nullable().required(requiredError),
  requiredTextMinMax: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 1, textMinLengthError(min || 1))
      .max(max || 150, textMaxLengthError(max || 150))
      .required(requiredError),
  requiredDob: yup
    .date()
    .nullable()
    .min("1/1/1900")
    .max(format(new Date(), "MM/dd/yyyy"))
    .required(requiredError)
    .typeError(dobInvalidError),
  dob: yup.date().nullable().min("1/1/1900").max(format(new Date(), "MM/dd/yyyy")).typeError(dobInvalidError),
  requiredEmail: yup
    .string()
    .max(65, textMaxLengthError(65))
    .required(requiredError)
    .email(invalidEmailError)
    .matches(/^[^"']*$/, "Field cannot contain quotes")
    .matches(/^[ -~]+$/, "Field cannot contain unicode"),
  email: yup
    .string()
    .max(65, textMaxLengthError(65))
    .email(invalidEmailError)
    .matches(/^[^"']*$/, "Field cannot contain quotes")
    .matches(/^[ -~]+$/, "Field cannot contain unicode"),
  nonEmptyArray: yup.array().min(1).required(),
  requiredFile: yup.mixed().required(),
  validateSchema: yup.string().required().test("isValidSchema", "Not a valid schema", validateSchemaString),
  baseFieldRules: yup
    .string()
    .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
    .matches(/^[\w\s\d.,!?]*$/, "Field can only contain letters, numbers, spaces, and punctuation")
    .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
      const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
      return !sqlKeywords.some((keyword) => value?.includes(keyword));
    })
};

export const getFormVersionWithoutLang = (value: string) => value.split("@")[0];

export const rowsNotPass = (formik: any, rows: any[]) =>
  !(
    formik?.dirty &&
    Object.keys(formik?.errors ?? []).filter((fieldName) => rows.flat(2).find((field) => field.name === fieldName))
      .length === 0
  );

export const disableFields = (f: {} | {}[]) => {
  if (Array.isArray(f)) {
    return f.map((field) => ({ ...field, disabled: true }));
  } else {
    return { ...f, disabled: true };
  }
};

export const getPostAmble = (footerMeta: FooterButtonMeta[]) =>
  footerMeta.length ? <ButtonBox buttonsMeta={footerMeta} /> : undefined;

export const getPreAmble = ({ PreAmble, props }: { PreAmble: FC | undefined; props: any }) =>
  PreAmble && <PreAmble {...props} />;

export const getReactElement = ({ Component, props }: { Component: FC; props: {} }) =>
  Component && <Component {...props} />;

export const getReactButtonsElement = ({ Component, props }: { Component: FC; props: { buttonsMeta?: [] } }) => {
  const { mainBlock, secondaryBlock } = props?.buttonsMeta
    ? props.buttonsMeta.reduce(
        (acc, prop) => {
          // @ts-ignore
          if (prop.separateButtonBlock) acc.secondaryBlock.push(prop);
          else acc.mainBlock.push(prop);
          return acc;
        },
        { mainBlock: [], secondaryBlock: [] }
      )
    : { mainBlock: [], secondaryBlock: [] };

  if (!Component) return null;

  return (
    <Grid container direction="row" justifyContent="space-between">
      {mainBlock.length ? (
        <Grid item>
          {/* @ts-ignore */}
          <Component {...{ buttonsMeta: mainBlock }} />
        </Grid>
      ) : null}
      {secondaryBlock.length ? (
        <Grid item>
          {/* @ts-ignore */}
          <Component {...{ buttonsMeta: secondaryBlock }} />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const createAction = (options: CreateActionParams) => {
  const {
    mutationFn,
    toaster: { displayAnErrorMessage, displayAnNotificationMessage },
    hideNotification = false
  } = options;
  const notifications = {
    success: {
      add: createSuccess,
      update: saveSuccess,
      delete: deleteSuccess,
      active: activateSuccess,
      deactive: deactivateSuccess,
      deactivate: deactivateSuccess,
      resetConsumer: resetConsumerSuccess
    },
    failure: {
      add: createFailure,
      update: saveFailure,
      delete: deleteFailure,
      active: activateFailure,
      deactive: deactivateFailure,
      deactivate: deactivateFailure,
      resetConsumer: resetConsumerError
    }
  };
  const { triggerFn } = useMidatoMutation({
    mutationFn,
    isSuccessCallback: async (data) => {
      if ("successHandler" in options) {
        if (typeof options.successHandler === "function") {
          await options.successHandler(data);
        }
        if (!hideNotification && typeof options.successHandler === "string") {
          displayAnNotificationMessage(options.successHandler);
        }
      }
      if (!hideNotification && "type" in options) {
        displayAnNotificationMessage(notifications.success?.[options.type] ?? "Success");
      }
    },
    isErrorCallback: async (error) => {
      if ("errorHandler" in options) {
        if (typeof options.errorHandler === "function") {
          await options.errorHandler(error);
        }
        if (!hideNotification && typeof options.errorHandler === "string") {
          displayAnErrorMessage(options.errorHandler);
        }
      }

      if (!hideNotification && "type" in options && !(error?.errorData?.extensions?.code === "CONFLICT")) {
        if (options?.type === "custom") {
          const errorMessage = error?.errorData?.message;
          displayAnErrorMessage(errorMessage ?? "Error");
        } else {
          displayAnErrorMessage(notifications.failure?.[options.type] ?? "Error");
        }
      }
    }
  });
  return triggerFn;
};

export const formIsUntouchedOrHasError = (formik: any) => formik && !formik?.dirty;
// (formik && !formik?.dirty) || Object.keys(formik?.errors ?? []).length > 0;

export const createButton = (options: CreateButtonParams) => {
  const {
    actionId,
    onPress,
    text,
    id,
    disabled = false,
    color = "primary",
    style = {},
    variant = "contained",
    hidden = false,
    fullWidth = false,
    separateButtonBlock = false
  } = options;
  return {
    actionId,
    onPress,
    text,
    id,
    disabled,
    color,
    style,
    variant,
    hidden,
    fullWidth,
    separateButtonBlock
  };
};

export const createButtonBoxPostAmble = ({
  buttonsMeta,
  permissions
}: {
  buttonsMeta: FooterButtonMeta[] | { [key: string]: FooterButtonMeta[] };
  permissions: string[];
}) => {
  if (Array.isArray(buttonsMeta)) {
    return {
      PostAmble: ButtonBox,
      props: {
        buttonsMeta: buttonsMeta.filter(({ actionId }) => userCan({ actionId, userPermissions: permissions }))
      }
    };
  } else {
    return Object.keys(buttonsMeta).reduce((acc, key) => {
      // @ts-ignore
      acc[key] = {
        PostAmble: ButtonBox,
        props: {
          buttonsMeta: buttonsMeta[key].filter(({ actionId }) => userCan({ actionId, userPermissions: permissions }))
        }
      };
      return acc;
    }, {});
  }
};

export const getLogUserEventData = (data: { [key: string]: any }) => {
  const selectedOrgId = window.localStorage.getItem("midato:selectedOrganization");
  return {
    browserType: navigator.userAgent,
    route: window.location.href,
    ...(selectedOrgId ? { ownerOrgId: selectedOrgId } : {}),
    ...{ ...data, params: data?.params ? JSON.stringify(data?.params) : "" }
  };
};

export const debounce = (fn: Function, delay: number) => {
  let timerId: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};
