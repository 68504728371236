import type { ReadonlyFieldProps } from "../../../types";
import { appStyles } from "../../../assets";
import assets from "@midato-mono/admin-ui/src/ui/assets";

const {
  appColors: { grayText, formText }
} = assets;

const ReadonlyTextFieldValueLabel = ({ fieldInfo, value }: ReadonlyFieldProps) => {
  return (
    <>
      <div style={{ ...sharedStyles, color: grayText }}>{fieldInfo.label}</div>
      <div style={{ ...sharedStyles, marginTop: "4px", color: formText }}>{value || ""}</div>
    </>
  );
};

export default ReadonlyTextFieldValueLabel;

// style
const {
  HTMLStyles,
  baseForm: { sharedStyles }
} = appStyles;
