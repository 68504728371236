import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export interface DimensionsState {
  height: number;
  width: number;
}

const initialState: DimensionsState = { width: 800, height: 600 };

const _dimensionsSlice: Slice<DimensionsState> = createSlice({
  name: "dimensions",
  initialState,
  reducers: {
    setDimensions: (state, { payload: { width, height } }: PayloadAction<DimensionsState>) => {
      return { ...state, width, height };
    }
  }
});

export const { setDimensions } = _dimensionsSlice.actions;
export const selectCurrentDimensions = <T extends { dimensions: DimensionsState }>(state: T) => state.dimensions;
const dimensionSlice = { ..._dimensionsSlice, setDimensions, selectCurrentDimensions };

export default dimensionSlice;
