import { useDispatch, useSelector } from "react-redux";
import { setToasterMessage, selectCurrentToaster } from "../reduxSlices/toaster";

export interface ToasterState {
  message: string | null;
  messageType: "error" | "notification" | null;
}

const useToasterCreator = () => {
  const toasterState = useSelector<{ toaster: ToasterState }, ToasterState>(selectCurrentToaster);

  const dispatch = useDispatch();

  const handleResetModalMessage = () => {
    dispatch(setToasterMessage({ message: "", messageType: null }));
    return {};
  };

  const displayAnErrorMessage = (message: string) => {
    dispatch(setToasterMessage({ message, messageType: "error" }));
  };

  const displayAnNotificationMessage = (message: string) => {
    dispatch(setToasterMessage({ message, messageType: "notification" }));
  };

  return {
    toasterState,
    handleResetModalMessage,
    displayAnErrorMessage,
    displayAnNotificationMessage
  };
};

export default useToasterCreator;
