import { memo } from "react";
import * as React from "react";
import { Box, TextField } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fieldValueEqual, formattedDate, getEarliestBirthDate, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  baseForm: { sharedStyles, asteriskStyles, formLabeErrorStyles },
  datePickerPopperSx
} = appStyles;

const DobField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  const sxProps = {
    ...sharedStyles,
    marginBottom: 0,
    ...(fieldInfo?.style ?? {})
  };

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={formattedDate(value)} />;
  } else {
    return (
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <div style={{ width: "98%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={error ? formLabeErrorStyles : {}}>
              <DatePicker
                disabled={isDisabledField}
                label={fieldInfo.label}
                value={value ? value : null}
                inputFormat="MM/dd/yyyy"
                PopperProps={{ sx: datePickerPopperSx }}
                onChange={(date: any) => {
                  setFieldValue(fieldInfo.name, date);
                  // try {
                  //   setFieldValue(fieldInfo.name, format(date, "yyyy-MM-dd"));
                  // } catch (err) {
                  //   setFieldValue(fieldInfo.name, date);
                  // }
                }}
                minDate={getEarliestBirthDate()}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "date of birth, datepicker",
                      "aria-required": fieldRequired,
                      "aria-invalid": Boolean(error),
                      type: "text"
                      // role: "combobox"
                    }}
                    variant={fieldInfo.variant ?? "standard"}
                    name={fieldInfo.name + (fieldInfo.formId || "")}
                    error={Boolean(error)}
                    helperText={error}
                    required={fieldRequired}
                    InputLabelProps={{
                      shrink: true,
                      sx: asteriskStyles,
                      htmlFor: fieldInfo.name + (fieldInfo.formId || "")
                    }}
                    id={fieldInfo.name + (fieldInfo.formId || "")}
                    sx={sxProps}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default memo(DobField, fieldValueEqual);
