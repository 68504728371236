import { PractitionerItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import { createButton, createButtonBoxPostAmble, createAction, formIsUntouchedOrHasError } from "../../utils";
import {
  useAddNewPractitionerMutation,
  useDeletePractitionerMutation,
  useFetchPractitionerQuery,
  useUpdatePractitionerMutation
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { userCan } from "../../utils";

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, deactivate, cancel, edit },
        prompts: { deleteConfirmation }
      }
    },
    admin: {
      practitioner: { item }
    }
  }
} = assets;

// new
type NC = PractitionerItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useAddNewPractitionerMutation, type: "add" })
});

export const createNewEntityName: NC["createEntityName"] = ({
  formik: {
    values: { firstName, lastName }
  }
}) => (firstName || lastName ? `${firstName} ${lastName}` : "New");

export const createNewRows: NC["createRows"] = () => [
  [fields.firstName],
  [fields.middleName],
  [fields.lastName],
  [fields.email],
  [fields.dea],
  [fields.npi]
];

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  permissions,
  closeWizard
}) => {
  const values = formik.values;
  const params = {
    provider: {
      firstName: values?.firstName ?? "",
      middleName: values?.middleName ?? "",
      lastName: values?.lastName ?? "",
      email: values?.email ?? "",
      dea: values?.dea ?? "",
      npi: values?.npi ?? ""
    }
  };

  const saveButton = createButton({
    actionId: "PRACTITIONER_ADD",
    id: "btn-save",
    text: save,
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const practitionerId = await add!(params).unwrap();
        closeWizard();
        navigate(`${routes.PRACTITIONERS}/${practitionerId}`, { replace: true });
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });
  const cancelButton = createButton({
    actionId: "PRACTITIONER_ADD",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton]
  });
};

// detail
type DC = PractitionerItemPageConfiguration["detail"];

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({ toaster, mutationFn: useUpdatePractitionerMutation, type: "update" }),
  delete: createAction({ toaster, mutationFn: useDeletePractitionerMutation, type: "delete" })
});

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: providerId } }) => {
  return useMidatoQuery({
    queryFn: useFetchPractitionerQuery,
    queryArgs: {
      providerId
    },
    queryOptions: { skip: !providerId }
  });
};

export const createDetailDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData: practitioner }) =>
  practitioner?.firstName && practitioner?.lastName
    ? `${practitioner.firstName} ${practitioner.lastName}`
    : item.detailPageHeader;

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData: practitioner }) =>
  `${practitioner?.firstName || ""} ${practitioner?.lastName || ""}`;

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData: practitioner }) => ({
  firstName: practitioner?.firstName ?? "",
  middleName: practitioner?.middleName ?? "",
  lastName: practitioner?.lastName ?? "",
  email: practitioner?.email ?? "",
  dea: practitioner?.dea ?? "",
  npi: practitioner?.npi ?? ""
});

export const createDetailRows: DC["createRows"] = () => [
  [fields.firstName],
  [fields.middleName],
  [fields.lastName],
  [fields.email],
  [fields.dea],
  [fields.npi]
];

export const createDetailPostAmble: DC["createPostAmble"] = ({
  params: { id: providerId },
  formik,
  actions: { update, delete: deletFn },
  navigate,
  permissions,
  closeWizard
}) => {
  const values = formik.values;
  const params = {
    providerId,
    provider: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      dea: values.dea,
      npi: values.npi
    }
  };

  const saveButton = createButton({
    actionId: "PRACTITIONER_UPDATE",
    id: "btn-save",
    text: save,
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        await update!(params).unwrap();
        navigate(`${routes.PRACTITIONERS}/${providerId}`);
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });

  const deleteButton = createButton({
    id: "btn-delete",
    text: deactivate,
    color: "error",
    onPress: async () => {
      const sure = window.confirm(deleteConfirmation);
      if (sure) {
        await deletFn!({ providerId });
        closeWizard();
        navigate(routes.PRACTITIONERS, { replace: true });
      }
    },
    variant: "outlined",
    actionId: "PRACTITIONER_DELETE",
    separateButtonBlock: true
  });

  const cancelButton = createButton({
    actionId: "PRACTITIONER_UPDATE",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton, deleteButton]
  });
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({ permissions, wizardFunctions }) => [
  {
    allowed: userCan({ actionId: "PRACTITIONER_UPDATE", userPermissions: permissions }),
    color: "primary",
    title: edit,
    onClick: () => {
      wizardFunctions?.launchEditPractitionerWizard();
    }
  }
];
