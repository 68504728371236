const cmsUserFields = `
fragment cmsUserFields on User {
  userId
  username
  sub
  firstName
  lastName
  email
  phoneNumber
  createdAt
  lastModified
  orgId,
  orgIds,
  isExternalAuthentication,
  isPolicyAccepted,
  organizations {
        name,
        orgId
    },
  rolePermissions {
    role,
    permissions
  },
  isExternalAuthentication,
  externalAuthenticationName,
  externalId,
}`;

const userActivityLogFields = `
  fragment userActivityLogFields on UserActivityLog {
       type
        params
        ipAddress
        browserType
        route
        consumer {
            orgId
            email
            firstName
            lastName
            active
            externalIds {
                organization {
                    name
                }
                externalId
                orgId
            }
        }
        user {
            email
            firstName
            lastName
            active
        }
        owner {
            email
            firstName
            lastName
            active
        }
        businessUserId
        consumerId
        timestamp
}`;

const addressFields = `
fragment addressFields on Address {
  address1
  address2
  city
  state
  zip
  expressZip
}`;

const consentFormFields = `
fragment consentFormFields on ConsentForm {
  formId
  code
  version
  title
  description
  schema
  expiryDays
  createdAt
  signedAt
}`;

const cmsConsentFormFields = `
fragment cmsConsentFormFields on ConsentForm {
  formId
  orgId
  code
  version
  lang
  title
  description
  schema
  formUrl
  expiryDays
  templates
  createdAt
  status
  generalDesignation
  tpo
  ocrEnabled
}`;

const webhookHeadersSettingsFields = `
fragment webhookHeadersSettingsFields on WebhookHeaderSettings {
  authorization
  topicName
  consumerId
  consentId
  webhookId
}`;

const webhookTopicSettingsFields = `
fragment webhookTopicSettingsFields on WebhookTopicSettings {
  subscribed
  overrideUrl
  headers { ...webhookHeadersSettingsFields }
}
`;

const webhookSettingsFields = `
fragment webhookSettingsFields on WebhookSettings {
  baseUrl
  CONSENT_ACTIVATED { ...webhookTopicSettingsFields }
  CONSENT_REVOKED { ...webhookTopicSettingsFields }
}`;

const organizationSettingsFields = `
fragment organizationSettingsFields on OrganizationSettings {
  webhooks { ...webhookSettingsFields }
  PATIENT_INVITE_SMS_TEMPLATE
}
`;

const organizationFields = `
fragment organizationFields on Organization {
  orgId
  parentOrgId
  name
  npi
  activeECMUser
  phoneNumber
  email
  fax
  allowRevocation
  reviewAfterDays
  allowGeneralDesignationConsents
  reviewNotificationLeadDays
  parentsSummary {
    name
  }
  address { ...addressFields }
  createdAt
  lastModified
  active
}`;

const providerFields = `
fragment providerFields on Provider {
  providerId
  orgId
  firstName
  middleName
  lastName
  email
  npi
  dea
  createdAt
  lastModified
  status
}`;

const consumerFields = `
fragment consumerFields on Consumer {
  consumerId
  orgId
  firstName
  middleName
  lastName
  suffixName
  medicaidId
  gender
  ssn
  dob
  phoneNumber
  landlineNumber
  email
  address { ...addressFields }
  createdAt
  lastModified
  externalIds {
    orgId,
    externalId
  }
  organization {
    name
  }
}`;

const consumerProfileFields = `
fragment consumerProfileFields on Consumer {
  patientId
  orgId
  firstName
  middleName
  lastName
  suffixName
  medicaidId
  gender
  ssn
  dob
  phoneNumber
  landlineNumber
  email
  address { ...addressFields }
  createdAt
  lastModified
}`;

const patientOrganizationFields = `
fragment patientOrganizationFields on Organization{
  name
  phoneNumber
  address { ...addressFields }
}`;

const patientProviderFields = `
fragment patientProviderFields on Provider {
  firstName
  middleName
  lastName
}`;

const patientConsentFields = `
fragment patientConsentFields on Consent {
  consentId
  form { ...consentFormFields }
  data
  downloadUrl
  disclosingOrganizations { ...patientOrganizationFields }
  recipientOrganizations { ...patientOrganizationFields }
  requestorProvider { ...patientProviderFields }
  recipientProvider { ...patientProviderFields }
  status
  createdAt
  lastModified
  expireAt
  startAt
  signedAt
}`;

const cmsOcrResultsSummaryFields = `
fragment cmsOcrResultsSummaryFields on OcrResultsSummary {
        ocrId
        createdAt
        updatedAt
        status
        consentId
        form {
            title
            code
            version
            formId
        }
        consumer {
            firstName
            lastName
            consumerId
        }
        organization {
            name
            orgId
        }
}`;

const providerConsentFields = `
fragment providerConsentFields on Consent {
  consentId
  consumer { ...consumerFields }
  form { ...consentFormFields }
  data
  requestorProvider { ...patientProviderFields }
  disclosingOrganizations { ...patientOrganizationFields }
  recipientOrganizations { ...patientOrganizationFields }
  status
  statusReason
  createdAt
  lastModified
  expireAt
  startAt
  signedAt
}`;

const cmsConsumerConsentFields = `
fragment cmsConsumerConsentFields on Consent {
  consentId
  consumer { ...consumerFields }
  form { ...consentFormFields }
  data
  downloadUrl
  disclosingOrganizations { ...patientOrganizationFields }
  recipientOrganizations { ...patientOrganizationFields }
  status
  createdAt
  lastModified
  expireAt
  startAt
  signedAt
}`;

const cmsBulkExportFields = `
fragment cmsBulkExportFields on BulkExport {
  orgId
  bulkActionId
  type
  filter
  format
  rowsProcessed
  exportUrl
  state
  createdAt
  lastModified
  startDate
  endDate
  totalRows
  description
}`;

const cmsBulkImportFields = `
fragment cmsBulkImportFields on BulkImport {
  orgId
  bulkActionId
  type
  format
  rowsProcessed
  scannedBytes
  totalBytes
  state
  createdAt
  lastModified
  fileName
  importUrl
  totalRows
  errorFile
  description
}`;

const cmsConsentFormSummaryFields = `fragment cmsConsentFormSummaryFields on ConsentFormSummary {
  code
  version
  title
  description
  formCreatedAt: createdAt
  formStatus: status
  formId
}`;

const cmsConsumerSummaryFields = `fragment cmsConsumerSummaryFields on ConsumerSummary {
  consumerId
  consumerFirstName: firstName
  consumerLastName: lastName
  consumerPhoneNumber: phoneNumber
  consumerEmail: email
  consumerCreatedAt: createdAt,
  consumerAge: age,
  consumerDob: dob,
  consumerGender: gender
}`;

const cmsConsentSummaryFields = `fragment cmsConsentSummaryFields on ConsentSummary {
  consentId
  consumer {
    consumerId
    firstName
    lastName
  }
  form {
    title
    code
    version
  }
  disclosingOrganizations {
    name
  }
  recipientOrganizations {
    name
  }
  startAt
  signedAt
  consentCreatedAt: createdAt
  consentStatus: status
  consentExpireAt: expireAt
  bidirectionalSharing,
  tpo
}`;

const cmsUserSummaryFields = `fragment cmsUserSummaryFields on UserSummary {
  userId
  userFirstName: firstName
  userLastName: lastName
  userEmail: email
  userPhoneNumber: phoneNumber
  userCreatedAt: createdAt
  userOrganization: organizations {
    name
  }
  userActive: active
}`;

const cmsUserRoleSummaryFields = `fragment cmsUserRoleSummaryFields on UserRoleSummary {
  userRoleId
  name
  permissions
  organization {name}
  active
  externalID
}`;

const cmsOrganizationSummaryFields = `fragment cmsOrganizationSummaryFields on OrganizationSummary {
  orgId
  name
  address { ...addressFields }
  organizationPhoneNumber: phoneNumber
  organizationEmail: email
  active
  organizationCreatedAt: createdAt
}`;

const cmsPractitionerSummaryFields = `fragment cmsPractitionerSummaryFields on PractitionerSummary {
  providerId
  dea
  npi
  practitionerFirstName: firstName
  practitionerLastName: lastName
  practitionerEmail: email
  practitionerCreatedAt: createdAt
  practitionerStatus: status
}`;

const cmsBulkImportSummaryFields = `fragment cmsBulkImportSummaryFields on BulkImportSummary {
  bulkImportId: bulkActionId
  bulkImportAction: action
  bulkImportType: type
  fileName
  importUrl
  bulkImportState: state
  bulkImportCreatedAt: createdAt
  bulkImportDescription: description
}`;

const cmsBulkExportSummaryFields = `fragment cmsBulkExportSummaryFields on BulkExportSummary {
  bulkExportId: bulkActionId
  bulkExportAction: action
  bulkExportType: type
  exportUrl
  startDate
  endDate
  bulkExportState: state
  bulkExportCreatedAt: createdAt
  bulkExportDescription: description
}`;

const fragments = [
  { name: "cmsUserFields", fragment: cmsUserFields },
  { name: "cmsOcrResultsSummaryFields", fragment: cmsOcrResultsSummaryFields },
  { name: "userActivityLogFields", fragment: userActivityLogFields },
  { name: "cmsUserRoleSummaryFields", fragment: cmsUserRoleSummaryFields },
  { name: "addressFields", fragment: addressFields },
  { name: "consentFormFields", fragment: consentFormFields },
  { name: "cmsConsentFormFields", fragment: cmsConsentFormFields },
  { name: "providerFields", fragment: providerFields },
  { name: "consumerFields", fragment: consumerFields },
  { name: "consumerProfileFields", fragment: consumerProfileFields },
  { name: "patientOrganizationFields", fragment: patientOrganizationFields },
  { name: "patientProviderFields", fragment: patientProviderFields },
  { name: "patientConsentFields", fragment: patientConsentFields },
  { name: "providerConsentFields", fragment: providerConsentFields },
  { name: "cmsConsumerConsentFields", fragment: cmsConsumerConsentFields },
  { name: "webhookHeadersSettingsFields", fragment: webhookHeadersSettingsFields },
  { name: "webhookTopicSettingsFields", fragment: webhookTopicSettingsFields },
  { name: "webhookSettingsFields", fragment: webhookSettingsFields },
  { name: "organizationSettingsFields", fragment: organizationSettingsFields },
  { name: "organizationFields", fragment: organizationFields },
  { name: "cmsBulkExportFields", fragment: cmsBulkExportFields },
  { name: "cmsBulkImportFields", fragment: cmsBulkImportFields },
  { name: "cmsConsentFormSummaryFields", fragment: cmsConsentFormSummaryFields },
  { name: "cmsConsumerSummaryFields", fragment: cmsConsumerSummaryFields },
  { name: "cmsConsentSummaryFields", fragment: cmsConsentSummaryFields },
  { name: "cmsUserSummaryFields", fragment: cmsUserSummaryFields },
  { name: "cmsOrganizationSummaryFields", fragment: cmsOrganizationSummaryFields },
  { name: "cmsPractitionerSummaryFields", fragment: cmsPractitionerSummaryFields },
  { name: "cmsBulkImportSummaryFields", fragment: cmsBulkImportSummaryFields },
  { name: "cmsBulkExportSummaryFields", fragment: cmsBulkExportSummaryFields }
];

function buildFragments(query) {
  return buildFragmentsList(query, []).join("\n");
}

function buildFragmentsList(query, collectedFragments) {
  return fragments.reduceRight((acc, { name, fragment }) => {
    if (!acc.includes(fragment) && query.search(name) > 0) {
      acc.push(fragment);
      return buildFragmentsList(fragment, acc);
    } else {
      return acc;
    }
  }, collectedFragments);
}

export default buildFragments;
