import * as service from "./midatoService";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  SignInConfig,
  GetUserConfig,
  ChangePasswordForgotConfig,
  QueryConfig,
  UpdateUserAttributesConfig,
  ForgotPasswordConfig,
  ConfirmForgotPasswordConfig,
  ChangePassowrdConfig,
  GetOcrResultPreSignedUrlConfig,
  SignOutConfig,
  ForgotPasswordRequestConfig,
  UpdateConsentWithOcrConfig,
  CompleteNewPasswordConfig,
  GetUsageReportConfig,
  GetConsentConfig,
  CheckConsentForDuplicatesConfig,
  FetchConsentHistoryConfig,
  GetConsentImageOrPdfConfig,
  FetchConsentsListConfig,
  AddPageLoadTimeConfig,
  FetchConsumerConfig,
  FetchOrganizationConfig,
  FetchOrganizationListConfig,
  FetchRootOrganizationConfig,
  FetchUserConfig,
  AddNewConsumerConfig,
  AddNewOrganizationConfig,
  AddNewUserConfig,
  UpdateConsumerConfig,
  GetLoadTimeSummaryReportConfig,
  UpdateOrganizationConfig,
  UpdateOrganizationSettingsConfig,
  UpdateUserConfig,
  DeleteConsentFormConfig,
  DeleteConsumerConfig,
  ActivateOrganizationsConfig,
  DeleteOrganizationByIdConfig,
  DeleteUserConfig,
  DeactivateUserConfig,
  UploadFileConfig,
  GetUploadUrlConfig,
  GetConsentFormConfig,
  GetFormFamilyConfig,
  GetConsentFormPreviewConfig,
  ConsentFormConfig,
  PublishConsentFormConfig,
  DeprecateConsentFormConfig,
  RequestConsentFormConfig,
  RequestConsentFormToGuestConfig,
  FetchUserListByOrgIdConfig,
  ResetConsumerConfig,
  AddNewPractitionerConfig,
  FetchPractitionerConfig,
  UpdatePractitionerConfig,
  DeletePractitionerConfig,
  GetBulkExportConfig,
  CreateBulkExport,
  GetBulkImportConfig,
  CreateBulkImport,
  SearchItemsConfig,
  CreateManualConsentConfig,
  FetchSecurityRoleConfig,
  AddNewSecurityRoleConfig,
  UpdateSecurityRoleConfig,
  DeleteSecurityRoleConfig,
  LogUserEventConfig,
  GetUserActivityLogsConfig,
  UploadConsentConfig,
  GetConsentPreSignedUrlConfig,
  DeactivateConsentConfig,
  UpdateConsentConfig,
  MarkConsentRevokedConfig,
  GetConsentStatisticsReportConfig,
  UpdateProfileConfig,
  UpdatePasswordConfig,
  GetConsentReportConfig,
  GetConsentExpiryReportConfig,
  GetOrganizationsConfig,
  GetCurrentUserOrganizationConfig,
  GetOcrConsentConfig,
  GetOcrDownloadUrlConfig,
  UpdateOcrResultsConfig,
  GetAuthTokenConfig,
  GetAuthTokenForUserConfig,
  AddConsentHistoryConfig,
  GetConsentHistoryConfig,
  GetLoadTimeReportConfig,
  VerifySignInConfig,
  ChangePasswordSignInConfig
} from "./midatoService";

export const api = createApi({
  tagTypes: [
    "Consent",
    "ConsentHistory",
    "ConsentForm",
    "Form",
    "User",
    "Consumer",
    "Organization",
    "Practitioner",
    "BulkExport",
    "BulkImport",
    "UserRole",
    "OcrResults"
  ],
  baseQuery: (args: { method: string; config: object }) => {
    return (service as any)[args.method](args.config);
  },
  endpoints: (builder) => ({
    signIn: builder.mutation<any, QueryConfig<SignInConfig>>({
      query: (config) => ({ method: "signIn", config })
    }),
    getCurrentUser: builder.mutation<any, QueryConfig<GetUserConfig>>({
      query: (config) => ({ method: "getCurrentUser", config })
    }),
    updateUserAttributes: builder.mutation<any, QueryConfig<UpdateUserAttributesConfig>>({
      query: (config) => ({ method: "updateUserAttributes", config })
    }),
    forgotPassword: builder.mutation<any, QueryConfig<ForgotPasswordConfig>>({
      query: (config) => ({ method: "forgotPassword", config })
    }),
    confirmForgotPassword: builder.mutation<any, QueryConfig<ConfirmForgotPasswordConfig>>({
      query: (config) => ({ method: "confirmForgotPassword", config })
    }),
    completeNewPassword: builder.mutation<any, QueryConfig<CompleteNewPasswordConfig>>({
      query: (config) => ({ method: "completeNewPassword", config })
    }),
    changePassword: builder.mutation<any, QueryConfig<ChangePassowrdConfig>>({
      query: (config) => ({ method: "changePassword", config })
    }),
    signOut: builder.mutation<any, QueryConfig<SignOutConfig>>({
      query: (config) => ({ method: "signOut", config })
    }),
    fetchOrganizationList: builder.query<any, QueryConfig<FetchOrganizationListConfig>>({
      query: (config) => ({ method: "fetchOrganizationList", config }),
      providesTags: () => [{ type: "Organization", id: "LIST" }]
    }),
    fetchCurrentUserOrganizations: builder.query<any, QueryConfig<null>>({
      query: (config) => ({ method: "fetchCurrentUserOrganizations", config })
    }),
    getOrganizations: builder.query<any, QueryConfig<GetOrganizationsConfig>>({
      query: (config) => ({ method: "getOrganizations", config })
    }),
    getCurrentUserOrganization: builder.query<any, QueryConfig<GetCurrentUserOrganizationConfig>>({
      query: (config) => ({ method: "getCurrentUserOrganization", config })
    }),
    fetchRootOrganization: builder.query<any, QueryConfig<FetchRootOrganizationConfig>>({
      query: (config) => ({ method: "fetchRootOrganization", config }),
      providesTags: (result) => [{ type: "Organization", id: result?.orgId }]
    }),
    addNewOrganization: builder.mutation<any, QueryConfig<AddNewOrganizationConfig>>({
      query: (config) => ({ method: "addNewOrganization", config }),
      invalidatesTags: (result) => [
        { type: "Organization", id: result?.orgId },
        { type: "Organization", id: "LIST" }
      ]
    }),
    updateOrganization: builder.mutation<any, QueryConfig<UpdateOrganizationConfig>>({
      query: (config) => ({ method: "updateOrganization", config }),
      invalidatesTags: (result) => [
        { type: "Organization", id: result?.orgId },
        { type: "Organization", id: "LIST" }
      ]
    }),
    deleteOrganizationById: builder.mutation<any, QueryConfig<DeleteOrganizationByIdConfig>>({
      query: (config) => ({ method: "deleteOrganizationById", config }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "Organization", id: arg.variables.orgId },
        { type: "Organization", id: "LIST" }
      ]
    }),
    updateOrganizationSettings: builder.mutation<any, QueryConfig<UpdateOrganizationSettingsConfig>>({
      query: (config) => ({ method: "updateOrganizationSettings", config }),
      invalidatesTags: () => [{ type: "Organization", id: "Settings" }]
    }),
    activateOrganizations: builder.mutation<any, QueryConfig<ActivateOrganizationsConfig>>({
      query: (config) => ({ method: "activateOrganizations", config }),
      invalidatesTags: (_result, _error, arg) => [
        ...arg.variables.orgIds.map<{ type: "Organization"; id: string }>((orgId) => ({
          type: "Organization",
          id: orgId
        })),
        { type: "Organization", id: "LIST" }
      ]
    }),
    fetchOrganization: builder.query<any, QueryConfig<FetchOrganizationConfig>>({
      query: (config) => ({ method: "fetchOrganization", config }),
      providesTags: (result) => [
        { type: "Organization", id: result?.orgId },
        { type: "Organization", id: "Settings" }
      ]
    }),
    fetchConsentsList: builder.query<any, QueryConfig<FetchConsentsListConfig>>({
      query: (config) => ({ method: "fetchConsentsList", config })
    }),
    fetchConsentHistory: builder.query<any, QueryConfig<FetchConsentHistoryConfig>>({
      query: (config) => ({ method: "fetchConsentHistory", config })
    }),
    getConsentImageOrPdf: builder.query<any, QueryConfig<GetConsentImageOrPdfConfig>>({
      query: (config) => ({ method: "getConsentImageOrPdf", config })
    }),
    uploadFile: builder.mutation<any, QueryConfig<UploadFileConfig>>({
      query: (config) => ({ method: "uploadFile", config })
    }),
    getUploadUrl: builder.mutation<any, QueryConfig<GetUploadUrlConfig>>({
      query: (config) => ({ method: "getUploadUrl", config })
    }),
    getConsentFormPreview: builder.query<any, QueryConfig<GetConsentFormPreviewConfig>>({
      query: (config) => ({ method: "getConsentFormPreview", config })
    }),
    getConsentForm: builder.query<any, QueryConfig<GetConsentFormConfig>>({
      query: (config) => ({ method: "getConsentForm", config }),
      providesTags: (result) => [{ type: "ConsentForm", id: `${result?.code}_${result?.version}` }]
    }),
    getFormFamily: builder.query<any, QueryConfig<GetFormFamilyConfig>>({
      query: (config) => ({ method: "getFormFamily", config }),
      providesTags: (result) => [{ type: "ConsentForm", id: `${result?.code}_${result?.version}` }]
    }),
    createConsentForm: builder.mutation<any, QueryConfig<ConsentFormConfig>>({
      query: (config) => ({ method: "createConsentForm", config }),
      invalidatesTags: () => [
        { type: "ConsentForm", id: "LIST" },
        { type: "Form", id: "LIST" }
      ]
    }),
    createManualConsent: builder.mutation<any, QueryConfig<CreateManualConsentConfig>>({
      query: (config) => ({ method: "createManualConsent", config }),
      invalidatesTags: (result) => [{ type: "Consent", id: "LIST" }]
    }),
    updateConsentForm: builder.mutation<any, QueryConfig<ConsentFormConfig>>({
      query: (config) => ({ method: "updateConsentForm", config }),
      invalidatesTags: (result) => [
        { type: "ConsentForm", id: `${result?.code}_${result?.version}` },
        { type: "ConsentForm", id: "LIST" },
        { type: "Form", id: "LIST" }
      ]
    }),
    publishConsentForm: builder.mutation<any, QueryConfig<PublishConsentFormConfig>>({
      query: (config) => ({ method: "publishConsentForm", config }),
      invalidatesTags: (result) => [
        { type: "ConsentForm", id: `${result?.code}_${result?.version}` },
        { type: "ConsentForm", id: "LIST" },
        { type: "Form", id: "LIST" }
      ]
    }),
    deleteConsentForm: builder.mutation<any, QueryConfig<DeleteConsentFormConfig>>({
      query: (config) => ({ method: "deleteConsentForm", config }),
      invalidatesTags: (result) => [
        { type: "ConsentForm", id: `${result?.code}_${result?.version}` },
        { type: "ConsentForm", id: "LIST" },
        { type: "Form", id: "LIST" }
      ]
    }),
    deprecateConsentForm: builder.mutation<any, QueryConfig<DeprecateConsentFormConfig>>({
      query: (config) => ({ method: "deprecateConsentForm", config }),
      invalidatesTags: (result) => [
        { type: "ConsentForm", id: `${result?.code}_${result?.version}` },
        { type: "ConsentForm", id: "LIST" },
        { type: "Form", id: "LIST" }
      ]
    }),
    getConsent: builder.query<any, QueryConfig<GetConsentConfig>>({
      query: (config) => ({ method: "getConsent", config }),
      providesTags: (result) => [{ type: "Consent", id: result?.consentId }]
    }),
    updateOcrResults: builder.mutation<any, QueryConfig<UpdateOcrResultsConfig>>({
      query: (config) => ({ method: "updateOcrResults", config }),
      // providesTags: (result) => [{ type: "Consent", id: result?.consentId }]
      invalidatesTags: (result) => [
        { type: "Consent", id: "LIST" },
        { type: "OcrResults", id: "LIST" }
      ]
    }),
    getOcrConsent: builder.query<any, QueryConfig<GetOcrConsentConfig>>({
      query: (config) => ({ method: "getOcrConsent", config }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: "OcrResults", id: result?.consentId }]
    }),
    requestConsentForm: builder.mutation<any, QueryConfig<RequestConsentFormConfig>>({
      query: (config) => ({ method: "requestConsentForm", config })
    }),
    requestConsentFormToGuest: builder.mutation<any, QueryConfig<RequestConsentFormToGuestConfig>>({
      query: (config) => ({ method: "requestConsentFormToGuest", config })
    }),
    uploadConsent: builder.mutation<any, QueryConfig<UploadConsentConfig>>({
      query: (config) => ({ method: "uploadConsent", config }),
      invalidatesTags: (result) => [
        { type: "Consent", id: "LIST" },
        { type: "Consent", id: result?.consentId }
      ]
    }),
    addConsentHistory: builder.mutation<any, QueryConfig<AddConsentHistoryConfig>>({
      query: (config) => ({ method: "addConsentHistory", config }),
      invalidatesTags: (result) => [{ type: "ConsentHistory", id: "LIST" }]
    }),
    getConsentHistory: builder.query<any, QueryConfig<GetConsentHistoryConfig>>({
      query: (config) => ({ method: "getConsentHistory", config }),
      providesTags: (result) => [{ type: "ConsentHistory", id: "LIST" }]
    }),
    fetchConsumer: builder.query<any, QueryConfig<FetchConsumerConfig>>({
      query: (config) => ({ method: "fetchConsumer", config }),
      providesTags: (result) => [{ type: "Consumer", id: result?.consumerId }]
    }),
    addNewConsumer: builder.mutation<any, QueryConfig<AddNewConsumerConfig>>({
      query: (config) => ({ method: "addNewConsumer", config }),
      invalidatesTags: () => [{ type: "Consumer", id: "LIST" }]
    }),
    updateConsumer: builder.mutation<any, QueryConfig<UpdateConsumerConfig>>({
      query: (config) => ({ method: "updateConsumer", config }),
      invalidatesTags: (result) => [
        { type: "Consumer", id: result?.consumerId },
        { type: "Consumer", id: "LIST" }
      ]
    }),
    deleteConsumer: builder.mutation<any, QueryConfig<DeleteConsumerConfig>>({
      query: (config) => ({ method: "deleteConsumer", config }),
      invalidatesTags: (result) => [
        { type: "Consumer", id: result?.consumerId },
        { type: "Consumer", id: "LIST" }
      ]
    }),
    resetConsumer: builder.mutation<any, QueryConfig<ResetConsumerConfig>>({
      query: (config) => ({ method: "resetConsumer", config }),
      invalidatesTags: () => [{ type: "Consumer", id: "LIST" }]
    }),
    fetchCurrentUser: builder.query<any, QueryConfig<null>>({
      query: (config) => ({ method: "fetchCurrentUser", config })
    }),
    fetchUser: builder.query<any, QueryConfig<FetchUserConfig>>({
      query: (config) => ({ method: "fetchUser", config }),
      providesTags: (result) => [{ type: "User", id: result?.userId }]
    }),
    fetchUserListByOrgId: builder.query<any, QueryConfig<FetchUserListByOrgIdConfig>>({
      query: (config) => ({ method: "fetchUserListByOrgId", config }),
      providesTags: () => [{ type: "User", id: "LIST" }]
    }),
    addNewUser: builder.mutation<any, QueryConfig<AddNewUserConfig>>({
      query: (config) => ({ method: "addNewUser", config }),
      invalidatesTags: () => [{ type: "User", id: "LIST" }]
    }),
    updateUser: builder.mutation<any, QueryConfig<UpdateUserConfig>>({
      query: (config) => ({ method: "updateUser", config }),
      invalidatesTags: (result) => [
        { type: "User", id: "LIST" },
        { type: "User", id: result?.userId }
      ]
    }),
    deleteUser: builder.mutation<any, QueryConfig<DeleteUserConfig>>({
      query: (config) => ({ method: "deleteUser", config }),
      invalidatesTags: (result) => [
        { type: "User", id: result?.userId },
        { type: "User", id: "LIST" }
      ]
    }),
    deactivateUser: builder.mutation<any, QueryConfig<DeactivateUserConfig>>({
      query: (config) => ({ method: "deactivateUser", config }),
      invalidatesTags: (result) => [
        { type: "User", id: result?.userId },
        { type: "User", id: "LIST" }
      ]
    }),
    addNewPractitioner: builder.mutation<any, QueryConfig<AddNewPractitionerConfig>>({
      query: (config) => ({ method: "addNewPractitioner", config }),
      invalidatesTags: () => [{ type: "Practitioner", id: "LIST" }]
    }),
    fetchPractitioner: builder.query<any, QueryConfig<FetchPractitionerConfig>>({
      query: (config) => ({ method: "fetchPractitioner", config }),
      providesTags: (result) => [{ type: "Practitioner", id: result?.providerId }]
    }),
    updatePractitioner: builder.mutation<any, QueryConfig<UpdatePractitionerConfig>>({
      query: (config) => ({ method: "updatePractitioner", config }),
      invalidatesTags: (result) => [
        { type: "Practitioner", id: result?.providerId },
        { type: "Practitioner", id: "LIST" }
      ]
    }),
    deletePractitioner: builder.mutation<any, QueryConfig<DeletePractitionerConfig>>({
      query: (config) => ({ method: "deletePractitioner", config }),
      invalidatesTags: (result) => [
        { type: "Practitioner", id: result?.providerId },
        { type: "Practitioner", id: "LIST" }
      ]
    }),
    dailyConsentReport: builder.query<any, QueryConfig<undefined>>({
      query: (config) => ({ method: "dailyConsentReport", config })
    }),
    getBulkExport: builder.query<any, QueryConfig<GetBulkExportConfig>>({
      query: (config) => ({ method: "getBulkExport", config })
    }),
    createBulkExport: builder.mutation<any, QueryConfig<CreateBulkExport>>({
      query: (config) => ({ method: "createBulkExport", config })
    }),
    getBulkImport: builder.query<any, QueryConfig<GetBulkImportConfig>>({
      query: (config) => ({ method: "getBulkImport", config })
    }),
    createBulkImport: builder.mutation<any, QueryConfig<CreateBulkImport>>({
      query: (config) => ({ method: "createBulkImport", config })
    }),
    searchItems: builder.query<any, QueryConfig<SearchItemsConfig>>({
      query: (config) => ({ method: "searchItems", config }),
      // @ts-expect-error will be fixed later
      providesTags: (_config, _, queryArg) => {
        const type = queryArg?.variables?.params?.type;

        if (type) return [{ type: type.charAt(0).toUpperCase() + type.slice(1), id: "LIST" }];
      }
    }),
    searchItemsByEntity: builder.mutation<any, QueryConfig<SearchItemsConfig>>({
      query: (config) => ({ method: "searchItems", config })
    }),
    getPermissions: builder.query<any, QueryConfig<undefined>>({
      query: (config) => ({ method: "getPermissions", config })
    }),
    getAssignedPermissions: builder.query<any, QueryConfig<undefined>>({
      query: (config) => ({ method: "getAssignedPermissions", config })
    }),
    getUserRoles: builder.query<any, QueryConfig<undefined>>({
      query: (config) => ({ method: "getUserRoles", config })
    }),
    fetchSecurityRole: builder.query<any, QueryConfig<FetchSecurityRoleConfig>>({
      query: (config) => ({ method: "fetchSecurityRole", config }),
      providesTags: (result) => [{ type: "UserRole", id: result?.userRoleId }]
    }),
    addNewSecurityRole: builder.mutation<any, QueryConfig<AddNewSecurityRoleConfig>>({
      query: (config) => ({ method: "addNewSecurityRole", config }),
      invalidatesTags: () => [{ type: "UserRole", id: "LIST" }]
    }),
    updateSecurityRole: builder.mutation<any, QueryConfig<UpdateSecurityRoleConfig>>({
      query: (config) => ({ method: "updateSecurityRole", config }),
      invalidatesTags: (result) => [
        { type: "UserRole", id: result?.userRoleId },
        { type: "UserRole", id: "LIST" }
      ]
    }),
    deleteSecurityRole: builder.mutation<any, QueryConfig<DeleteSecurityRoleConfig>>({
      query: (config) => ({ method: "deleteSecurityRole", config }),
      invalidatesTags: (result) => [
        { type: "UserRole", id: result?.userRoleId },
        { type: "UserRole", id: "LIST" }
      ]
    }),
    logUserEvent: builder.mutation<any, QueryConfig<LogUserEventConfig>>({
      query: (config) => ({ method: "logUserEvent", config })
    }),

    getUserActivityLogs: builder.query<any, QueryConfig<GetUserActivityLogsConfig>>({
      query: (config) => ({ method: "getUserActivityLogs", config })
    }),

    getUserActivityLogsExport: builder.mutation<any, QueryConfig<GetUserActivityLogsConfig>>({
      query: (config) => ({ method: "getUserActivityLogsExport", config })
    }),

    getReportURL: builder.mutation<any, QueryConfig<{ userReportJobID: string }>>({
      query: (config) => ({ method: "getReportURL", config })
    }),

    getConsentReport: builder.query<any, QueryConfig<GetConsentReportConfig>>({
      query: (config) => ({ method: "getConsentReport", config })
    }),

    getConsentExpiryReport: builder.query<any, QueryConfig<GetConsentExpiryReportConfig>>({
      query: (config) => ({ method: "getConsentExpiryReport", config })
    }),

    getConsentStatisticsReport: builder.query<any, QueryConfig<GetConsentStatisticsReportConfig>>({
      query: (config) => ({ method: "getConsentStatisticsReport", config })
    }),

    getUsageReport: builder.query<any, QueryConfig<GetUsageReportConfig>>({
      query: (config) => ({ method: "getUsageReport", config })
    }),

    getUsageReportExport: builder.mutation<any, QueryConfig<GetUsageReportConfig>>({
      query: (config) => ({ method: "getUsageReportExport", config })
    }),

    getLoadTimeReport: builder.query<any, QueryConfig<GetLoadTimeReportConfig>>({
      query: (config) => ({ method: "getLoadTimeReport", config })
    }),

    getLoadTimeReportExport: builder.mutation<any, QueryConfig<GetLoadTimeReportConfig>>({
      query: (config) => ({ method: "getLoadTimeReportExport", config })
    }),

    getLoadTimeSummaryReport: builder.query<any, QueryConfig<GetLoadTimeSummaryReportConfig>>({
      query: (config) => ({ method: "getLoadTimeSummaryReport", config })
    }),

    getConsentExpiryReportExport: builder.mutation<any, QueryConfig<GetConsentExpiryReportConfig>>({
      query: (config) => ({ method: "getConsentExpiryReportExport", config })
    }),

    getConsentReportExport: builder.mutation<any, QueryConfig<GetConsentReportConfig>>({
      query: (config) => ({ method: "getConsentReportExport", config })
    }),

    getConsentStatisticsReportExport: builder.mutation<any, QueryConfig<GetConsentStatisticsReportConfig>>({
      query: (config) => ({ method: "getConsentStatisticsReportExport", config })
    }),

    getConsentPreSignedUrl: builder.mutation<any, QueryConfig<GetConsentPreSignedUrlConfig>>({
      query: (config) => ({ method: "getConsentPreSignedUrl", config })
    }),

    getOcrResultPreSignedUrl: builder.mutation<any, QueryConfig<GetOcrResultPreSignedUrlConfig>>({
      query: (config) => ({ method: "getOcrResultPreSignedUrl", config })
    }),

    getAuthToken: builder.mutation<any, QueryConfig<GetAuthTokenConfig>>({
      query: (config) => ({ method: "getAuthToken", config })
    }),

    getAuthTokenForUser: builder.mutation<any, QueryConfig<GetAuthTokenForUserConfig>>({
      query: (config) => ({ method: "getAuthTokenForUser", config })
    }),

    deactivateConsent: builder.mutation<any, QueryConfig<DeactivateConsentConfig>>({
      query: (config) => ({ method: "deactivateConsent", config }),
      invalidatesTags: (result) => [{ type: "Consent", id: "LIST" }]
    }),

    updateConsent: builder.mutation<any, QueryConfig<UpdateConsentConfig>>({
      query: (config) => ({ method: "updateConsentConsent", config }),
      invalidatesTags: (result) => [
        { type: "Consent", id: result?.consentId },
        { type: "OcrResults", id: result?.consentId },
        { type: "Consent", id: "LIST" }
      ]
    }),

    updateConsentWithOcr: builder.mutation<any, QueryConfig<UpdateConsentWithOcrConfig>>({
      query: (config) => ({ method: "updateConsentWithOcr", config }),
      invalidatesTags: (result) => [
        { type: "Consent", id: result?.consentId },
        { type: "Consent", id: "LIST" }
      ]
    }),

    markConsentRevoked: builder.mutation<any, QueryConfig<MarkConsentRevokedConfig>>({
      query: (config) => ({ method: "markConsentRevoked", config }),
      invalidatesTags: (result) => [
        { type: "Consent", id: "LIST" },
        { type: "Consent", id: result?.consentId }
      ]
    }),

    updateProfile: builder.mutation<any, QueryConfig<UpdateProfileConfig>>({
      query: (config) => ({ method: "updateProfile", config })
    }),

    updatePassword: builder.mutation<any, QueryConfig<UpdatePasswordConfig>>({
      query: (config) => ({ method: "updatePassword", config })
    }),

    getOcrDownloadUrl: builder.query<any, QueryConfig<GetOcrDownloadUrlConfig>>({
      query: (config) => ({ method: "getOcrDownloadUrl", config })
    }),

    checkConsentForDuplicates: builder.mutation<any, QueryConfig<CheckConsentForDuplicatesConfig>>({
      query: (config) => ({ method: "checkConsentForDuplicates", config })
    }),

    addPageLoadTime: builder.mutation<any, QueryConfig<AddPageLoadTimeConfig>>({
      query: (config) => ({ method: "addPageLoadTime", config })
    }),

    verifySignIn: builder.mutation<any, QueryConfig<VerifySignInConfig>>({
      query: (config) => ({ method: "verifySignIn", config })
    }),

    changePasswordSignIn: builder.mutation<any, QueryConfig<ChangePasswordSignInConfig>>({
      query: (config) => ({ method: "changePasswordSignIn", config })
    }),

    changePasswordForgot: builder.mutation<any, QueryConfig<ChangePasswordForgotConfig>>({
      query: (config) => ({ method: "changePasswordForgot", config })
    }),

    forgotPasswordRequest: builder.mutation<any, QueryConfig<ForgotPasswordRequestConfig>>({
      query: (config) => ({ method: "forgotPasswordRequest", config })
    })
  })
});

export const {
  useForgotPasswordRequestMutation,
  useChangePasswordForgotMutation,
  useChangePasswordSignInMutation,
  useVerifySignInMutation,
  useGetConsentStatisticsReportQuery,
  useGetConsentHistoryQuery,
  useAddConsentHistoryMutation,
  useAddPageLoadTimeMutation,
  useGetOcrConsentQuery,
  useGetOrganizationsQuery,
  useGetCurrentUserOrganizationQuery,
  useGetConsentImageOrPdfQuery,
  useFetchCurrentUserOrganizationsQuery,
  useUpdateProfileMutation,
  useGetAuthTokenMutation,
  useGetAuthTokenForUserMutation,
  useUpdateOcrResultsMutation,
  useUpdatePasswordMutation,
  useMarkConsentRevokedMutation,
  useUpdateConsentMutation,
  useCheckConsentForDuplicatesMutation,
  useUpdateConsentWithOcrMutation,
  useDeactivateConsentMutation,
  useGetConsentPreSignedUrlMutation,
  useGetOcrResultPreSignedUrlMutation,
  useUploadConsentMutation,
  useLogUserEventMutation,
  useDeleteSecurityRoleMutation,
  useUpdateSecurityRoleMutation,
  useAddNewSecurityRoleMutation,
  useFetchSecurityRoleQuery,
  useGetUserActivityLogsQuery,
  useGetUserActivityLogsExportMutation,
  useGetReportURLMutation,
  useAddNewConsumerMutation,
  useFetchRootOrganizationQuery,
  useFetchOrganizationListQuery,
  useLazyFetchOrganizationListQuery,
  useAddNewOrganizationMutation,
  useAddNewUserMutation,
  useChangePasswordMutation,
  useSignOutMutation,
  useCompleteNewPasswordMutation,
  useConfirmForgotPasswordMutation,
  useCreateConsentFormMutation,
  useDeleteConsentFormMutation,
  useDeleteConsumerMutation,
  useDeleteOrganizationByIdMutation,
  useActivateOrganizationsMutation,
  useDeleteUserMutation,
  useDeactivateUserMutation,
  useDeprecateConsentFormMutation,
  useGetConsentQuery,
  useFetchConsentHistoryQuery,
  useFetchConsentsListQuery,
  useFetchConsumerQuery,
  useFetchOrganizationQuery,
  useFetchUserListByOrgIdQuery,
  useFetchUserQuery,
  useFetchCurrentUserQuery,
  useGetPermissionsQuery,
  useGetAssignedPermissionsQuery,
  useAddNewPractitionerMutation,
  useFetchPractitionerQuery,
  useUpdatePractitionerMutation,
  useDeletePractitionerMutation,
  useForgotPasswordMutation,
  useGetConsentFormPreviewQuery,
  useGetConsentFormQuery,
  useGetFormFamilyQuery,
  useGetCurrentUserMutation,
  useGetUploadUrlMutation,
  usePublishConsentFormMutation,
  useRequestConsentFormMutation,
  useRequestConsentFormToGuestMutation,
  useSignInMutation,
  useUpdateConsentFormMutation,
  useUpdateConsumerMutation,
  useResetConsumerMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationSettingsMutation,
  useCreateManualConsentMutation,
  useUpdateUserAttributesMutation,
  useUpdateUserMutation,
  useUploadFileMutation,
  useDailyConsentReportQuery,
  useCreateBulkExportMutation,
  useGetBulkExportQuery,
  useCreateBulkImportMutation,
  useGetBulkImportQuery,
  useLazyFetchOrganizationQuery,
  useSearchItemsQuery,
  useSearchItemsByEntityMutation,
  useGetUserRolesQuery,
  useGetConsentReportExportMutation,
  useGetConsentExpiryReportExportMutation,
  useGetConsentReportQuery,
  useGetConsentExpiryReportQuery,
  useGetUsageReportQuery,
  useGetUsageReportExportMutation,
  useGetOcrDownloadUrlQuery,
  useGetConsentStatisticsReportExportMutation,
  useGetLoadTimeReportQuery,
  useGetLoadTimeSummaryReportQuery,
  useGetLoadTimeReportExportMutation
} = api;
