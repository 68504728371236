import { FormItemPageConfiguration } from "./types";
import {
  createNewActions,
  createNewEntityName,
  createNewInitialValues,
  createNewRows,
  createNewTabs,
  createNewInitialTab,
  createDetailActions,
  useDetailInitialQuery,
  createDetailDocumentTitle,
  createDetailEntityName,
  createDetailInitialValues,
  createDetailRows,
  createDetailTabs,
  createDetailInitialTab,
  useDetailEffects,
  additionalButtonsList,
  createEditDetailTabs,
  useNewEffects,
  createNewPostAmble,
  createDetailPostAmble,
  useNewInitialQuery
} from "./lib";
import assets from "../../../ui/assets";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    admin: {
      form: { list }
    }
  }
} = assets;

const formItemPageConfiguration: FormItemPageConfiguration = {
  common: {
    listPageLabel: list.listPageHeader,
    listPageUrl: routes.FORMS
  },
  new: {
    initialCustomStates: { editMode: false },
    createActions: createNewActions,
    createEntityName: createNewEntityName,
    createInitialValues: createNewInitialValues,
    createRows: createNewRows,
    useInitialQuery: useNewInitialQuery,
    createPostAmble: createNewPostAmble,
    createTabs: createNewTabs,
    createInitialTab: createNewInitialTab,
    useEffects: useNewEffects
  },
  detail: {
    initialCustomStates: { consentForms: [], currentLang: "", editMode: true },
    createActions: createDetailActions,
    useInitialQuery: useDetailInitialQuery,
    createDocumentTitle: createDetailDocumentTitle,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createPostAmble: createDetailPostAmble,
    createInitialValues: createDetailInitialValues,
    createTabs: createEditDetailTabs,
    createInitialTab: createDetailInitialTab,
    useEffects: useDetailEffects
  },
  detail_view: {
    initialCustomStates: { consentForms: [], currentLang: "", editMode: true },
    createActions: createDetailActions,
    useInitialQuery: useDetailInitialQuery,
    createDocumentTitle: createDetailDocumentTitle,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    createTabs: createDetailTabs,
    createInitialTab: createDetailInitialTab,
    useEffects: useDetailEffects,
    additionalButtonsList: additionalButtonsList
  }
};

export default formItemPageConfiguration;
