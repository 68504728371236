import { memo } from "react";
import * as React from "react";
import { Box, TextareaAutosize, FormHelperText, useTheme, FormLabel } from "@mui/material";
import { appStyles, appConstants, appColors } from "../../../assets";
import { fieldValueEqual } from "../../../utils";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  formBuilder: { formBuilderTextInput },
  flexRow,
  customAsteriskStyle
} = appStyles;

const { smallPadding, miniPadding } = appConstants;
const { formError } = appColors;

const textAreaStyle = {
  width: "100%",
  borderWidth: 2,
  outline: 0,
  ...formBuilderTextInput
};

function SchemaField({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) {
  const theme = useTheme();
  const { label = "Form Definition/Schema", name: fieldName, minRows = 22, maxRows = 25, disabled = false } = fieldInfo;
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  return (
    <Box>
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <Box sx={{ ...flexRow, paddingTop: `${smallPadding}px`, paddingBottom: `${miniPadding}px` }}>
          <FormLabel>
            {label} <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
          </FormLabel>
        </Box>
        <TextareaAutosize
          style={{
            ...textAreaStyle,
            borderColor: error ? theme.palette.error.main : "transparent"
          }}
          minRows={minRows}
          maxRows={maxRows}
          placeholder="Paste or type your JSON here"
          name={fieldName}
          value={value}
          onChange={(e) => {
            setFieldValue(fieldName, e.target.value);
          }}
          disabled={disabled}
          aria-label="Schema"
        />
        {error && <FormHelperText sx={{ color: formError }}>{error}</FormHelperText>}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
}

export default memo(SchemaField, fieldValueEqual);
