import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { appColors, commonStrings } from "../../../assets";
import { fieldValueEqual } from "../../../utils";
import type { ReadonlyFieldProps } from "../../../types";

const {
  appStrings: { labels }
} = commonStrings;

const { formText } = appColors;

const signedSignatureStyles = {
  backgroundRepeat: "no-repeat",
  position: "relative",
  zIndex: "auto",
  flex: 1
};

const floatingLabelStyles = {
  alignSelf: "flex-end",
  height: 20,
  fontSize: 12,
  color: formText
};

const ReadonlySignatureField = ({ fieldInfo, value }: ReadonlyFieldProps) => {
  return (
    <>
      {value && value !== "" ? (
        <Typography style={{ ...floatingLabelStyles, marginTop: "8px" }}>
          {fieldInfo.label ? fieldInfo.label : labels.signature}
        </Typography>
      ) : (
        <div>[none]</div>
      )}
      <Box
        height={40}
        sx={{
          ...signedSignatureStyles,
          backgroundImage: `url(data:image/jpeg;base64,${value})`
        }}
      />
    </>
  );
};

export default memo(ReadonlySignatureField, fieldValueEqual);
