import { memo, useEffect } from "react";
import * as React from "react";
import { InputAdornment, Box } from "@mui/material";
import ReadonlyTextField from "./ReadonlyTextField";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
const {
  baseForm: { sharedStyles, inputLabelEllipsis, asteriskStyles },
  styledComponents: { DefaultTextField }
} = appStyles;

const NormalTextField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const isFloatingLabel = !fieldInfo.labelStyle || fieldInfo.labelStyle === "floating";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  useEffect(() => {
    return () => {
      fieldInfo.clearOnReinitialize && setFieldValue(fieldInfo.name, "");
    };
  }, []);
  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <Box
      style={
        needConfirmation?.includes(fieldInfo.name)
          ? { display: "flex", alignItems: "flex-end", ...fieldInfo?.style }
          : { ...fieldInfo?.style }
      }
    >
      <DefaultTextField
        multiline={fieldInfo.multiline || false}
        minRows={fieldInfo.minRows}
        disabled={isDisabledField}
        style={{ ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
        InputLabelProps={{
          style: inputLabelEllipsis,
          shrink: fieldInfo.shrink,
          sx: asteriskStyles
        }}
        inputProps={{ maxLength: fieldInfo.maxTextLength }}
        error={Boolean(error)}
        helperText={error}
        onChange={(e) => {
          setFieldValue(fieldInfo.name, e.target.value);
        }}
        onBlur={(e) => {
          if (fieldInfo.isEmail) {
            setFieldValue(fieldInfo.name, e.target.value?.toLowerCase());
          }
        }}
        type="text"
        aria-label={isFloatingLabel ? `${fieldInfo.formId} ${fieldInfo.label} ${fieldInfo.name}` : ""}
        aria-required={fieldRequired}
        aria-invalid={Boolean(error)}
        label={isFloatingLabel ? fieldInfo.label : ""}
        required={fieldRequired}
        variant={fieldInfo.variant ?? "standard"}
        value={value}
        id={fieldInfo.name}
        InputProps={
          "startAdornment" in fieldInfo
            ? {
                startAdornment: <InputAdornment position="start">{fieldInfo.startAdornment}</InputAdornment>
              }
            : {}
        }
      />
      {!isFloatingLabel && fieldInfo.labelStyle === "below" && (
        <ReadonlyTextField
          fieldInfo={{ fieldType: "readonly_text", name: "", label: fieldInfo.label }}
          value={fieldInfo.label}
        />
      )}
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(NormalTextField, fieldValueEqual);
