import { memo } from "react";
import * as React from "react";
import { FormControl, FormControlLabel, Box, Typography, Switch } from "@mui/material";
import { fieldValueEqual, parseHTMLElements, extractFieldLabel } from "../../../utils";
import { appStyles, appConstants } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { formFieldVerticalMargin } = appConstants;

const {
  baseForm: { sharedStyles, errorStyles }
} = appStyles;

const SwitchField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <FormControl
          style={{
            ...sharedStyles,
            marginTop: formFieldVerticalMargin,
            paddingTop: "20px",
            marginBottom: 0,
            ...(fieldInfo?.style ?? {})
          }}
        >
          <FormControlLabel
            disabled={fieldInfo.disabled || fieldInfo.readOnly}
            control={
              <Switch
                disabled={fieldInfo.disabled}
                color="primary"
                checked={value === true}
                onChange={(e) => {
                  setFieldValue(fieldInfo.name, e.target.checked);
                }}
                inputProps={{ "aria-label": "primary switch" }}
                id={fieldInfo.name}
              />
            }
            aria-label={fieldInfo.label}
            label={extractFieldLabel(fieldInfo.label, fieldInfo.componentLabel)}
          />
        </FormControl>
        {error && <Typography style={errorStyles}>{parseHTMLElements(error)}</Typography>}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(SwitchField, fieldValueEqual);
