import { Box, styled, Stack } from "@mui/material";
import { appConstants, appStyles } from "../../assets";
import BaseForm from "./BaseForm";
import BaseButton from "../BaseButton";
import type { BaseButtonProps } from "../../types";

interface ConsentFormPageProps {
  rows: any[];
  formik: any;
  currentSectionIdx: number;
  sectionTitle: string;
  isDesktop: boolean;
  postAmbleButtons: BaseButtonProps[];
  sectionIdx: number;
}

const { gutterMargin } = appConstants;

const {
  consentDetail: { postAmble }
} = appStyles;

const flexEnd = {
  justifyContent: "flex-end"
};

const DesktopSectionBox = styled(Box)({
  paddingLeft: gutterMargin,
  paddingRight: gutterMargin
});

function ConsentForm({
  rows,
  formik,
  currentSectionIdx,
  isDesktop,
  sectionTitle,
  postAmbleButtons,
  sectionIdx
}: ConsentFormPageProps) {
  const SectionBox = isDesktop ? DesktopSectionBox : Box;

  const sxProps =
    currentSectionIdx === 1
      ? { ...flexEnd, ...postAmble, width: "100%", paddingTop: `${gutterMargin}px` }
      : { ...postAmble, width: "100%", paddingTop: `${gutterMargin}px` };

  return (
    <Box key={`form-${currentSectionIdx}`} style={{ display: sectionIdx === currentSectionIdx ? "block" : "none" }}>
      <SectionBox>
        <BaseForm
          postAmble={
            <Box sx={sxProps}>
              <Stack direction="row" spacing={2}>
                {postAmbleButtons.map((props) => (
                  <BaseButton {...props} key={props.id} ariaLabel="button" />
                ))}
              </Stack>
            </Box>
          }
          preAmble={null}
          rows={rows}
          formik={formik}
        />
      </SectionBox>
    </Box>
  );
}

export default ConsentForm;
