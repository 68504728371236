import { validations } from "../../../../utils";

export type OrganizationField =
  | "name"
  | "npi"
  | "email"
  | "phoneNumber"
  | "address1"
  | "address2"
  | "city"
  | "state"
  | "zip"
  | "fax"
  | "allowRevocation"
  | "allowGeneralDesignationConsents"
  | "active"
  | "reviewAfterDays";

const formFields = {
  name: "Name",
  email: "Email",
  city: "City",
  state: "State",
  phone: "Phone",
  fax: "Fax",
  npi: "NPI",
  address1: "Address 1",
  address2: "Address 2",
  zip: "Zip",
  expressZip: "Express Zip",
  allowRevocation: "Allow Revocation",
  allowGeneralDesignationConsents: "Allow General Designation Consents",
  active: "Active",
  reviewAfterDays: "Review After Days",
  reviewNotificationLeadDays: "Review Notification Lead Days"
};

const fields: { [key in OrganizationField]: any } = {
  name: {
    name: "name",
    label: formFields.name,
    width: "45%",
    validation: validations.requiredTextMinMax(1, 150)
  },
  email: {
    name: "email",
    label: formFields.email,
    width: "45%",
    validation: validations.requiredEmail
  },
  npi: {
    name: "npi",
    label: formFields.npi,
    width: "45%",
    validation: validations.textMinMax(0, 9)
  },
  phoneNumber: {
    name: "phoneNumber",
    label: formFields.phone,
    // fieldType: "us-phone",
    width: "45%",
    validation: validations.requiredPhone
  },
  fax: {
    name: "fax",
    label: formFields.fax,
    // fieldType: "us-phone",
    width: "45%",
    validation: validations.phone
  },
  allowRevocation: {
    name: "allowRevocation",
    label: formFields.allowRevocation,
    showLabel: true,
    width: "25%",
    fieldType: "switch",
    defaultValue: true
  },
  allowGeneralDesignationConsents: {
    name: "allowGeneralDesignationConsents",
    label: formFields.allowGeneralDesignationConsents,
    showLabel: true,
    width: "25%",
    fieldType: "switch",
    defaultValue: false
  },
  active: {
    name: "active",
    label: formFields.active,
    showLabel: true,
    width: "25%",
    fieldType: "switch",
    defaultValue: true
  },
  reviewAfterDays: {
    name: "reviewAfterDays",
    label: formFields.reviewAfterDays,
    width: "45%",
    validation: validations.requiredNumberMinMax(30, 365)
  },
  address1: {
    name: "address1",
    label: formFields.address1,
    width: "45%",
    validation: validations.text
  },
  address2: {
    name: "address2",
    label: formFields.address2,
    width: "45%",
    validation: validations.text
  },
  city: {
    name: "city",
    label: formFields.city,
    width: "31%",
    validation: validations.text
  },
  state: {
    name: "state",
    label: formFields.state,
    width: "10%",
    fieldType: "us-state",
    validation: validations.text
  },
  zip: {
    name: "zip",
    label: formFields.zip,
    width: "22%",
    fieldType: "zip",
    validation: validations.zip
  }
};

export default fields;
