import { ExportItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  formIsUntouchedOrHasError,
  createButtonBoxPostAmble,
  createAction,
  getValuesForSingleFilter,
  disableFields
} from "../../utils";
import {
  useCreateBulkExportMutation,
  useFetchOrganizationQuery,
  useGetBulkExportQuery,
  useLazyFetchOrganizationQuery
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { downloadFile } from "@midato-mono/common/src/utils";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, download }
      }
    }
  }
} = assets;

// new
type NC = ExportItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useCreateBulkExportMutation, type: "add" })
});

export const useNewInitialQuery: NC["useInitialQuery"] = ({ orgId }) => {
  const { data: callerOrganization, ...others } = useMidatoQuery({
    queryFn: useFetchOrganizationQuery,
    queryArgs: {
      orgId
    }
  });
  const organizations = (callerOrganization ? [callerOrganization] : []).map(({ orgId, name }: any) => ({
    name: orgId,
    label: name
  }));
  return { data: organizations, ...others };
};

export const createNewInitialValues: NC["createInitialValues"] = ({ initialQueryData: organizations }) => ({
  orgId: organizations?.[0]?.name ?? "",
  status: ["draft", "rejected", "active", "inactive", "expired", "abandoned"]
});

export const createNewRows: NC["createRows"] = ({ initialQueryData: organizations }) => [
  [fields.description, fields.type, fields.format],
  [fields.filterData],
  [fields.status],
  [{ ...fields.orgId, options: organizations }],
  [fields.startDate, fields.endDate]
];

export const createNewPostAmble: NC["createPostAmble"] = ({ formik, actions: { add }, navigate, permissions }) => {
  const saveButton = createButton({
    id: "btn-save",
    text: save,
    actionId: "EXPORT_ADD",
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const { type, format, orgId, endDate, startDate, status, description } = formik.values;
        const params = {
          bulkExport: {
            type,
            format,
            orgId,
            filter: {
              endDate,
              startDate,
              consentStatus: status
            },
            description
          }
        };
        const bulkActionId = await add!(params).unwrap();
        navigate(`${routes.EXPORTS}/${bulkActionId}`);
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });
  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton]
  });
};

// detail
type DC = ExportItemPageConfiguration["detail"];

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  getOrganization: createAction({
    toaster,
    mutationFn: useLazyFetchOrganizationQuery
  })
});

export const useDetailInitialQuery: DC["useInitialQuery"] = ({
  params: { id: bulkActionId },
  setCustomStates,
  actions: { getOrganization }
}) => {
  return useMidatoQuery({
    queryFn: useGetBulkExportQuery,
    queryArgs: {
      bulkActionId
    },
    queryOptions: { skip: !bulkActionId },
    isSuccessCallback: async ({ orgId }: any) => {
      const organization = await getOrganization!({
        orgId
      }).unwrap();
      setCustomStates({
        organizations: [
          {
            name: orgId,
            label: organization.name
          }
        ]
      });
    }
  });
};

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData }) =>
  initialQueryData?.bulkActionId ?? "";

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData: bulkExport }) => ({
  orgId: bulkExport?.orgId ?? "",
  type: bulkExport?.type ?? "",
  format: bulkExport?.format ?? "",
  status: getValuesForSingleFilter(bulkExport?.filter ?? "", "status"),
  startDate: bulkExport?.startDate ?? "",
  endDate: bulkExport?.endDate ?? "",
  rowsProcessed: Number(bulkExport?.rowsProcessed ?? 0),
  description: bulkExport?.description ?? ""
});

export const createDetailRows: DC["createRows"] = ({
  customStates: { organizations },
  initialQueryData: bulkExport
}) => [
  disableFields([fields.description, fields.type, fields.format]),
  [fields.filterData],
  disableFields([fields.status]),
  disableFields([
    {
      ...fields.orgId,
      options: organizations.length ? organizations : [{ name: bulkExport?.orgId ?? "", label: "" }]
    }
  ]),
  disableFields([fields.startDate, fields.endDate]),
  [fields.exportStatus],
  [{ ...fields.rowsProcessed, totalRows: bulkExport?.totalRows ?? 0 }],
  [fields.results]
];

export const createDetailPostAmble: DC["createPostAmble"] = ({ initialQueryData: bulkExport, permissions }) => {
  const downloadButton = createButton({
    actionId: "BULK_EXPORT",
    id: "btn-download",
    text: download,
    onPress: async () => {
      if (bulkExport?.exportUrl) {
        downloadFile(bulkExport.exportUrl);
      }
    }
  });
  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [downloadButton]
  });
};
