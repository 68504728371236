import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export interface AuthState {
  token: string;
  firstName?: string;
  lastName?: string;
  orgId?: string;
  userId?: string;
  patientId?: string;
  permissions?: string[];
}

const initialState: AuthState = {
  token: ""
};

const _authSlice: Slice<AuthState> = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      if (payload) return { ...state, ...payload };
      return payload;
    }
  }
});

export const { setCredentials } = _authSlice.actions;
export const selectCurrentUser = <T extends { auth: AuthState }>(state: T) => state.auth;

// @ts-ignore
const authSlice = { ..._authSlice, setCredentials, selectCurrentUser };

export default authSlice;
