import { memo } from "react";
import * as React from "react";
import { Box, TextField } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fieldValueEqual, formattedDate, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  baseForm: { sharedStyles, asteriskStyles, formLabeErrorStyles },
  datePickerPopperSx
} = appStyles;

const isExpirationField = (name: string) => name?.toLowerCase()?.includes("expir");

const DateField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  const sxProps = {
    ...sharedStyles,
    marginBottom: 0,
    ...(fieldInfo?.style ?? {})
  };

  React.useEffect(() => {
    if (!value) {
      setFieldValue(fieldInfo.name, null);
    }
  }, [value]);

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={formattedDate(value)} />;
  } else {
    return (
      <Box
        style={
          needConfirmation?.includes(fieldInfo.name)
            ? { display: "flex", flexWrap: "nowrap", alignItems: "flex-end" }
            : {}
        }
      >
        <div style={needConfirmation?.includes(fieldInfo.name) ? { width: "220px" } : { width: "98%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={error ? formLabeErrorStyles : {}}>
              <DatePicker
                disabled={isDisabledField}
                label={fieldInfo.label}
                value={value}
                inputFormat="MM/dd/yyyy"
                disablePast={isExpirationField(fieldInfo.name)}
                PopperProps={{ sx: datePickerPopperSx }}
                onChange={(date: Date) => {
                  setFieldValue(fieldInfo.name, date);

                  // if (date?.getFullYear()?.toString()?.length < 4) return;
                  // try {
                  //   const newDate = new Date(`${format(date, "yyyy-MM-dd")}T09:00:00.000Z`);
                  //   setFieldValue(fieldInfo.name, newDate);
                  // } catch (error) {
                  //   setFieldValue(fieldInfo.name, new Date());
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": fieldInfo.label,
                      "aria-required": fieldRequired,
                      "aria-invalid": Boolean(error),
                      type: "text"
                    }}
                    variant={fieldInfo.variant ?? "standard"}
                    name={fieldInfo.name}
                    error={Boolean(error)}
                    helperText={error}
                    required={fieldRequired}
                    InputLabelProps={{
                      shrink: true,
                      sx: asteriskStyles
                    }}
                    id={fieldInfo.name}
                    sx={sxProps}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default memo(DateField, fieldValueEqual);
