import { memo, useRef, useState, CSSProperties, useEffect } from "react";
import * as React from "react";
import { Box, Typography, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SignatureCanvas from "react-signature-canvas";
import { useWindowDimensions } from "../../../hooks";
import { fieldValueEqual, parseHTMLElements } from "../../../utils";
import { appConstants, appColors, commonStrings, appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import BaseButton from "../../BaseButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { isDesktopDevice, primaryFontSize } = appConstants;
const {
  baseForm: { sharedStyles, asteriskStyles },
  formFields: { signedSignatureStyles, signatureIcon, canvasStyles, canvasContainerStyles, printNameTextFieldStyles }
} = appStyles;

const { primaryTheme, formError, formText } = appColors;
const {
  defaultErrorStrings: { requiredError },
  appStrings: {
    buttonText: { clear, save },
    labels
  }
} = commonStrings;

const SignatureField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const [showDrawingSurface, setShowDrawingSurface] = useState(false);
  const [isBlank, setIsBlank] = useState(true);
  const canvas = useRef<any>({});
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (value?.canvas && showDrawingSurface === true) {
      setIsBlank(false);
      canvas.current.fromDataURL(`data:image/jpeg;base64,${value?.canvas}`, { ratio: 1 });
    }
  }, [showDrawingSurface]);

  const showSignatureAsLabel = fieldInfo?.readOnly || (!!value && value !== "");
  const hasValue = !!value && value !== "";
  const showLabel = hasValue || showDrawingSurface;

  const activeSignatureField = () => {
    if (!showDrawingSurface) {
      setIsBlank(true);
      setShowDrawingSurface(true);
    }
  };

  const isDesktop = isDesktopDevice();

  const canvasWidth = (width: number) => {
    if (isDesktop) {
      if (width * 0.6 > 700) {
        return 700;
      }
      return width * 0.6;
    } else {
      return width - 50;
    }
  };

  const floatingLabelStyles: CSSProperties = {
    alignSelf: "flex-start",
    fontSize: 15,
    marginTop: 20,
    color: showDrawingSurface ? primaryTheme : formText,
    ...(error ? { color: formError } : fieldInfo.disabled ? { color: "lightgrey" } : {})
  };

  const labelStyles = {
    alignSelf: "flex-end",
    paddingBottom: 3,
    color: formText,
    ...(error ? { color: formError } : fieldInfo.disabled ? { color: "lightgrey" } : {})
  };

  const sxProps = {
    ...sharedStyles,
    marginTop: 1,
    width: canvasWidth(width),
    height: showDrawingSurface ? 80 : undefined,
    borderBottom: error ? `solid 2px ${formError}` : "solid 1px darkgrey",
    "&:hover": {
      borderBottom: error ? `solid 2px ${formError}` : "solid 2px darkgrey"
    },
    ...(fieldInfo?.style ?? {})
  };

  const updateCanvasValue = (clear = false) => {
    if (!clear) {
      const cleanData = canvas.current.toDataURL().split("base64,")[1];
      setFieldValue(fieldInfo.name, { ...value, canvas: cleanData });
    } else {
      setFieldValue(fieldInfo.name, undefined);
    }
  };

  const handleSaveSignatureBtn = () => {
    // if (!isBlank && value?.name?.length > 0) {
    if (!isBlank) {
      updateCanvasValue();
      setShowDrawingSurface(false);
    }
  };

  const handleStrokeEnd = () => {
    updateCanvasValue();
  };

  const handleClearSignatureBtn = () => {
    setIsBlank(true);
    canvas.current.clear();
    updateCanvasValue(true);
  };

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Stack>
        {showLabel ? (
          <Typography style={floatingLabelStyles as CSSProperties}>
            {fieldInfo.label ? fieldInfo.label : labels.signature}
          </Typography>
        ) : null}
        <Box
          sx={sxProps}
          style={{ width: "100%" }}
          onClick={fieldInfo.disabled || fieldInfo.readOnly ? undefined : (): void => activeSignatureField()}
        >
          {showSignatureAsLabel && !showDrawingSurface ? (
            <Box
              height={80}
              style={{
                ...(signedSignatureStyles as CSSProperties),
                backgroundImage: `url(data:image/jpeg;base64,${value?.canvas})`,
                width: "100%"
              }}
            />
          ) : showDrawingSurface ? (
            <Box style={{ width: "100%" }}>
              {/* <TextField */}
              {/*   id="standard-basic" */}
              {/*   style={{ ...printNameTextFieldStyles, width: canvasWidth(width) }} */}
              {/*   label={labels.printName} */}
              {/*   onChange={(e) => { */}
              {/*     setFieldValue(fieldInfo.name, { ...value, name: e.target.value }); */}
              {/*   }} */}
              {/*   variant="standard" */}
              {/*   value={value?.name} */}
              {/*   fullWidth */}
              {/*   required={true} */}
              {/*   InputLabelProps={{ */}
              {/*     sx: asteriskStyles */}
              {/*   }} */}
              {/* /> */}
              <Box sx={canvasContainerStyles}>
                <SignatureCanvas
                  ref={canvas}
                  onBegin={() => setIsBlank(false)}
                  onEnd={handleStrokeEnd}
                  canvasProps={{
                    style: {
                      ...canvasStyles
                    }
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Typography style={labelStyles}>{fieldInfo.label ? fieldInfo.label : labels.signature}</Typography>
          )}
          {!showDrawingSurface && isDesktop ? (
            <IconButton
              aria-label="add signature"
              onClick={() => {}}
              style={signatureIcon as CSSProperties}
              size="large"
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Box>

        {showDrawingSurface && (
          <Box>
            <Stack direction="row" spacing={2}>
              <BaseButton
                ariaLabel={save}
                onPress={handleSaveSignatureBtn}
                color="primary"
                variant="text"
                disabled={isBlank}
              >
                {save}
              </BaseButton>
              <BaseButton
                ariaLabel={clear}
                onPress={handleClearSignatureBtn}
                color="primary"
                variant="text"
                disabled={isBlank}
              >
                {clear}
              </BaseButton>
            </Stack>
          </Box>
        )}
        {error && (
          <Typography
            style={{
              color: formError,
              paddingTop: 2,
              fontSize: primaryFontSize
            }}
          >
            {fieldInfo.label ? parseHTMLElements(error) : requiredError}
          </Typography>
        )}
      </Stack>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(SignatureField, fieldValueEqual);
