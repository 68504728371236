import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useApp } from "./app/hooks";
import RoutesList from "./Router/RoutesList";
import ErrorBoundary from "@midato-mono/common/src/components/ErrorBoundary";

import ToasterMessage from "@midato-mono/common/src/components/ToasterMessage";
import { Suspense } from "react";
import { Amplify } from "aws-amplify";

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const REACT_APP_ADMIN_USER_POOL_ID = import.meta.env.REACT_APP_ADMIN_USER_POOL_ID;
const REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID = import.meta.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID,
      userPoolId: REACT_APP_ADMIN_USER_POOL_ID
    }
    // region: REACT_APP_REGION
  }
});

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

const App = () => {
  const { theme } = useApp();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense>
        <ErrorBoundary>
          <Router>
            <RoutesList />
          </Router>
        </ErrorBoundary>
      </Suspense>
      <ToasterMessage />
    </ThemeProvider>
  );
};

export default App;
