import {
  createDetailActions,
  createDetailDocumentTitle,
  createDetailEntityName,
  createDetailInitialValues,
  createDetailPostAmble,
  createDetailRows,
  createNewActions,
  createNewEntityName,
  createNewPostAmble,
  createNewRows,
  additionalButtonsList,
  useDetailInitialQuery
} from "./lib";
import { UserItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    admin: {
      user: { list }
    }
  }
} = assets;

const userItemPageConfiguration: UserItemPageConfiguration = {
  common: {
    listPageLabel: list.listPageHeader,
    listPageUrl: routes.USERS
  },
  new: {
    createActions: createNewActions,
    createEntityName: createNewEntityName,
    createRows: createNewRows,
    createPostAmble: createNewPostAmble,
    useInitialQuery: useDetailInitialQuery
  },
  detail: {
    useInitialQuery: useDetailInitialQuery,
    createActions: createDetailActions,
    createDocumentTitle: createDetailDocumentTitle,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    createPostAmble: createDetailPostAmble
  },
  detail_view: {
    useInitialQuery: useDetailInitialQuery,
    createActions: createDetailActions,
    createDocumentTitle: createDetailDocumentTitle,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    additionalButtonsList: additionalButtonsList
  }
};

export default userItemPageConfiguration;
