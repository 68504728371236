import { Link } from "react-router-dom";

export function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link aria-label="Go to home page" to="/">
          Go to the home page
        </Link>
      </p>
    </div>
  );
}
