import { useAuth } from "@midato-mono/common/src/hooks";
import useMidatoQuery from "./useMidatoQuery";
import { useGetCurrentUserOrganizationQuery } from "../redux/api";

const useHeader = () => {
  const { orgId, firstName, lastName } = useAuth();

  const { data: rawRootOrganization } = useMidatoQuery({
    queryFn: useGetCurrentUserOrganizationQuery,
    queryArgs: {
      orgId
    }
  });

  return { firstName, lastName, organization: rawRootOrganization };
};

export default useHeader;
