import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface GetOcrDownloadUrlConfig {
  fileKey: string;
}

const getOcrDownloadUrl = async ({ auth, variables }: QueryConfig<GetOcrDownloadUrlConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($fileKey: String!) {
        getOcrDownloadUrl(fileKey: $fileKey)
      }
    `,
    variables,
    auth
  );
};

export interface GetOcrConsentConfig {
  consentId: string;
}

const getOcrConsent = async ({ auth, variables }: QueryConfig<GetOcrConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consentId: ID!) {
        getOcrResultForConsent(consentId: $consentId) {
          ocrId
          results {
            key
            value
            confidence
            confirmationStatus
          }
          status
          consentId
          form {
            title
            code
            version
            formId
          }
          consumer {
            firstName
            lastName
            consumerId
          }
          organization {
            name
            orgId
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentConfig {
  consumerId: string;
  consentId: string;
}

const getConsent = async ({ auth, variables }: QueryConfig<GetConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consumerId: ID!, $consentId: ID!) {
        getConsent(consumerId: $consumerId, consentId: $consentId) {
          bidirectionalSharing
          consentId
          consumer {
            consumerId
            firstName
            lastName
            email
          }
          form {
            code
            version
            title
            description
            schema
          }
          requestorProvider {
            firstName
            lastName
            email
          }
          disclosingOrganizations {
            name
            phoneNumber
            email
            address {
              ...addressFields
            }
          }
          recipientProvider {
            firstName
            lastName
            email
          }
          recipientOrganizations {
            name
            phoneNumber
            email
            address {
              ...addressFields
            }
          }
          createdAt
          expireAt
          signedAt
          status
          data
          fileType
          fileKey
          revokeReason
          isOcr
          tpo
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateOcrResultsConfig {
  consentId: string;
  fileKey: string;
}

const updateOcrResults = async ({ auth, variables }: QueryConfig<UpdateOcrResultsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consentId: ID!, $fileKey: String!) {
        updateOcrResultByPreSignedUrl(consentId: $consentId, fileKey: $fileKey) {
          ocrId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeactivateConsentConfig {
  consentId: string;
}

const deactivateConsent = async ({ auth, variables }: QueryConfig<DeactivateConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consentId: ID!) {
        deactivateConsent(consentId: $consentId) {
          consentId
          status
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateConsentWithOcrConfig {
  consentId: string;
  fileKey: string;
}

const updateConsentWithOcr = async ({ auth, variables }: QueryConfig<UpdateConsentWithOcrConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consentId: ID!, $fileKey: String!) {
        updateConsentByPreSignedUrl(consentId: $consentId, fileKey: $fileKey) {
          consentId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateConsentConfig {
  consentId: string;
  consent: {
    data: { [key: string]: any };
    status: string;
    expireAt?: string;
    signedAt?: string;
  };
}

const updateConsentConsent = async ({ auth, variables }: QueryConfig<UpdateConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consentId: ID!, $consent: ConsentUpdateInput!) {
        updateConsent(consentId: $consentId, consent: $consent) {
          consentId
          consumer {
            consumerId
            orgId
            firstName
            lastName
          }
          form {
            formId
            code
            version
          }
          data
          status
          createdAt
          lastModified
          expireAt
        }
      }
    `,
    variables,
    auth
  );
};

export interface MarkConsentRevokedConfig {
  consentId: string;
  patientId: string;
  revokeReason: string;
}

const markConsentRevoked = async ({ auth, variables }: QueryConfig<MarkConsentRevokedConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($patientId: ID!, $consentId: ID!, $revokeReason: String!) {
        markConsentRevoked(patientId: $patientId, consentId: $consentId, revokeReason: $revokeReason) {
          consentId
          revokeReason
          consumer {
            consumerId
            orgId
            firstName
            lastName
          }
          form {
            formId
            code
            version
          }
          data
          status
          createdAt
          lastModified
          expireAt
        }
      }
    `,
    variables,
    auth
  );
};

export interface AddConsentHistoryConfig {
  consentId: string;
  userId: string;
  status: string;
}

const addConsentHistory = async ({ auth, variables }: QueryConfig<AddConsentHistoryConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consentId: ID!, $userId: ID!, $status: ConsentStatus!) {
        addConsentHistory(consentId: $consentId, userId: $userId, status: $status) {
          consentId
          updatedBy {
            firstName
            lastName
            email
          }
          modifiedAt
          status
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentHistoryConfig {
  consentId: string;
}

const getConsentHistory = async ({ auth, variables }: QueryConfig<GetConsentHistoryConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consentId: ID!) {
        getConsentHistory(consentId: $consentId) {
          consentId
          updatedBy {
            firstName
            lastName
            email
          }
          modifiedAt
          status
        }
      }
    `,
    variables,
    auth
  );
};

export {
  addConsentHistory,
  getConsentHistory,
  updateOcrResults,
  getOcrDownloadUrl,
  getConsent,
  getOcrConsent,
  deactivateConsent,
  updateConsentConsent,
  markConsentRevoked,
  updateConsentWithOcr
};
