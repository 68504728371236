import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface GetUploadUrlConfig {
  type?: "Form" | "Setting" | "BulkImport";
  extension?: "pdf" | "png" | "jpg" | "css" | "ndjson";
  orgId?: string;
}

const getUploadUrl = async ({ auth, variables }: QueryConfig<GetUploadUrlConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($orgId: ID, $type: FileUploadType!, $extension: FileExtension!) {
        createPresignedPost(orgId: $orgId, type: $type, extension: $extension) {
          fields
          url
        }
      }
    `,
    { type: "Form", extension: "pdf", ...variables },
    auth
  );
};

export interface UploadFileConfig {
  url: string;
  fields: Record<string, string>;
  file: File;
}

const uploadFile = async ({ variables }: QueryConfig<UploadFileConfig>) => {
  const { fields, url, file } = variables;
  const form = new FormData();
  Object.keys(fields).forEach((key) => form.append(key, fields[key]));
  form.append("file", file);
  try {
    const result = await fetch(url, {
      method: "POST",
      body: form
    });
    return {
      data: result.url,
      error: null
    };
  } catch (error) {
    return {
      data: null,
      error
    };
  }
};

export { getUploadUrl, uploadFile };
