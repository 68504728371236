import { CSSProperties } from "react";
import {
  styled,
  Box,
  CardMedia,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  TextField,
  Typography,
  InputBase,
  Divider,
  Link
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { appStyles, appConstants, appColors, themeColors as theme } from "@midato-mono/common/src/assets";

const {
  flexCenter,
  modal: { rootDisplay, actionModal },
  sidebar: { contentPaper, headerImg },
  flexCenterAndAlign,
  actionRibbon: { actionRibbonTextField },
  flexRow,
  fullWidth,
  centeredAlignedContainer,
  flexEnd,
  searchableAppBarStyles,
  appBarStyle,
  entityTitleIconBtn,
  header: { nameBox },
  spaceBetweenRow,
  formBuilder: { formContent, formBuilderTextInput },
  flexColumn,
  flexOne,
  formBuilder: { footerRow },
  justifyLeft,
  justifyRight,
  consentDetail: {
    postAmble,
    outerSection,
    consentDetailSectionHeader: { mobileHeader, sectionTitle: sectionTitleStyles }
  },
  autoMargin,
  settings: { termsDoneButton },
  wizards: {
    wizardPaperPadding,
    sendConsentFormsBodyElement: sendConsentsBodyElement,
    attachmentListTitle,
    attachmentRootContainer,
    attachmentListContainer,
    attachmentListItem,
    wizardBody,
    wizardBodyColumn,
    wizardButtonContainer,
    wizardRecipientChoice,
    wizardTitleText,
    defaultWizardButton
  },
  formBuilder: {
    formBuilderShell: { shellContainer, header, form, footer }
  }
} = appStyles;

const {
  moderatePadding,
  minPageWidth,
  headerHeight,
  actionRibbonSearchFieldHeight,
  font30,
  searchBtnFont,
  margin30,
  actionRibbonButtonHeight,
  standardBorderWidth,
  miniPadding,
  subTitleFontSize,
  filterControlMenuWidth,
  gutterMargin,
  standardPadding,
  pagerContainerPadding,
  tableHeight,
  tableBorder,
  smallMargin,
  flexShrinkZero,
  margin10,
  standardPostAmbleBtn,
  smallPadding,
  margin20,
  zero,
  formViewerWizardDimensions,
  wizardConfirmationIconSize,
  runReportModalCenterMargins,
  runReportModalDimensions,
  profileFormMinWidth,
  mIconSize
} = appConstants;

const {
  tabBarBackground,
  actionRibbonControlColor,
  selectButtonBackground,
  whiteText,
  headerBorder,
  tableHeaderCellBG,
  tableRowHoverColor,
  primaryTheme,
  sidebarSelectedItemBG,
  sidebarBG,
  breadCrumbActive,
  grayText,
  secondaryText,
  formToolbarBackground,
  textLink,
  subTitleText,
  darkBorder,
  defaultBG,
  defaultBorder,
  sidebarDivider,
  defaultIcon,
  infoBackground,
  appBarBackground,
  appBarLightBackground
} = appColors;

// components > common > form
export const AddEditFormStyle = {
  FormBox: styled(Box)({})
};

// components > common > layouts
export const AdminLayoutStyle = {
  PageContainer: styled(Box)({
    minWidth: `${minPageWidth}px`
  }),
  DrawerHeader: styled(Box)({
    borderBottom: "2px solid #E9EFEF",
    height: 100,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  }),
  StyledImg: styled(CardMedia)({
    ...headerImg
  }),
  ImgContainer: styled(Link)({
    ...flexCenter
  }),
  paper: contentPaper,
  paperAnchorDockedLeft: {
    borderRight: "0 !important"
  },
  headerGrid: { position: "sticky", top: 0, zIndex: 10 },
  conditionalPadding: (open: boolean) => ({
    paddingLeft: open ? 260 : 0
  })
};

// components > common
export const ActionModalStyle = {
  StyledModal: styled(Modal)({
    ...rootDisplay,
    "&:focus": {
      outline: 0
    }
  }),
  ModalBody: styled(Box)({
    ...(actionModal as CSSProperties),
    "&:focus": {
      outline: 0
    }
  }),
  CloseButtonContainer: styled(Box)({
    position: "absolute",
    right: 0,
    top: "8px",
    margin: smallPadding
  }),
  closingModalIcon: {
    fontSize: 24,
    color: defaultIcon
  },
  whiteIcon: {
    color: whiteText
  }
};

export const ActionRibbonStyle = {
  iconContainer: {
    padding: 0,
    color: actionRibbonControlColor
  },
  iconSize: { fontSize: font30 },
  inputPropsStyles: {
    height: actionRibbonSearchFieldHeight
  },
  gridCenterEvenly: {
    ...flexCenterAndAlign,
    justifyContent: "space-evenly"
  },
  addIconWrapper: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center"
  },
  textFieldStyles: {
    ...actionRibbonTextField
  },
  buttonStyles: {
    backgroundColor: actionRibbonControlColor,
    color: tabBarBackground,
    fontSize: searchBtnFont,
    marginLeft: `${margin30}px`,
    maxHeight: actionRibbonButtonHeight
  },
  flexStyles: { ...(flexRow as CSSProperties) },
  rootContainer: { ...fullWidth },
  tableInfoMessage: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: infoBackground
  },
  tableFiltersContainer: {
    backgroundColor: defaultBG,
    borderRadius: "4px"
  },
  CssTextField: styled(TextField)({
    "& .MuiOutlinedInput-root": {
      caretColor: grayText,
      "&:hover fieldset": {
        borderColor: darkBorder,
        borderWidth: standardBorderWidth
      }
    }
  }),
  StyledBox: styled(Box)({
    width: filterControlMenuWidth,
    backgroundColor: "transparent"
  }),
  ControlBox: styled(Box)({
    display: "flex",
    position: "relative",
    top: miniPadding,
    padding: `0 ${miniPadding}px`
  }),
  Text: styled(Typography)({
    color: whiteText,
    fontSize: subTitleFontSize,
    whiteSpace: "nowrap",
    textTransform: "capitalize"
  }),
  ArrowIcon: styled(ArrowDropDownIcon)({
    color: whiteText
  })
};

export const DetailPageTabControlBoxStyle = {
  DropDownContainer: styled(Box)({
    ...flexEnd
  }),
  dropDownStyles: {
    container: {
      display: "flex",
      marginRight: gutterMargin
    },
    icon: {
      fontSize: font30,
      color: whiteText
    },
    menuItem: {
      fontSize: 14,
      paddingTop: 1,
      paddingBottom: 1
    }
  },
  appBarStyles: {
    ...appBarStyle,
    marginTop: 24
  },
  tabBarStyling: {
    activeTabStyle: { color: `${whiteText} !important` }
  }
};

export const DetailPageTitleStyle = {
  Row: styled(Box)({
    ...flexRow,
    justifyContent: "space-between"
  } as any),
  TitleText: styled(Typography)({
    ...appStyles.entityDashboard.titleText
  })
};

export const EntityTitleStyle = {
  Row: styled(Box)({
    ...flexRow,
    justifyContent: "space-between"
  } as any),
  TitleText: styled(Typography)({
    ...appStyles.entityDashboard.titleText
  }),
  StyledIconBtn: styled(IconButton)({
    ...entityTitleIconBtn
  }),
  StyledIconBox: styled(Box)({
    ...entityTitleIconBtn
  })
};

export const HeaderStyle = {
  RowBox: styled(Box)({
    ...(flexRow as CSSProperties)
  }),
  NameBox: styled(Box)({
    ...(nameBox as CSSProperties)
  }),
  HeaderContent: styled(Box)({
    height: `${headerHeight}px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `${standardBorderWidth}px solid ${headerBorder}`,
    padding: standardPadding,
    backgroundColor: whiteText,
    zIndex: 500,
    position: "relative",
    alignItems: "center",
    marginLeft: "-25px"
  }),
  IconContainer: styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "25px"
  }),
  Text: styled(Typography)({}),
  BoldText: styled(Typography)({
    fontWeight: "bold"
  })
};

export const ListPageMainTableStyle = {
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    boxShadow: "none",
    borderLeft: `0.5px solid ${defaultBorder}`,
    borderBottom: `0.5px solid ${defaultBorder}`,
    borderRight: `0.5px solid ${defaultBorder}`,
    borderRadius: "0 0 4px 4px"
  },
  arrowWrapper: {
    display: "flex",
    minWidth: "100px",
    justifyContent: "space-around",
    marginLeft: "15px"
  },
  leftArrow: {
    color: grayText,
    cursor: "pointer"
  },
  rightArrow: {
    color: grayText,
    cursor: "pointer"
  },
  tableContainer: {
    height: "100%",
    "& table tbody tr:last-child td": {
      borderBottom: 0
    }
  },
  PagerRow: styled(Box)({
    display: "flex",
    justifyContent: "right",
    padding: `${pagerContainerPadding}px`,
    borderTop: `0.5px solid ${defaultBorder}`
  })
};

export const ListPageMainTableBodyStyle = {
  dropDownStyles: {
    container: {
      display: "flex",
      justifyContent: "end"
    },
    icon: {
      fontSize: font30,
      color: defaultIcon,
      cursor: "pointer"
    },
    menuItem: {
      fontSize: 14,
      paddingTop: 1,
      paddingBottom: 1
    }
  },
  tableCell: {
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  hover: {
    "&:hover": {
      background: `${tableRowHoverColor} !important`
    },
    "&:focus": {
      background: `${tableRowHoverColor} !important`
    }
  }
};

export const CondensedTableStyle = {
  boxShadow: "0",
  border: `1px solid ${defaultBorder}`
};

export const ListPageMainTableHeadStyle = {
  headerCell: {
    background: tableHeaderCellBG,
    overflow: "overlay"
  },
  defaultHeaderStyles: {
    borderLeft: tableBorder,
    borderRight: tableBorder,
    color: primaryTheme
  }
};

export const ListPageTitleStyle = {
  row: {
    ...flexRow,
    justifyContent: "space-between"
  },
  titleText: appStyles.entityDashboard.titleText
};

export const SidebarStyle = {
  SidebarContent: styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    background: defaultBG,
    padding: "8px 0 8px 8px"
  }),
  SubListWrapper: styled("div")({
    marginLeft: "4px",
    color: secondaryText,
    // background: appBarLightBackground,
    // height: "fit-content",
    width: "155px",
    borderRadius: "4px",
    height: "100%",
    padding: "4px",
    border: `1px solid ${appBarLightBackground}`
  }),
  ListWrapper: styled("div")({
    width: "90px",
    color: whiteText,
    background: sidebarSelectedItemBG,
    padding: "4px",
    borderRadius: "4px"
  }),
  StyledListItem: styled(ListItem)({
    width: "100%",
    // color: whiteText,
    color: secondaryText,
    display: "flex",
    flexDirection: "column",
    "&.Mui-selected": {
      color: whiteText,
      backgroundColor: `${tabBarBackground} !important`
    },
    "&:focus": {
      // color: whiteText,
      opacity: 0.9
      // backgroundColor: `${appBarBackground} !important`
      // backgroundColor: `${tabBarBackground} !important`
    }
  }),
  StyledListSubItem: styled(ListItem)({
    padding: "4px",
    width: "100%",
    color: secondaryText,
    display: "flex",
    flexDirection: "column",
    background: appBarLightBackground,
    border: `1px solid ${tabBarBackground}`,
    "&.Mui-selected": {
      color: whiteText,
      backgroundColor: `${tabBarBackground} !important`
    },
    "&:focus": {
      opacity: 0.9
      // backgroundColor: `${tabBarBackground} !important`
    }
  }),
  StyledDivider: styled(Divider)({
    backgroundColor: sidebarDivider,
    opacity: "50%"
  }),
  FooterList: styled(List)({
    color: whiteText,
    padding: 0,
    bottom: 20,
    left: 8,
    position: "fixed",
    cursor: "pointer"
  }),
  iconStyle: {
    marginRight: "0.5em",
    width: mIconSize,
    height: mIconSize
  }
};

// components > consentForm
export const FormBuilderEditorFormStyle = {
  Row: styled(Box)({
    ...spaceBetweenRow,
    paddingBottom: margin30,
    flex: 1
  } as any),
  Left: styled(Box)({
    ...formContent,
    paddingRight: standardPadding
  }),
  Right: styled(Box)({
    ...formContent,
    paddingLeft: standardPadding
  }),
  FormAttributesContainer: styled(Box)({
    flex: 1
  }),
  FlexBox: styled(Box)({
    ...flexColumn,
    ...flexOne
  } as any),
  SubTitleContainer: styled(Box)({
    ...flexRow,
    paddingBottom: miniPadding
  } as any)
};

export const FormBuilderFooterStyle = {
  Row: styled(Box)({
    ...footerRow,
    flex: 1
  } as any),
  Left: styled(Box)({
    ...justifyLeft,
    flexShrink: flexShrinkZero
  } as any),
  Right: styled(Box)({
    ...justifyRight,
    flexShrink: flexShrinkZero
  } as any)
};

export const FormUploadsStyle = {
  textAreaStyle: {
    ...formBuilderTextInput
  },
  FlexBox: styled(Box)({
    ...flexColumn,
    ...flexOne
  } as any),
  SubTitleContainer: styled(Box)({
    ...flexRow,
    paddingBottom: miniPadding
  } as any),
  PdfTitleContainer: styled(Box)({
    ...flexRow,
    paddingTop: smallPadding,
    paddingBottom: miniPadding
  } as any),
  TitleText: styled(Typography)({
    ...appStyles.entityDashboard.titleText,
    color: breadCrumbActive
  })
};

// components > consentFormPreview
export const FormBuilderPreviewFormStyle = {
  PreviewWindowContainer: styled(Box)({
    width: fullWidth
  } as any),
  flexEnd: {
    justifyContent: "flex-end"
  },
  prevButtonStyle: {
    width: standardPostAmbleBtn,
    marginRight: `${margin10}px`
  },
  nextButtonStyle: {
    width: standardPostAmbleBtn
  },
  DesktopSectionTitle: styled(Box)({
    ...sectionTitleStyles.desktop
  }),
  MobileSectionTitle: styled(Box)({
    ...sectionTitleStyles.mobile
  }),
  SectionContainer: styled(Box)<{ web: boolean }>(({ web }) => ({
    padding: web ? 16 : 5
  })),
  MobileHeader: styled(Box)({
    ...mobileHeader
  } as any),
  OuterSectionContainer: styled(Box)({
    ...outerSection,
    height: "100%"
  } as any),
  TitleText: styled(Typography)({
    ...appStyles.consentDetail.consentDetailSectionHeader.titleText
  }),
  PostAmbleContainer: styled(Box)({
    ...postAmble,
    width: "100%",
    paddingTop: gutterMargin
  } as any),
  DesktopSectionBox: styled(Box)({
    paddingLeft: gutterMargin,
    paddingRight: gutterMargin
  })
};

// components > organization
export const OrganizationSettingsForm = {
  SettingsHeader: styled(Typography)({
    color: subTitleText,
    fontSize: subTitleFontSize,
    fontWeight: 500,
    marginBottom: smallMargin,
    marginTop: "2em"
  })
};

export const FormPreviewToolbarStyle = {
  formStyle: {
    ...flexRow
  },
  buttonStyle: {
    marginRight: `${margin20}px`
  },
  ControlContainer: styled(Box)({
    ...spaceBetweenRow,
    backgroundColor: formToolbarBackground,
    color: grayText,
    padding: "8px"
  } as any),
  ButtonsContainer: styled(Box)({
    ...termsDoneButton,
    marginTop: smallPadding
  }),
  LabelContainer: styled(Box)({
    ...autoMargin,
    paddingRight: smallPadding
  })
};

// components > wizards
export const SendConsentFormsWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    padding: 0,
    width: 1000,
    height: 696
  }),
  WizardPaperMobile: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    padding: 0,
    width: "100%",
    height: "100%"
  }),
  bodyElementStyles: {
    ...sendConsentsBodyElement
  },
  ListTitle: styled(Typography)({
    ...attachmentListTitle
  }),
  iconStyles: {
    fontSize: 24,
    color: defaultIcon,
    position: "relative",
    top: "3px"
  },
  itemIconStyles: {
    fontSize: 24,
    color: defaultIcon
  },
  accordionStyles: {
    marginBottom: 15,
    borderRadius: 0
  },
  itemStyles: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    backgroundColor: theme.secondaryGray,
    width: "100%",
    borderRadius: 4
  },
  formInputStyles: {
    padding: standardPadding
  },
  nestedItemStyles: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  secondaryItemStyles: {
    display: "flex",
    alignItems: "center",
    margin: "5px 0px",
    width: "100%"
  },
  subtitleStyles: {
    paddingLeft: 5,
    paddingRight: 15
  },
  modalStyles: {
    width: 900,
    margin: "0 25px"
  },
  CssTextField: styled(InputBase)({
    padding: "0 10px",
    backgroundColor: whiteText,
    border: "2px solid rgba(0,0,0,0.08)",
    borderRadius: 4,
    underline: {
      "&&&:before": {
        border: "none"
      },
      "&&:after": {
        border: "none"
      }
    }
  }),
  buttonStyles: {
    backgroundColor: selectButtonBackground,
    fontSize: 14,
    marginLeft: `${margin10}px`,
    maxHeight: actionRibbonButtonHeight,
    padding: "10px 25px",
    "& span": {
      lineHeight: "normal"
    }
  }
};

export const FormViewerWizardStyle = {
  WizardPaper: styled(Paper)({
    ...wizardPaperPadding,
    height: `${formViewerWizardDimensions}vh`,
    width: `${formViewerWizardDimensions}vw`
  } as any),
  WizardPaperMobile: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    padding: 0,
    width: "100%",
    height: "100%"
  }),
  HistoryContainer: styled(Box)({
    margin: `${zero} ${gutterMargin}px`,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column"
  }),
  wizardBodyStyles: { height: "100%" },
  MainContainer: styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 35%",
    height: "100%",
    overflow: "auto"
  }),
  pdfDocument: {
    height: "100%"
  },
  pdfPage: {
    margin: `${gutterMargin}px 0`,
    border: `solid ${standardBorderWidth}px ${defaultBorder}`
  },
  container: {
    overflow: "auto"
    // maxWidth: "100%"
  }
};

export const ManuallyRecordConsentWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    height: `${formViewerWizardDimensions}vh`,
    width: `${runReportModalDimensions}vw`
  }),
  WizardPaperMobile: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    width: "100%",
    height: "100%"
  })
};

export const MobileWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    minHeight: `100%`,
    minWidth: `100%`,
    padding: "24px"
  })
};

export const SelectOrganizationWizardStyleStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `${formViewerWizardDimensions}vh`,
    width: `${runReportModalDimensions}vw`
  }),
  wizardBodyStyles: { height: "100%", padding: "30px", overflow: "auto" }
};

export const AddItemWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    height: `${formViewerWizardDimensions}vh`,
    width: `${runReportModalDimensions}vw`
  }),
  wizardBodyStyles: { height: "100%", padding: "30px" }
};

export const BaseWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties)
  }),
  WizardPaperMobile: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    width: "100%",
    height: "100%"
  }),
  wizardBodyStyles: { height: "100%", padding: 0 },
  wizardBodyStylesMobile: { height: "100%", width: "100%", padding: 0 }
};

export const EditProfileStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `${formViewerWizardDimensions}vh`,
    maxWidth: `${runReportModalDimensions}vw`
  }),
  wizardBodyStyles: { padding: 0, height: "400px" }
};

export const SaveScvStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `${formViewerWizardDimensions}vh`,
    maxWidth: `${runReportModalDimensions}vw`
  }),
  wizardBodyStyles: { padding: 0, height: "250px" }
};

export const CopyRoleWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `${formViewerWizardDimensions}vh`,
    maxWidth: `${runReportModalDimensions}vw`
  }),
  wizardBodyStyles: { height: "100%", padding: "0 30px 30px 30px", overflow: "auto" }
};

export const TrackUserActivityStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `300px`,
    maxWidth: `300px`
  }),
  wizardBodyStyles: { height: "100%", padding: "30px", overflow: "auto" }
};

export const CreateOrganizationStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    maxHeight: `80vh`,
    maxWidth: `600px`
  }),
  wizardBodyStyles: { height: "100%", padding: "30px", overflow: "auto" }
};

export const RunReportWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(wizardPaperPadding as CSSProperties),
    height: `${formViewerWizardDimensions}vh`,
    width: `${runReportModalDimensions}vw`,
    maxHeight: `${runReportModalDimensions}vh`
  }),
  HistoryContainer: styled(Box)({
    width: "60%",
    margin: runReportModalCenterMargins
  }),
  TextContainer: styled(Box)({
    height: "80%"
  }),
  wizardBodyStyles: { height: "100%" },
  BodyText: styled(Typography)({ textAlign: "center", marginBottom: wizardConfirmationIconSize })
};

export const WizardAttachmentListStyle = {
  ListTitle: styled(Typography)({
    ...attachmentListTitle
  }),
  RootContainer: styled(Box)({
    ...attachmentRootContainer
  }),
  ListContainer: styled(Box)({
    ...attachmentListContainer
  }),
  ItemBox: styled(Box)({
    ...attachmentListItem
  })
};

export const WizardStyle = {
  RowContainer: styled(Box)({
    ...(wizardBody as CSSProperties)
  }),
  ColumnContainer: styled(Box)({
    ...(wizardBodyColumn as CSSProperties)
  }),
  ButtonContainer: styled(Box)({
    ...wizardButtonContainer,
    position: "absolute",
    bottom: 10,
    width: 1000
  }),
  wizardButton: {
    ...defaultWizardButton
  },
  CenteredContainer: styled(Box)({
    ...(centeredAlignedContainer as CSSProperties)
  }),
  Icon: styled(CheckCircleIcon)({
    fontSize: wizardConfirmationIconSize,
    color: defaultIcon
  }),
  Text: styled(Typography)({
    padding: miniPadding
  }),
  TitleText: styled(Typography)({
    ...(wizardTitleText as CSSProperties),
    padding: zero,
    margin: `${zero} ${gutterMargin}px ${gutterMargin}px ${zero}`,
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  }),
  LargeTitleText: styled(Typography)({
    ...(wizardTitleText as CSSProperties),
    fontSize: "24px",
    padding: 0,
    paddingTop: 5
  }),
  root: wizardRecipientChoice
};

export const FormBuilderHeaderStyle = {
  HeaderContainer: styled(Box)({
    backgroundColor: defaultBG,
    display: "flex",
    alignItems: "center"
  })
};

export const DetailPageBreadCrumbsStyle = {
  HeaderContainer: styled(Box)({
    backgroundColor: defaultBG,
    display: "flex",
    alignItems: "center"
  })
};

// screens

export const ConsumersStyle = {
  EmptyFiller: styled(Box)({
    textAlign: "left",
    width: "100%",
    zIndex: 1000,
    padding: gutterMargin,
    maxHeight: `${tableHeight}px`,
    height: `${tableHeight}px`
  })
};

export const DashboardStyle = {
  appBarStyles: {
    ...(searchableAppBarStyles as CSSProperties)
  }
};

export const FormsStyle = {
  OuterPaper: styled(Paper)({
    padding: gutterMargin
  }),
  FormBuilderShellContainer: styled(Box)({
    ...shellContainer
  } as any),
  HeaderContainer: styled(Box)({
    ...header,
    paddingBottom: `${margin10}px`
  }),
  FormContainer: styled(Box)({
    ...form
  }),
  FooterContainer: styled(Box)({
    ...footer
  }),
  buttonStyle: {
    marginRight: `${margin20}px`
  },
  ButtonsContainer: styled(Box)({
    ...termsDoneButton,
    marginRight: "5px",
    marginBottom: "16px"
  })
};

export const LoginStyle = {
  linkText: {
    "&: hover": {
      cursor: "pointer"
    },
    color: textLink
  },
  LinksBox: styled(Box)({
    ...flexCenter
  }),
  LinkBox: styled(Box)({
    ...flexCenter
  })
};

export const OrganizationsStyle = {
  EmptyFiller: styled(Box)({
    textAlign: "left",
    width: "100%",
    zIndex: 1000,
    padding: gutterMargin,
    maxHeight: `${tableHeight}px`,
    height: `${tableHeight}px`
  }),
  TreeListContainer: styled(Box)({
    paddingTop: standardPadding
  })
};

export const ProfileStyle = {
  StyledPaper: styled(Paper)({
    padding: standardPadding
  }),
  FormContainer: styled(Box)({
    minWidth: profileFormMinWidth
  }),
  ButtonBox: styled(Box)({
    display: "flex",
    marginTop: moderatePadding
  }),
  Line: styled(Box)({
    border: `1px solid ${headerBorder}`,
    marginTop: standardPadding,
    marginBottom: standardPadding
  })
};
