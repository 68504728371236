import type { ReactElement } from "react";
import { Box, styled, Stack } from "@mui/material";
import BaseButton from "../../BaseButton";
import { appConstants, appStyles } from "../../../assets";
import type { BaseButtonMeta } from ".";

const { space, flexCenter, textButton } = appStyles;
const { isDesktopDevice } = appConstants;

interface SetupPostAmbleWrapperProps {
  children: ReactElement | null;
  cancelButton: BaseButtonMeta | null;
  submitButton: BaseButtonMeta | null;
  submitButtonDisabled?: boolean;
}

const SetupPostAmbleWrapper = ({
  submitButtonDisabled,
  children,
  cancelButton,
  submitButton
}: SetupPostAmbleWrapperProps) => {
  const isDesktop = isDesktopDevice();

  return (
    <>
      <ButtonBox>
        {isDesktop ? (
          <Stack direction="row" spacing={3}>
            {!!cancelButton && (
              <BaseButton
                ariaLabel={cancelButton.text}
                id="cancel"
                variant="outlined"
                onPress={(): void => cancelButton.onPress()}
              >
                {cancelButton.text}
              </BaseButton>
            )}
            {!!submitButton && (
              <BaseButton
                disabled={submitButtonDisabled}
                ariaLabel={submitButton.text}
                id="submit"
                color="primary"
                onPress={(): void => submitButton.onPress()}
              >
                {submitButton.text}
              </BaseButton>
            )}
          </Stack>
        ) : (
          <Stack direction="column" spacing={2}>
            {!!submitButton && (
              <BaseButton
                disabled={submitButtonDisabled}
                id="submit"
                ariaLabel={submitButton.text}
                color="primary"
                onPress={(): void => submitButton.onPress()}
              >
                {submitButton.text}
              </BaseButton>
            )}
            {!!cancelButton && (
              <BaseButton
                id="cancel"
                ariaLabel={cancelButton.text}
                variant="text"
                style={textButton}
                onPress={(): void => cancelButton.onPress()}
              >
                {cancelButton.text}
              </BaseButton>
            )}
          </Stack>
        )}
      </ButtonBox>
      {children}
    </>
  );
};

export default SetupPostAmbleWrapper;

const { standardPadding } = appConstants;

const Space = styled(Box)({
  ...space
});

const ButtonBox = styled(Box)({
  ...flexCenter,
  marginTop: standardPadding,
  marginBottom: standardPadding
});
