import { useRef, useState, MutableRefObject } from "react";
import { Box, Button, InputLabel, styled, TextField, InputAdornment } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { appStyles, appConstants } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";

const {
  formBuilder: {
    formUploads: { PDFField: pdfField, attachIcon }
  },
  flexColumn,
  flexOne,
  flexRow,
  generalInputLabel,
  forceEllipsis,
  displayNone,
  customAsteriskStyle
} = appStyles;

const { miniPadding } = appConstants;

const TouchableField = styled(Button)({
  ...pdfField
});

const FlexBoxRow = styled(Box)({
  ...flexRow,
  paddingBottom: miniPadding
} as any);

const StyledIcon = styled(AttachFileIcon)({
  ...attachIcon
});

const FlexBox = styled(Box)({
  ...flexColumn,
  ...flexOne
} as any);

const Label = styled(InputLabel)({
  ...generalInputLabel
});

function PDFField({ fieldInfo, setFieldValue, error, value: _value }: InteractiveFieldProps) {
  const { label, defaultPDFName, name: fieldName = "upload", withIcon = true, disabled = false } = fieldInfo;
  const input = useRef<HTMLInputElement>(null);
  const [pdfName, setPdfName] = useState(defaultPDFName ? defaultPDFName : "");
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";
  const activateInput = (inputEl: MutableRefObject<HTMLInputElement | null>) => {
    if (inputEl.current) {
      inputEl.current.click();
    }
  };

  const handleFileUpload = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    setPdfName(file.name);
    setFieldValue(fieldName, file);
  };

  return (
    <FlexBoxRow>
      <FlexBox>
        {label && (
          <Label>
            {label} <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
          </Label>
        )}
        <TouchableField onClick={() => activateInput(input)} disableRipple={true}>
          <TextField
            disabled={disabled}
            fullWidth
            value={pdfName ?? ""}
            InputProps={{
              sx: { "& input": forceEllipsis },
              placeholder: "PDF Template",
              readOnly: true,
              startAdornment: withIcon ? (
                <InputAdornment position="start" style={{ height: "unset" }}>
                  <StyledIcon />
                </InputAdornment>
              ) : null
            }}
            error={Boolean(error)}
            helperText={error}
            required={fieldRequired}
            FormHelperTextProps={{
              style: {
                textTransform: "none"
              }
            }}
            variant={fieldInfo.variant ?? "standard"}
            id={fieldInfo.name}
          />
          <input
            type="file"
            disabled={disabled}
            style={displayNone}
            ref={input}
            name={fieldName}
            accept="application/pdf"
            onChange={handleFileUpload}
          />
        </TouchableField>
      </FlexBox>
    </FlexBoxRow>
  );
}

export default PDFField;
