import { ConsumerItemPageConfiguration } from "./types";
import {
  createDetailActions,
  createDetailEntityName,
  createDetailInitialTab,
  createDetailInitialValues,
  createDetailPostAmble,
  createDetailRows,
  createDetailTabs,
  createNewActions,
  createNewDocumentTitle,
  createNewEntityName,
  createNewPostAmble,
  createNewRows,
  useDetailEffects,
  useDetailInitialQuery,
  useNewInitialQuery,
  additionalButtonsList
} from "./lib";
import assets from "../../../ui/assets";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    admin: {
      consumer: { list }
    }
  }
} = assets;
const consumerItemPageConfiguration: ConsumerItemPageConfiguration = {
  common: {
    listPageLabel: list.listPageHeader,
    listPageUrl: routes.CONSUMERS
  },
  new: {
    createActions: createNewActions,
    createEntityName: createNewEntityName,
    createRows: createNewRows,
    useInitialQuery: useNewInitialQuery,
    createPostAmble: createNewPostAmble
  },
  detail: {
    initialCustomStates: { editMode: true, additionalExternalIdFields: {} },
    useInitialQuery: useDetailInitialQuery,
    createActions: createDetailActions,
    createDocumentTitle: createNewDocumentTitle,
    createRows: createDetailRows,
    createPostAmble: createDetailPostAmble,
    createInitialValues: createDetailInitialValues,
    createEntityName: createDetailEntityName,
    useEffects: useDetailEffects
  },
  detail_view: {
    initialCustomStates: { editMode: false, additionalExternalIdFields: {} },
    useInitialQuery: useDetailInitialQuery,
    // createActionMenuMeta: createDetailActionMenuMeta,
    createActions: createDetailActions,
    createDocumentTitle: createNewDocumentTitle,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    createTabs: createDetailTabs,
    createInitialTab: createDetailInitialTab,
    useEffects: useDetailEffects,
    additionalButtonsList: additionalButtonsList
  }
};

export default consumerItemPageConfiguration;
