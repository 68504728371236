import SelectorField from "./SelectorField";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { memo } from "react";
import { fieldValueEqual } from "../../../utils";

const GenderField = ({ fieldInfo, value, setFieldValue, error = "" }: InteractiveFieldProps) => {
  fieldInfo.options = [
    {
      name: "male",
      label: "Male"
    },
    {
      name: "female",
      label: "Female"
    },
    {
      name: "other",
      label: "Other"
    }
  ];

  fieldInfo.labelStyle = "floating";

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />;
  } else return <SelectorField fieldInfo={fieldInfo} value={value} setFieldValue={setFieldValue} error={error} />;
};

export default memo(GenderField, fieldValueEqual);
