import { useCallback } from "react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "@midato-mono/common/src/hooks";
import ACTIONS from "../actions";
import assets from "../../ui/assets";
import { routes } from "../../Router/constants";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";

const {
  appStrings: {
    admin: {
      sidebar: { labels }
    }
  }
} = assets;

const useSidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const checkPermissions = ({ actionId }: { actionId?: string; key: string; label: string }) => {
    if (actionId === undefined) {
      return true;
    } else {
      return (ACTIONS as any)?.[actionId]?.requiredScopes.every((permission: string) =>
        permissions?.includes(permission)
      );
    }
  };

  const menuItems = [
    {
      key: routes.CONSENT_MGMT,
      path: routes.CONSUMERS,
      icon: <HandshakeIcon aria-label="Handshake Icon" />,
      label: labels.consentMgmt,
      actionIds: ["CONSUMER_LIST", "CONSENT_LIST", "OCR_RESULTS_LIST"],
      tabIndex: 1,
      subItems: [
        { key: routes.CONSUMERS, label: labels.consumers, actionId: "CONSUMER_LIST", tabIndex: 2 },
        { key: routes.CONSENTS, label: labels.consents, actionId: "CONSENT_LIST", tabIndex: 3 },
        { key: routes.OCR_RESULTS, label: labels.ocrResults, actionId: "OCR_RESULTS_LIST", tabIndex: 4 }
      ].filter(checkPermissions)
    },
    {
      key: routes.ADMIN,
      label: labels.admin,
      icon: <AdminPanelSettingsIcon aria-label="Admin panel Settings Icon" />,
      path: routes.ORGANIZATIONS,
      tabIndex: 10,
      actionIds: ["ORGANIZATION_LIST", "USER_LIST", "SECURITY_ROLES_LIST", "FORM_LIST"],
      subItems: [
        { key: routes.ORGANIZATIONS, label: labels.organizations, actionId: "ORGANIZATION_LIST", tabIndex: 11 },
        { key: routes.PRACTITIONERS, label: labels.practitioners, actionId: "PRACTITIONER_LIST", tabIndex: 12 },
        { key: routes.USERS, label: labels.users, actionId: "USER_LIST", tabIndex: 13 },
        { key: routes.SECURITY_ROLES, label: labels.securityRoles, actionId: "SECURITY_ROLES_LIST", tabIndex: 14 },
        { key: routes.FORMS, label: labels.forms, actionId: "FORM_LIST", tabIndex: 15 },
        { key: routes.EXPORTS, label: labels.forms, actionId: "EXPORTS_LIST", tabIndex: 16 },
        { key: routes.IMPORTS, label: labels.forms, actionId: "IMPORTS_LIST", tabIndex: 17 }
      ].filter(checkPermissions)
    },
    {
      key: routes.REPORTS,
      label: labels.reports,
      path: routes.ACTIVITY_REPORT,
      icon: <AssessmentIcon aria-label="Assessment Icon" />,
      tabIndex: 20,
      actionIds: [
        "REPORT_USER_ACTIVITY_LOG",
        "REPORT_CONSENT_STATISTICS",
        "REPORT_EXPIRY_LOG",
        "REPORT_CONSENT_LOG",
        "REPORT_USAGE_LOG"
      ],
      subItems: [
        {
          key: routes.ACTIVITY_REPORT,
          label: labels.activityReport,
          actionId: "REPORT_USER_ACTIVITY_LOG",
          tabIndex: 21
        },
        { key: routes.EXPIRY_REPORT, label: labels.expiryReport, actionId: "REPORT_EXPIRY_LOG", tabIndex: 22 },
        { key: routes.CONSENT_REPORT, label: labels.consentReport, actionId: "REPORT_CONSENT_LOG", tabIndex: 23 },
        {
          key: routes.CONSENT_STATISTICS_REPORT,
          label: labels.consentStatisticsReport,
          actionId: "REPORT_CONSENT_STATISTICS",
          tabIndex: 24
        },
        { key: routes.USAGE_REPORT, label: labels.usageReport, actionId: "REPORT_USAGE_LOG", tabIndex: 25 },
        { key: routes.LOAD_TIME_REPORT, label: labels.loadTimeReport, actionId: "REPORT_LOAD_TIME", tabIndex: 26 }
        // { key: routes.DASHBOARD, label: labels.dashboard, actionId: "DASHBOARD_GET" , tabIndex:26,}
      ].filter(checkPermissions)
    }
  ].filter(({ actionIds }: { actionIds?: string[] }) => {
    if (actionIds === undefined) {
      return true;
    } else {
      const visibleItemsCount = actionIds
        .map((actionId) =>
          (ACTIONS as any)?.[actionId]?.requiredScopes.every((permission: string) => permissions?.includes(permission))
        )
        .filter((item) => !!item);
      return visibleItemsCount.length > 0;
    }
  });

  const handleItemClicked = useCallback(
    (path: string) => {
      if (path !== pathname) {
        navigate(path);
      }
    },
    [navigate, pathname]
  );

  return { pathname, menuItems, handleItemClicked };
};

export default useSidebar;
