import React from "react";
import { Box } from "@mui/material";
import { appConstants } from "../assets";

const { gutterMargin, isDesktopDevice } = appConstants;

const DashView = ({ children, nopadding = false }: { children: React.ReactNode; nopadding: boolean }) => {
  const isDesktop = isDesktopDevice();
  return isDesktop ? (
    <Box sx={{ padding: nopadding ? 0 : "8px 24px 24px 24px" }}>{children}</Box>
  ) : (
    <Box>{children}</Box>
  );
};

export default DashView;
