import * as React from "react";
import { lazy, useRef, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { appStyles } from "../assets";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const BaseButton = lazy(() => import("@midato-mono/common/src/components/BaseButton"));

// TODO: uncomment this once deploy script is fixed and correctly loading the worker file
// pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

// Hotfix: load worker file from CDN while deploy script is failing
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

interface PreviewPDFProps {
  logUserEvent?: (type: string) => void;
  fileUrl?: string;
  onLoadSuccess?: () => void;
  scale?: number;
  height?: number;
  width?: number;
  classes?: { pdfDocument?: any; pdfPage?: any; container?: any };
  viewMode?: boolean;
  hasDownloadPermission: boolean;
  hasPrintPermission: boolean;
}

const { pdfViewer } = appStyles;

const PreviewPDF = ({
  logUserEvent,
  fileUrl,
  onLoadSuccess,
  scale: defaultScale,
  width,
  height,
  classes,
  viewMode,
  hasDownloadPermission,
  hasPrintPermission
}: PreviewPDFProps) => {
  const containerRef = useRef();
  const [numPages, setNumPages] = useState<number>(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    onLoadSuccess?.();
    zoomToFit();
  };
  const [scale, setScale] = useState(defaultScale || 1.0);

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "document.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    logUserEvent?.("consentDownload");
  };

  const handlePrint = () => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = fileUrl;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow.print();
      logUserEvent?.("consentPrint");
    };
  };

  const options = useMemo(
    () => ({
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@2.13.216/standard_fonts/`
    }),
    []
  );

  const zoomToFit = () => {
    // @ts-expect-error will be fixed later
    const containerWidth = containerRef?.current?.offsetWidth;
    const pageWidth = 700;

    if (containerWidth) {
      // Calculate the scale to fit the page within the container
      const calculatedScale = containerWidth / pageWidth;
      setScale(calculatedScale > 1 ? Math.floor(calculatedScale) : calculatedScale);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "15px 0px",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <ZoomInIcon
          tabIndex={0}
          style={{ width: "37px", height: "37px" }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleZoomIn();
            }
          }}
          onClick={handleZoomIn}
        />
        <ZoomOutIcon
          tabIndex={0}
          style={{ width: "37px", height: "37px" }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleZoomOut();
            }
          }}
          onClick={handleZoomOut}
        />
        {!viewMode && (
          <>
            {hasDownloadPermission && (
              <BaseButton
                style={{ marginLeft: "16px" }}
                id="btn-download"
                color="primary"
                variant="outlined"
                onPress={handleDownload}
                ariaLabel="Download PDF"
              >
                Download PDF
              </BaseButton>
            )}
            {hasPrintPermission && (
              <BaseButton
                style={{ marginLeft: "16px" }}
                id="btn-download"
                color="primary"
                variant="outlined"
                onPress={handlePrint}
                ariaLabel="Print"
              >
                Print
              </BaseButton>
            )}
          </>
        )}
      </div>
      <Box
        ref={containerRef}
        tabIndex={0}
        sx={pdfViewer}
        style={{
          position: "relative",
          maxWidth: "100%",
          width: "100%",
          height: "calc( 100% - 40px)",
          overflow: "auto"
        }}
        className={classes?.container}
      >
        <Document
          className={classes?.pdfDocument}
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          options={options}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className={classes?.pdfPage}
              pageNumber={index + 1}
              scale={scale}
              height={height}
              width={width}
            />
          ))}
        </Document>
      </Box>
    </Box>
  );
};

export default PreviewPDF;
