import { ReactElement, ReactNode, CSSProperties } from "react";
import assets from "../../assets";

const {
  appStyles: {
    WizardStyle: { RowContainer, ColumnContainer }
  }
} = assets;

interface WizardBodyProps {
  children: ReactNode;
  flexDirection?: "row" | "column";
  style?: CSSProperties;
}

function WizardBody({ children, flexDirection = "row", style }: WizardBodyProps): ReactElement {
  return flexDirection === "row" ? (
    <RowContainer style={{ ...style }}>{children}</RowContainer>
  ) : (
    <ColumnContainer style={{ ...style }}>{children}</ColumnContainer>
  );
}

export default WizardBody;
