/* eslint-disable max-params */
import axios from "axios";
import buildFragments from "./graphql-fragments";

export const getValue = (obj) => {
  if (Object.keys(obj).length === 1 && !Array.isArray(obj)) {
    return getValue(obj[Object.keys(obj)[0]]);
  } else {
    return obj;
  }
};

class CustomGraphQLError extends Error {
  constructor(message: string, errorData: any) {
    super(message);
    // @ts-ignore
    this.errorData = errorData;
  }
}

export const graphql = async (url, queryString, variables = {}, auth, simplify = true) => {
  const selectedOrgId = window.localStorage.getItem("midato:selectedOrganization");
  const headers = {
    ...(selectedOrgId ? { "session-org-id": selectedOrgId } : {}),
    ...(auth ? { Authorization: auth } : {})
  };

  const fragments = buildFragments(queryString);
  const query = `
    ${fragments}
    ${queryString}
  `;

  try {
    const resp = await axios({
      method: "post",
      url,
      headers,
      data: {
        query,
        variables: JSON.stringify(variables)
      }
    });

    const { data, errors } = resp.data;

    if (errors) {
      return {
        data: null,
        error: new CustomGraphQLError(errors?.[0]?.message ?? "server error", errors?.[0])
      };
    } else {
      return {
        data: simplify ? getValue(data) : data,
        error: null
      };
    }
  } catch (error) {
    console.log(error);
    return {
      data: null,
      error
    };
  }
};
