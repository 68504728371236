import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentWizard,
  setActiveWizard,
  resetWizard,
  setWizardError,
  addExtraInfo
} from "../redux/reducers/wizard";
import { WizardName } from "../types";

interface HandledResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  status: string;
  // todo - not sure if this makes sense as a generic response field
  statusText: string;
}
interface WizardError {
  errorMessage: string;
  errors: ReadonlyArray<HandledResponse>;
}

const useWizard = () => {
  const wizardState = useSelector(selectCurrentWizard);

  const dispatch = useDispatch();

  const closeWizard = () => {
    dispatch(resetWizard());
    return {};
  };

  const launchWizardByName = (wizardName: WizardName) => {
    dispatch(setActiveWizard({ activeWizard: wizardName }));
  };

  const setErrorOfWizard = (wizardError: WizardError) => {
    dispatch(setWizardError({ wizardError }));
  };

  const setExtraInfo = (extraInfo: object) => {
    dispatch(addExtraInfo(extraInfo));
  };

  const launchFormViewerWizard = (
    consentId: string,
    consumerId: string,
    consumerFirstName?: string,
    consumerLastName?: string
  ) => {
    setExtraInfo({ consentId, consumerId, consumerFirstName, consumerLastName });
    launchWizardByName("FormViewer");
  };

  const launchInviteConsumerWizard = () => {
    launchWizardByName("InviteConsumer");
  };

  const launchSendConsentsWizard = (consumerId: string, firstName: string, lastName: string) => {
    setExtraInfo({ consumerId, firstName, lastName });
    launchWizardByName("SendConsents");
  };

  const launchSendConsentsToGuestWizard = (consumerId: string, firstName: string, lastName: string) => {
    setExtraInfo({ consumerId, firstName, lastName });
    launchWizardByName("SendConsentsToGuest");
  };

  const launchCreateConsentWizard = (consumerId: string, firstName: string, lastName: string) => {
    setExtraInfo({ consumerId, firstName, lastName });
    launchWizardByName("CreateConsentWizard");
  };

  const launchConsumerListPageWizard = () => {
    launchWizardByName("ConsumerNewListPageWizard");
  };

  const launchPractitionerListPageWizard = () => {
    launchWizardByName("PractitionerNewListPageWizard");
  };

  const launchFormListPageWizard = () => {
    launchWizardByName("FormNewListPageWizard");
  };

  const launchUserListPageWizard = () => {
    launchWizardByName("UserNewListPageWizard");
  };

  const launchOrganizationListPageWizard = () => {
    launchWizardByName("OrganizationNewListPageWizard");
  };

  const launchUserRoleListPageWizard = () => {
    launchWizardByName("UserRoleNewListPageWizard");
  };

  const launchEditUserRoleWizard = () => {
    launchWizardByName("EditUserRoleWizard");
  };

  const launchEditConsumerWizard = () => {
    launchWizardByName("EditConsumerWizard");
  };

  const launchEditPractitionerWizard = () => {
    launchWizardByName("EditPractitionerWizard");
  };

  const launchEditFormWizard = () => {
    launchWizardByName("EditFormWizard");
  };

  const launchEditUserWizard = () => {
    launchWizardByName("EditUserWizard");
  };

  const launchEditOrganizationWizard = () => {
    launchWizardByName("EditOrganizationWizard");
  };

  const launchSelectOrganizationWizard = () => {
    launchWizardByName("SelectOrganizationWizard");
  };

  const launchCopyRoleWizard = (userRoleData: { [key: string]: unknown }) => {
    setExtraInfo({ userRoleData });
    launchWizardByName("CopyRoleWizard");
  };

  const launchChangePasswordWizard = () => {
    launchWizardByName("ChangePasswordWizard");
  };

  const launchEditProfileWizard = () => {
    launchWizardByName("EditProfileWizard");
  };

  const launchUploadConsentWizard = ({
    consumerId,
    consentId,
    form,
    formValues,
    uploadType
  }: {
    consumerId: string;
    consentId?: string;
    form?: { code: string; version: string; title: string } | null;
    formValues?: any;
    uploadType: "consent" | "consumer";
  }) => {
    launchWizardByName("UploadConsentWizard");
    setExtraInfo({ consumerId, consentId, form, formValues, uploadType });
  };

  const launchConsentRevokeWizard = (currentConsent: unknown) => {
    launchWizardByName("ConsentRevokeWizard");
    setExtraInfo({ currentConsent });
  };

  const launchLegalPoliciesWizard = () => {
    launchWizardByName("LegalPoliciesWizard");
  };

  return {
    wizardState,
    closeWizard,
    launchWizardByName,
    setErrorOfWizard,
    setExtraInfo,
    wizardFunctions: {
      launchLegalPoliciesWizard,
      launchConsentRevokeWizard,
      launchUploadConsentWizard,
      launchEditProfileWizard,
      launchChangePasswordWizard,
      launchFormViewerWizard,
      launchInviteConsumerWizard,
      launchSendConsentsWizard,
      launchSendConsentsToGuestWizard,
      launchCreateConsentWizard,
      launchConsumerListPageWizard,
      launchPractitionerListPageWizard,
      launchFormListPageWizard,
      launchUserListPageWizard,
      launchEditConsumerWizard,
      launchEditPractitionerWizard,
      launchEditFormWizard,
      launchEditUserWizard,
      launchEditOrganizationWizard,
      launchOrganizationListPageWizard,
      launchSelectOrganizationWizard,
      launchUserRoleListPageWizard,
      launchEditUserRoleWizard,
      launchCopyRoleWizard
    }
  };
};

export default useWizard;
