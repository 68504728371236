import { useMidatoQuery as useCommonMidatoQuery, useToaster, useSpinner, useAuth } from "@midato-mono/common/src/hooks";
import { httpErrorHandlerCreators } from "@midato-mono/common/src/utils";
import { tokenToAuthMapCallback } from "./../utils";
import { DecodedToken } from "./../types";

interface UseMidatoQueryProps {
  queryFn: Function;
  queryArgs: any;
  queryOptions?: any;
  isSuccessCallback?: (data: any) => void;
  isErrorCallback?: (error: any) => void;
  isLoadingCallback?: () => void;
  isFetchingCallback?: () => void;
  auth?: string;
  otherQueriesAreSuccess?: boolean;
}

const useMidatoQuery = (args: UseMidatoQueryProps) => {
  const { setAuth } = useAuth();
  const { closeSpinner } = useSpinner();
  const { displayAnNotificationMessage } = useToaster();
  return useCommonMidatoQuery({
    ...args,
    errorHandlers: {
      401: httpErrorHandlerCreators[401]<DecodedToken>({
        closeSpinner,
        setAuth,
        displayAnNotificationMessage,
        ClientId: import.meta.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID,
        tokenToAuthMapCallback
      })
    }
  });
};

export default useMidatoQuery;
