import { Typography, Box, Container, CardMedia, styled } from "@mui/material";
import type { SetupWrapperMeta } from ".";
import SetupNavButtons from "./SetupNavButtons";
import { appStyles, appColors, appConstants } from "../../../assets";

interface SetupWrapperProps {
  readonly children?: JSX.Element;
  readonly launchScreenLogo: string;
  readonly wrapperMeta?: SetupWrapperMeta;
}

// todo: apply dynamic vh and % values uniformly and via appConstants based on updated Style guide specs
const {
  flexCenter,
  setupwrapperBoxPadding,
  pageLogin: { headerText }
} = appStyles;

const { grayText } = appColors;
const { isDesktopDevice, margin20, mobileGutterMargin } = appConstants;

const StyledImg = styled(CardMedia)({
  height: "150px",
  width: "700px",
  backgroundSize: "contain"
});

const SmImg = styled(CardMedia)({
  marginTop: margin20,
  marginBottom: mobileGutterMargin,
  height: "120px",
  width: "90vw",
  backgroundSize: "contain"
});

const SetupWrapper = ({ children, wrapperMeta, launchScreenLogo }: SetupWrapperProps) => {
  const isDesktop = isDesktopDevice();
  return (
    <Container sx={{ marginTop: "4%", width: isDesktop ? "700px" : "90vw" }}>
      <Box style={{ position: "relative" }} sx={flexCenter}>
        {isDesktop ? (
          <StyledImg aria-label="company logo" image={launchScreenLogo} />
        ) : (
          <SmImg aria-label="company logo" image={launchScreenLogo} />
        )}
        <div
          style={{
            position: "absolute",
            top: isDesktop ? "118px" : "105px",
            fontSize: isDesktop ? "27px" : "14px",
            left: isDesktop ? "200px" : "calc(45vw - 82px)",
            color: "#777ca6"
          }}
        >
          Powered by Midato Health
        </div>
      </Box>
      {wrapperMeta?.wrapperText && (
        <Box sx={{ ...setupwrapperBoxPadding, textAlign: "center" }}>
          {wrapperMeta?.wrapperText?.header && (
            <Typography sx={headerText}>{wrapperMeta?.wrapperText?.header}</Typography>
          )}
          {wrapperMeta?.wrapperText?.textArray &&
            wrapperMeta.wrapperText?.textArray.map((string) => (
              <Typography sx={{ color: grayText }} key={string}>
                {string}
              </Typography>
            ))}
        </Box>
      )}
      <Box sx={{ ...setupwrapperBoxPadding, ...flexCenter, minHeight: "320px" }}>{children}</Box>
      {wrapperMeta?.buttons && (
        <Box sx={{ ...setupwrapperBoxPadding, textAlign: "center" }}>
          <SetupNavButtons buttons={wrapperMeta.buttons} />
        </Box>
      )}
    </Container>
  );
};

export default SetupWrapper;
