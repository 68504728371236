import { memo } from "react";
import * as React from "react";
import type { InteractiveFieldProps } from "../../../types";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Box } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import ReadonlyTextField from "./ReadonlyTextField";
import { fieldValueEqual, parseHTMLElements, getIsDisabledField, makeDOMElementId } from "../../../utils";
import { appColors, appConstants, appStyles } from "../../../assets";

const { primaryFontSize, margin10, selectFieldMaxHeight } = appConstants;
const { defaultBG, formError } = appColors;
const {
  customAsteriskStyle,
  baseForm: { sharedStyles }
} = appStyles;
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const SelectorField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const readOnlyValue = fieldInfo?.options?.find((option) => option?.name === value)?.label;
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        {fieldInfo.readOnly && (
          <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={readOnlyValue || value || ""} />
        )}
        {!fieldInfo.readOnly && (
          <FormControl
            variant={fieldInfo.variant ?? "standard"}
            disabled={isDisabledField}
            style={{ ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
          >
            {fieldInfo.globalFloatingLabel !== false || fieldInfo.labelStyle === "floating" ? (
              <InputLabel
                htmlFor={`custom-select-${fieldInfo.label}`}
                id={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)}
              >
                {fieldInfo.label} <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
              </InputLabel>
            ) : (
              <ReadonlyTextField
                fieldInfo={{ fieldType: "readonly_text", name: "", label: fieldInfo.label }}
                value={fieldInfo.label}
              />
            )}
            <Select
              inputProps={{ id: `custom-select-${fieldInfo.label}` }}
              labelId={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)}
              id={fieldInfo.name}
              name={fieldInfo.label}
              sx={{ position: "relative" }}
              onChange={(event: any) => {
                setFieldValue(fieldInfo.name, event.target.value, true);
              }}
              // inputProps={{
              //   "aria-label": fieldInfo.label
              // }}
              displayEmpty
              value={value}
              error={Boolean(error)}
              label={fieldInfo.label}
              aria-label="Choose an option"
              MenuProps={{
                sx: {
                  "& .MuiMenu-paper": {
                    marginTop: `${margin10}px`,
                    maxHeight: selectFieldMaxHeight
                  },
                  "& .MuiMenu-paper ul": {
                    backgroundColor: defaultBG
                  },
                  "& .MuiMenu-paper li": {
                    fontSize: primaryFontSize
                  }
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }}
            >
              {fieldInfo?.options?.map((option: string | { name: string; label: string }, i: number) => {
                if (typeof option === "object") {
                  return (
                    <MenuItem value={option.name} key={i}>
                      {parseHTMLElements(option.label)}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem value={option} key={i}>
                      {parseHTMLElements(option)}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {error && (
              <FormHelperText
                sx={{
                  color: formError
                }}
              >
                {error}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(SelectorField, fieldValueEqual);
