import * as React from "react";
import { lazy, ReactElement } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { NoMatch } from "./NoMatch";
import { routes } from "./constants";
import ACTIONS from "../app/actions";

import Layout from "../ui/screens/Layout";

import ScopesProtectionProvider from "./ScopesProtectionProvider";
import LicensingRequirements from "../ui/screens/LicensingRequirements";
import TrackLocationChange from "./TrackLocationChange";

const Login = lazy(() => import("../ui/screens/Login"));
const Signup = lazy(() => import("../ui/screens/Signup"));
const ForgotPassword = lazy(() => import("../ui/screens/ForgotPassword"));
const ExternalLogin = lazy(() => import("../ui/screens/ExternalLogin"));
const CognitoLogin = lazy(() => import("../ui/screens/CognitoLogin"));
const SAWLogin = lazy(() => import("../ui/screens/SAWLogin"));
const WATECHLogin = lazy(() => import("../ui/screens/WATECHLogin"));
const NewPassword = lazy(() => import("../ui/screens/NewPassword"));
const ForgotPasswordRequest = lazy(() => import("../ui/screens/ForgotPasswordRequest"));
// list pages
const Consumers = lazy(() => import("../ui/screens/listPages/Consumers"));
const Users = lazy(() => import("../ui/screens/listPages/Users"));
const SecurityRoles = lazy(() => import("../ui/screens/listPages/UserRoles"));
const Forms = lazy(() => import("../ui/screens/listPages/Forms"));
const Organizations = lazy(() => import("../ui/screens/listPages/Organizations"));
const Practitioners = lazy(() => import("../ui/screens/listPages/Practitioners"));
const Consents = lazy(() => import("../ui/screens/listPages/Consents"));
const Exports = lazy(() => import("../ui/screens/listPages/Exports"));
const Imports = lazy(() => import("../ui/screens/listPages/Imports"));
const OcrResult = lazy(() => import("../ui/screens/listPages/OcrResult"));
const ActivityReport = lazy(() => import("../ui/screens/listPages/Reports/ActivityReport"));
const ConsentReport = lazy(() => import("../ui/screens/listPages/Reports/ConsentReport"));
const LoadTimeReport = lazy(() => import("../ui/screens/listPages/Reports/LoadTimeReport"));
const ConsentStatisticReport = lazy(() => import("../ui/screens/listPages/Reports/ConsentStatisticReport"));
const UsageReport = lazy(() => import("../ui/screens/listPages/Reports/UsageReport"));
const ExpiryReport = lazy(() => import("../ui/screens/listPages/Reports/ExpiryReport"));
// new pages
const ImportNew = lazy(() => import("../ui/screens/newPages/ImportNew"));
const ExportNew = lazy(() => import("../ui/screens/newPages/ExportNew"));
// detail pages
const ConsumerDetail = lazy(() => import("../ui/screens/detailPages/ConsumerDetail"));
const FormBuilder = lazy(() => import("../ui/screens/detailPages/FormDetail"));
const UserDetail = lazy(() => import("../ui/screens/detailPages/UserDetail"));
const OrganizationDetail = lazy(() => import("../ui/screens/detailPages/OrganizationDetail"));
const PractitionerDetail = lazy(() => import("../ui/screens/detailPages/PractitionerDetail"));
const ExportDetail = lazy(() => import("../ui/screens/detailPages/ExportDetail"));
const ImportDetail = lazy(() => import("../ui/screens/detailPages/ImportDetail"));
const SecurityRolesDetail = lazy(() => import("../ui/screens/detailPages/UserRolesDetail"));

const Dashboard = lazy(() => import("../ui/screens/Dashboard"));
const Profile = lazy(() => import("../ui/screens/Profile"));
const UserGuide = lazy(() => import("../ui/screens/UserGuide"));

const renderRoute = ({
  content,
  actionId,
  fallback,
  unprotectedPath
}: {
  content: ReactElement;
  actionId?: keyof typeof ACTIONS;
  fallback: string;
  unprotectedPath?: boolean;
}) => (
  <ScopesProtectionProvider actionId={actionId} fallback={fallback} unprotectedPath={unprotectedPath}>
    {content}
  </ScopesProtectionProvider>
);

const RoutesList = () => {
  return (
    <>
      <TrackLocationChange />
      <Routes>
        {/* <Route path="/" element={<Navigate to={routes.CONSUMERS} />} /> */}
        <Route path="*" element={<NoMatch />} />

        <Route
          path={routes.FORGOT_PASSWORD}
          element={renderRoute({ content: <ForgotPassword />, unprotectedPath: true, fallback: routes.LOGIN })}
        />

        <Route
          path={routes.SIGNUP}
          element={renderRoute({ content: <Signup />, unprotectedPath: true, fallback: routes.LOGIN })}
        />

        <Route
          path={routes.LOGIN}
          element={renderRoute({ content: <Login />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path={routes.EXTERNAL_LOGIN_REDIRECT}
          element={renderRoute({ content: <ExternalLogin />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path={routes.COGNITO_LOGIN}
          element={renderRoute({ content: <CognitoLogin />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path="/"
          element={renderRoute({ content: <SAWLogin />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path={routes.WATECH_LOGIN}
          element={renderRoute({ content: <WATECHLogin />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path={routes.LICENSING_REQUIREMENTS}
          element={renderRoute({ content: <LicensingRequirements />, unprotectedPath: true, fallback: routes.LOGIN })}
        />
        <Route
          path={routes.NEW_PASSWORD}
          element={renderRoute({ content: <NewPassword />, unprotectedPath: true, fallback: routes.CONSUMERS })}
        />
        <Route
          path={routes.PASSWORD_REQUEST}
          element={renderRoute({
            content: <ForgotPasswordRequest />,
            unprotectedPath: true,
            fallback: routes.LOGIN
          })}
        />

        <Route path={routes.ADMIN_APP} element={<Layout />}>
          <Route
            path={routes.USER_GUIDE}
            element={renderRoute({
              content: <UserGuide />,
              // actionId: "PROFILE_GET",
              fallback: routes.LOGIN
            })}
          />
          <Route
            path={routes.PROFILE}
            element={renderRoute({
              content: <Profile />,
              actionId: "PROFILE_GET",
              fallback: routes.LOGIN
            })}
          />
          <Route path={`${routes.ADMIN_APP}${routes.CONSENT_MGMT}`} element={<Outlet />}>
            <Route
              path={routes.CONSENTS}
              element={renderRoute({
                content: <Consents />,
                actionId: "CONSENT_LIST",
                fallback: routes.CONSUMERS
              })}
            />

            <Route path={routes.CONSUMERS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <Consumers />,
                  actionId: "CONSUMER_LIST",
                  fallback: routes.CONSUMERS
                })}
              />
              <Route
                path={routes.CONSUMER}
                element={renderRoute({
                  content: <ConsumerDetail />,
                  actionId: "CONSUMER_GET",
                  fallback: routes.CONSUMERS
                })}
              />
            </Route>
            <Route path={routes.OCR_RESULTS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <OcrResult />,
                  actionId: "OCR_RESULTS_LIST",
                  fallback: routes.CONSUMERS
                })}
              />
            </Route>
          </Route>

          <Route path={`${routes.ADMIN_APP}${routes.ADMIN}`} element={<Outlet />}>
            <Route path={routes.PRACTITIONERS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <Practitioners />,
                  actionId: "PRACTITIONER_LIST",
                  fallback: routes.CONSUMERS
                })}
              />

              <Route
                path={routes.PRACTITIONER}
                element={renderRoute({
                  content: <PractitionerDetail />,
                  actionId: "PRACTITIONER_GET",
                  fallback: routes.PRACTITIONERS
                })}
              />
            </Route>
            <Route path={routes.FORMS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <Forms />,
                  actionId: "FORM_LIST",
                  fallback: routes.CONSUMERS
                })}
              />

              <Route
                path={routes.FORM}
                element={renderRoute({
                  content: <FormBuilder />,
                  actionId: "FORM_GET",
                  fallback: routes.FORMS
                })}
              />
            </Route>
            <Route path={routes.USERS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <Users />,
                  actionId: "USER_LIST",
                  fallback: routes.CONSUMERS
                })}
              />
              <Route
                path={routes.USER}
                element={renderRoute({
                  content: <UserDetail />,
                  actionId: "USER_GET",
                  fallback: routes.USERS
                })}
              />
            </Route>
            <Route path={routes.SECURITY_ROLES} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <SecurityRoles />,
                  actionId: "SECURITY_ROLES_LIST",
                  fallback: routes.CONSUMERS
                })}
              />
              <Route
                path={routes.SECURITY_ROLE}
                element={renderRoute({
                  content: <SecurityRolesDetail />,
                  actionId: "SECURITY_ROLE_GET",
                  fallback: routes.SECURITY_ROLES
                })}
              />
            </Route>
            <Route path={routes.ORGANIZATIONS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({
                  content: <Organizations />,
                  actionId: "ORGANIZATION_LIST",
                  fallback: routes.CONSUMERS
                })}
              />

              <Route
                path={routes.ORGANIZATION}
                element={renderRoute({
                  content: <OrganizationDetail />,
                  actionId: "ORGANIZATION_GET",
                  fallback: routes.ORGANIZATIONS
                })}
              />
            </Route>
            <Route path={routes.EXPORTS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({ content: <Exports />, actionId: "EXPORTS_LIST", fallback: routes.CONSUMERS })}
              />
              <Route
                path={routes.EXPORT}
                element={renderRoute({ content: <ExportDetail />, actionId: "EXPORT_GET", fallback: routes.EXPORTS })}
              />
              <Route
                path={routes.EXPORTS_NEW}
                element={renderRoute({ content: <ExportNew />, actionId: "EXPORT_ADD", fallback: routes.EXPORTS })}
              />
            </Route>
            <Route path={routes.IMPORTS} element={<Outlet />}>
              <Route
                index
                element={renderRoute({ content: <Imports />, actionId: "IMPORTS_LIST", fallback: routes.CONSUMERS })}
              />
              <Route
                path={routes.IMPORT}
                element={renderRoute({ content: <ImportDetail />, actionId: "IMPORT_GET", fallback: routes.IMPORTS })}
              />
              <Route
                path={routes.IMPORTS_NEW}
                element={renderRoute({ content: <ImportNew />, actionId: "IMPORT_ADD", fallback: routes.IMPORTS })}
              />
            </Route>
          </Route>

          <Route path={`${routes.ADMIN_APP}${routes.REPORTS}`} element={<Outlet />}>
            <Route
              index
              path={routes.ACTIVITY_REPORT}
              element={renderRoute({
                content: <ActivityReport />,
                actionId: "REPORT_USER_ACTIVITY_LOG",
                fallback: routes.CONSUMERS
              })}
            />
            <Route
              index
              path={routes.CONSENT_REPORT}
              element={renderRoute({
                content: <ConsentReport />,
                actionId: "REPORT_CONSENT_LOG",
                fallback: routes.CONSUMERS
              })}
            />
            <Route
              index
              path={routes.LOAD_TIME_REPORT}
              element={renderRoute({
                content: <LoadTimeReport />,
                actionId: "REPORT_CONSENT_LOG",
                // actionId: "REPORT_LOAD_TIME",
                fallback: routes.CONSUMERS
              })}
            />
            <Route
              index
              path={routes.EXPIRY_REPORT}
              element={renderRoute({
                content: <ExpiryReport />,
                actionId: "REPORT_EXPIRY_LOG",
                fallback: routes.CONSUMERS
              })}
            />
            <Route
              index
              path={routes.CONSENT_STATISTICS_REPORT}
              element={renderRoute({
                content: <ConsentStatisticReport />,
                actionId: "REPORT_CONSENT_STATISTICS",
                fallback: routes.CONSUMERS
              })}
            />

            <Route
              index
              path={routes.USAGE_REPORT}
              element={renderRoute({
                content: <UsageReport />,
                actionId: "REPORT_USAGE_LOG",
                fallback: routes.CONSUMERS
              })}
            />
            <Route
              index
              path={routes.DASHBOARD}
              element={renderRoute({ content: <Dashboard />, actionId: "DASHBOARD_GET", fallback: routes.LOGIN })}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default RoutesList;
