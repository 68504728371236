import { memo, useState, useEffect } from "react";
import { Switch, FormControl, FormControlLabel, FormGroup, TextField, InputAdornment } from "@mui/material";
import { fieldValueEqual, extractFieldLabel } from "../../../utils";
import { appStyles, appConstants } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";

const { formFieldVerticalMargin } = appConstants;

const {
  baseForm: { sharedStyles }
} = appStyles;

const SwitchTextField = ({ fieldInfo, value, setFieldValue, error = "" }: InteractiveFieldProps) => {
  const [beingUsed, setBeingUsed] = useState(() => !!value);
  useEffect(() => {
    if (beingUsed === false) {
      setFieldValue(fieldInfo.name, "");
    }
  }, [beingUsed]);
  return (
    <FormGroup row sx={{ minWidth: "max-content" }}>
      <FormControl
        style={{
          ...sharedStyles,
          marginTop: formFieldVerticalMargin,
          marginBottom: 0,
          ...(fieldInfo?.style ?? {})
        }}
        sx={{ maxWidth: "fit-content" }}
      >
        <FormControlLabel
          sx={{ marginRight: "5px" }}
          disabled={fieldInfo.disabled || fieldInfo.readOnly}
          control={
            <Switch
              disabled={fieldInfo.disabled}
              color="primary"
              checked={beingUsed}
              onChange={(e) => {
                setBeingUsed((beingUsed) => !beingUsed);
              }}
              inputProps={{ "aria-label": "primary switch" }}
              id={`${fieldInfo.name}-switch`}
            />
          }
          aria-label={extractFieldLabel(fieldInfo.label, fieldInfo.componentLabel)}
          label={extractFieldLabel(fieldInfo.label, fieldInfo.componentLabel)}
        />
      </FormControl>
      {beingUsed ? (
        <TextField
          variant="standard"
          disabled={fieldInfo.disabled}
          sx={{ flex: "1 1 0%", marginTop: "20px" }}
          inputProps={{ style: { paddingBottom: 0 } }}
          InputProps={
            "startAdornment" in fieldInfo
              ? {
                  startAdornment: <InputAdornment position="start">{fieldInfo.startAdornment}</InputAdornment>
                }
              : {}
          }
          error={Boolean(error)}
          helperText={error}
          onChange={(e) => {
            setFieldValue(fieldInfo.name, e.target.value);
          }}
          value={value}
          id={fieldInfo.name}
        />
      ) : null}
    </FormGroup>
  );
};

export default memo(SwitchTextField, fieldValueEqual);
