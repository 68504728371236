import { validations } from "../../utils";
import { UserField } from "./types";
import assets from "../../../ui/assets";

const {
  appStrings: {
    admin: {
      user: {
        item: { formFields }
      }
    }
  }
} = assets;

const fields: { [key in UserField]: any } = {
  firstName: {
    name: "firstName",
    label: formFields.firstName,
    width: "28%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  lastName: {
    name: "lastName",
    label: formFields.lastName,
    width: "28%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  externalId: {
    name: "externalId",
    label: formFields.externalId,
    width: "28%",
    validation: validations.textMinMax(1, 50),
    showIf: (formik: any) => !!formik?.values?.isExternalAuthentication
  },
  email: {
    name: "email",
    isEmail: true,
    label: formFields.email,
    width: "50%",
    validation: validations.requiredEmail
  },
  phoneNumber: {
    name: "phoneNumber",
    label: formFields.mobilePhone,
    fieldType: "us-phone",
    width: "50%",
    validation: validations.usPhoneRequired
  },
  permissions: {
    name: "permissions",
    label: "Permissions",
    showLabel: true,
    width: "25%",
    fieldType: "checkbox_group",
    options: []
  },
  roles: {
    name: "roles",
    label: "Roles",
    showLabel: true,
    width: "25%",
    fieldType: "checkbox_group",
    tooltip: true,
    options: []
  },
  organizations: {
    name: "organizations",
    label: "Organizations",
    showLabel: true,
    width: "50%",
    fieldType: "async-multi-select",
    showAll: false,
    entityName: "organization",
    entityLabelAttribute: "name",
    entityKeyAttribute: "orgId",
    options: [],
    validation: validations.nonEmptyArray,
    transformFunction: (value: { label: string; name: string }[]) => value.map(({ name }) => name).join(",")
  },
  isExternalAuthentication: {
    name: "isExternalAuthentication",
    label: formFields.isExternalAuthentication,
    showLabel: true,
    width: "22%",
    fieldType: "switch",
    defaultValue: false
  }
};

export default fields;
