import { initiateAuth, revokeToken } from "@midato-mono/common/src/aws";
import { graphql } from "@midato-mono/common/src/aws";

const CLIENT_ID = import.meta.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID;

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;
const GQL_PUBLIC_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_PUBLIC_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface ChangePasswordSignInConfig {
  userId: string;
  tempPassword: string;
  newPassword: string;
}

const changePasswordSignIn = async ({ auth, variables }: QueryConfig<ChangePasswordSignInConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      mutation ($userId: ID!, $tempPassword: String!, $newPassword: String!) {
        changeTempPassword(userId: $userId, tempPassword: $tempPassword, newPassword: $newPassword)
      }
    `,
    variables,
    auth
  );
};

export interface ChangePasswordForgotConfig {
  email: string;
  verificationCode: string;
  newPassword: string;
}

const changePasswordForgot = async ({ auth, variables }: QueryConfig<ChangePasswordForgotConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      mutation ($email: String!, $verificationCode: String!, $newPassword: String!) {
        changeForgottenPassword(email: $email, verificationCode: $verificationCode, newPassword: $newPassword)
      }
    `,
    variables,
    auth
  );
};

export interface ForgotPasswordRequestConfig {
  email: string;
}

const forgotPasswordRequest = async ({ auth, variables }: QueryConfig<ForgotPasswordRequestConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      mutation ($email: String!) {
        forgotPassword(email: $email)
      }
    `,
    variables,
    auth
  );
};

export interface VerifySignInConfig {
  userId: string;
  activationCode: string;
}

const verifySignIn = async ({ auth, variables }: QueryConfig<VerifySignInConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      mutation ($userId: ID!, $activationCode: String!) {
        activateUser(userId: $userId, activationCode: $activationCode)
      }
    `,
    variables,
    auth
  );
};

export interface SignInConfig {
  USERNAME: string;
  PASSWORD: string;
}

const signIn = async ({ variables }: QueryConfig<SignInConfig>) => {
  return await initiateAuth({
    AuthFlow: "USER_PASSWORD_AUTH",
    AuthParameters: variables,
    ClientId: CLIENT_ID,
    AnalyticsMetadata: {
      AnalyticsEndpointId: "a25a993e0b9b4a4f8cade7a018cef0c1" /* random UUID unique for each Cognito user */ // remove this
    }
  });
};

export interface GetUserConfig {
  AccessToken: string;
}

const getCurrentUser = async ({ variables }: QueryConfig<GetUserConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface UpdateUserAttributesConfig {
  firstName: string;
  lastName: string;
  email: string;
}

const updateUserAttributes = async ({ variables }: QueryConfig<UpdateUserAttributesConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface ForgotPasswordConfig {
  Username: string;
}

const forgotPassword = async ({ variables }: QueryConfig<ForgotPasswordConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface ConfirmForgotPasswordConfig {
  ConfirmationCode: string;
  Password: string;
  Username: string;
}

const confirmForgotPassword = async ({ variables }: QueryConfig<ConfirmForgotPasswordConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface CompleteNewPasswordConfig {
  username: string;
  password: string;
}

const completeNewPassword = async ({ variables }: QueryConfig<CompleteNewPasswordConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface ChangePassowrdConfig {
  PreviousPassword: string;
  ProposedPassword: string;
}

const changePassword = async ({ variables }: QueryConfig<ChangePassowrdConfig>) => {
  return {
    data: null,
    error: null
  };
};

export interface SignOutConfig {
  Token: string;
}

const signOut = async ({ variables }: QueryConfig<SignOutConfig>) => {
  return await revokeToken({
    ClientId: CLIENT_ID,
    Token: variables.Token
  });
};

const getPermissions = async ({ auth, variables }: QueryConfig<undefined>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        getPermissions {
          permissions {
            id
            name
            tableName
            active
          }
        }
      }
    `,
    variables,
    auth
  );
};

const getAssignedPermissions = async ({ auth, variables }: QueryConfig<undefined>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        getAssignedPermissions {
          permissions {
            id
            name
            tableName
            active
          }
        }
      }
    `,
    variables,
    auth
  );
};

const getUserRoles = async ({ auth, variables }: QueryConfig<undefined>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        getUserRoles {
          orgId
          userRoleId
          permissions
          name
          active
        }
      }
    `,
    variables,
    auth
  );
};

export interface FetchSecurityRoleConfig {
  userRoleId: string;
}

const fetchSecurityRole = async ({ auth, variables }: QueryConfig<FetchSecurityRoleConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($userRoleId: ID!) {
        getUserRole(userRoleId: $userRoleId) {
          orgId
          userRoleId
          permissions
          name
          active
          externalID
        }
      }
    `,
    variables,
    auth
  );
};

export interface AddNewSecurityRoleConfig {
  userRoleId: string;
}

const addNewSecurityRole = async ({ auth, variables }: QueryConfig<AddNewSecurityRoleConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation addUserRole($userRole: UserRoleInput!) {
        addUserRole(userRole: $userRole) {
          userRoleId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateSecurityRoleConfig {
  userRoleId: string;
}

const updateSecurityRole = async ({ auth, variables }: QueryConfig<UpdateSecurityRoleConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation updateUserRole($userRoleId: ID!, $userRole: UserRoleUpdateInput!) {
        updateUserRole(userRoleId: $userRoleId, userRole: $userRole) {
          userRoleId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeleteSecurityRoleConfig {
  userRoleId: string;
}

const deleteSecurityRole = async ({ auth, variables }: QueryConfig<DeleteSecurityRoleConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($userRoleId: ID!) {
        deactivateUserRole(userRoleId: $userRoleId) {
          userRoleId
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetAuthTokenConfig {
  code: string;
  clientId: string;
  redirectUri: string;
}

const getAuthToken = async ({ auth, variables }: QueryConfig<GetAuthTokenConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      query ($code: String!, $clientId: String!, $redirectUri: String!) {
        getAuthToken(clientId: $clientId, code: $code, redirectUri: $redirectUri) {
          access_token
          id_token
          refresh_token
          expires_in
          token_type
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetAuthTokenForUserConfig {
  username: string;
  password: string;
  clientId: string;
}

const getAuthTokenForUser = async ({ auth, variables }: QueryConfig<GetAuthTokenForUserConfig>) => {
  return await graphql(
    GQL_PUBLIC_ENDPOINT,
    `
      mutation ($username: String!, $password: String!, $clientId: String!) {
        cognitoLogin(username: $username, password: $password, clientId: $clientId) {
          access_token
          id_token
          refresh_token
          expires_in
          token_type
        }
      }
    `,
    variables,
    auth
  );
};

export {
  getAuthTokenForUser,
  getAuthToken,
  deleteSecurityRole,
  updateSecurityRole,
  addNewSecurityRole,
  fetchSecurityRole,
  signIn,
  getCurrentUser,
  updateUserAttributes,
  forgotPassword,
  confirmForgotPassword,
  completeNewPassword,
  changePassword,
  signOut,
  getAssignedPermissions,
  getPermissions,
  getUserRoles,
  verifySignIn,
  changePasswordForgot,
  changePasswordSignIn,
  forgotPasswordRequest
};
