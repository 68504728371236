import { graphql } from "@midato-mono/common/src/aws";
import { ResourceType, SortOrderType } from "@midato-mono/common/src/types";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface SearchItemsConfig {
  params: {
    type: ResourceType;
    query: string;
    limit: number;
    filter?: string;
    orgId?: string;
    nextToken?: string;
    order?: SortOrderType;
    sortBy?: string;
  };
}

const searchItems = async ({ auth, variables }: QueryConfig<SearchItemsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: SearchParams!) {
        searchItems(params: $params) {
          results {
            __typename
            ...cmsConsentFormSummaryFields
            ...cmsConsumerSummaryFields
            ...cmsConsentSummaryFields
            ...cmsUserSummaryFields
            ...cmsUserRoleSummaryFields
            ...cmsOrganizationSummaryFields
            ...cmsPractitionerSummaryFields
            ...cmsBulkImportSummaryFields
            ...cmsBulkExportSummaryFields
            ...cmsOcrResultsSummaryFields
          }
          total
          nextToken
        }
      }
    `,
    variables,
    auth
  );
};

export { searchItems };
