import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // You can log the error here
    console.error(error, info, 111);
    if (error?.message?.includes("Failed to fetch")) {
      window.location.reload();
    } else {
      this.setState({ hasError: true });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            fontSize: "25px",
            color: "red",
            width: "100vw",
            height: "100vh"
          }}
        >
          <div>
            <div>
              <ErrorOutlineIcon style={{ marginRight: "12px", verticalAlign: "middle" }} /> Something went wrong. Please
              try to refresh.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px"
              }}
            >
              <button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
