import { ImportItemPageConfiguration } from "./types";
import {
  createDetailEntityName,
  createDetailInitialValues,
  createDetailPostAmble,
  createDetailPreAmble,
  createDetailRows,
  createNewActions,
  createNewPostAmble,
  createNewRows,
  useDetailInitialQuery
} from "./lib";
import assets from "../../../ui/assets";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    admin: {
      bulkImport: { list }
    }
  }
} = assets;

const importItemPageConfiguration: ImportItemPageConfiguration = {
  common: {
    listPageLabel: list.listPageHeader,
    listPageUrl: routes.IMPORTS
  },
  new: {
    createActions: createNewActions,
    createRows: createNewRows,
    createPostAmble: createNewPostAmble
  },
  detail: {
    useInitialQuery: useDetailInitialQuery,
    createEntityName: createDetailEntityName,
    createRows: createDetailRows,
    createPostAmble: createDetailPostAmble,
    createInitialValues: createDetailInitialValues,
    createPreAmble: createDetailPreAmble
  }
};

export default importItemPageConfiguration;
