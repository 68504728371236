const ADMIN_APP = "/app";
const ADMIN = "/admin";
const CONSENT_MGMT = "/consents";
const REPORTS = "/reports";

export const routes: { [key: string]: string } = {
  ADMIN_APP,
  ADMIN,
  CONSENT_MGMT,
  REPORTS,

  LOGIN: `/login`,
  SIGNUP: "/verify",
  FORGOT_PASSWORD: "/forgot-password",
  COGNITO_LOGIN: `/midato`,
  SAW_LOGIN: `/`,
  EXTERNAL_LOGIN_REDIRECT: "/login_external",
  WATECH_LOGIN: `/hca`,
  LICENSING_REQUIREMENTS: `/licensingRequirements`,
  NEW_PASSWORD: `/newpassword`,
  PASSWORD_REQUEST: "/password-request",

  USER_GUIDE: `${ADMIN_APP}/user-guide`,
  PROFILE: `${ADMIN_APP}/profile`,
  DASHBOARD: `${ADMIN_APP}${REPORTS}/dashboard`,
  ACTIVITY_REPORT: `${ADMIN_APP}${REPORTS}/activityReport`,
  LOAD_TIME_REPORT: `${ADMIN_APP}${REPORTS}/loadTimeReport`,
  CONSENT_REPORT: `${ADMIN_APP}${REPORTS}/consentReport`,
  EXPIRY_REPORT: `${ADMIN_APP}${REPORTS}/expiryReport`,
  CONSENT_STATISTICS_REPORT: `${ADMIN_APP}${REPORTS}/consentStatisticsReport`,
  USAGE_REPORT: `${ADMIN_APP}${REPORTS}/usageReport`,
  CONSUMERS: `${ADMIN_APP}${CONSENT_MGMT}/consumers`,
  CONSUMER: `${ADMIN_APP}${CONSENT_MGMT}/consumers/:id`,
  CONSENTS: `${ADMIN_APP}${CONSENT_MGMT}/consents`,
  OCR_RESULTS: `${ADMIN_APP}${CONSENT_MGMT}/ocrResults`,
  EXPORTS: `${ADMIN_APP}${ADMIN}/exports`,
  EXPORTS_NEW: `${ADMIN_APP}${ADMIN}/exports/new`,
  EXPORT: `${ADMIN_APP}${ADMIN}/exports/:id`,
  IMPORTS: `${ADMIN_APP}${ADMIN}/imports`,
  IMPORTS_NEW: `${ADMIN_APP}${ADMIN}/imports/new`,
  IMPORT: `${ADMIN_APP}${ADMIN}/imports/:id`,
  FORMS: `${ADMIN_APP}${ADMIN}/forms`,
  FORM: `${ADMIN_APP}${ADMIN}/forms/:code/:version`,
  USERS: `${ADMIN_APP}${ADMIN}/users`,
  USER: `${ADMIN_APP}${ADMIN}/users/:id`,
  SECURITY_ROLES: `${ADMIN_APP}${ADMIN}/securityRoles`,
  SECURITY_ROLE: `${ADMIN_APP}${ADMIN}/securityRoles/:id`,
  ORGANIZATIONS: `${ADMIN_APP}${ADMIN}/organizations`,
  ORGANIZATION: `${ADMIN_APP}${ADMIN}/organizations/:id`,
  PRACTITIONERS: `${ADMIN_APP}${ADMIN}/practitioners`,
  PRACTITIONER: `${ADMIN_APP}${ADMIN}/practitioners/:id`
};

export const TITLES: { [key: string]: string } = {
  [routes.LOGIN]: "Login",
  [routes.SIGNUP]: "Signup",
  [routes.FORGOT_PASSWORD]: "Forgot Password",
  [routes.USER_GUIDE]: "User Guide",
  [routes.COGNITO_LOGIN]: "Login",
  [routes.SAW_LOGIN]: "Login",
  [routes.WATECH_LOGIN]: "Login",
  [routes.LICENSING_REQUIREMENTS]: "Licensing Requirements",
  [routes.NEW_PASSWORD]: "New Password",
  [routes.PROFILE]: "Profile",

  [routes.DASHBOARD]: "Dashboard",
  [routes.USER_GUIDE]: "User Guide",
  [routes.ACTIVITY_REPORT]: "Activity report",
  [routes.CONSENT_REPORT]: "Consent report",
  [routes.LOAD_TIME_REPORT]: "Response Time report",
  [routes.EXPIRY_REPORT]: "Expiry report",
  [routes.CONSENT_STATISTICS_REPORT]: "Consent Statistics report",
  [routes.USAGE_REPORT]: "Usage report",
  [routes.CONSUMERS]: "Consumers",
  [routes.CONSUMER]: "Consumer",
  [routes.CONSENTS]: "Consents",
  [routes.EXPORTS]: "Exports",
  [routes.EXPORTS_NEW]: "Exports New",
  [routes.EXPORT]: "Export",
  [routes.IMPORTS]: "Imports",
  [routes.IMPORTS_NEW]: "Imports New",
  [routes.IMPORT]: "Import",
  [routes.FORMS]: "Forms",
  [routes.OCR_RESULTS]: "OCR",
  [routes.FORM]: "Form",
  [routes.USERS]: "Users",
  [routes.USER]: "User",
  [routes.ORGANIZATIONS]: "Organizations",
  [routes.ORGANIZATION]: "Organization",
  [routes.SECURITY_ROLES]: "Security Roles",
  [routes.SECURITY_ROLE]: "Security Role",
  [routes.PRACTITIONERS]: "Practitioners",
  [routes.PRACTITIONER]: "Practitioner",
  [routes.PASSWORD_REQUEST]: "Forgot Password"
};
