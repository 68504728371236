import { useMemo } from "react";
import * as React from "react";
import { useNavigate } from "react-router";
import { useAuth } from "@midato-mono/common/src/hooks";
import { clearAllTokens } from "@midato-mono/common/src/utils";
import { List, ListItemText } from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { useSidebar } from "../../../app/hooks";
import assets from "../../assets";
import { routes } from "../../../Router/constants";
import Stack from "@mui/material/Stack";
import { signOut } from "aws-amplify/auth";

const {
  appStrings: {
    admin: {
      ariaLabels: { sideBarNavigation }
    }
  },
  appStyles: {
    SidebarStyle: {
      SidebarContent,
      SubListWrapper,
      ListWrapper,
      StyledListItem,
      StyledListSubItem,
      StyledDivider,
      FooterList,
      iconStyle
    }
  },
  appStrings: {
    admin: {
      sidebar: {
        labels: { logout, settings, userGuide }
      }
    }
  }
} = assets;

const Sidebar = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { pathname, menuItems, handleItemClicked } = useSidebar();
  const doLogout = () => {
    clearAllTokens();
    setAuth(null);
    signOut();
    const loginType = window.localStorage.getItem("midato:loginType") || "ALL";
    switch (loginType) {
      case "COGNITO":
        navigate(routes.COGNITO_LOGIN);
        break;
      case "SAW":
        navigate(routes.SAW_LOGIN);
        break;
      case "WATECH":
        navigate(routes.WATECH_LOGIN);
        break;
      default:
        navigate(routes.LOGIN);
        break;
    }
  };
  const goProfile = () => {
    navigate(routes.PROFILE);
  };

  const goUserGuide = () => {
    // @ts-expect-error will be fixed later
    window.open(routes.USER_GUIDE, "_blank").focus();
    // navigate(routes.USER_GUIDE);
  };

  const subMenuMap = menuItems.reduce<{
    [key: string]: { actionId?: string; label: string; key: string; tabIndex?: number }[];
  }>((acc, item) => {
    if (item.subItems) {
      acc[item.key] = item.subItems;
    }
    return acc;
  }, {});

  const selectedSubCat = useMemo(() => {
    if (pathname.includes(routes.ADMIN)) return routes.ADMIN;
    else if (pathname.includes(routes.CONSENT_MGMT)) return routes.CONSENT_MGMT;
    else if (pathname.includes(routes.REPORTS)) return routes.REPORTS;
    else return "";
  }, [pathname]);

  return (
    <SidebarContent>
      <ListWrapper>
        <List component="nav" aria-label={sideBarNavigation} style={{ padding: 0 }} id="sidebar">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              minHeight: "365px",
              height: "81vh"
            }}
          >
            <div>
              {menuItems.map((item) =>
                item.label === "divider" ? (
                  <StyledDivider key={item.key} />
                ) : item?.subItems?.length ? (
                  <StyledListItem
                    key={item.key}
                    button
                    tabIndex={item.tabIndex}
                    selected={pathname.includes(item.key)}
                    onClick={() => handleItemClicked(item?.subItems?.length ? item?.subItems[0]?.key : item.path)}
                  >
                    {item.icon}
                    <ListItemText primary={item.label} id={item.path} />
                  </StyledListItem>
                ) : null
              )}
            </div>
            <div>
              <StyledListItem
                tabIndex={40}
                button
                onClick={goUserGuide}
                selected={pathname.includes(routes.USER_GUIDE)}
              >
                <MenuBookOutlinedIcon sx={iconStyle} />
                <ListItemText primary={userGuide} id={routes.USER_GUIDE} />
              </StyledListItem>
              <StyledListItem tabIndex={40} button onClick={goProfile} selected={pathname.includes(routes.PROFILE)}>
                <Settings sx={iconStyle} />
                <ListItemText primary={settings} id={routes.PROFILE} />
              </StyledListItem>
              <StyledListItem tabIndex={50} button onClick={doLogout}>
                <Logout sx={iconStyle} />
                <ListItemText primary={logout} />
              </StyledListItem>
            </div>
          </div>
        </List>
        {/* <FooterList component="nav" aria-label={sideBarNavigation}> */}
        {/*   <StyledListItem tabIndex={40} button onClick={goProfile} selected={pathname.includes(routes.PROFILE)}> */}
        {/*     <Settings sx={iconStyle} /> */}
        {/*     <ListItemText primary={settings} id={routes.PROFILE} /> */}
        {/*   </StyledListItem> */}
        {/*   <StyledListItem tabIndex={50} button onClick={doLogout}> */}
        {/*     <Logout sx={iconStyle} /> */}
        {/*     <ListItemText primary={logout} /> */}
        {/*   </StyledListItem> */}
        {/* </FooterList> */}
      </ListWrapper>
      {subMenuMap?.[selectedSubCat] && (
        <SubListWrapper>
          <Stack spacing={0.5}>
            {subMenuMap?.[selectedSubCat].map((item) => (
              <StyledListSubItem
                tabIndex={item.tabIndex}
                key={item.key}
                button
                selected={pathname.includes(item.key)}
                onClick={() => handleItemClicked(item.key)}
              >
                <ListItemText primary={item.label} id={item.key} />
              </StyledListSubItem>
            ))}
          </Stack>
        </SubListWrapper>
      )}
    </SidebarContent>
  );
};

export default Sidebar;
