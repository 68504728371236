import { memo } from "react";
import * as React from "react";
import { Box, TextField } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { fieldValueEqual, formattedDate, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";

const {
  baseForm: { sharedStyles, asteriskStyles, formLabeErrorStyles },
  datePickerPopperSx
} = appStyles;

const DateTimeField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation,
  isFromUserActivity = false
}: InteractiveFieldProps) => {
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  React.useEffect(() => {
    if (!value) {
      setFieldValue(fieldInfo.name, null);
    }
  }, [value, setFieldValue, fieldInfo.name]);

  const sxProps = {
    ...sharedStyles,
    marginBottom: 0,
    ...(fieldInfo?.style ?? {})
  };

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={formattedDate(value)} />;
  } else {
    return (
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <div style={{ width: "98%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={error ? formLabeErrorStyles : {}}>
              <DateTimePicker
                disabled={isDisabledField}
                label={fieldInfo.label}
                value={value}
                ignoreInvalidInputs
                inputFormat="MM/dd/yyyy HH:mm"
                PopperProps={{ sx: datePickerPopperSx }}
                onChange={(date: any) => {
                  try {
                    if (isFromUserActivity) {
                      const newDate = format(date, "yyyy-MM-dd HH:mm:ss");
                      const updatedDate = new Date(newDate);
                      setFieldValue(fieldInfo.name, updatedDate);
                      return;
                    }
                    const newDate = new Date(`${format(date, "yyyy-MM-dd")}T09:00:00.000Z`);
                    setFieldValue(fieldInfo.name, newDate);
                  } catch (error) {
                    setFieldValue(fieldInfo.name, new Date());
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={fieldInfo.variant ?? "standard"}
                    name={fieldInfo.name}
                    error={Boolean(error)}
                    helperText={error}
                    required={fieldRequired}
                    aria-required={fieldRequired}
                    aria-invalid={Boolean(error)}
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": fieldInfo.label,
                      "aria-required": fieldRequired,
                      "aria-invalid": Boolean(error),
                      type: "text"
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: asteriskStyles
                    }}
                    id={fieldInfo.name}
                    sx={sxProps}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default memo(DateTimeField, fieldValueEqual);
