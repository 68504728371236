import { memo, useState, useEffect, useMemo } from "react";
import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Box,
  FormLabel,
  FormHelperText
} from "@mui/material";
import { fieldValueEqual } from "../../../utils";
import { appStyles, appColors } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  baseForm: { sharedStyles },
  formFields: { checkboxWithPrePostTextStyle, checkboxPrePostTextStyle }
} = appStyles;
const { formError } = appColors;

const CheckboxWithPrePostTextField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const [beingUsed, setBeingUsed] = useState(false);
  useEffect(() => {
    setBeingUsed(!(value === (fieldInfo?.uncheckedDefaultValue ?? "")));
  }, [value]);

  const inputProps = useMemo(
    () => ({
      style: { ...checkboxPrePostTextStyle, visibility: beingUsed === false ? "hidden" : "visible" }
    }),
    [beingUsed]
  );

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <FormGroup
          row
          sx={{ minWidth: "max-content", marginTop: 6 }}
          style={{
            ...sharedStyles,
            ...(fieldInfo?.style ?? {})
          }}
        >
          <FormControl sx={{ maxWidth: "fit-content" }}>
            <FormControlLabel
              sx={{ marginRight: "5px" }}
              disabled={fieldInfo.disabled || fieldInfo.readOnly}
              control={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    disabled={fieldInfo.disabled || fieldInfo.readOnly}
                    color="primary"
                    checked={beingUsed}
                    onChange={() => {
                      setBeingUsed((beingUsed) => !beingUsed);
                      if (value === (fieldInfo?.uncheckedDefaultValue ?? "")) {
                        setFieldValue(fieldInfo.name, "");
                      }
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    id={`${fieldInfo.name}-checkbox`}
                  />
                  <FormLabel aria-label={fieldInfo?.label}> {fieldInfo?.label}</FormLabel>
                  <TextField
                    variant="standard"
                    disabled={!beingUsed || fieldInfo.disabled || fieldInfo.readOnly}
                    sx={checkboxWithPrePostTextStyle}
                    inputProps={inputProps as any}
                    onChange={(e) => {
                      setFieldValue(fieldInfo.name, e.target.value);
                    }}
                    value={value}
                    id={fieldInfo.name}
                  />
                  {fieldInfo?.psotText && <FormLabel>{fieldInfo?.psotText}</FormLabel>}
                </Box>
              }
              label=""
            />
          </FormControl>
        </FormGroup>
        {error && <FormHelperText sx={{ color: formError }}>{error}</FormHelperText>}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(CheckboxWithPrePostTextField, fieldValueEqual);
