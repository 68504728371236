import { SupportedLanguages } from "@midato-mono/common/src/types";
import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface GetConsentFormPreviewConfig {
  formId: string;
  formContent: any;
}

const getConsentFormPreview = async ({ auth, variables }: QueryConfig<GetConsentFormPreviewConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($formId: ID!, $data: String!) {
        getConsentFormPreview(formId: $formId, data: $data)
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentFormConfig {
  code: string;
  version: string;
  lang?: SupportedLanguages;
}

const getConsentForm = async ({ auth, variables }: QueryConfig<GetConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($code: String!, $version: String!) {
        getConsentForm(code: $code, version: $version) {
          ...cmsConsentFormFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetFormFamilyConfig {
  code: string;
  version: string;
}

const getFormFamily = async ({ auth, variables }: QueryConfig<GetFormFamilyConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($code: String!, $version: String!) {
        getFormFamily(code: $code, version: $version) {
          ...cmsConsentFormFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface ConsentFormConfig {
  formInput: {
    code: string;
    version: string;
    lang: SupportedLanguages;
    title: string;
    description: string;
    formUrl: string;
    expiryDays: number;
    templates: string[];
    schema?: string;
    orgId?: string;
    ocrEnabled?: boolean;
  };
}

const createConsentForm = async ({ auth, variables }: QueryConfig<ConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($formInput: ConsentFormInput!) {
        addConsentForm(formInput: $formInput) {
          code
          version
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateConsentFormConfig {
  formId: string;
  formInput: {
    code: string;
    version: string;
    lang: SupportedLanguages;
    title?: string;
    description?: string;
    formUrl?: string;
    expiryDays?: number;
    templates: string[];
    schema?: string;
  };
}

const updateConsentForm = async ({ auth, variables }: QueryConfig<UpdateConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($formId: ID!, $formInput: UpdateConsentFormInput!) {
        updateConsentForm(formId: $formId, formInput: $formInput) {
          code
          version
          title
          description
          schema
          formUrl
          expiryDays
          tpo
          generalDesignation
          ocrEnabled
        }
      }
    `,
    variables,
    auth
  );
};
export interface PublishConsentFormConfig {
  code: string;
  version: string;
}

const publishConsentForm = async ({ auth, variables }: QueryConfig<PublishConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($code: String!, $version: String!) {
        publishConsentForm(code: $code, version: $version) {
          formId
          code
          version
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeleteConsentFormConfig {
  code: string;
  version: string;
}

const deleteConsentForm = async ({ auth, variables }: QueryConfig<DeleteConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($code: String!, $version: String!) {
        deleteConsentForm(code: $code, version: $version) {
          formId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeprecateConsentFormConfig {
  code: string;
  version: string;
  supercededBy?: string;
}

const deprecateConsentForm = async ({ auth, variables }: QueryConfig<DeprecateConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($code: String!, $version: String!, $supercededBy: ID) {
        deprecateConsentForm(code: $code, version: $version, supercededBy: $supercededBy) {
          code
          version
        }
      }
    `,
    variables,
    auth
  );
};

export {
  getConsentFormPreview,
  getConsentForm,
  getFormFamily,
  createConsentForm,
  updateConsentForm,
  publishConsentForm,
  deleteConsentForm,
  deprecateConsentForm
};
