import { ReactElement, CSSProperties } from "react";
import assets from "../../assets";
import { ConsumersConsentType } from "@midato-mono/common/src/types";
import { Divider, Box, Typography } from "@mui/material";

const {
  appStyles: {
    WizardStyle: { TitleText, LargeTitleText },
    styledComponents: { StyledChip }
  },
  appStrings: {
    common: {
      appStrings: { consumersConsentStatus }
    }
  },
  appConstants: { gutterMargin }
} = assets;

interface WizardTitleProps {
  children?: string;
  style?: CSSProperties;
  id?: string;
  formstatus?: string;
  chipStyles?: any;
}

function WizardTitle({ children, style, id, formstatus, chipStyles }: WizardTitleProps): ReactElement {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {formstatus && chipStyles ? (
        <Box style={{ ...style }}>
          <StyledChip
            sx={{ ...chipStyles[formstatus], marginTop: 1 }}
            label={consumersConsentStatus?.[formstatus as ConsumersConsentType] ?? "unknown"}
            size="medium"
          />
          <br />
          <LargeTitleText id={id}>{children}</LargeTitleText>
          {children && <Divider style={{ margin: `${gutterMargin}px 0` }} />}
        </Box>
      ) : (
        <Typography variant="h6">
          <TitleText id={id} style={{ ...style }}>
            {children}
          </TitleText>
        </Typography>
      )}
    </>
  );
}

export default WizardTitle;
