import { validations } from "../../utils";
import { SecurityRoleField } from "./types";
import assets from "../../../ui/assets";

const {
  appStrings: {
    admin: {
      securityRole: {
        item: { formFields }
      }
    }
  }
} = assets;

const fields: { [key in SecurityRoleField]: any } = {
  name: {
    name: "name",
    label: formFields.name,
    width: "50%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  externalID: {
    name: "externalID",
    label: "External ID",
    showLabel: true,
    width: "50%",
    validation: validations.textMinMax(1, 100)
  },
  orgId: {
    name: "orgId",
    label: "Organization",
    showLabel: true,
    width: "50%",
    fieldType: "select",
    options: []
    // validation: validations.nonEmptyArray
  },
  active: {
    name: "active",
    label: "Active",
    showLabel: true,
    width: "50%",
    fieldType: "switch"
  },
  permissions: {
    maxHeight: "40vh",
    name: "permissions",
    label: "Permissions",
    showLabel: true,
    width: "50%",
    fieldType: "checkbox_group",
    options: [],
    validation: validations.nonEmptyArray
  }
};

export default fields;
