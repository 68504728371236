import { memo, useState, MouseEvent } from "react";
import * as React from "react";
import type { InteractiveFieldProps } from "../../../types";
import { Typography, Box, IconButton, Popover, Menu, MenuItem } from "@mui/material";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { capitalizedString, makeDOMElementId } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  formFields: { chipBoxStyle, chipBoxTitleStyle },
  styledComponents: { ListItem, StyledChip }
} = appStyles;

const getChipLabel = (options: { name: string; label: string }[], selectedOptionName: string) => {
  return options.find((option) => option.name === selectedOptionName)?.label ?? selectedOptionName;
};

const ChipsField = ({
  fieldInfo,
  value,
  setFieldValue,
  error: _error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const options = fieldInfo.options;
  const chipStyles = fieldInfo?.chipStyles;
  const clickCallback = fieldInfo?.clickCallback;
  const addCallback = fieldInfo?.addCallback;
  const deleteDisabled = fieldInfo?.deleteDisabled;
  const addIconHidden = fieldInfo?.addIconHidden;

  const handleDelete = (optionNameToDelete: string) => {
    setFieldValue(
      fieldInfo.name,
      value.filter((optionName) => optionName !== optionNameToDelete)
    );
  };

  const handleClick = (optionNameClicked: string) => {
    clickCallback?.(optionNameClicked);
  };

  const handleAddBtn = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (clickedOptionName) => {
    setFieldValue(fieldInfo.name, [...value, clickedOptionName]);
    setAnchorEl(null);
    addCallback?.(clickedOptionName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getChipStyle = (optionName: string) => {
    return chipStyles
      ? fieldInfo?.activeChipName === optionName
        ? chipStyles["activeChip"]
        : chipStyles[optionName]
      : null;
  };

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <Typography sx={chipBoxTitleStyle}>{fieldInfo.label}</Typography>
        <Box id={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)} sx={chipBoxStyle}>
          {value.map((selectedOptionName) => {
            return (
              <ListItem key={selectedOptionName}>
                <StyledChip
                  size="small"
                  sx={getChipStyle(selectedOptionName)}
                  aria-label={String(getChipLabel(options, selectedOptionName)) + fieldInfo.formId}
                  label={getChipLabel(options, selectedOptionName)}
                  onClick={fieldInfo.disabled ? undefined : () => handleClick(selectedOptionName)}
                  onDelete={fieldInfo.disabled || deleteDisabled ? undefined : () => handleDelete(selectedOptionName)}
                />
              </ListItem>
            );
          })}
          {value.length !== fieldInfo?.options?.length && !isDisabledField && !addIconHidden ? (
            <IconButton color="primary" onClick={handleAddBtn} aria-label="add new">
              <AddCircleIcon />
            </IconButton>
          ) : null}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {fieldInfo?.options?.length > 0 &&
                fieldInfo?.options
                  .filter((option) => !value.includes(option.name))
                  .map((option) => {
                    return (
                      <MenuItem key={option.name} onClick={() => handleMenuItemClick(option.name)}>
                        {capitalizedString(option.label)}
                      </MenuItem>
                    );
                  })}
            </Menu>
          </Popover>
        </Box>
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(ChipsField, fieldValueEqual);
