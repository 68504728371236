import { memo, useRef, useState } from "react";
import type { InteractiveFieldProps } from "../../../types";
import { Box, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { fieldValueEqual, getIsDisabledField, makeDOMElementId } from "../../../utils";
import { appStyles, commonStrings } from "../../../assets";
import BaseButton from "../../BaseButton";

const {
  styledComponents: { FileUploadFieldLabel }
} = appStyles;

const {
  appStrings: { labels }
} = commonStrings;

const FileUploadField = ({ fieldInfo, value, setFieldValue, error: _error = "" }: InteractiveFieldProps) => {
  const { name: fieldName = "upload", accept = "*", tooltipText } = fieldInfo;
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const hiddenFileInput = useRef(null);
  const [filename, setFilename] = useState(value?.name || labels.defaultFile);
  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    setFilename(fileUploaded.name);
    setFieldValue(fieldName, fileUploaded);
  };

  return (
    <Box>
      <FileUploadFieldLabel>
        {fieldInfo.label}
        &nbsp;
        <Tooltip title={tooltipText || "Supported file types: NDJSON"} placement="top">
          <InfoIcon sx={{ verticalAlign: "middle" }} />
        </Tooltip>
      </FileUploadFieldLabel>
      <BaseButton
        id={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)}
        onPress={handleUpload}
        disabled={isDisabledField}
        color="inherit"
        variant="contained"
        ariaLabel={labels.chooseFile}
      >
        {labels.chooseFile}
      </BaseButton>
      <input type="file" onChange={handleChange} ref={hiddenFileInput} accept={accept} hidden />
      <Typography aria-live="polite" aria-atomic="true" display="inline">
        &nbsp;{filename}
      </Typography>
    </Box>
  );
};

export default memo(FileUploadField, fieldValueEqual);
