import {
  commonStrings,
  appConstants,
  appColors,
  themeColors,
  appStyles as commonAppStyles
} from "@midato-mono/common/src/assets";
import adminStrings from "./strings";
import * as adminAppStyles from "./styles";

export default {
  appConstants,
  appColors,
  theme: themeColors,
  appStyles: { ...commonAppStyles, ...adminAppStyles },
  appStrings: {
    admin: adminStrings,
    common: commonStrings
  }
};
