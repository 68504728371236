import { Typography } from "@mui/material";
import { ImportItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  createButtonBoxPostAmble,
  createAction,
  formIsUntouchedOrHasError,
  disableFields
} from "../../utils";
import { useCreateBulkImportMutation, useFetchOrganizationQuery, useGetBulkImportQuery } from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import { downloadFile, formattedDate } from "@midato-mono/common/src/utils";
import fields from "./fields";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, download }
      }
    },
    admin: {
      bulkImport: {
        item: { formFields }
      }
    }
  }
} = assets;

// new
type NC = ImportItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useCreateBulkImportMutation, type: "add" })
});

export const useNewInitialQuery: NC["useInitialQuery"] = ({ orgId }) => {
  const { data: callerOrganization, ...others } = useMidatoQuery({
    queryFn: useFetchOrganizationQuery,
    queryArgs: {
      orgId
    }
  });
  const organizations = (callerOrganization ? [callerOrganization] : []).map(({ orgId, name }: any) => ({
    name: orgId,
    label: name
  }));
  return { data: organizations, ...others };
};

export const createNewRows: NC["createRows"] = () => [[fields.description], [fields.type], [fields.upload]];

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  upload: { getUploadUrl, uploadFile },
  orgId,
  permissions
}) => {
  const saveButton = createButton({
    id: "btn-import",
    text: save,
    actionId: "IMPORT_ADD",
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const { type, upload, description } = formik.values;
        const { url, fields: rawFields } = await getUploadUrl({
          extension: "ndjson",
          type: "BulkImport",
          orgId
        }).unwrap();
        const fields = JSON.parse(rawFields);
        const rawUploadUrl = await uploadFile({ variables: { url, fields, file: upload } }).unwrap();
        const uploadUrl = `${rawUploadUrl}/${fields.key}`;
        const params = {
          bulkImport: {
            orgId,
            type,
            uploadUrl,
            fileName: upload.name,
            description
          }
        };
        const bulkActionId = await add!(params).unwrap();
        navigate(`${routes.IMPORTS}/${bulkActionId}`);
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton]
  });
};

// detail
type DC = ImportItemPageConfiguration["detail"];

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: bulkActionId } }) => {
  return useMidatoQuery({
    queryFn: useGetBulkImportQuery,
    queryArgs: {
      bulkActionId
    },
    queryOptions: { skip: !bulkActionId }
  });
};

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData }) =>
  initialQueryData?.bulkActionId ?? "";

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData: bulkImport }) => ({
  orgId: bulkImport?.orgId ?? "",
  type: bulkImport?.type ?? "",
  index: bulkImport?.index ?? "",
  createdAt: bulkImport?.createdAt ?? "",
  rowsProcessed: bulkImport?.rowsProcessed ?? 0,
  description: bulkImport?.description
});

export const createDetailRows: DC["createRows"] = ({ initialQueryData: bulkImport }) => [
  disableFields([fields.description]),
  disableFields([fields.type]),
  disableFields([fields.file]),
  [fields.importStatus],
  [{ ...fields.rowsProcessed, totalRows: bulkImport?.totalRows ?? 0 }]
];

export const createDetailPostAmble: DC["createPostAmble"] = ({ initialQueryData: bulkImport, permissions }) => {
  const downloadButton = createButton({
    actionId: "BULK_IMPORT",
    id: "btn-download",
    text: download,
    onPress: async () => {
      if (bulkImport?.importUrl && bulkImport?.fileName) {
        downloadFile(bulkImport.importUrl, bulkImport.fileName);
      }
    }
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [downloadButton]
  });
};

export const createDetailPreAmble: DC["createPreAmble"] = ({ initialQueryData: bulkImport }) => ({
  PreAmble: Typography,
  props: {
    children: `${formFields.createdAt} ${bulkImport?.createdAt && formattedDate(bulkImport.createdAt)}`
  }
});
