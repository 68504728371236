import { memo } from "react";
import * as React from "react";
import type { InteractiveFieldProps } from "../../../types";
import { FormControl, FormHelperText, MenuItem, Select, Box, Checkbox, ListItemText, InputLabel } from "@mui/material";
import { fieldValueEqual, getIsDisabledField, makeDOMElementId } from "../../../utils";
import { appColors, appStyles } from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { formError } = appColors;

const {
  customAsteriskStyle,
  baseForm: { sharedStyles }
} = appStyles;

const MultiSelectorField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  onBlurAction,
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);

  const menuItems = fieldInfo.options?.map((option: { name: string; label: string }, i: number) => (
    <MenuItem value={option.name} key={i}>
      <Checkbox checked={value.includes(option.name)} />
      <ListItemText primary={option.label} />
    </MenuItem>
  ));

  const labelMap = fieldInfo?.options?.reduce((acc, { name, label }) => {
    acc[name] = label;
    return acc;
  }, {});

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <FormControl
          variant={fieldInfo.variant ?? "standard"}
          disabled={isDisabledField}
          style={{ ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
        >
          <InputLabel
            htmlFor={`custom-multi-select-${fieldInfo.label}`}
            id={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)}
          >
            {fieldInfo.label} <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
          </InputLabel>
          <Select
            inputProps={{ id: `custom-multi-select-${fieldInfo.label}` }}
            id={fieldInfo.name}
            name={fieldInfo.label}
            labelId={makeDOMElementId(fieldInfo.label, `${fieldInfo.fieldType}-field`)}
            sx={{ position: "relative" }}
            onChange={(event: any) => {
              const previousValueIncludeAll = value.includes("*") || value.includes("all");
              const currentValueIncludeAll = event.target.value.includes("*") || event.target.value.includes("all");
              if (previousValueIncludeAll && !currentValueIncludeAll) setFieldValue(fieldInfo.name, [], true);
              else if (!previousValueIncludeAll && currentValueIncludeAll) {
                setFieldValue(
                  fieldInfo.name,
                  fieldInfo?.options?.map(({ name }) => name),
                  true
                );
              } else if (
                fieldInfo?.options.length !== event.target.value.length &&
                !(fieldInfo?.options.length - 1 === event.target.value.length && !currentValueIncludeAll)
              ) {
                setFieldValue(
                  fieldInfo.name,
                  event.target.value.filter((name) => name !== "*" && name !== "all"),
                  true
                );
              } else if (fieldInfo?.options.length - 1 === event.target.value.length && !currentValueIncludeAll) {
                setFieldValue(
                  fieldInfo.name,
                  fieldInfo?.options?.map(({ name }) => name),
                  true
                );
              } else setFieldValue(fieldInfo.name, event.target.value, true);
            }}
            onBlur={
              onBlurAction
                ? (event: any) => {
                    onBlurAction(fieldInfo.name, event.target.value, true);
                  }
                : undefined
            }
            displayEmpty
            value={[...value]}
            error={Boolean(error)}
            label={fieldInfo.label}
            multiple
            // inputProps={{
            //   "aria-label": fieldInfo.label
            // }}
            renderValue={(selected) => {
              const values = [...selected.map((value) => labelMap?.[value])];
              if (value.includes("All") || value.includes("all") || value.includes("*")) return ["All"];
              return values.join(", ");
            }}
          >
            {menuItems}
          </Select>
          {error && (
            <FormHelperText
              sx={{
                color: formError
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(MultiSelectorField, fieldValueEqual);
