/* eslint-disable */
const dateString = (date: string): string =>
  new Date(date).toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
export default {
  defaultErrorStrings: {
    requiredError: "This field is required",
    usernameRequiredError: "Username is required",
    phoneRequiredError: "Phone number is required",
    phoneNumInvalidError: "Invalid phone number",
    invalidPhoneError: "Country code required (ie +1)",
    endDateError: "End date must be greater than start date",
    phoneMaxLengthError: (max: number) => `Phone number must be less than ${max} numbers`,
    phoneMinLengthError: (min: number) => `Phone number must be at least ${min} digits`,
    textMaxLengthError: (max: number) => `Text must be less than ${max} characters`,
    textMinLengthError: (min: number) => `Text must be a least ${min} characters`,
    npiError: (max: number) => `NPI should contain  ${max} digits`,
    numberMax: (max: number) => `Value must be less than or equal to ${max}`,
    numberMin: (min: number) => `Value must be greater than or equal to ${min}`,
    passwordRequiredError: "Password is required",
    confirmationRequiredError: "Password confirmation is required",
    confirmationMismatchError: "Passwords don't match",
    emailRequiredError: "Email Address is required",
    invalidEmailError: "Invalid email address",
    invalidZipError: "Invalid Zip Code",
    minLengthError: "Password must be at least 8 characters",
    maxLengthError: "Password must be less than 17 characters",
    nameMaxLengthError: "Name must be less than 17 characters",
    invalidPasswordError: "Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number",
    hasCapError: "Password must contain an uppercase letter",
    hasLowerError: "Password must contain an lowercase letter",
    hasDigitError: "Password must contain a number",
    hasSymbolError: "Password must contain at least one special character",
    unexpectedError: "Unexpected Error",
    agreementError: "Must accept agreement before continuing",
    dobMinYearError: "DOB must be later than 110 years ago",
    dobMaxYearError: "DOB must be earlier than today",
    dobRequiredError: "Date of birth is required",
    dobInvalidError: "Invalid date of birth",
    invalidDateError: "Invalid date ",
    generalError: "some error happens",
    secretKeyError: "secret is not correct",
    linkExpiredError: "The link has expired",
    formExpiredNumInvalidError: "Please provide a number",
    dateMin: "Date should be after",
    invalidDateFormateError: "Invalid date format. Use MM/dd/yyyy.",
    dateExpiry: "Date must be today or in the future"
  },
  regex: {
    isValidEmail:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    isValidPhone: /^\+[1-9]{1}[0-9]{3,14}$/,
    isValidAnyPhone: /^(?:\+\d{1,3})?\d{10}$/,
    isValidZip: /^\d{5}$/,
    isValidExpressZip: /(^\d{4}$)/,
    hasCap: /[A-Z]/,
    hasLower: /[a-z]/,
    hasDigit: /[[\d]/,
    hasSymbol: /[\W]/,
    isNumber: /^\d*$/,
    isValidUsPhone: /^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/
  },
  appStrings: {
    metaStrings: {
      shareApprove: "Share Approve"
    },
    prompts: {
      deleteConfirmation: "Are you sure you want to delete?",
      deactivateConfirmation: "Are you sure you want to deactivate?",
      goBackConfirmation: "You currently have unsaved changes! Are you sure you want to leave without saving?",
      apiCallError: "Failed to delete form",
      nonDraftError: "Only draft forms can be deleted",
      draft: "draft",
      confirmDeletion: "Are you sure you want to delete this draft?",
      formDeleteSuccess: "Form successfully deleted",
      formDeprecateSuccess: "Form successfully deprecated",
      formPublishSuccess: "Form successfully published",
      formUpdateSuccess: "Form successfully updated",
      formCreateSuccess: "Form successfully created as draft",
      genericFailure: "An error has occured",
      confirmPublish:
        "After publishing the form you will no longer be able to edit it. Are you sure you want to publish the form?",
      infoChanged: "Organization Saved",
      addedAndLinkedSuccess: "Added and Linked Organization",
      linkedSuccess: "Linked Organization",
      unlinkedSuccess: "Unlinked Organization",
      confirmDelete: "Organization Deleted",
      updateError: "Error updating organization: ",
      linkedError: "Error linking organization: ",
      addingSubError: "Error adding sub organization: ",
      errorFetchingChild: "There was an issue fetching child organizations",
      errorFetchingRoot: "There was an issue fetching root organizations",
      noneSelectedError: "No Selected Organizations",
      deleteFailedNoId: "Delete Failed. Selected organization not found.",
      deleteFailed: "There was an issue deleting organization",
      deactivateDoubleConfirmation:
        "This user belongs to multiple organizations.  Are you sure you want to deactivate them?"
    },
    labels: {
      name: "Name",
      email: "Email",
      city: "City",
      state: "State",
      tenant: "Tenant",
      firstName: "First Name",
      middleName: "Middle",
      lastName: "Last Name",
      npi: "NPI",
      dea: "DEA",
      phone: "Phone",
      address1: "Address 1",
      address2: "Address 2",
      zip: "Zip",
      expressZip: "Express Zip",
      signature: "Click in Field to Initial",
      dobLabel: "Date of Birth (MM/DD/YYYY)",
      organization: "Organization",
      recipientOrganizationTitle: "Recipient Organization(s)",
      requestorOrganizationTitle: "Disclosing Organization(s)",
      practitioner: "Practitioner",
      chooseFile: "Choose File",
      defaultFile: "No file chosen",
      emptyFiller: (item: string) => `No ${item.toLowerCase()}`,
      currentPassword: "Current Password",
      newPassword: "New Password",
      printName: "Print Name"
    },
    buttonText: {
      agree: "Agree",
      login: "Login",
      add: "Add",
      back: "Back",
      done: "Done",
      deprecate: "Deprecate",
      copy: "Copy",
      continueText: "Continue",
      deleteText: "Delete",
      publish: "Publish",
      delete: "Delete",
      deactivate: "Deactivate",
      saveDraft: "Save Draft",
      cancel: "Cancel",
      submit: "Submit",
      clear: "Clear",
      logout: "Log Out",
      dismiss: "Dismiss",
      previous: "Previous",
      next: "Next",
      validate: "Validate",
      save: "Save",
      updatePassword: "Update Password",
      send: "Send",
      createNew: "Create New",
      change: "Change",
      deleteStr: "Delete",
      download: "Download",
      import: "Import",
      edit: "Edit",
      reset: "Reset",
      viewReport: "View Report",
      viewSummaryReport: "View Summary",
      exportReport: "Export Report",
      uploadConsent: "Upload Consent",
      upload: "Upload",
      saveForLater: "Save for Later",
      revoke: "Revoke",
      editConsumer: "Edit Consumer",
      createConsent: "Create Consent",
      close: "Close",
      continueSession: "Continue Session"
    },
    rowsProcessed: "rows processed",
    countryCodes: { us: "+1" },
    urlPrefix: "https://",
    actionRibbon: {
      searchLabel: "Search"
    },
    sectionDetails: {
      sectionTitle: (currIdx: string | number, length: string | number | undefined) => {
        return `page ${currIdx} of ${length}`;
      }
    },
    dates: {
      standardDateString: dateString,
      sentDateString: (date: string) => `Sent ${dateString(date)}`
    },
    formViewerWizard: {
      titles: {
        history: "Status History",
        defaultViewerTitle: "Consumer Consent"
      },
      details: {
        requestedForm: ` requested consent to send treatment records to `
      }
    },
    loginErrorMessage: "Incorrect username or password.",
    notifications: {
      sessionExpired: "Your session has expired; please log in.",
      signOnSuccess: "Thanks for signing up!",
      genericFailure: "An error has occured"
    },
    consumersConsentStatus: {
      draft: "Pending",
      rejected: "Rejected",
      active: "Active",
      inactive: "Revoked",
      expired: "Expired",
      abandoned: "Abandoned",
      deleted: "Deleted"
    },
    consentInfo: { requestedByText: "Requested by", sendToText: "Send to" },
    languagesOptions: {
      en: { name: "en", label: "English" },
      es: { name: "es", label: "Spanish" },
      fr: { name: "fr", label: "French" },
      zh: { name: "zh", label: "Chinese (Simplified)" }
    }
  }
};
