import { CSSProperties, useRef, useState, memo } from "react";
import * as React from "react";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import { BorderColor as BorderColorIcon } from "@mui/icons-material";
import SignatureCanvas from "react-signature-canvas";
import { useWindowDimensions } from "../../../hooks";
import { fieldValueEqual, parseHTMLElements } from "../../../utils";
import { appConstants, appColors, commonStrings, appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import BaseButton from "../../BaseButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { isDesktopDevice, primaryFontSize } = appConstants;
const {
  baseForm: { sharedStyles }
} = appStyles;
const { primaryTheme, formError, consentFieldBackground, formText } = appColors;

const {
  defaultErrorStrings: { requiredError },
  appStrings: {
    buttonText: { clear, save },
    labels
  }
} = commonStrings;

const signedSignatureStyles = {
  backgroundRepeat: "no-repeat",
  position: "relative",
  zIndex: "auto",
  flex: 1
};

const signatureIcon = {
  backgroundColor: "transparent",
  top: 6,
  position: "relative"
};

const InitialsSignatureField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const [showDrawingSurface, setShowDrawingSurface] = useState(false);
  const [isBlank, setIsBlank] = useState(true);
  const canvas = useRef<any>({});
  const { width } = useWindowDimensions();

  const showSignatureAsLabel = !!value && value !== "";
  const hasValue = !!value && value !== "";
  const showLabel = hasValue || showDrawingSurface;

  const handleShow = (shouldShow: boolean): void => {
    setShowDrawingSurface(shouldShow);
  };

  const activeSignatureField = () => {
    if (!showDrawingSurface) {
      setIsBlank(true);
      handleShow(true);
    }
  };
  const floatingLabelStyles: CSSProperties = {
    alignSelf: "flex-end",
    height: 20,
    fontSize: 12,
    color: showDrawingSurface ? primaryTheme : formText,
    ...(error ? { color: formError } : fieldInfo.disabled ? { color: "lightgrey" } : {})
  };

  const labelStyles = {
    alignSelf: "flex-end",
    paddingBottom: 3,
    color: formText,
    ...(error ? { color: formError } : fieldInfo.disabled ? { color: "lightgrey" } : {})
  };

  const sxProps = {
    ...sharedStyles,
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    height: showDrawingSurface ? 80 : undefined,
    borderBottom: error ? `solid 2px ${formError}` : "solid 1px darkgrey",
    "&:hover": {
      borderBottom: error ? `solid 2px ${formError}` : "solid 2px darkgrey"
    },
    ...(fieldInfo?.style ?? {})
  };

  const isDesktop = isDesktopDevice();
  const canvasWidth = (width: number) => {
    if (isDesktop) {
      if (width * 0.6 > 700) {
        return 300;
      }
      return width * 0.6;
    } else {
      return width - 50;
    }
  };

  const updateCanvasValue = () => {
    if (!isBlank) {
      const cleanData = canvas.current.getTrimmedCanvas().toDataURL().split("base64,")[1];
      setFieldValue(fieldInfo.name, cleanData, true);
    } else {
      setFieldValue(fieldInfo.name, "", true);
    }
  };

  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <div>
        {showLabel ? (
          <Typography style={{ marginTop: "20px", ...(floatingLabelStyles as CSSProperties) }}>
            {fieldInfo.label ? fieldInfo.label : labels.signature}
          </Typography>
        ) : null}
        <Box sx={sxProps} onClick={fieldInfo.disabled ? undefined : (): void => activeSignatureField()}>
          {showSignatureAsLabel && !showDrawingSurface ? (
            <Box
              height={80}
              style={{
                ...(signedSignatureStyles as CSSProperties),
                backgroundImage: `url(data:image/jpeg;base64,${value})`
              }}
            />
          ) : showDrawingSurface ? (
            <SignatureCanvas
              ref={canvas}
              onBegin={() => setIsBlank(false)}
              onEnd={updateCanvasValue}
              canvasProps={{
                width: canvasWidth(width),
                height: 80,
                style: {
                  backgroundColor: consentFieldBackground
                }
              }}
            />
          ) : (
            <Typography style={labelStyles}>{fieldInfo.label ? fieldInfo.label : labels.signature}</Typography>
          )}

          {!showDrawingSurface && isDesktop ? (
            <IconButton
              aria-label="add initials"
              onClick={() => {}}
              style={signatureIcon as CSSProperties}
              size="large"
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Box>

        {showDrawingSurface && (
          <Box>
            <Stack direction="row" spacing={2}>
              <BaseButton
                onPress={() => {
                  updateCanvasValue();
                  handleShow(false);
                }}
                color="primary"
                variant="text"
                ariaLabel={save}
              >
                {save}
              </BaseButton>
              <BaseButton
                onPress={() => {
                  setIsBlank(true);
                  canvas.current.clear();
                }}
                color="primary"
                variant="text"
                ariaLabel={clear}
              >
                {clear}
              </BaseButton>
            </Stack>
          </Box>
        )}
        {error && (
          <Typography
            style={{
              color: formError,
              paddingTop: 2,
              fontSize: primaryFontSize
            }}
          >
            {fieldInfo.label ? parseHTMLElements(error) : requiredError}
          </Typography>
        )}
      </div>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(InitialsSignatureField, fieldValueEqual);
