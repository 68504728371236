import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { fieldValueEqual, makeDOMElementId } from "../../../utils";
import { appStyles, commonStrings } from "../../../assets";

const {
  styledComponents: { BorderLinearProgress }
} = appStyles;

const {
  appStrings: { rowsProcessed }
} = commonStrings;

const ProgressBarField = ({ fieldInfo, value = 0 }: { fieldInfo: any; value: number }) => {
  const { totalRows } = fieldInfo;
  let progress = 0;
  let nValue = Number(value);
  let nTotal = Number(totalRows);
  if (nValue && nTotal) {
    progress = (nValue / nTotal) * 100;
  }

  return (
    <Box>
      <BorderLinearProgress
        id={makeDOMElementId(fieldInfo.name, `${fieldInfo.fieldType}-field`)}
        variant="determinate"
        value={progress}
      />
      <Typography>
        {nValue || 0}/{nTotal || 0} {rowsProcessed}
      </Typography>
    </Box>
  );
};

export default memo(ProgressBarField, fieldValueEqual);
