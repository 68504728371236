const ACTIONS = {
  DASHBOARD_GET: {
    requiredScopes: []
  },
  PROFILE_GET: {
    requiredScopes: []
  },
  PROFILE_UPDATE: {
    requiredScopes: ["profileUpdate"]
  },
  BULK_LIST: {
    requiredScopes: ["bulkRead"]
  },
  EXPORT_ADD: {
    requiredScopes: ["bulkCreate"]
  },
  EXPORT_GET: {
    requiredScopes: ["exportRead"]
  },
  EXPORTS_LIST: {
    requiredScopes: ["exportRead"]
  },
  IMPORT_ADD: {
    requiredScopes: ["importCreate"]
  },
  IMPORT_GET: {
    requiredScopes: ["importRead"]
  },
  IMPORTS_LIST: {
    requiredScopes: ["importRead"]
  },
  BULK_EXPORT: {
    requiredScopes: ["exportRead"]
  },
  BULK_IMPORT: {
    requiredScopes: ["importRead"]
  },
  FORM_ADD: {
    requiredScopes: ["formTemplateCreate"]
  },
  FORM_DELETE: {
    requiredScopes: ["formTemplateDelete"]
  },
  FORM_UPDATE: {
    requiredScopes: ["formTemplateUpdate"]
  },
  FORM_PREVIEW: {
    requiredScopes: ["formTemplateRead"]
  },
  FORM_GET: {
    requiredScopes: ["formTemplateRead"]
  },
  FORM_LIST: {
    requiredScopes: ["formTemplateRead"]
  },
  FORM_PUBLISH: {
    requiredScopes: ["formTemplatePublish"]
  },
  FORM_DEACTIVATE: {
    requiredScopes: ["formTemplateDelete"]
  },
  CONSUMER_ADD: {
    requiredScopes: ["consumerCreate"]
  },
  CONSUMER_DELETE: {
    requiredScopes: ["consumerDelete"]
  },
  CONSUMER_UPDATE: {
    requiredScopes: ["consumerUpdate"]
  },
  CONSUMER_GET: {
    requiredScopes: ["consumerRead"]
  },
  CONSUMER_LIST: {
    requiredScopes: ["consumerRead"]
  },
  CONSUMER_USER_INVITE: {
    requiredScopes: ["consumerUserInvite"]
  },
  USER_ADD: {
    requiredScopes: ["userCreate"]
  },
  USER_DELETE: {
    requiredScopes: ["userDelete"]
  },
  USER_UPDATE: {
    requiredScopes: ["userUpdate"]
  },
  USER_GET: {
    requiredScopes: ["userRead"]
  },
  USER_LIST: {
    requiredScopes: ["userRead"]
  },
  SECURITY_ROLES_LIST: {
    requiredScopes: ["securityRoleRead"]
  },
  SECURITY_ROLE_COPY: {
    requiredScopes: ["securityRoleCopy"]
  },
  SECURITY_ROLE_ADD: {
    requiredScopes: ["securityRoleCreate"]
  },
  SECURITY_ROLE_UPDATE: {
    requiredScopes: ["securityRoleUpdate"]
  },
  SECURITY_ROLE_GET: {
    requiredScopes: ["securityRoleRead"]
  },
  SECURITY_ROLE_DELETE: {
    requiredScopes: ["securityRoleDelete"]
  },
  ORGANIZATION_ADD: {
    requiredScopes: ["organizationCreate"]
  },
  ORGANIZATION_DELETE: {
    requiredScopes: ["organizationDelete"]
  },
  ORGANIZATION_ACTIVATE: {
    requiredScopes: ["organizationUpdate"]
  },
  ORGANIZATION_UPDATE: {
    requiredScopes: ["organizationUpdate"]
  },
  ORGANIZATION_GET: {
    requiredScopes: ["organizationRead"]
  },
  ORGANIZATION_LIST: {
    requiredScopes: ["organizationRead"]
  },
  ORGANIZATION_SETTINGS_UPDATE: {
    requiredScopes: ["organizationUpdate"]
  },
  CONSENT_LIST: {
    requiredScopes: ["consentRead"]
  },
  CONSENT_GET: {
    requiredScopes: ["consentRead"]
  },
  CONSENT_UPLOAD: {
    requiredScopes: ["consentUpload"]
  },
  CONSENT_PRINT: {
    requiredScopes: ["consentPrint"]
  },
  CONSENT_DOWNLOAD: {
    requiredScopes: ["consentDownload"]
  },
  CONSENT_ADD: {
    requiredScopes: ["consentCreate"]
  },
  CONSENT_OBJECT_REQUEST: {
    requiredScopes: ["consentRequestSend"]
  },
  PRACTITIONER_ADD: {
    requiredScopes: ["practitionerRead"]
  },
  PRACTITIONER_DELETE: {
    requiredScopes: ["practitionerDelete"]
  },
  PRACTITIONER_UPDATE: {
    requiredScopes: ["practitionerUpdate"]
  },
  PRACTITIONER_GET: {
    requiredScopes: ["practitionerRead"]
  },
  PRACTITIONER_LIST: {
    requiredScopes: ["practitionerRead"]
  },
  RESET_CONSUMER: {
    requiredScopes: ["consumerRead"]
  },
  PASSWORD_RESET_INVITE: {
    requiredScopes: ["passwordResetInvite"]
  },
  PASSWORD_CHANGE: {
    requiredScopes: ["passwordChange"]
  },

  REPORT_USER_ACTIVITY_LOG: {
    requiredScopes: ["reportUserActivityLog"]
  },

  REPORT_CONSENT_LOG: {
    requiredScopes: ["reportConsent"]
  },

  REPORT_EXPIRY_LOG: {
    requiredScopes: ["reportExpiry"]
  },

  REPORT_USAGE_LOG: {
    requiredScopes: ["reportSystemUsage"]
  },

  CONSENT_REVOKE: {
    requiredScopes: ["consentRevoke"]
  },

  CONSENT_REVOKE_ANY: {
    requiredScopes: ["consentRevokeAny"]
  },

  OCR_RESULTS_LIST: {
    requiredScopes: ["OCRQueueView"]
  },

  OCR_RESULT_UPDATE: {
    requiredScopes: ["OCRResultsCorrect"]
  },

  REPORT_CONSENT_STATISTICS: {
    requiredScopes: ["reportConsentStatistics"]
  },

  REPORT_LOAD_TIME: {
    requiredScopes: ["reportPageLoadTime"]
  }
};

export default ACTIONS;
