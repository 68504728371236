import {
  styled,
  TextField,
  Box,
  Paper,
  Fab,
  Typography,
  Chip,
  LinearProgress,
  Modal,
  linearProgressClasses
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { appColors, buttonColors, themeColors as theme } from "./colors";
import * as constants from "./constants";
import { CSSProperties } from "react";

const {
  actionRibbonSearchFieldHeight,
  actionModalInviteHeight,
  actionModalInviteWidth,
  wizardRecipientChoiceHeight,
  wizardRecipientChoiceWidth,
  standardBorderWidth,
  sidebarWidth,
  gutterMargin,
  smallPadding,
  standardPadding,
  accountIconSize,
  careTeamAddIconSize,
  betweenIconBtnMargin,
  standarButtonHeight,
  subTitleFontSize,
  minDrawerWidth,
  pageTitleHeaderPaddingString,
  margin10,
  margin30,
  defaultTextFontSize,
  miniPadding,
  smFontSpace,
  titleFontSize,
  smButtonFontSize,
  smButtonHeight,
  titleBoxPadding,
  miniIconSize,
  buttonFontSize,
  primaryFontSize,
  betweenBtnMargin,
  stretchedButtonWidth,
  descriptionFontSize,
  moderatePadding,
  smButtonWidth,
  smIconSize,
  formFieldHorizontalMargin,
  roleTitleWidth,
  zero,
  tableHeight,
  chipTextFontSize,
  mIconSize
} = constants;

const {
  consentFieldBackground,
  appBarBackground,
  appBarFilterBackground,
  defaultBG,
  defaultGrayFont,
  formLabelColor,
  consentDetailBorder,
  grayText,
  secondaryText,
  formError,
  asteriskColor,
  subTitleText,
  whiteText,
  titleText,
  emptyFillerText,
  primaryTheme,
  lightChip,
  darkChip,
  tabInactiveText,
  NavigatorTabGray,
  consentHistoryBG,
  SectionTitleBackground,
  breadCrumb,
  addButton,
  settingsBoxBG,
  defaultBorder,
  defaultIcon
} = appColors;

const { primaryBG } = buttonColors;

const { secondaryGray } = theme;

const chipStyles = {
  solidBlue: { backgroundColor: primaryTheme, color: whiteText, border: "none" },
  outlinedWhite: { backgroundColor: defaultBG, border: `1px solid ${primaryTheme}`, color: primaryTheme },
  solidGray: { backgroundColor: lightChip, color: titleText, border: "none" },
  solidBlack: { backgroundColor: darkChip, color: whiteText, border: "none" },
  solidRed: { backgroundColor: formError, color: whiteText, border: "none" },
  unknown: { backgroundColor: defaultBG }
};

const appStyles: any = {
  // general
  flexOne: {
    flex: 1
  },
  space: {
    width: 50
  },
  displayInline: {
    display: "inline"
  },
  displayNone: {
    display: "none"
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center"
  },
  flexCenterAndAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 25
  },
  centeredAlignedContainer: {
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    display: "flex"
  },
  fullWidth: {
    width: "100%"
  },
  autoMargin: {
    margin: "auto"
  },
  mappedButtonMargin: {
    margin: `${margin30}px ${betweenBtnMargin}px`
  },
  setupwrapperBoxPadding: {
    paddingTop: "1vh",
    paddingBottom: "1vh"
  },
  mediumFont: {
    fontWeight: "medium",
    fontSize: "20px"
  },
  spaceBetweenRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  justifyLeft: {
    flexDirection: "row",
    display: "flex",
    justifySelf: "left",
    justifyContent: "left"
  },
  justifyRight: {
    flexDirection: "row",
    display: "flex",
    justifySelf: "right",
    justifyContent: "right"
  },
  forceEllipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden"
  },
  textCenter: {
    textAlign: "center"
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  buttonBox: {
    padding: "10px 15px",
    "& span": {
      lineHeight: "normal"
    }
  },
  customAsteriskStyle: { color: asteriskColor },
  // components
  generalInputLabel: {
    fontSize: descriptionFontSize,
    color: formLabelColor,
    padding: `6px 4px 0`
  },
  baseForm: {
    sharedStyles: {
      marginLeft: `${formFieldHorizontalMargin}px`,
      marginTop: `${standardPadding}px`,
      flex: 1,
      display: "flex",
      fontSize: primaryFontSize,
      color: grayText
    },
    inputLabelEllipsis: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      color: formLabelColor
    },
    asteriskStyles: {
      "& .MuiInputLabel-root.Mui-error": { color: formLabelColor },
      "& .MuiInputLabel-asterisk": { color: asteriskColor },
      "& .MuiFormHelperText-root": { color: formLabelColor }
    },
    errorStyles: {
      color: formError,
      paddingTop: 2,
      fontSize: primaryFontSize
    },
    formLabeErrorStyles: {
      "& .MuiInputLabel-root": { color: formLabelColor }
    }
  },
  formFields: {
    chipBoxStyle: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      alignItems: "center",
      p: 0.5,
      m: 0
    },
    chipBoxTitleStyle: {
      paddingTop: smFontSpace,
      fontSize: smButtonFontSize,
      color: formLabelColor
    },
    checkboxWithPrePostTextStyle: {
      width: 50,
      marginLeft: 2,
      marginRight: 2
    },
    checkboxPrePostTextStyle: {
      paddingBottom: 0,
      textAlign: "center"
    },
    signedSignatureStyles: {
      backgroundRepeat: "no-repeat",
      position: "relative",
      zIndex: "auto",
      flex: 1,
      backgroundSize: "contain"
    },
    signatureIcon: {
      backgroundColor: "transparent",
      top: 6,
      position: "relative"
    },
    canvasContainerStyles: { height: "80px", padding: 0, margin: 0, width: "100%" },
    canvasStyles: { backgroundColor: consentFieldBackground, width: "100%", height: "100%" },
    printNameTextFieldStyles: { marginBottom: 15 },
    readonlyTextFieldStyles: {
      textStyle: {
        marginTop: margin10,
        flexDirection: "column",
        fontSize: subTitleFontSize
      },
      readonlyHeader: {
        marginTop: standardPadding,
        fontSize: subTitleFontSize,
        fontWeight: 500
      },
      readonlyText: {
        marginTop: smallPadding,
        fontSize: primaryFontSize
      }
    }
  },
  modal: {
    rootDisplay: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center"
    },
    actionModal: {
      // minWidth: 1000,
      // minHeight: 580,
      display: "flex",
      flexDirection: "column",
      // height: "100%",
      position: "relative"
    },
    modalBody: {
      width: 400,
      height: 200,
      backgroundColor: defaultBG,
      border: `2px solid ${defaultBorder}`,
      padding: 20,
      display: "flex",
      flexDirection: "column"
    },
    bodyText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      flexGrow: 1
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      flexGrow: 1,
      flexDirection: "column"
    },
    button: {
      maxHeight: standarButtonHeight,
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  actionRibbon: {
    actionRibbonTextField: {
      display: "flex",
      height: actionRibbonSearchFieldHeight,
      width: "98%",
      maxWidth: 750,
      backgroundColor: defaultBG,
      borderRadius: "4px"
    }
  },
  sidebar: {
    headerImg: {
      height: "75px",
      width: "253px",
      backgroundSize: "253px"
    },
    headerText: {
      fontSize: 16,
      textTransform: "uppercase"
    },
    contentPaper: {
      borderRight: 0,
      width: sidebarWidth
    }
  },
  dropDown: {
    menu: {
      position: "absolute",
      top: "35px",
      marginRight: "200px",
      "& .MuiMenu-paper": {
        width: "fit-content"
      },
      "&: hover": {
        cursor: "pointer"
      },
      "& .MuiList-padding": {
        padding: "2px"
      }
    },
    menuItem: {
      width: "246px",
      fontSize: 16,
      paddingTop: 1.5,
      paddingBottom: 1.5
    },
    menuIconBtn: {
      fontSize: 31,
      position: "relative",
      top: 1,
      padding: 0
    }
  },
  header: {
    nameBox: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      top: 3.5,
      paddingRight: 4
    }
  },
  organizations: {
    emptyTableFiller: {
      textAlign: "left",
      width: "100%",
      zIndex: 1000,
      padding: gutterMargin,
      maxHeight: `${tableHeight}px`,
      height: `${tableHeight}px`
    },
    emptyTableFillerText: {
      fontSize: 18,
      color: emptyFillerText
    }
  },
  formBuilder: {
    formBuilderTextInput: {
      backgroundColor: consentFieldBackground,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      padding: 5
    },
    footerRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    formContent: {
      flex: 1,
      justifyContent: "center"
    },
    footerButtonsLeft: {
      margin: `30px 20px 30px 0px`
    },
    footerButtonsRight: {
      margin: `30px 0px 30px 20px`
    },
    formUploads: {
      PDFField: {
        flex: 1,
        padding: `6px 4px 0`,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      },
      attachIcon: {
        alignSelf: "flex-end",
        paddingBottom: 5,
        paddingRight: 5,
        color: defaultIcon
      }
    },
    formBuilderShell: {
      shellContainer: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        height: 680
      },
      header: {
        height: "6%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginRight: 10,
        marginLeft: 10
      },
      notifier: {
        height: "5%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginRight: 10,
        marginLeft: 10
      },
      form: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginRight: 10,
        marginLeft: 10
      },
      footer: {
        height: "10%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        marginLeft: 10
      }
    }
  },
  consentStatusChip: {
    pending: chipStyles.outlinedWhite,
    draft: chipStyles.outlinedWhite,
    revoked: chipStyles.solidBlack,
    active: chipStyles.solidBlue,
    inactive: chipStyles.solidBlack,
    expired: chipStyles.solidGray,
    abandoned: chipStyles.solidGray,
    rejected: chipStyles.solidGray,
    deleted: chipStyles.solidRed
  },
  formStatusChip: {
    draft: chipStyles.outlinedWhite,
    active: chipStyles.solidBlue,
    deprecated: chipStyles.solidBlack
  },
  orgStatusChip: {
    active: chipStyles.solidBlue,
    deleted: chipStyles.solidBlack
  },
  bulkActionStateChip: {
    requested: chipStyles.outlinedWhite,
    processing: chipStyles.solidBlack,
    complete: chipStyles.solidBlue
  },
  languagesChip: {
    en: chipStyles.outlinedWhite,
    es: chipStyles.outlinedWhite,
    fr: chipStyles.outlinedWhite,
    zh: chipStyles.outlinedWhite,
    activeChip: chipStyles.solidBlue
  },
  wizards: {
    wizardPaperPadding: {
      padding: "0",
      display: "flex",
      flexDirection: "column",
      minWidth: actionModalInviteWidth
    },
    textField: {
      display: "flex",
      flexDirection: "row"
    },
    wizardButtonContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "15px 30%"
    },
    defaultWizardButton: {
      margin: "0 10px",
      fontSize: 14,
      padding: "10px 15px",
      minWidth: 75,
      "& span": {
        lineHeight: "normal"
      }
    },
    wizardTitleText: {
      fontWeight: "medium",
      fontSize: 20,
      padding: "0 16px"
    },
    wizardBody: {
      padding: 10,
      display: "flex",
      minHeight: actionModalInviteHeight - 150
    },
    wizardBodyColumn: {
      padding: 10,
      flexDirection: "column",
      display: "flex",
      height: "100%"
    },
    attachmentRootContainer: {
      padding: "0 0 10px 0"
    },
    attachmentListContainer: {
      padding: "0 0 10px 0",
      height: 300,
      overflow: "auto"
    },
    attachmentListTitle: {
      fontSize: 19,
      padding: "0 0 10px 0"
    },
    attachmentListItem: {
      height: 20,
      borderRadius: 2,
      padding: "8px 10px",
      border: "1px solid gainsboro",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    sendConsentFormsBodyElement: {
      titleTextStyle: {
        padding: "16px 40px",
        margin: 0,
        color: whiteText,
        backgroundColor: primaryTheme,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
      }
    },
    wizardRecipientChoice: {
      "&:hover": {
        cursor: "pointer"
      },
      margin: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minWidth: wizardRecipientChoiceWidth,
      minHeight: wizardRecipientChoiceHeight,
      border: `solid ${standardBorderWidth}px ${secondaryGray}`
    }
  },
  HistoryContainer: styled(Box)({
    display: "flex",
    flexDirection: "column",
    overflow: "auto"
  }),
  pdfViewer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1000,
    overflowY: "scroll",
    border: `solid ${standardBorderWidth}px ${defaultBorder}`
  },
  appBarStyle: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    backgroundColor: appBarBackground,
    color: defaultGrayFont,
    zIndex: 0
  },
  searchableAppBarStyles: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    backgroundColor: appBarFilterBackground,
    zIndex: 0,
    padding: smallPadding,
    boxShadow: "none",
    borderRadius: "4px 4px 0 0"
  },
  tableLink: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  listContentWrapper: {
    wordBreak: "break-word",
    whiteSpace: "break-spaces"
  },
  consumerHeader: {
    headerImg: {
      height: "75px",
      width: "253px"
    },
    responsiveHeaderImg: {
      height: "50px",
      width: "168px"
    },
    accountIcon: {
      fontSize: accountIconSize,
      color: defaultIcon
    },
    arrowIcon: {
      position: "relative",
      top: 3,
      color: defaultIcon
    },
    dropDownButtonContainer: {
      backgroundColor: "transparent",
      position: "relative",
      marginBottom: "5.5px"
    },
    dropDownStyle: {
      width: 220
    },
    headerPadding: (isDesktop: boolean) => (isDesktop ? standardPadding : 10)
  },
  navigatorTabs: {
    consentsImage: {
      height: "25px",
      width: "20px",
      margin: "8px",
      position: "relative",
      bottom: "3px"
    },
    careTeamImage: {
      height: "22px",
      width: "37px",
      margin: "5px",
      position: "relative",
      bottom: "3px"
    },
    inactiveTabStyle: {
      opacity: 1,
      color: tabInactiveText,
      minWidth: 160
    },
    activeTabStyle: { minWidth: 160 },
    conditionalStyles: (active: boolean): {} => {
      return {
        color: active ? primaryTheme : NavigatorTabGray
      };
    },
    badgeStyle: {
      position: "absolute",
      right: "15px",
      top: "35.5px",
      "& .MuiBadge-colorSecondary": { backgroundColor: buttonColors.primaryBG }
    }
  },
  careTeamAddIcon: {
    fontSize: careTeamAddIconSize
  },
  entityTitleIconBtn: {
    padding: 2.5,
    marginLeft: betweenIconBtnMargin
  },
  navBarTabs: {
    desktopTab: { color: NavigatorTabGray },
    smMobileTab: { color: NavigatorTabGray, flex: 1, whiteSpace: "nowrap", fontSize: smButtonFontSize },
    mobileTab: { fontSize: buttonFontSize, color: NavigatorTabGray, flex: 1, whiteSpace: "nowrap" }
  },
  settings: {
    termsDoneButton: {
      maxHeight: standarButtonHeight,
      alignSelf: "center"
    }
  },
  consentDetail: {
    stretchedButton: {
      marginTop: `${betweenBtnMargin}px`,
      marginBottom: `${betweenBtnMargin}px`,
      maxWidth: stretchedButtonWidth
    },
    historyContainer: {
      flex: 0.3,
      padding: 30,
      backgroundColor: consentHistoryBG
    },
    drawerContent: {
      minWidth: minDrawerWidth,
      padding: 10,
      paddingTop: 20
    },
    drawerExitBtn: {
      top: 0,
      right: 2,
      position: "absolute",
      display: "flex",
      justifyContent: "flex-end",
      width: "100%"
    },
    postAmble: {
      backgroundColor: defaultBG,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingHorizontal: 0
    },
    consentDetailScreenContainer: {
      backgroundColor: defaultBG,
      border: `solid 1px ${consentDetailBorder}`,
      borderRadius: 1
    },
    outerSection: {
      backgroundColor: defaultBG,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 2,
      paddingBottom: "20px"
    },
    consentDetailSectionHeader: {
      mobileHeader: {
        position: "absolute",
        left: 0,
        top: 134
      },
      titleText: {
        paddingRight: "5px",
        paddingLeft: "5px",
        fontSize: "18px",
        color: SectionTitleBackground
      },
      sectionTitle: {
        mobile: {
          display: "flex",
          justifyContent: "center",
          borderBottom: `solid 2px ${consentDetailBorder}`,
          padding: "8px"
        },
        desktop: {
          display: "flex",
          justifyContent: "center",
          borderBottom: `solid 2px ${consentDetailBorder}`,
          padding: "15px"
        }
      }
    }
  },
  pageLogin: {
    headerText: { fontSize: "25px", color: grayText }
  },
  pageHeader: {
    responsiveContainer: {
      desktop: {
        borderBottom: `solid 2px ${consentDetailBorder}`,
        display: "flex"
      },
      mobile: {
        marginBottom: "45px",
        alignItems: "center",
        borderBottom: `solid 2px ${consentDetailBorder}`,
        display: "flex"
      }
    }
  },
  breadCrumbs: {
    linkText: {
      color: breadCrumb,
      alignItems: "center",
      display: "flex",
      "&:hover": {
        cursor: "pointer"
      }
    },
    titleText: {
      textTransform: "capitalize",
      color: secondaryText
    },
    backIcon: {
      position: "absolute",
      left: 2,
      fontSize: 20
    }
  },
  entityDashboard: {
    titleRow: {
      justifyContent: "space-between",
      padding: pageTitleHeaderPaddingString,
      alignItems: "center"
    },
    titleText: {
      color: titleText,
      fontSize: titleFontSize
    }
  },
  defaultFloatingButton: {
    position: "fixed",
    right: 30,
    bottom: 30,
    zIndex: 100
  },
  linkStyle: {
    fontWeight: "bold",
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    color: secondaryText,
    fontSize: subTitleFontSize
  },
  consumerFillerText: {
    display: "flex",
    fontSize: primaryFontSize,
    alignSelf: "center",
    fontWeight: "bold",
    color: emptyFillerText,
    padding: `${standardPadding}px`
  },
  rolesDisplay: {
    addButton: {
      width: smButtonWidth,
      fontSize: smButtonFontSize,
      maxHeight: smButtonHeight,
      textTransform: "capitalize",
      color: defaultGrayFont,
      borderColor: addButton
    },
    titleBox: {
      paddingBottom: `${titleBoxPadding}px`
    },
    addIcon: {
      fontSize: miniIconSize,
      position: "relative",
      right: 3,
      bottom: 0.5
    },
    displayContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: `${moderatePadding}px`
    },
    rolesList: {
      roleListContainer: {
        padding: `${miniPadding}px 0`
      },
      roleItemContainer: {
        display: "flex",
        paddingBottom: `${betweenBtnMargin}px`
      },
      roleContainer: {
        display: "flex",
        flexWrap: "wrap"
      },
      roleTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${miniPadding}px 0 0`,
        textAlign: "center",
        width: roleTitleWidth,
        marginRight: `${miniPadding}px`,
        borderRadius: 1,
        backgroundColor: settingsBoxBG
      },
      orgNameDivider: {
        padding: `0 ${smFontSpace}px`
      },
      deleteRoleBtn: {
        padding: zero,
        margin: `0 ${smallPadding}px`
      },
      deleteIcon: {
        fontSize: smIconSize
      }
    }
  },
  organizationCard: {
    cardStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: defaultBG,
      border: "none",
      outline: "none",
      padding: 1,
      minWidth: "250px"
    },
    subtitleStyle: {
      color: subTitleText,
      marginBottom: 1.5,
      fontWeight: "500",
      fontSize: "1.1em"
    },
    iconStyle: {
      verticalAlign: "middle",
      background: secondaryGray,
      borderRadius: "50%",
      fontSize: "inherit",
      padding: 0.5,
      marginBottom: 0.5,
      width: mIconSize,
      height: mIconSize
    }
  },
  datePickerPopperSx: {
    "& .MuiPickersDay-dayWithMargin": {
      "&:hover": {
        backgroundColor: alpha(primaryTheme, 0.2)
      },
      "&:active": {
        backgroundColor: primaryTheme,
        color: whiteText
      },
      "&.Mui-selected": {
        backgroundColor: `${primaryTheme} !important`,
        color: whiteText
      }
    },

    "& .PrivatePickersYear-yearButton": {
      "&:hover": {
        backgroundColor: alpha(primaryTheme, 0.2)
      },
      "&:active": {
        backgroundColor: primaryTheme,
        color: whiteText
      },
      "&.Mui-selected": {
        backgroundColor: `${primaryTheme} !important`,
        color: whiteText
      }
    }
  },
  textButton: {
    color: primaryBG
  },
  styledComponents: {
    // todo: figure out how to apply MUI theme in shared-web-ui
    FlexBox: styled(Box)({
      display: "flex"
    }),
    DefaultTextField: styled(TextField)({
      flex: 1,
      '&>[class*="MuiInputLabel-shrink-"]': {
        overflow: "visible !important"
      },
      "& .MuiInputLabel-root.Mui-error": { color: formLabelColor },
      "& .MuiInputLabel-asterisk": { color: asteriskColor },
      "& .MuiFormHelperText-root": { color: formLabelColor }
    }),
    RBFloatingButton: styled(Fab)({
      position: "fixed",
      right: 30,
      bottom: 30,
      zIndex: 100
    }),
    EmptyFillerText: styled(Typography)({
      fontSize: subTitleFontSize,
      alignSelf: "center",
      fontWeight: "bold",
      color: emptyFillerText,
      padding: 10
    }),
    PageControlBox: styled(Box)({
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    }),
    DashViewPageWrapper: styled(Paper)({
      padding: gutterMargin,
      boxShadow: "none"
    }),
    Vertical: styled(Box)({
      backgroundColor: grayText,
      width: 2,
      margin: "0 10px",
      height: 22
    }),
    StyledChip: styled(Chip)({
      marginRight: "12px",
      textTransform: "capitalize",
      height: "min-content",
      padding: "6px 16px",
      borderRadius: "50px",
      fontWeight: "bold",
      "& .MuiChip-label": {
        padding: 0,
        fontSize: chipTextFontSize,
        height: "min-content"
      },
      "& .MuiSvgIcon-root": {
        marginLeft: 10,
        marginRight: 0,
        color: defaultIcon
      }
    }),
    BorderLinearProgress: styled(LinearProgress)(({ theme }) => ({
      height: 15,
      borderRadius: 5,
      marginTop: gutterMargin,
      marginBottom: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: primaryTheme
      }
    })),
    CategoryLabel: styled(Typography)({
      padding: "0.5em",
      paddingLeft: 0
    }),
    ListItem: styled("li")(({ theme }) => ({
      margin: theme.spacing(0.3)
    })),
    FileUploadFieldLabel: styled(Typography)({
      marginTop: standardPadding,
      marginBottom: miniPadding,
      fontWeight: 500
    })
  },
  DescriptionContainer: styled(Typography)({
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    fontSize: defaultTextFontSize,
    paddingTop: smallPadding,
    color: defaultGrayFont
  }),
  DateContainer: styled(Typography)({
    marginRight: margin10,
    fontSize: defaultTextFontSize,
    padding: miniPadding,
    paddingRight: standardPadding,
    paddingLeft: standardPadding,
    width: "fit-content",
    background: consentDetailBorder,
    color: defaultGrayFont
  }),
  SubtextContainer: styled(Typography)({
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    fontSize: subTitleFontSize,
    color: secondaryText
  }),
  DropDownContainer: styled(Box)({
    display: "flex",
    justifyContent: "center"
  }),
  ButtonBoxContainer: styled(Box)({
    marginTop: moderatePadding
  }),
  HTMLStyles: {
    about: `
    <style>
      h1 {
          color: ${titleText};
          font-size: ${subTitleFontSize}px;
          font-weight: 400;
      }
      li {
        color: ${grayText};
        font-size: ${primaryFontSize}px;
      }
    </style>
    `,
    policy: `
    <style>
      h1 {
          color: ${titleText};
          font-size: ${subTitleFontSize}px;
      }
      p {
        color: ${grayText};
        font-size: ${primaryFontSize}px;
      }
      li {
        color: $grayText};
        font-size: ${primaryFontSize}px;
      }
    </style>
    `,
    formViewer: `
    <style>
      h2 {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
        margin-bottom: 0;
      }
      h1 {
        font-size: ${titleFontSize}px;
        color: ${titleText};
        margin-bottom: 0;
      }
      p {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
      }
      .st {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
      }
      .lg {
        font-size: ${titleFontSize}px;
        color: ${titleText};
      }
      .md {
        font-size: ${primaryFontSize}px;
        color: ${titleText};
      }
      .sm {
        font-size: ${descriptionFontSize}px;
        color: ${titleText};
      }
      b {
        color: ${titleText};
        display: inline;
      }
      ul {
        margin-bottom: 0;
      }
      li {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
        line-height: 1.5;
      }
      #text {
        color: ${titleText};
        font-size: ${subTitleFontSize}px;
      }
      a {
        /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
        -ms-word-break: break-all;
        word-break: break-all;
        /* Non standard for webkit */
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        font-size: ${subTitleFontSize}px;
      }
      .MuiFormControlLabel-root p, .MuiFormControlLabel-root b {
        margin-bottom: 0;
        font-size: ${primaryFontSize}px;
      }
    </style>`,
    checkbox: `
    <style>
      p {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
        margin: 0px;
      }
    </style>
    `,
    switch: `
    <style>
      p {
        font-size: ${subTitleFontSize}px;
        color: ${titleText};
        margin: 0px;
      }
    </style>
    `
  }
};

export default appStyles;

export const AddEditFormStyle = {
  FormBox: styled(Box)({})
};

export const ActionModalStyle = {
  StyledModal: styled(Modal)({
    ...appStyles.modal.rootDisplay,
    "&:focus": {
      outline: 0
    }
  }),
  ModalBody: styled(Box)({
    ...(appStyles.modal.actionModal as CSSProperties),
    "&:focus": {
      outline: 0
    }
  }),
  CloseButtonContainer: styled(Box)({
    position: "absolute",
    right: 0,
    top: "8px",
    margin: smallPadding
  }),
  closingModalIcon: {
    fontSize: 24,
    color: defaultIcon
  },
  whiteIcon: {
    color: whiteText
  }
};

export const BaseWizardStyle = {
  WizardPaper: styled(Paper)({
    ...(appStyles.wizards.wizardPaperPadding as CSSProperties)
  }),
  wizardBodyStyles: { height: "100%", padding: 0 }
};
