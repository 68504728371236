import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface FetchConsentsListConfig {
  consumerId: string;
  limit: number;
  nextToken?: string;
}

const fetchConsentsList = async ({ auth, variables }: QueryConfig<FetchConsentsListConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consumerId: ID!, $limit: Int!, $nextToken: String) {
        listConsentsByConsumer(consumerId: $consumerId, limit: $limit, nextToken: $nextToken) {
          consents {
            consentId
            consumer {
              consumerId
              firstName
              lastName
            }
            form {
              code
              version
              title
              description
            }
            disclosingOrganizations [{
              name
            }]
            createdAt
            expireAt
            signedAt
            status
          }
          nextToken
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentImageOrPdfConfig {
  consentId: string;
  patientId: string;
}

const getConsentImageOrPdf = async ({ auth, variables }: QueryConfig<GetConsentImageOrPdfConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consentId: ID!, $patientId: ID!) {
        getConsentFile(consentId: $consentId, patientId: $patientId) {
          content
          type {
            ext
            mime
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface FetchConsentHistoryConfig {
  consumerId: string;
  consentId: string;
  limit?: number;
  nextToken?: string;
}

const fetchConsentHistory = async ({ auth, variables }: QueryConfig<FetchConsentHistoryConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consumerId: ID!, $consentId: ID!, $limit: Int, $nextToken: String) {
        listConsentHistory(consumerId: $consumerId, consentId: $consentId, limit: $limit, nextToken: $nextToken) {
          history {
            event
            timestamp
            fromIp
          }
          nextToken
        }
      }
    `,
    variables,
    auth
  );
};

export interface RequestConsentFormConfig {
  consumerId: string;
  formId: string;
  disclosingOrgIds?: string[];
  requestorProviderId?: string;
  recipientOrgIds?: string;
  recipientProviderId?: string;
}

const requestConsentForm = async ({ auth, variables }: QueryConfig<RequestConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation (
        $consumerId: ID!
        $code: String!
        $version: String!
        $disclosingOrgIds: [ID]
        $requestorProviderId: ID
        $recipientOrgIds: [ID]
        $recipientProviderId: ID
      ) {
        requestConsent(
          consumerId: $consumerId
          code: $code
          version: $version
          disclosingOrgIds: $disclosingOrgIds
          requestorProviderId: $requestorProviderId
          recipientOrgIds: $recipientOrgIds
          recipientProviderId: $recipientProviderId
        ) {
          consentId
        }
      }
    `,
    variables,
    auth
  );
};

export interface CheckConsentForDuplicatesConfig {
  consumerId: string;
  sessionOrgId: string;
  consent: any;
}

const checkConsentForDuplicates = async ({ auth, variables }: QueryConfig<CheckConsentForDuplicatesConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consumerId: ID!, $sessionOrgId: ID!, $consent: ValidateConsentInput!) {
        checkConsentForDuplicates(consumerId: $consumerId, sessionOrgId: $sessionOrgId, consent: $consent) {
          hasDuplicates
          hasTpo
          disclosingOrganizations {
            orgId
            name
          }
          recipientOrganizations {
            orgId
            name
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface CreateManualConsentConfig {
  consumerId: string;
  code: string;
  version: string;
  data: { [key: string]: any };
  orgId: string;
  disclosingOrgIds?: string[];
  recipientOrgIds?: string;
  status?: string;
  expireAt?: string;
  signedAt?: string;
}

const createManualConsent = async ({ auth, variables }: QueryConfig<CreateManualConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation (
        $consumerId: ID!
        $code: String!
        $version: String!
        $status: ConsentStatus
        $expireAt: String
        $signedAt: String
        $data: JSON!
        $orgId: ID!
        $disclosingOrgIds: [ID]
        $recipientOrgIds: [ID]
      ) {
        createConsent(
          consumerId: $consumerId
          code: $code
          version: $version
          data: $data
          orgId: $orgId
          disclosingOrgIds: $disclosingOrgIds
          recipientOrgIds: $recipientOrgIds
          status: $status
          signedAt: $signedAt
          expireAt: $expireAt
        ) {
          consentId
          consumer {
            consumerId
            orgId
            firstName
            lastName
          }
          form {
            formId
            code
            version
          }
          data
          status
          createdAt
          lastModified
          expireAt
        }
      }
    `,
    variables,
    auth
  );
};

export interface RequestConsentFormToGuestConfig {
  consumerId: string;
  formId: string;
  disclosingOrgIds?: string[];
  requestorProviderId?: string;
  recipientOrgIds?: string;
  recipientProviderId?: string;
}

const requestConsentFormToGuest = async ({ auth, variables }: QueryConfig<RequestConsentFormConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation (
        $consumerId: ID!
        $code: String!
        $version: String!
        $disclosingOrgIds: [ID]
        $requestorProviderId: ID
        $recipientOrgIds: [ID]
        $recipientProviderId: ID
      ) {
        inviteConsumerAsGuest(
          consumerId: $consumerId
          code: $code
          version: $version
          disclosingOrgIds: $disclosingOrgIds
          requestorProviderId: $requestorProviderId
          recipientOrgIds: $recipientOrgIds
          recipientProviderId: $recipientProviderId
        ) {
          username
          message
        }
      }
    `,
    variables,
    auth
  );
};

export interface FetchConsumerConfig {
  consumerId: string;
}

const fetchConsumer = async ({ auth, variables }: QueryConfig<FetchConsumerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($consumerId: ID!) {
        getConsumer(consumerId: $consumerId) {
          ...consumerFields
        }
      }
    `,
    variables,
    auth
  );
};
export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  expressZip?: string;
}

export interface AddNewConsumerConfig {
  consumer: {
    orgId: string;
    firstName: string;
    lastName: string;
    dob: string;
    phoneNumber: string;
    email: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      expressZip?: string;
    };
    landlineNumber?: string;
    middleName?: string;
    suffixName?: string;
    gender?: "male" | "female" | "other";
  };
}

const addNewConsumer = async ({ auth, variables }: QueryConfig<AddNewConsumerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consumer: ConsumerInfo!) {
        addConsumer(consumer: $consumer) {
          consumerId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateConsumerConfig {
  consumerId: string;
  consumer: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffixName?: string;
    gender?: "male" | "female" | "other";
    dob?: string;
    phoneNumber?: string;
    email?: string;
    landlineNumber?: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      expressZip?: string;
    };
  };
}

const updateConsumer = async ({ auth, variables }: QueryConfig<UpdateConsumerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consumerId: ID!, $consumer: UpdateConsumerInfo!) {
        updateConsumer(consumerId: $consumerId, consumer: $consumer) {
          consumerId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeleteConsumerConfig {
  consumerId: string;
}

const deleteConsumer = async ({ auth, variables }: QueryConfig<DeleteConsumerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consumerId: ID!) {
        deleteConsumer(consumerId: $consumerId) {
          consumerId
        }
      }
    `,
    variables,
    auth
  );
};

export interface ResetConsumerConfig {
  consumerId: string;
}

const resetConsumer = async ({ auth, variables }: QueryConfig<ResetConsumerConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($consumerId: ID!) {
        resetConsumer(consumerId: $consumerId)
      }
    `,
    variables,
    auth
  );
};

export interface UploadConsentConfig {
  patientId: string;
  code?: string;
  version?: string;
  orgId: string;
  file: {};
  fileKey: string;
}

const uploadConsent = async ({ auth, variables }: QueryConfig<UploadConsentConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation (
        $patientId: ID!
        $code: String!
        $version: String!
        $consentId: ID!
        $orgId: ID!
        $fileKey: String!
        $status: ConsentStatus
        $data: JSON
      ) {
        uploadConsent(
          patientId: $patientId
          code: $code
          version: $version
          orgId: $orgId
          consentId: $consentId
          fileKey: $fileKey
          status: $status
          data: $data
        ) {
          consentId
          consumer {
            consumerId
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetOcrResultPreSignedUrlConfig {
  name: string;
  fileType: string;
  contentType: string;
}

const getOcrResultPreSignedUrl = async ({ auth, variables }: QueryConfig<GetOcrResultPreSignedUrlConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($name: String!, $fileType: String!, $contentType: String!) {
        getOcrResultPreSignedUrl(name: $name, fileType: $fileType, contentType: $contentType) {
          uploadURL
          fileKey
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentPreSignedUrlConfig {
  patientId: string;
  fileType: string;
  contentType: string;
}

const getConsentPreSignedUrl = async ({ auth, variables }: QueryConfig<GetConsentPreSignedUrlConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($patientId: String!, $fileType: String!, $contentType: String!) {
        getConsentPreSignedUrl(patientId: $patientId, fileType: $fileType, contentType: $contentType) {
          uploadURL
          fileKey
          consentId
        }
      }
    `,
    variables,
    auth
  );
};

export {
  getConsentPreSignedUrl,
  uploadConsent,
  fetchConsentHistory,
  fetchConsentsList,
  fetchConsumer,
  requestConsentForm,
  requestConsentFormToGuest,
  addNewConsumer,
  updateConsumer,
  deleteConsumer,
  resetConsumer,
  createManualConsent,
  getConsentImageOrPdf,
  getOcrResultPreSignedUrl,
  checkConsentForDuplicates
};
