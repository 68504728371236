import { OrganizationField } from "./types";
import assets from "../../../ui/assets";
import { validations } from "../../utils";

const {
  appStrings: {
    admin: {
      organization: {
        item: { formFields }
      }
    },
    common: {
      defaultErrorStrings: { npiError }
    }
  }
} = assets;

const fields: { [key in OrganizationField]: any } = {
  name: {
    name: "name",
    label: formFields.name,
    width: "45%",
    validation: validations.requiredTextMinMax(1, 150)
  },
  email: {
    name: "email",
    isEmail: true,
    label: formFields.email,
    width: "45%",
    validation: validations.requiredEmail
  },
  npi: {
    name: "npi",
    label: formFields.npi,
    width: "45%",
    validation: validations.numberMinMaxInteger(1000000000, 9999999999, npiError(10))
  },
  phoneNumber: {
    name: "phoneNumber",
    label: formFields.phone,
    fieldType: "us-phone",
    width: "45%",
    validation: validations.usPhoneRequired
  },
  fax: {
    name: "fax",
    label: formFields.fax,
    fieldType: "us-phone",
    width: "45%",
    validation: validations.usPhone
  },
  allowRevocation: {
    name: "allowRevocation",
    label: formFields.allowRevocation,
    showLabel: true,
    width: "22%",
    fieldType: "switch",
    defaultValue: true
  },
  allowGeneralDesignationConsents: {
    name: "allowGeneralDesignationConsents",
    label: formFields.allowGeneralDesignationConsents,
    showLabel: true,
    width: "22%",
    fieldType: "switch",
    defaultValue: false
  },
  activeECMUser: {
    name: "activeECMUser",
    label: formFields.activeECMUser,
    showLabel: true,
    width: "22%",
    fieldType: "switch",
    defaultValue: false
  },
  active: {
    name: "active",
    label: formFields.active,
    showLabel: true,
    width: "22%",
    fieldType: "switch",
    defaultValue: true
  },
  reviewAfterDays: {
    name: "reviewAfterDays",
    label: formFields.reviewAfterDays,
    width: "45%",
    validation: validations.requiredNumberMinMax(30, 365)
  },
  address1: {
    name: "address1",
    label: formFields.address1,
    width: "45%",
    validation: validations.text
  },
  address2: {
    name: "address2",
    label: formFields.address2,
    width: "45%",
    validation: validations.text
  },
  city: {
    name: "city",
    label: formFields.city,
    width: "31%",
    validation: validations.text
  },
  state: {
    name: "state",
    label: formFields.state,
    width: "10%",
    fieldType: "us-state",
    validation: validations.text
  },
  zip: {
    name: "zip",
    label: formFields.zip,
    width: "22%",
    fieldType: "zip",
    validation: validations.zip
  }
};

export default fields;
