import React, { Suspense } from "react";
import assets from "../../assets";
import { useWizard } from "../../../app/hooks";
import DialogLayout from "./DialogLayout";
import Spinner from "@midato-mono/common/src/components/Spinner";

const CopyRoleWizard = React.lazy(() => import("../wizards/CopyRoleWizard"));
const InviteWizard = React.lazy(() => import("../wizards/InviteWizard"));
const SendConsentFormsWizard = React.lazy(() => import("../wizards/SendConsentFormsWizard"));
const SendConsentFormsToGuestWizard = React.lazy(() => import("../wizards/SendConsentFormsToGuestWizard"));
const FormViewerWizard = React.lazy(() => import("../wizards/FormViewerWizard"));
const RunReportWizard = React.lazy(() => import("../wizards/RunReportWizard"));
const ManuallyRecordConsentWizard = React.lazy(() => import("../wizards/ManuallyRecordConsentWizard"));
const SelectOrganizationModal = React.lazy(() => import("../wizards/SelectOrganizationModal"));
const EditProfile = React.lazy(() => import("../wizards/EditProfile"));
const ChangePassword = React.lazy(() => import("../wizards/ChangePassword"));
const UploadConsent = React.lazy(() => import("../wizards/UploadConsent"));
const ConsentRevoke = React.lazy(() => import("../wizards/ConsentRevoke"));
const LegalPolicies = React.lazy(() => import("../wizards/LegalPolicies"));

// new pages
const ConsumerNew = React.lazy(() => import("../../screens/newPages/ConsumerNew"));
const FormNew = React.lazy(() => import("../../screens/newPages/FormNew"));
const UserNew = React.lazy(() => import("../../screens/newPages/UserNew"));
const PractitionerNew = React.lazy(() => import("../../screens/newPages/PractitionerNew"));
const OrganizationNew = React.lazy(() => import("../../screens/newPages/OrganizationNew"));
const SecurityRoleNew = React.lazy(() => import("../../screens/newPages/UserRoleNew"));

const EditPageTemplate = React.lazy(() => import("../../components/common/templates/EditPageTemplate"));

const {
  appStyles: {
    ActionModalStyle: { StyledModal, ModalBody },
    ManuallyRecordConsentWizardStyle,
    MobileWizardStyle,
    AddItemWizardStyle,
    SelectOrganizationWizardStyleStyle,
    CopyRoleWizardStyle,
    EditProfileStyle
  }
} = assets;

function ActionModal() {
  const {
    wizardState: { activeWizard }
  } = useWizard();
  const { appConstants } = assets;
  const { isMobileDevice } = appConstants;
  const isMobile = isMobileDevice();

  let modalAriaLabel = "";

  let currentWizard = null;
  switch (activeWizard) {
    case "InviteConsumer":
      modalAriaLabel = "Invite Consumer";
      currentWizard = (
        <DialogLayout>
          <InviteWizard />
        </DialogLayout>
      );
      break;
    case "SendConsents":
      modalAriaLabel = "Send Consents";
      currentWizard = <SendConsentFormsWizard />;
      break;
    case "SendConsentsToGuest":
      modalAriaLabel = "Send Consents To Guest";
      currentWizard = <SendConsentFormsToGuestWizard />;
      break;
    case "FormViewer":
      modalAriaLabel = "Form Viewer";
      currentWizard = <FormViewerWizard />;
      break;
    case "CreateConsentWizard":
      modalAriaLabel = "Create Consent";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : ManuallyRecordConsentWizardStyle}>
          <ManuallyRecordConsentWizard />
        </DialogLayout>
      );
      break;
    case "ConsumerNewListPageWizard":
      modalAriaLabel = "Create consumer";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <ConsumerNew />
        </DialogLayout>
      );
      break;
    case "PractitionerNewListPageWizard":
      modalAriaLabel = "Create Practitioner";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <PractitionerNew />
        </DialogLayout>
      );
      break;

    case "FormNewListPageWizard":
      modalAriaLabel = "Create Form";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <FormNew />
        </DialogLayout>
      );
      break;
    case "UserNewListPageWizard":
      modalAriaLabel = "Create User";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <UserNew />
        </DialogLayout>
      );
      break;
    case "OrganizationNewListPageWizard":
      modalAriaLabel = "Create Organization";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <OrganizationNew />
        </DialogLayout>
      );
      break;
    case "UserRoleNewListPageWizard":
      modalAriaLabel = "Create user role";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <SecurityRoleNew />
        </DialogLayout>
      );
      break;
    case "EditUserRoleWizard":
      modalAriaLabel = "Edit user";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="userRole" />
        </DialogLayout>
      );
      break;
    case "EditConsumerWizard":
      modalAriaLabel = "Edit consumer";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="consumer" />
        </DialogLayout>
      );
      break;
    case "EditPractitionerWizard":
      modalAriaLabel = "Edit Practitioner";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="practitioner" />
        </DialogLayout>
      );
      break;
    case "EditFormWizard":
      modalAriaLabel = "Edit Form";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="form" />
        </DialogLayout>
      );
      break;
    case "EditUserWizard":
      modalAriaLabel = "Edit User";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="user" />
        </DialogLayout>
      );
      break;
    case "EditOrganizationWizard":
      modalAriaLabel = "Edit Organization";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : AddItemWizardStyle}>
          <EditPageTemplate type="organization" />
        </DialogLayout>
      );
      break;
    case "SelectOrganizationWizard":
      modalAriaLabel = "Select Active Organization";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : SelectOrganizationWizardStyleStyle}>
          <SelectOrganizationModal />
        </DialogLayout>
      );
      break;
    case "RunReport":
      modalAriaLabel = "Report";
      currentWizard = <RunReportWizard />;
      break;
    case "CopyRoleWizard":
      modalAriaLabel = "Copy Role";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : CopyRoleWizardStyle}>
          <CopyRoleWizard />
        </DialogLayout>
      );
      break;
    case "ChangePasswordWizard":
      modalAriaLabel = "Change Password";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : EditProfileStyle}>
          <ChangePassword />
        </DialogLayout>
      );
      break;
    case "EditProfileWizard":
      modalAriaLabel = "Edit Profile";
      currentWizard = (
        <DialogLayout customStyles={isMobile ? MobileWizardStyle : EditProfileStyle}>
          <EditProfile />
        </DialogLayout>
      );
      break;
    case "UploadConsentWizard":
      modalAriaLabel = "Upload Consent";
      currentWizard = (
        <DialogLayout>
          <UploadConsent />
        </DialogLayout>
      );
      break;
    case "ConsentRevokeWizard":
      modalAriaLabel = "Consent Revoke";
      currentWizard = (
        <DialogLayout>
          <ConsentRevoke />
        </DialogLayout>
      );
      break;
    case "LegalPoliciesWizard":
      modalAriaLabel = "Legal Policies";
      currentWizard = (
        <DialogLayout>
          <LegalPolicies />
        </DialogLayout>
      );
      break;

    default:
      break;
  }

  return (
    <StyledModal
      sx={
        isMobile
          ? {
              width: "100%",
              height: "100%"
            }
          : null
      }
      aria-label={modalAriaLabel}
      role="dialog"
      open={!!activeWizard}
    >
      <ModalBody
        sx={
          isMobile
            ? {
                width: "100%",
                height: "100%"
              }
            : null
        }
      >
        <Suspense fallback={<Spinner />}>{currentWizard}</Suspense>
      </ModalBody>
    </StyledModal>
  );
}
export default ActionModal;
