// --- USE_DELAY_TIMEOUT ---
import { useState, useEffect, useRef } from "react";
// Set an action to be invoked after some time, unless a boolean state is set to false. Practical use for spinner screen timeout errors to not overlap.
const useDelayTimeout = (state: boolean, timeoutAction: Function, seconds?: number) => {
  const delayTime = seconds || 40;
  const intervalRef = useRef();
  const [delayRunning, setDelayRunning] = useState(false);
  const [count, setCount] = useState(0);
  // sync local state with provided sitkaState
  useEffect(() => {
    if (!state) {
      setDelayRunning(false);
    } else {
      setDelayRunning(true);
    }
    setCount(0);
  }, [state]);
  // cleanup interval on delayRunning state change
  useEffect(() => {
    if (delayRunning) {
      const interval = setInterval(() => {
        setCount((count) => count + 1);
      }, 1000);
      intervalRef.current = interval as any;
    }
    return (): void => clearInterval(intervalRef.current);
  }, [delayRunning]);
  // timeout function when time is up
  useEffect(() => {
    if (count === delayTime) {
      timeoutAction();
    }
  }, [count, delayTime]);
};

export default useDelayTimeout;
