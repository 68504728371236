import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSpinner, setSpinner } from "../reduxSlices/spinner";

export interface SpinnerState {
  extendedTimeout: number;
  spinner: boolean;
}

const useSpinner = () => {
  const spinnerState = useSelector<{ spinner: SpinnerState }, SpinnerState>(selectCurrentSpinner);
  const dispatch = useDispatch();

  const closeSpinner = (extendedTimeout = 0) => dispatch(setSpinner({ spinner: false, extendedTimeout }));
  const openSpinner = (extendedTimeout = 0) => dispatch(setSpinner({ spinner: true, extendedTimeout }));

  return { spinnerState, closeSpinner, openSpinner };
};

export default useSpinner;
