import React, { lazy, useState, useEffect } from "react";
import assets from "../assets";
import { Box, Stack, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { routes } from "../../Router/constants";
import { useAuth } from "@midato-mono/common/src";
import { userCan } from "../../app/utils";

const BaseButton = lazy(() => import("@midato-mono/common/src/components/BaseButton"));

const {
  appStyles: {
    FormViewerWizardStyle: { pdfDocument, pdfPaper, container },

    WizardStyle: { wizardButton }
  },

  appStrings: {
    common: {
      appStrings: {
        buttonText: { close }
      }
    }
  }
} = assets;

const PreviewPDF = React.lazy(() => import("@midato-mono/common/src/components/PreviewPDF"));
const prefix = "PreviewPDF";
const classes = {
  pdfDocument: `${prefix}-document`,
  pdfPaper: `${prefix}-paper`,
  container: `${prefix}-container`
};

const StyledPreviewPDF = styled(PreviewPDF)({
  [`&.${classes.container}`]: container,
  [`& .${classes.pdfDocument}`]: pdfDocument,
  [`& .${classes.pdfPaper}`]: pdfPaper
});

const LicensingRequirements = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [downloadUrl, setDownloadUrl] = useState("");

  const backUrl = queryParams?.get("backUrl") || routes.LOGIN;

  // const downloadUrl = import.meta.env.REACT_APP_LEGAL_POLICIES_URL;

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        // Dynamically import the PDF file
        const { default: pdf } = await import("../assets/LicensingRequirements.pdf");
        setDownloadUrl(pdf);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchPDF();
  }, []);

  const { permissions } = useAuth();
  const hasDownloadPermission = userCan({ actionId: "CONSENT_DOWNLOAD", userPermissions: permissions });
  const hasPrintPermission = userCan({ actionId: "CONSENT_PRINT", userPermissions: permissions });

  return (
    <Box
      aria-label="licensing requirements"
      tabIndex={0}
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        overflow: "auto",
        padding: "20px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Box
        style={{
          flex: "1",
          overflow: "hidden",
          paddingTop: "5px",
          width: "80%"
        }}
      >
        {downloadUrl && (
          <StyledPreviewPDF
            hasDownloadPermission={hasDownloadPermission}
            hasPrintPermission={hasPrintPermission}
            classes={classes}
            fileUrl={downloadUrl}
          />
        )}
      </Box>
      <Stack direction="row" justifyContent="center" style={{ marginTop: "20px" }} spacing={2}>
        <BaseButton
          variant="outlined"
          id={`btn-${close}`}
          style={{ ...wizardButton }}
          onPress={() => {
            navigate(backUrl);
          }}
          ariaLabel={close}
        >
          {close}
        </BaseButton>
        <div />
      </Stack>
    </Box>
  );
};

export default LicensingRequirements;
