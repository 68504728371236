import { graphql } from "@midato-mono/common/src/aws";
import { ConsentsType } from "../../types";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface GetBulkExportConfig {
  bulkActionId: string;
}

const getBulkExport = async ({ auth, variables }: QueryConfig<GetBulkExportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query getBulkExport($bulkActionId: ID!) {
        getBulkExport(bulkActionId: $bulkActionId) {
          ...cmsBulkExportFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetBulkImportConfig {
  bulkActionId: string;
}

const getBulkImport = async ({ auth, variables }: QueryConfig<GetBulkImportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query getBulkImport($bulkActionId: ID!) {
        getBulkImport(bulkActionId: $bulkActionId) {
          ...cmsBulkImportFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface CreateBulkExport {
  bulkExport: {
    orgId?: string;
    type: string;
    fiilter: {
      startDate: string;
      endDate: string;
      filter: ConsentsType[];
    };
    format: string;
  };
}

const createBulkExport = async ({ auth, variables }: QueryConfig<CreateBulkExport>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($bulkExport: BulkExportInput!) {
        createBulkExport(bulkExport: $bulkExport) {
          bulkActionId
        }
      }
    `,
    variables,
    auth
  );
};

export interface CreateBulkImport {
  bulkImport: {
    orgId?: string;
    type: string;
    uploadUrl: string;
    fileName: string;
  };
}

const createBulkImport = async ({ auth, variables }: QueryConfig<CreateBulkImport>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($bulkImport: BulkImportInput!) {
        createBulkImport(bulkImport: $bulkImport) {
          bulkActionId
        }
      }
    `,
    variables,
    auth
  );
};

export { getBulkExport, getBulkImport, createBulkExport, createBulkImport };
