import { memo, useEffect, useState } from "react";
import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  TextField,
  TableCell,
  Box,
  Autocomplete,
  Chip,
  FormHelperText,
  IconButton,
  Typography,
  TableRow,
  Popper,
  Table,
  TableBody
} from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import { appColors, appStyles } from "../../../assets";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../hooks";
import { graphql } from "../../../aws";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";

const { formError } = appColors;

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: "0",
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: "0",
    color: "black"
  }
}));

const useTableStyles = makeStyles({
  tableRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  }
});

interface Option {
  name: string;
  label: string;
}

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

interface SearchItemsConfig {
  showAll: boolean;
  // params: {
  //   type: string;
  //   query: string;
  //   limit: number;
  //   filter?: string;
  //   orgId?: string;
  //   nextToken?: string;
  //   order?: SortOrderType;
  //   sortBy?: string;
  // };
}

// @ts-expect-error willbe fixed later
const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;
const searchItems = async ({ auth, variables }: QueryConfig<SearchItemsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($showAll: Boolean, $query: String) {
        getOrganizations(showAll: $showAll, query: $query) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

const { customAsteriskStyle } = appStyles;

const useListStyles = makeStyles({
  customListbox: {
    overflow: "auto",
    display: "inline-block"
  }
});

const sortByLabel = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

const AsyncMultiSelectorField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const { token: auth, orgId } = useAuth();
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);

  const tableClasses = useTableStyles();

  useEffect(() => {
    return () => {
      fieldInfo.clearOnReinitialize && setFieldValue(fieldInfo.name, []);
    };
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    const fetchOptions = async (searchValue: string) => {
      setLoading(true);
      try {
        const { data } = await searchItems({
          auth,
          variables: {
            showAll: fieldInfo?.showAll || fieldInfo?.showAll === false ? fieldInfo?.showAll : true,
            ...(searchValue?.trim() ? { query: searchValue?.trim() } : {})
            // params: {
            //   type: fieldInfo?.entityName,
            //   limit: 100000,
            //   query: searchValue.trim() === "" ? "*" : searchValue.trim(),
            //   orgId
            // }
          }
        });
        if (data) {
          const options = data?.map((item) => ({
            label: item?.[fieldInfo?.entityLabelAttribute],
            name: item?.[fieldInfo?.entityKeyAttribute],
            external: item
          }));
          setOptions(options.sort(sortByLabel));
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const handleInputChange = (value: string) => {
      clearTimeout(timer!);
      timer = setTimeout(() => {
        fetchOptions(value);
      }, 500);
    };

    if (inputValue.length > 2) handleInputChange(inputValue);

    return () => {
      clearTimeout(timer!);
    };
  }, [inputValue]);
  const classes = useStyles();
  const classesList = useListStyles();

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value?.map(({ label }) => label).join(", ")} />
  ) : (
    <Box
      style={
        needConfirmation?.includes(fieldInfo.name)
          ? { display: "flex", alignItems: "flex-end", marginTop: "20px" }
          : { marginTop: "20px" }
      }
    >
      <Box style={{ ...fieldInfo.style }}>
        <Autocomplete
          classes={{ listbox: classesList.customListbox }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                deleteIcon={
                  <IconButton aria-label={`remove selected ${option.label}`} id="remove-selected-item" size="medium">
                    <CloseIcon />
                  </IconButton>
                }
                key={index}
                label={option.label}
                {...getTagProps({ index })}
                style={{ padding: 0, height: "22px", margin: "0 0 2px 0" }}
                aria-label={`${option.label} selected, ${fieldInfo.label}`}
              />
            ))
          }
          renderOption={(props, option, state) => (
            // @ts-expect-error willbe fixed later
            <TableRow
              {...props}
              className={tableClasses.tableRow}
              sx={{
                "td, th": { border: "none", padding: "4px 8px", margin: 0, textAlign: "left", cursor: "pointer" }
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "200px" }}
              >
                {option.label}
              </TableCell>
              <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "150px" }}>
                {option?.external?.email ? option?.external?.email : ""}
              </TableCell>
              <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "100px" }}>
                {option?.external?.address?.address1 ? option?.external?.address?.address1 : ""}
              </TableCell>
              <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "100px" }}>
                {option?.external?.address?.city ? option?.external?.address?.city : ""}
              </TableCell>
              <TableCell align="right" style={{ textWrap: "nowrap", overflow: "hidden", maxWidth: "50px" }}>
                {option?.external?.address?.state ? option?.external?.address?.state : ""}
              </TableCell>
            </TableRow>
          )}
          PopperComponent={(props) => (
            <Popper {...props}>
              <Table>
                {/* @ts-expect-error willbe fixed later*/}
                <TableBody>{props.children}</TableBody>
              </Table>
            </Popper>
          )}
          componentsProps={{
            popper: {
              style: {
                maxWidth: "600px"
              }
            }
          }}
          forcePopupIcon={false}
          noOptionsText="No matching results, type at least 3 characters to search"
          multiple
          limitTags={2}
          id={`async-lookup-${fieldInfo.name}`}
          options={options}
          value={value || []}
          onChange={(event: any, newValue: any | null) => {
            setFieldValue(fieldInfo.name, newValue, true);
          }}
          disableClearable
          sx={{ width: "100%", display: "flex" }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Type min 3 characters to search"
              error={Boolean(error)}
              variant="standard"
              disabled={isDisabledField}
              aria-label={fieldInfo.label}
              aria-required={fieldRequired}
              // aria-invalid={Boolean(error)}
              label={
                <>
                  {fieldInfo.label} <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
                </>
              }
              value={inputValue}
              onChange={(event) => {
                setInputValue(event.target.value || "");
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    {inputValue && (
                      <IconButton aria-label="clear selected organizations" id="clear_multiselect" size="medium">
                        <ClearIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFieldValue(fieldInfo.name, [], true);
                            setInputValue("");
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              setFieldValue(fieldInfo.name, "", true);
                              setInputValue("");
                            }
                          }}
                        />
                      </IconButton>
                    )}
                  </>
                )
              }}
            />
          )}
        />
        {error && (
          <FormHelperText
            sx={{
              color: formError
            }}
          >
            {error}
          </FormHelperText>
        )}
        <Box role="status" aria-live="polite" aria-atomic="true">
          <Typography className={classes.visuallyHidden}>
            {value && `Selected: ${value?.map(({ label }) => label).join(", ")}`}
          </Typography>
        </Box>
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            tabIndex={0}
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            tabIndex={0}
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(AsyncMultiSelectorField, fieldValueEqual);
