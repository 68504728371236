import { PractitionerField } from "./types";
import assets from "../../../ui/assets";
import { validations } from "../../utils";

const {
  appStrings: {
    admin: {
      practitioner: {
        item: { formFields }
      }
    }
  }
} = assets;

const fields: { [key in PractitionerField]: any } = {
  firstName: {
    name: "firstName",
    label: formFields.firstName,
    width: "28%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  middleName: {
    name: "middleName",
    label: formFields.middleName,
    width: "28%",
    validation: validations.textMinMax(0, 50)
  },
  lastName: {
    name: "lastName",
    label: formFields.lastName,
    width: "28%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  email: {
    name: "email",
    label: formFields.email,
    width: "50%",
    validation: validations.requiredEmail
  },
  dea: {
    name: "dea",
    label: formFields.dea,
    width: "50%",
    validation: validations.text
  },
  npi: {
    name: "npi",
    label: formFields.npi,
    width: "50%",
    validation: validations.textMinMax(0, 9)
  }
};

export default fields;
