import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { WizardName } from "../../types";

interface WizardState {
  activeWizard: WizardName | null;
  wizardError: WizardError | null;
  extraInfo: any;
}

interface HandledResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  status: string;
  // todo - not sure if this makes sense as a generic response field
  statusText: string;
}
interface WizardError {
  errorMessage: string;
  errors: ReadonlyArray<HandledResponse>;
}

const initialState: WizardState = { activeWizard: null, wizardError: null, extraInfo: null };

const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    setActiveWizard: (state, { payload: { activeWizard } }: PayloadAction<{ activeWizard: WizardName }>) => {
      return { ...state, activeWizard };
    },
    setWizardError: (state, { payload: { wizardError } }: PayloadAction<{ wizardError: WizardError }>) => {
      return { ...state, wizardError };
    },
    resetWizard: (state) => {
      return { activeWizard: null, wizardError: null, extraInfo: null };
    },
    addExtraInfo: (state, { payload }: PayloadAction<any>) => {
      return { ...state, extraInfo: payload };
    }
  }
});

export const { setActiveWizard, setWizardError, resetWizard, addExtraInfo } = wizardSlice.actions;

export default wizardSlice.reducer;

export const selectCurrentWizard = (state: RootState) => state.wizard;
