import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export interface ToasterState {
  message: string | null;
  messageType: "error" | "notification" | null;
}

const initialState: ToasterState = { message: null, messageType: null };

const _toasterSlice: Slice<ToasterState> = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    setToasterMessage: (state, { payload: { message, messageType } }: PayloadAction<ToasterState>) => {
      return { ...state, message, messageType };
    }
  }
});

export const { setToasterMessage } = _toasterSlice.actions;
export const selectCurrentToaster = <T extends { toaster: ToasterState }>(state: T) => state.toaster;
const toasterSlice = { ..._toasterSlice, selectCurrentToaster, setToasterMessage };

export default toasterSlice;
