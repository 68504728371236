import { Box, Grid, Stack } from "@mui/material";
import type { SetupWrapperMeta } from ".";
import BaseButton from "../../BaseButton";

const renderButtons = (wrapperMeta: SetupWrapperMeta) => {
  const { buttons } = wrapperMeta;
  return (
    <Grid container>
      {buttons?.map((button) => {
        return (
          <Grid item key={button.text}>
            <Stack direction="row" spacing={2}>
              <BaseButton onPress={button.onPress} ariaLabel={button.text}>
                {button.text}
              </BaseButton>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default function SetupNavButtons(wrapperMeta: SetupWrapperMeta) {
  return <Box>{renderButtons(wrapperMeta)}</Box>;
}
