import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser, setCredentials } from "../reduxSlices/auth";

const useAuth = () => {
  const dispatch = useDispatch();
  const setAuth = (auth) => dispatch(setCredentials(auth));
  const auth = useSelector(selectCurrentUser);
  return { ...auth, setAuth };
};

export default useAuth;
