import React from "react";
import WizardTitle from "../wizards/WizardTitle";
import WizardBody from "../wizards/WizardBody";
import assets from "../../assets";

const {
  appStyles: {
    BaseWizardStyle: { WizardPaper, WizardPaperMobile, wizardBodyStyles, wizardBodyStylesMobile },
    wizards: { sendConsentFormsBodyElement: sendConsentsBodyElement }
  }
} = assets;

const DialogLayout = ({
  title,
  children,
  customStyles
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  customStyles?: any;
}) => {
  const { appConstants } = assets;
  const { isMobileDevice } = appConstants;
  const isMobile = isMobileDevice();
  const CustomComponent = customStyles?.WizardPaper;
  const WizardPaperComponent = isMobile ? WizardPaperMobile : WizardPaper;

  return customStyles?.WizardPaper ? (
    <CustomComponent>
      {title && (
        <WizardTitle id="modal-modal-title" style={{ ...sendConsentsBodyElement.titleTextStyle }}>
          TODO title
        </WizardTitle>
      )}
      <WizardBody flexDirection="column" style={customStyles?.wizardBodyStyles || wizardBodyStyles}>
        {children}
      </WizardBody>
    </CustomComponent>
  ) : (
    <WizardPaperComponent>
      {title && (
        <WizardTitle id="modal-modal-title" style={{ ...sendConsentsBodyElement.titleTextStyle }}>
          TODO title
        </WizardTitle>
      )}
      <WizardBody
        flexDirection="column"
        style={customStyles?.wizardBodyStyles || isMobile ? wizardBodyStylesMobile : wizardBodyStyles}
      >
        {children}
      </WizardBody>
    </WizardPaperComponent>
  );
};

export default DialogLayout;
