import { memo, useState, MouseEvent } from "react";
import * as React from "react";
import { styled, TextField, Box, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReadonlyTextField from "./ReadonlyTextField";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { appStyles } from "../../../assets";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  flexOne,
  baseForm: { sharedStyles, inputLabelEllipsis }
} = appStyles;

const DefaultTextField = styled(TextField)({
  ...flexOne
});

const PasswordField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const isFloatingLabel = !fieldInfo.labelStyle || fieldInfo.labelStyle === "floating";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const [showPassword, setShowPassword] = useState(false);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <div>
        <DefaultTextField
          disabled={isDisabledField}
          style={{ ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
          InputLabelProps={{
            style: inputLabelEllipsis
          }}
          autoComplete={fieldInfo.autoComplete ?? "on"}
          inputProps={{ maxLength: fieldInfo.maxTextLength }}
          error={Boolean(error)}
          helperText={error}
          onChange={(e) => {
            setFieldValue(fieldInfo.name, e.target.value);
          }}
          type={showPassword ? "text" : "password"}
          label={isFloatingLabel ? fieldInfo.label : ""}
          aria-label={isFloatingLabel ? fieldInfo.label : ""}
          aria-required={fieldRequired}
          aria-invalid={Boolean(error)}
          variant={fieldInfo.variant ?? "standard"}
          value={value}
          required={fieldRequired}
          id={fieldInfo.name}
          InputProps={{
            endAdornment: (
              <InputAdornment onKeyDown={(e) => e.stopPropagation()} position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!isFloatingLabel && fieldInfo.labelStyle === "below" && (
          <ReadonlyTextField
            fieldInfo={{ fieldType: "readonly_text", name: "", label: fieldInfo.label }}
            value={fieldInfo.label}
          />
        )}
      </div>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(PasswordField, fieldValueEqual);
