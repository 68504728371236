import { useEffect } from "react";

import { useAuth } from "@midato-mono/common/src";

import { useLocation } from "react-router";
import useMidatoMutation from "../app/hooks/useMidatoMutation";
import { useLogUserEventMutation } from "../app/redux/api";
import { routes } from "./constants";
import { getLogUserEventData } from "../app/utils";

const TrackLocationChange = () => {
  const { email } = useAuth();
  const { triggerFn: logUserEvent } = useMidatoMutation({ mutationFn: useLogUserEventMutation });
  const location = useLocation();
  useEffect(() => {
    performance.clearMarks(`start:${location.pathname}`);
    performance.mark(`start:${location.pathname}`);

    if (
      location.pathname !== "/" &&
      !location.pathname.includes(routes.WATECH_LOGIN) &&
      !location.pathname.includes(routes.LOGIN) &&
      !location.pathname.includes(routes.SAW_LOGIN) &&
      !location.pathname.includes(routes.LICENSING_REQUIREMENTS)
    )
      logUserEvent(getLogUserEventData({ route: location.pathname, type: "navigation", ownerEmail: email }));
  }, [location]);

  return null;
};

export default TrackLocationChange;
