import { themeColors, appColors, buttonColors } from "./colors";
import * as appConstants from "./constants";
import appStyles from "./styles";
import { ActionModalStyle, AddEditFormStyle, BaseWizardStyle } from "./styles";
import en from "./locales/en";
import es from "./locales/es";
import fr from "./locales/fr";
import zh from "./locales/zh";

const lang = { en, es, zh, fr };
const locale = (window.localStorage.getItem("midato:locale") ?? "en") as "en" | "es" | "zh" | "fr";
const commonStrings = lang?.[locale] as typeof en;

export {
  commonStrings,
  themeColors,
  appColors,
  appConstants,
  appStyles,
  buttonColors,
  ActionModalStyle,
  AddEditFormStyle,
  BaseWizardStyle
};
