import { memo } from "react";
import { Box } from "@mui/material";
import type { ReadonlyFieldProps } from "../../../types";
import { fieldValueEqual } from "../../../utils";

const ReadonlyComponentField = ({ fieldInfo }: ReadonlyFieldProps) => {
  return <Box>{fieldInfo.displayComponent}</Box>;
};

export default memo(ReadonlyComponentField, fieldValueEqual);
