import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface FetchOrganizationListConfig {
  orgId: string;
}

const fetchOrganizationList = async ({ auth, variables }: QueryConfig<FetchOrganizationListConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($orgId: ID!) {
        getOrganization(orgId: $orgId) {
          orgId
          name
          phoneNumber
          email
          address {
            ...addressFields
          }
          createdAt
          organizationList {
            organizations {
              orgId
              name
              phoneNumber
              email
              address {
                ...addressFields
              }
              createdAt
            }
            nextToken
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface FetchRootOrganizationConfig {
  orgId: string;
}

const fetchRootOrganization = async ({ auth, variables }: QueryConfig<FetchRootOrganizationConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($orgId: ID!) {
        getOrganization(orgId: $orgId) {
          orgId
          name
          phoneNumber
          email
          address {
            ...addressFields
          }
          createdAt
        }
      }
    `,
    variables,
    auth
  );
};

interface AddressInput {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  expressZip: string;
}
interface OrganizationInput {
  name: string;
  npi: string;
  phoneNumber: string;
  email?: string;
  address: AddressInput;
  isServiceArea: boolean;
}

export interface AddNewOrganizationConfig {
  parentOrgId: string;
  organization: OrganizationInput;
}

const addNewOrganization = async ({ auth, variables }: QueryConfig<AddNewOrganizationConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($organization: OrganizationInput!) {
        addOrganization(organization: $organization) {
          orgId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateOrganizationConfig {
  orgId: string;
  organization: OrganizationInput;
}

const updateOrganization = async ({ auth, variables }: QueryConfig<UpdateOrganizationConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($orgId: ID!, $organization: OrganizationInput!) {
        updateOrganization(orgId: $orgId, organization: $organization) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeleteOrganizationByIdConfig {
  orgId: string;
}

const deleteOrganizationById = async ({ auth, variables }: QueryConfig<DeleteOrganizationByIdConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($orgId: ID!) {
        deactivateOrganization(orgId: $orgId) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface ActivateOrganizationsConfig {
  orgIds: string[];
}

const activateOrganizations = async ({ auth, variables }: QueryConfig<ActivateOrganizationsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($orgIds: [ID!]!) {
        activateOrganizations(orgIds: $orgIds)
      }
    `,
    variables,
    auth
  );
};

export interface FetchOrganizationConfig {
  orgId: string;
}

const fetchOrganization = async ({ auth, variables }: QueryConfig<FetchOrganizationConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($orgId: ID!) {
        getOrganization(orgId: $orgId) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

interface WebhookTopicSettingsInput {
  subscribed: boolean;
  overrideUrl: URL;
  headers: {
    authorization: string;
    topicName: boolean;
    consumerId: boolean;
    consentId: boolean;
    webhookId: boolean;
  };
}

interface WebhookSettingsInput {
  baseUrl: string;
  CONSENT_ACTIVATED: WebhookTopicSettingsInput;
  CONSENT_REVOKED: WebhookTopicSettingsInput;
}

interface OrganizationSettingsInput {
  webhooks: WebhookSettingsInput;
  PATIENT_INVITE_SMS_TEMPLATE: string;
}

export interface UpdateOrganizationSettingsConfig {
  orgId: string;
  settings: OrganizationSettingsInput;
}

const updateOrganizationSettings = async ({ auth, variables }: QueryConfig<UpdateOrganizationSettingsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($orgId: ID!, $settings: OrganizationSettingsInput!) {
        setOrganizationSettings(orgId: $orgId, settings: $settings) {
          ...organizationSettingsFields
        }
      }
    `,
    variables,
    auth
  );
};

const fetchCurrentUserOrganizations = async ({ auth }: QueryConfig<null>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        getCurrentUserOrganizations {
          ...organizationFields
        }
      }
    `,
    undefined,
    auth
  );
};

export interface GetOrganizationsConfig {
  showAll?: boolean;
}

const getOrganizations = async ({ auth, variables }: QueryConfig<GetOrganizationsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($showAll: Boolean) {
        getOrganizations(showAll: $showAll) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetCurrentUserOrganizationConfig {
  orgId: string;
}

const getCurrentUserOrganization = async ({ auth, variables }: QueryConfig<GetCurrentUserOrganizationConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($orgId: ID!) {
        getCurrentUserOrganization(orgId: $orgId) {
          ...organizationFields
        }
      }
    `,
    variables,
    auth
  );
};

export {
  getCurrentUserOrganization,
  getOrganizations,
  fetchCurrentUserOrganizations,
  fetchOrganization,
  fetchOrganizationList,
  fetchRootOrganization,
  addNewOrganization,
  updateOrganization,
  deleteOrganizationById,
  activateOrganizations,
  updateOrganizationSettings
};
