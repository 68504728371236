import * as React from "react";
import { Children, ReactElement } from "react";
import { Navigate } from "react-router-dom";

import { useApp } from "../app/hooks";
import ACTIONS from "../app/actions";
import { routes } from "./constants";

const ScopesProtectionProvider = ({
  actionId,
  fallback,
  unprotectedPath = false,
  children
}: {
  fallback: string;
  actionId?: keyof typeof ACTIONS;
  unprotectedPath?: boolean;
  children: ReactElement;
}) => {
  const { permissions: userPermissions = [] } = useApp();
  if (
    (!unprotectedPath && !actionId) ||
    (!unprotectedPath &&
      (ACTIONS[actionId as keyof typeof ACTIONS]?.requiredScopes.length < 1 ||
        ACTIONS[actionId as keyof typeof ACTIONS]?.requiredScopes?.every((permission: string) =>
          userPermissions?.includes(permission)
        ))) ||
    unprotectedPath
    // (unprotectedPath && userPermissions?.length < 1)
  ) {
    return Children.only(children ?? null);
  }

  if (fallback === routes.LOGIN) {
    const loginType = window.localStorage.getItem("midato:loginType") || "ALL";
    switch (loginType) {
      case "COGNITO":
        return <Navigate to={routes.COGNITO_LOGIN} replace />;
      case "SAW":
        return <Navigate to={routes.SAW_LOGIN} replace />;
      case "WATECH":
        return <Navigate to={routes.WATECH_LOGIN} replace />;
      default:
        return <Navigate to={routes.LOGIN} replace />;
    }
  } else {
    return <Navigate to={fallback} replace />;
  }
};

export default ScopesProtectionProvider;
