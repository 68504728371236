import { Snackbar, IconButton, SnackbarContent } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useToaster } from "../hooks";
import { appColors, themeColors } from "../assets";

const { toasterErrorBackground } = appColors;

const toasterErrorStyle = {
  backgroundColor: toasterErrorBackground
};

const { primaryBlue } = themeColors;

const snackbarContent = {
  backgroundColor: primaryBlue
};

function ToasterMessage() {
  const { toasterState, handleResetModalMessage } = useToaster();
  const useErrorStyle = !!toasterState && toasterState.messageType === "error" ? toasterErrorStyle : undefined;

  const showModal = toasterState?.message ? true : false;
  const snackbarStyle = useErrorStyle ? useErrorStyle : snackbarContent;
  const message = toasterState?.message ?? null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={showModal}
      autoHideDuration={6000}
      onClose={(): any => handleResetModalMessage()}
      id="toaster-message"
    >
      <SnackbarContent
        style={snackbarStyle}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={(): any => handleResetModalMessage()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        message={message}
      />
    </Snackbar>
  );
}

export default ToasterMessage;
