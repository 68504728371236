import { memo } from "react";
import * as React from "react";
import { TextField, Box } from "@mui/material";
import ReadonlyTextField from "./ReadonlyTextField";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  flexOne,
  baseForm: { sharedStyles, inputLabelEllipsis, asteriskStyles }
} = appStyles;

const ZipTextField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isFloatingLabel = !fieldInfo.labelStyle || fieldInfo.labelStyle === "floating";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <TextField
          disabled={isDisabledField}
          sx={{ ...flexOne, ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
          InputLabelProps={{
            style: inputLabelEllipsis,
            sx: asteriskStyles
          }}
          inputProps={{ maxLength: fieldInfo.maxTextLength }}
          error={Boolean(error)}
          helperText={error}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d*$/.test(inputValue)) {
              setFieldValue(fieldInfo.name, inputValue);
            }
          }}
          type="text"
          aria-label={isFloatingLabel ? fieldInfo.label : ""}
          aria-required={fieldRequired}
          aria-invalid={Boolean(error)}
          label={isFloatingLabel ? fieldInfo.label : ""}
          variant={fieldInfo.variant ?? "standard"}
          value={value}
          required={fieldRequired}
          id={fieldInfo.name}
        />
        {!isFloatingLabel && fieldInfo.labelStyle === "below" && (
          <ReadonlyTextField
            fieldInfo={{ fieldType: "readonly_text", name: "", label: fieldInfo.label }}
            value={fieldInfo.label}
          />
        )}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(ZipTextField, fieldValueEqual);
