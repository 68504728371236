import * as yup from "yup";
import { useFormik } from "formik";

interface formikConfigurationProps {
  initialValues: object;
  validationRules: object;
}

interface FieldMeta {
  name: string;
  defaultValue: string;
  validation: any;
}

const useForm = ({
  rows,
  handleSubmit,
  initialValues,
  otherFormikOptions = {}
}: {
  rows: any[];
  handleSubmit?: Function;
  initialValues?: object;
  otherFormikOptions?: object;
}) => {
  const config = rows.flat(2).reduce(
    (acc: formikConfigurationProps, cur: FieldMeta) => {
      const { name, defaultValue, validation } = cur;
      if (name) {
        (acc.initialValues as any)[name] = defaultValue;
        (acc.validationRules as any)[name] = validation;
      }
      return acc;
    },
    { initialValues: {}, validationRules: {} }
  );

  const formik = useFormik({
    initialValues: initialValues ? initialValues : config.initialValues,
    validationSchema: yup.object(config.validationRules),
    onSubmit: handleSubmit as any,
    enableReinitialize: true,
    ...otherFormikOptions
  });

  return formik;
};

export default useForm;
