const dateString = (date: string): string =>
  new Date(date).toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
export default {
  defaultErrorStrings: {
    requiredError: "This field is required",
    usernameRequiredError: "Username is required",
    phoneRequiredError: "Phone number is required",
    phoneNumInvalidError: "Invalid phone number",
    invalidPhoneError: "Country code required (ie +1)",
    phoneMaxLengthError: "Phone number must be less than 16 numbers",
    phoneMinLengthError: "Phone number must be a least 8 numbers",
    passwordRequiredError: "Password is required",
    confirmationRequiredError: "Password confirmation is required",
    confirmationMismatchError: "Passwords don't match",
    emailRequiredError: "Email Address is required",
    invalidEmailError: "Invalid email address",
    invalidZipError: "Invalid Zip Code",
    minLengthError: "Password must be at least 8 characters",
    maxLengthError: "Password must be less than 17 characters",
    nameMaxLengthError: "Name must be less than 17 characters",
    invalidPasswordError: "Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number",
    hasCapError: "Password must contain an uppercase letter",
    hasLowerError: "Password must contain an lowercase letter",
    hasDigitError: "Password must contain a number",
    hasSymbolError: "Password must contain at least one special character",
    unexpectedError: "Unexpected Error",
    agreementError: "Must accept agreement before continuing",
    dobMinYearError: "DOB must be later than 110 years ago",
    dobMaxYearError: "DOB must be earlier than today",
    dobRequiredError: "Date of birth is required",
    dobInvalidError: "Invalid date of birth",
    generalError: "some error happens",
    secretKeyError: "secret is not correct",
    linkExpiredError: "The link has expired",
    formExpiredNumInvalidError: "Please provide a number"
  },
  regex: {
    isValidEmail:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    isValidPhone: /^\+[1-9]{1}[0-9]{3,14}$/,
    isValidZip: /^\d{5}$/,
    isValidExpressZip: /(^\d{4}$)/,
    hasCap: /[A-Z]/,
    hasLower: /[a-z]/,
    hasDigit: /[[\d]/,
    hasSymbol: /[\W]/,
    isNumber: /^\d*$/,
    isValidUsPhone: /^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/
  },
  appStrings: {
    metaStrings: {
      shareApprove: "Share Approve"
    },
    prompts: {
      deleteConfirmation: "Are you sure you want to delete?",
      goBackConfirmation: "You currently have unsaved changes! Are you sure you want to leave without saving?"
    },
    labels: {
      name: "Name",
      email: "Email",
      city: "City",
      state: "State",
      tenant: "Tenant",
      firstName: "First Name",
      middleName: "Middle",
      lastName: "Last Name",
      npi: "NPI",
      dea: "DEA",
      phone: "Phone",
      address1: "Address 1",
      address2: "Address 2",
      zip: "Zip",
      expressZip: "Express Zip",
      signature: "Signature Here",
      dobLabel: "Date of Birth (MM/DD/YYYY)",
      organization: "Organization",
      practitioner: "Practitioner",
      chooseFile: "Choose File",
      defaultFile: "No file chosen",
      emptyFiller: (item: string) => `No ${item.toLowerCase()}`,
      currentPassword: "Current Password",
      newPassword: "New Password",
      printName: "Por favor, escriba su nombre"
    },
    buttonText: {
      login: "Login",
      add: "Add",
      back: "Back",
      done: "Done",
      continueText: "Continue",
      cancel: "Cancel",
      submit: "Submit",
      clear: "Clear",
      logout: "Log Out",
      dismiss: "Dismiss",
      previous: "Previous",
      next: "Next",
      save: "Save",
      updatePassword: "Update Password",
      send: "Send",
      createNew: "Create New",
      change: "Change",
      deleteStr: "Delete",
      download: "Download",
      import: "Import"
    },
    rowsProcessed: "rows processed",
    countryCodes: { us: "+1" },
    urlPrefix: "https://",
    actionRibbon: {
      searchLabel: "Search"
    },
    sectionDetails: {
      sectionTitle: (currIdx: string | number, length: string | number | undefined) => {
        return `page ${currIdx} of ${length}`;
      }
    },
    dates: {
      standardDateString: dateString,
      sentDateString: (date: string) => `Sent ${dateString(date)}`
    },
    formViewerWizard: {
      titles: {
        history: "History",
        defaultViewerTitle: "Consumer Consent"
      },
      details: {
        requestedForm: ` requested consent to send treatment records to `
      }
    },
    loginErrorMessage: "Incorrect username or password.",
    notifications: {
      sessionExpired: "Your session has expired; please log in.",
      signOnSuccess: "Thanks for signing up!",
      genericFailure: "An error has occured"
    },
    consumersConsentStatus: {
      draft: "Pending",
      rejected: "Rejected",
      active: "Active",
      inactive: "Revoked",
      expired: "Expired",
      abandoned: "Abandoned"
    },
    consentInfo: { requestedByText: "Requested by", sendToText: "Send to" }
  }
};
