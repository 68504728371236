import { useSpinner, useDelayTimeout } from "../hooks";
import Spinner from "./Spinner";

function SpinnerOverlay({ style }: { style?: {} }) {
  const { spinnerState, closeSpinner } = useSpinner();

  useDelayTimeout(spinnerState.spinner, () => closeSpinner(), spinnerState.extendedTimeout);

  return spinnerState.spinner ? <Spinner style={style} /> : null;
}

export default SpinnerOverlay;
