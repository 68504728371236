import * as React from "react";
import { ConsumerField } from "./types";
import { validations } from "../../utils";
import assets from "../../../ui/assets";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const {
  appStrings: {
    admin: {
      consumer: {
        item: { formFields }
      }
    },
    common: {
      defaultErrorStrings: { requiredError }
    }
  }
} = assets;

const fields: { [key in ConsumerField]: any } = {
  personalHeader: {
    name: "personalHeader",
    label: formFields.consumerIdentiryHeader,
    value: formFields.consumerIdentiryHeader,
    fieldType: "readonly_headertext"
  },
  firstName: {
    name: "firstName",
    label: formFields.firstName,
    width: "45%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  middleName: {
    name: "middleName",
    label: formFields.middle,
    width: "45%",
    validation: validations.textMinMax(0, 50)
  },
  lastName: {
    name: "lastName",
    label: formFields.lastName,
    width: "45%",
    validation: validations.requiredTextMinMax(1, 50)
  },
  medicaidId: {
    name: "medicaidId",
    label: formFields.medicaidId,
    width: "45%",
    validation: validations.textMinMax(1, 50).required(requiredError)
  },
  suffixName: {
    name: "suffixName",
    label: formFields.suffix,
    fieldType: "select",
    width: "45%",
    options: [...Object.values(formFields.dropDownOptions)],
    validation: validations.text
  },
  dob: {
    name: "dob",
    label: formFields.dob,
    fieldType: "dob",
    width: "45%",
    validation: validations.requiredDob
  },
  gender: {
    name: "gender",
    row: true,
    label: formFields.sex,
    options: Object.entries(formFields.genderDropDownOptions).map(([key, value]) => ({ name: key, label: value })),
    fieldType: "radio",
    width: "90%",
    validation: validations.requiredText
  },
  organization: {
    name: "organization",
    label: "",
    fieldType: "readonly_text",
    width: "90%",
    style: { marginTop: 40, flexDirection: "row", alignItems: "baseline" },
    additionLabelStyles: { fontSize: "14px", marginRight: "16px" }
  },
  consumerId: {
    name: "divider",
    fieldType: "divider",
    style: { marginTop: 25 }
  },
  externalIdsHeader: {
    name: "externalIdsHeader",
    label: formFields.consumerExternalIdsHeader,
    value: formFields.consumerExternalIdsHeader,
    fieldType: "readonly_headertext",
    width: "90px",
    style: { marginTop: 40, width: "110px" }
  },
  externalIdHeader: {
    name: "externalIdHeader",
    fieldType: "readonly_text",
    width: "40%",
    style: { marginTop: "20px" }
  },
  externalId: {
    name: "externalId",
    label: formFields.externalId,
    fieldType: "text",
    width: "40%",
    validation: validations.text,
    style: { marginTop: 0 }
    // clearOnReinitialize: true
  },
  addExternalId: {
    name: "addExternalId",
    label: <AddIcon style={{ fontSize: "25px", verticalAlign: "text-bottom" }} color="primary" />,
    fieldType: "icon-button",
    style: { marginTop: "20px", cursor: "pointer" }
  },
  removeExternalId: {
    width: "10%",
    name: "removeExternalId",
    label: <DeleteOutlinedIcon style={{ fontSize: "25px", verticalAlign: "text-bottom" }} color="error" />,
    fieldType: "icon-button",
    style: { marginTop: "20px", cursor: "pointer" }
  },
  contactHeader: {
    name: "contactHeader",
    label: formFields.consumerContactHeader,
    value: formFields.consumerContactHeader,
    fieldType: "readonly_headertext",
    style: { marginTop: 40 }
  },
  phoneNumber: {
    name: "phoneNumber",
    label: formFields.mobilePhone,
    fieldType: "us-phone",
    width: "45%",
    validation: validations.usPhone
  },
  landlineNumber: {
    name: "landlineNumber",
    label: formFields.landPhone,
    fieldType: "us-phone",
    width: "45%",
    validation: validations.usPhone
  },
  email: {
    name: "email",
    isEmail: true,
    label: formFields.email,
    width: "45%",
    validation: validations.email
  },
  address1: {
    name: "address1",
    label: formFields.address1,
    width: "90%",
    validation: validations.text
  },
  address2: {
    name: "address2",
    label: formFields.address2,
    width: "90%",
    validation: validations.text
  },
  state: {
    name: "state",
    label: formFields.state,
    fieldType: "us-state",
    width: "45%",
    validation: validations.text
  },
  city: {
    name: "city",
    label: formFields.city,
    width: "45%",
    validation: validations.text
  },
  zip: {
    name: "zip",
    label: formFields.zip,
    fieldType: "zip",
    width: "45%",
    validation: validations.zip
  }
};

export default fields;
