import { UserItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  createButtonBoxPostAmble,
  createAction,
  disableFields,
  formIsUntouchedOrHasError
} from "../../utils";
import {
  useAddNewUserMutation,
  useDeactivateUserMutation,
  useFetchOrganizationQuery,
  useFetchUserQuery,
  useUpdateUserMutation,
  useGetAssignedPermissionsQuery,
  useGetUserRolesQuery,
  useGetOrganizationsQuery
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { userCan } from "../../utils";
import { formatePhoneNumberAndRemoveCountryCode, useAuth } from "@midato-mono/common/src";

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, deactivate, cancel, edit },
        countryCodes,
        prompts: { deactivateConfirmation, deactivateDoubleConfirmation }
      }
    },
    admin: {
      user: {
        item: { detailPageHeader }
      }
    }
  }
} = assets;

// new
type NC = UserItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useAddNewUserMutation, type: "add" })
});

export const useNewInitialQuery: NC["useInitialQuery"] = ({ orgId }) => {
  const { data: callerOrganization, ...others } = useMidatoQuery({
    queryFn: useFetchOrganizationQuery,
    queryArgs: {
      orgId
    }
  });
  const organizations = (callerOrganization ? [callerOrganization] : []).map(({ orgId, name }: any) => ({
    name: orgId,
    label: name
  }));
  return { data: organizations, ...others };
};

export const createNewEntityName: NC["createEntityName"] = ({
  formik: {
    values: { firstName, lastName }
  }
}) => (firstName && lastName ? `${firstName} ${lastName}` : "New");

export const createNewRows: NC["createRows"] = ({ initialQueryData }) => {
  return [
    [fields.firstName],
    [fields.lastName],
    [fields.email],
    [fields.phoneNumber],
    [fields.organizations],
    [fields.isExternalAuthentication],
    [fields.externalId],
    [
      {
        ...fields.roles,
        options: initialQueryData?.roles?.map(
          ({ userRoleId, name, permissions }: { userRoleId: string; name: string }) => ({
            name: userRoleId,
            label: name,
            permissions
          })
        ),
        permissions: initialQueryData?.permissions
      },
      {
        ...fields.permissions,
        options: initialQueryData?.permissions?.map(({ id, name }: { id: string; name: string }) => ({
          name: id,
          label: name
        }))
      }
    ]
  ];
};

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  searchParams,
  permissions,
  closeWizard,
  userEmail
}) => {
  const values = formik.values;
  const params = {
    orgId: searchParams.get("orgId") ?? "/",
    user: {
      firstName: values?.firstName ?? "",
      lastName: values?.lastName ?? "",
      email: values?.email ?? "",
      phoneNumber: values.phoneNumber,
      permissions: values?.permissions ?? [],
      roles: values?.roles ?? [],
      orgIds: values?.organizations?.map(({ name }: { name: string }) => name) ?? [],
      isExternalAuthentication: values?.isExternalAuthentication,
      externalId: values?.externalId
    },
    // TODO temporary solution for user create
    password: "@changePassword12345"
  };

  const saveButton = createButton({
    id: "btn-save",
    disabled: formIsUntouchedOrHasError(formik),
    color: "primary",
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const userId = await add!(params).unwrap();
        closeWizard();
        navigate(`${routes.USERS}/${userId}`, { replace: true });
      }
    },
    text: save,
    actionId: "USER_ADD"
  });

  const cancelButton = createButton({
    actionId: "USER_ADD",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton]
  });
};

// detail
type DC = UserItemPageConfiguration["detail"];

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({ toaster, mutationFn: useUpdateUserMutation, type: "update" }),
  deactivate: createAction({ toaster, mutationFn: useDeactivateUserMutation, type: "deactivate" })
});

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: userId } }) => {
  const { orgId } = useAuth();
  const { data: permissions } = useMidatoQuery({
    queryFn: useGetAssignedPermissionsQuery,
    queryArgs: {}
  });

  const { data: roles } = useMidatoQuery({
    queryFn: useGetUserRolesQuery,
    queryArgs: {}
  });

  const { data: userData } = userId
    ? useMidatoQuery({
        queryFn: useFetchUserQuery,
        queryArgs: {
          userId
        },
        queryOptions: { skip: !userId }
      })
    : { data: {} };

  const { data: organizations } = useMidatoQuery({
    queryFn: useGetOrganizationsQuery,
    queryArgs: {
      showAll: false
      // params: {
      //   type: "organization",
      //   limit: 100000,
      //   query: "*",
      //   orgId
      // }
    }
  });

  return {
    data: {
      permissions: permissions
        ?.filter(({ active }: { active: boolean }) => active)
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      userData,
      roles: roles
        ?.filter(({ active }: { active: boolean }) => active)
        .sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      organizations
    }
  };
};

export const createDetailDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData: { userData: user } }) =>
  user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : detailPageHeader;

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData: { userData: user } }) =>
  `${user?.firstName || ""} ${user?.lastName || ""}`;

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData: { userData: user } }) => {
  const { roles, permissions } = user?.rolePermissions
    ? user.rolePermissions.reduce(
        (
          acc: { roles: string[]; permissions: string[] },
          { role, permissions }: { role: string; permissions: string[] }
        ) => {
          if (role === "custom") {
            acc.permissions = [...acc.permissions, ...permissions];
          } else {
            acc.roles.push(role);
          }
          return acc;
        },
        { roles: [], permissions: [] }
      )
    : { roles: [], permissions: [] };

  return {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    permissions: permissions,
    roles: roles,
    isExternalAuthentication: user?.isExternalAuthentication,
    externalId: user?.externalId,
    organizations:
      user?.organizations?.map(({ name, orgId }: { name: string; orgId: string }) => ({ name: orgId, label: name })) ??
      []
  };
};

export const createDetailRows: DC["createRows"] = ({ initialQueryData }) => [
  [fields.firstName],
  [fields.lastName],
  disableFields([fields.email]),
  [fields.phoneNumber],
  [fields.organizations],
  // disableFields([fields.isExternalAuthentication]),
  [fields.isExternalAuthentication],
  [fields.externalId],
  [
    {
      ...fields.roles,
      options: initialQueryData?.roles?.map(
        ({ userRoleId, name, permissions }: { userRoleId: string; name: string }) => ({
          name: userRoleId,
          label: name,
          permissions
        })
      ),
      permissions: initialQueryData?.permissions
    },
    {
      ...fields.permissions,
      options: initialQueryData?.permissions?.map(({ id, name }: { id: string; name: string }) => ({
        name: id,
        label: name
      }))
    }
  ]
];

export const createDetailPostAmble: DC["createPostAmble"] = ({
  formik,
  params: { id: userId },
  actions: { update, deactivate: deactivateFn },
  navigate,
  permissions,
  closeWizard,
  userEmail
}) => {
  const values = formik.values;
  const params = {
    userId: userId,
    user: {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: formatePhoneNumberAndRemoveCountryCode(values.phoneNumber),
      permissions: values.permissions,
      roles: values.roles,
      orgIds: values.organizations?.map(({ name }: { name: string }) => name),
      isExternalAuthentication: values?.isExternalAuthentication,
      externalId: values?.externalId
    }
  };

  const updateButton = createButton({
    actionId: "USER_UPDATE",
    id: "btn-save",
    text: save,
    disabled: formIsUntouchedOrHasError(formik),
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        await update!(params).unwrap();
        closeWizard();
        navigate(`${routes.USERS}/${userId}`);
      }
    }
  });

  const deactivateButton = createButton({
    id: "btn-deactivate",
    text: deactivate,
    color: "error",
    variant: "outlined",
    onPress: async () => {
      if (params?.user?.orgIds?.length > 1) {
        const sure = window.confirm(deactivateDoubleConfirmation);
        if (!sure) return;
      }
      const sure = window.confirm(deactivateConfirmation);
      if (sure) {
        await deactivateFn!({ userId });
        closeWizard();
        navigate(routes.USERS, { replace: true });
      }
    },
    actionId: "USER_DELETE",
    separateButtonBlock: true
  });

  const cancelButton = createButton({
    actionId: "USER_UPDATE",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [updateButton, cancelButton, deactivateButton]
  });
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({
  permissions,
  wizardFunctions,
  userId,
  currentItemId
}) => [
  ...(userId !== currentItemId
    ? [
        {
          allowed: userCan({ actionId: "USER_UPDATE", userPermissions: permissions }),
          color: "primary",
          title: edit,
          onClick: () => {
            wizardFunctions?.launchEditUserWizard();
          }
        }
      ]
    : [])
];
