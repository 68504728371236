import { memo } from "react";
import { appStyles } from "../../../assets";
import type { ReadonlyFieldProps } from "../../../types";
import ReactMarkdown from "react-markdown";

const {
  baseForm: { sharedStyles },
  formFields: {
    readonlyTextFieldStyles: { textStyle, readonlyHeader, readonlyText }
  }
} = appStyles;

const ReadonlyTextField = ({ fieldInfo, value }: ReadonlyFieldProps) => {
  return (
    <div
      style={
        fieldInfo.fieldType === "readonly_text"
          ? {
              ...readonlyText,
              ...sharedStyles,
              ...textStyle,
              ...(fieldInfo?.style ?? {})
            }
          : { ...readonlyHeader, ...(fieldInfo?.style ?? {}) }
      }
    >
      {fieldInfo.additionLabel && <span style={fieldInfo.additionLabelStyles}>{fieldInfo.additionLabel}</span>}
      <ReactMarkdown>{value}</ReactMarkdown>
    </div>
  );
};

export default memo(ReadonlyTextField);
