import {
  createNewActions,
  createNewEntityName,
  createNewPostAmble,
  createNewRows,
  useDetailInitialQuery,
  createDetailActions,
  createDetailRows,
  createDetailInitialValues,
  createDetailPostAmble,
  additionalButtonsList,
  createDetailEntityName,
  createDetailDocumentTitle
} from "./lib";
import { SecurityRoleItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import { routes } from "../../../Router/constants";

const {
  appStrings: {
    admin: {
      securityRole: { list }
    }
  }
} = assets;

const securityRoleItemPageConfiguration: SecurityRoleItemPageConfiguration = {
  common: {
    listPageLabel: list.listPageHeader,
    listPageUrl: routes.SECURITY_ROLES
  },
  new: {
    createActions: createNewActions,
    createEntityName: createNewEntityName,
    createRows: createNewRows,
    createPostAmble: createNewPostAmble,
    useInitialQuery: useDetailInitialQuery,
    createInitialValues: createDetailInitialValues
  },
  detail: {
    createEntityName: createDetailEntityName,
    useInitialQuery: useDetailInitialQuery,
    createActions: createDetailActions,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    createPostAmble: createDetailPostAmble,
    createDocumentTitle: createDetailDocumentTitle
  },
  detail_view: {
    createEntityName: createDetailEntityName,
    useInitialQuery: useDetailInitialQuery,
    createActions: createDetailActions,
    createRows: createDetailRows,
    createInitialValues: createDetailInitialValues,
    additionalButtonsList: additionalButtonsList,
    createDocumentTitle: createDetailDocumentTitle
  }
};

export default securityRoleItemPageConfiguration;
