import type { ReactElement } from "react";
import type { BaseButtonProps } from "../types";
import { createTheme, ThemeProvider, Button } from "@mui/material";
import { buttonColors } from "../assets";
import { alpha } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { color: "primary", variant: "contained" },
          style: {
            color: buttonColors.primaryText,
            backgroundColor: buttonColors.primaryBG,
            "&:hover": {
              backgroundColor: buttonColors.primaryHoverBG
            }
          }
        },
        {
          props: { color: "primary", variant: "text" },
          style: {
            color: buttonColors.defaultBG,
            backgroundColor: buttonColors.defaultText
          }
        },
        {
          props: { color: "secondary", variant: "outlined" },
          style: {
            border: `1px solid ${alpha(buttonColors.primaryBG, 0.5)}`,
            color: buttonColors.secondaryText,
            backgroundColor: buttonColors.secondaryBG,
            "&:hover": {
              border: `1px solid ${alpha(buttonColors.primaryBG, 0.5)}`,
              backgroundColor: alpha(buttonColors.primaryBG, 0.04)
            }
          }
        },
        {
          props: { color: "inherit" },
          style: {
            color: buttonColors.defaultText,
            backgroundColor: buttonColors.defaultBG,
            "&:hover": {
              backgroundColor: alpha(buttonColors.defaultBG, 0.8)
            }
          }
        },
        {
          props: { color: "success" },
          style: {}
        },
        {
          props: { color: "error" },
          style: {}
        },
        {
          props: { color: "info" },
          style: {}
        },
        {
          props: { color: "warning" },
          style: {
            color: buttonColors.info,
            backgroundColor: buttonColors.warning,
            "&:hover": {
              backgroundColor: buttonColors.primaryHoverBG
            }
          }
        },
        {
          props: { color: "warning", variant: "outlined" },
          style: {
            border: `1px solid ${alpha(buttonColors.warning, 0.5)}`,
            color: buttonColors.warning,
            backgroundColor: buttonColors.info,
            "&:hover": {
              border: `1px solid ${alpha(buttonColors.primaryBG, 0.5)}`,
              backgroundColor: alpha(buttonColors.primaryBG, 0.04)
            }
          }
        }
      ]
    }
  }
});

function BaseButton({
  type = "button",
  children,
  ariaLabel,
  onPress,
  id,
  fullWidth = false,
  disabled = false,
  style = {},
  color = "primary",
  variant = "contained",
  hidden = false
}: BaseButtonProps): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-label={ariaLabel}
        id={id}
        sx={{ fontSize: 14, minWidth: 20, ...style }}
        color={color}
        fullWidth={fullWidth}
        variant={variant}
        onClick={onPress}
        disabled={disabled}
        hidden={hidden}
        type={type}
      >
        <span className="MuiButton-label">{children}</span>
      </Button>
    </ThemeProvider>
  );
}

export default BaseButton;
