// --- USE_WINDOW_DIMENSIONS ---
import { useEffect, useState } from "react";

// Destructure current window height and width (in px) by using const { height, width } = useWindowDimensions(). Allows for easy access to these values within components.
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default useWindowDimensions;
