import axios from "axios";
// @ts-ignore
const APP_REGION = import.meta.env.REACT_APP_REGION;

const COGNITO_ENDPOINT = `https://cognito-idp.${APP_REGION}.amazonaws.com/`;

const cognito = async (serviceName, requestBody) => {
  try {
    const response = await axios({
      method: "post",
      url: COGNITO_ENDPOINT,
      headers: {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": `AWSCognitoIdentityProviderService.${serviceName}`
      },
      data: requestBody
    });
    return {
      data: response.data,
      error: null
    };
  } catch (error) {
    return {
      data: null,
      error: new Error(error?.response?.data?.message ?? error.message)
    };
  }
};

export const initiateAuth = async (requestBody) => cognito("InitiateAuth", requestBody);

export const respondToAuthChallenge = async (requestBody) => cognito("RespondToAuthChallenge", requestBody);

export const revokeToken = async (requestBody) => cognito("RevokeToken", requestBody);

export const globalSignOut = async (requestBody) => cognito("GlobalSignOut", requestBody);

export const signUp = async (requestBody) => cognito("SignUp", requestBody);
