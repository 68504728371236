import React, { useEffect } from "react";
import { FormItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  createButtonBoxPostAmble,
  createAction,
  formIsUntouchedOrHasError,
  rowsNotPass,
  getReactElement,
  validations,
  disableFields
} from "../../utils";
import {
  useCreateConsentFormMutation,
  useDeleteConsentFormMutation,
  useDeprecateConsentFormMutation,
  useGetFormFamilyQuery,
  useGetOrganizationsQuery,
  usePublishConsentFormMutation,
  useUpdateConsentFormMutation
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import { combineSchemaData, downloadFile } from "@midato-mono/common/src/utils";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { userCan } from "../../utils";

const AddEditForm = React.lazy(() => import("../../../ui/components/common/form/AddEditForm"));
const FormBuilderPreview = React.lazy(() => import("../../../ui/components/consentForm/FormBuilderPreview"));
const FormBuilderPreviewPdf = React.lazy(() => import("../../../ui/components/consentForm/FormBuilderPreviewPdf"));

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText,
        prompts: {
          deleteConfirmation,
          formDeleteSuccess,
          formDeprecateSuccess,
          formPublishSuccess,
          formUpdateSuccess,
          genericFailure,
          confirmPublish
        }
      }
    },
    admin: {
      form: {
        item: { formFields, tabs }
      }
    }
  }
} = assets;

const statusIsDraft = (forms: any) => forms?.[0]?.status === "draft";

// new
type NC = FormItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useCreateConsentFormMutation, type: "add" })
});

export const createNewEntityName: NC["createEntityName"] = ({
  formik: {
    values: { name }
  }
}) => (name ? name : "New");

export const createNewInitialValues: NC["createInitialValues"] = () => ({
  orgId: "",
  formType: "roi",
  expiryDays: 90,
  version: 1,
  lang: [],
  schema: JSON.stringify(JSON.parse(formFields.schemaPlaceholder), null, 2)
});

export const createNewRows: NC["createRows"] = ({ initialQueryData, customStates: { editMode }, setCustomStates }) => {
  return [
    [
      [fields.formType],
      [
        {
          ...fields.orgId,
          options: initialQueryData?.organizations?.map(({ orgId, name }: { orgId: string; name: string }) => ({
            name: orgId,
            label: name
          }))
        }
      ],
      [fields.name],
      [fields.description],
      [fields.code],
      [fields.version],
      [fields.expiryDays],
      // [fields.generalDesignation, fields.tpo]
      [fields.tpo, fields.ocrEnabled]
    ],
    [
      // [
      //   {
      //     ...fields.lang,
      //     addCallback: () => {
      //       setCustomStates({ editMode: true });
      //     },
      //     addIconHidden: editMode
      //   }
      // ],
      [fields.upload],
      [fields.schema]
    ]
  ];
};

export const createNewTabs: NC["createTabs"] = ({
  formik,
  rows,
  postAmbleInfo
  // utils: { formIsUntouchedOrHasError, getPostAmble }
}) => {
  // @ts-expect-error will be fixed later
  const { details, schema } = postAmbleInfo || {};

  return [
    {
      label: { text: tabs.details, disabled: false },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "form-detail",
        rows: rows[0],
        postAmble: details
          ? getReactElement({
              Component: details["PostAmble"],
              props: details["props"]
            })
          : null
        // wrapperSx: { maxWidth: 600 }
      }
    },
    {
      label: { text: tabs.schema, disabled: formIsUntouchedOrHasError(formik) },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "form-schema",
        rows: rows[1],
        postAmble: schema
          ? getReactElement({
              Component: schema["PostAmble"],
              props: schema["props"]
            })
          : null
        // wrapperSx: { maxWidth: 800 }
      }
    }
  ];
};

export const createNewInitialTab: NC["createInitialTab"] = ({ searchParams }) => {
  const tab = searchParams.get("tab");
  return tab === "details" || tab === "schema" ? tab : "details";
};

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  upload: { getUploadUrl, uploadFile, orgId },
  setCurrentTab,
  rows,
  permissions,
  closeWizard
}) => {
  const continueButton = createButton({
    actionId: "FORM_ADD",
    id: "btn-schema",
    disabled: rowsNotPass(formik, rows[0]),
    text: buttonText.continueText,
    onPress: async () => {
      setCurrentTab("schema");
    }
  });

  const saveButton = createButton({
    id: "btn-save",
    disabled: !formik.isValid,
    text: buttonText.save,
    actionId: "FORM_ADD",
    onPress: async () => {
      const values = formik.values;
      formik.handleSubmit();
      if (formik.isValid) {
        const { url, fields: rawFields } = await getUploadUrl({ extension: "pdf", orgId }).unwrap();
        const fields = JSON.parse(rawFields);
        const uploadUrl = await uploadFile({ variables: { url, fields, file: values?.upload } }).unwrap();
        const formUrl = `${uploadUrl}/${fields.key}`;
        const addParams = {
          formInput: {
            code: String(values.code),
            version: String(values.version),
            // lang: values?.lang?.[0],
            formType: values?.formType,
            title: values?.name,
            generalDesignation: values?.generalDesignation,
            tpo: values?.tpo,
            ocrEnabled: values?.ocrEnabled,
            // orgId: values?.orgId || orgId,
            description: values?.description,
            expiryDays: values?.expiryDays ? parseInt(values?.expiryDays, 10) : 0,
            schema: JSON.stringify(JSON.parse(values?.schema ?? formFields.schemaPlaceholder)),
            formUrl,
            templates: values?.templates
          }
        };
        const [{ code, version }] = await add!(addParams).unwrap();
        closeWizard();
        navigate(`${routes.FORMS}/${code}/${version}`, { replace: true });
      }
    }
  });

  const cancelButton = createButton({
    actionId: "FORM_ADD",
    id: "btn-cancel",
    text: buttonText.cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: { details: [continueButton], schema: [saveButton, cancelButton] }
  });
};

export const useNewEffects: NC["useEffects"] = ({ setSearchParams, currentTab }) => {
  useEffect(() => {
    currentTab && setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab]);
};

// detail
type DC = FormItemPageConfiguration["detail"];

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({
    toaster,
    mutationFn: useUpdateConsentFormMutation,
    successHandler: formUpdateSuccess,
    errorHandler: genericFailure
  }),
  delete: createAction({
    toaster,
    mutationFn: useDeleteConsentFormMutation,
    successHandler: formDeleteSuccess,
    errorHandler: genericFailure
  }),
  deprecate: createAction({
    toaster,
    mutationFn: useDeprecateConsentFormMutation,
    successHandler: formDeprecateSuccess,
    errorHandler: genericFailure
  }),
  publish: createAction({
    toaster,
    mutationFn: usePublishConsentFormMutation,
    successHandler: formPublishSuccess,
    errorHandler: genericFailure
  })
});

export const useNewInitialQuery: DC["useInitialQuery"] = ({
  params: queryArgs,
  setCustomStates
  // actions: { getOrganization }
}) => {
  const { data: organizations = [] } = useMidatoQuery({
    queryFn: useGetOrganizationsQuery,
    queryArgs: {
      showAll: false
    }
  });

  return {
    data: {
      forms: [],
      organizations
    }
  };
};

export const useDetailInitialQuery: DC["useInitialQuery"] = ({
  params: queryArgs,
  setCustomStates
  // actions: { getOrganization }
}) => {
  const { data: organizations = [] } = useMidatoQuery({
    queryFn: useGetOrganizationsQuery,
    queryArgs: {
      showAll: false
    }
  });

  const { data: forms = [] } = useMidatoQuery({
    queryFn: useGetFormFamilyQuery,
    queryArgs,
    isSuccessCallback: (rawforms: any) => {
      const forms = rawforms.map((data: any) => ({
        schema: combineSchemaData({ schema: data.schema, data: formFields.schemaPlaceholder }),
        formUrl: data.formUrl,
        code: data.code,
        lang: data.lang
      }));
      setCustomStates((prev: any) => ({ ...prev, currentLang: forms[0]?.lang, consentForms: forms }));
    }
  });

  return {
    data: {
      forms,
      organizations
    }
  };
};

export const createDetailDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData }) => {
  const forms = initialQueryData?.forms;
  return `Edit version ${forms?.[0]?.version} of form ${forms?.[0]?.code}`;
};

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData }) => {
  const forms = initialQueryData?.forms;
  return `${forms?.[0]?.title || ""}`;
};

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData, customStates }) => {
  const forms = initialQueryData?.forms;
  const currentForm =
    forms?.find((currentForm: any) => currentForm.lang === customStates?.currentLang) ??
    (customStates?.currentLang && forms?.length
      ? { ...forms?.[0], schema: formFields.schemaPlaceholder, lang: customStates?.currentLang }
      : forms?.[0]);

  return {
    name: currentForm?.title ?? "",
    formType: currentForm?.formType ?? "roi",
    code: currentForm?.code ?? "",
    formId: currentForm?.formId ?? "",
    // version: getFormVersionWithoutLang(currentForm?.version ?? "1"),
    version: currentForm?.version ?? "1",
    ocrEnabled: currentForm?.ocrEnabled ?? false,
    description: currentForm?.description ?? "",
    templates: currentForm?.templates ?? [],
    expiryDays: currentForm?.expiryDays ?? "90",
    generalDesignation: currentForm?.generalDesignation ?? false,
    tpo: currentForm?.tpo ?? false,
    orgId: currentForm?.orgId ?? "",
    upload: currentForm?.status && currentForm?.status !== "draft" ? { name: "N/A" } : null,
    lang: [
      ...new Set([...(customStates?.consentForms.map((form: any) => form.lang) ?? []), customStates?.currentLang])
    ],
    schema: currentForm?.schema
      ? JSON.stringify(
          {
            sections: JSON.parse(currentForm.schema).sections,
            title: JSON.parse(currentForm.schema).title,
            description: JSON.parse(currentForm.schema).description
          },
          null,
          2
        )
      : JSON.stringify(JSON.parse(formFields.schemaPlaceholder), null, 2)
  };
};

export const createDetailRows: DC["createRows"] = ({
  initialQueryData,
  customStates: { editMode, currentLang },
  setCustomStates
}) => {
  return [
    [
      // [{ ...fields.formType, disabled: !statusIsDraft(initialQueryData?.forms) }],
      // [{ ...fields.name, disabled: !statusIsDraft(initialQueryData?.forms) }],
      // [{ ...fields.description, disabled: !statusIsDraft(initialQueryData?.forms) }],
      // [{ ...fields.code, disabled: true }],
      // [{ ...fields.version, disabled: true }],
      // [{ ...fields.expiryDays, disabled: !statusIsDraft(initialQueryData?.forms) }]
      [fields.formType],
      disableFields([
        {
          ...fields.orgId,
          options: initialQueryData?.organizations?.map(({ orgId, name }: { orgId: string; name: string }) => ({
            name: orgId,
            label: name
          }))
        }
      ]),
      [fields.name],
      [fields.description],
      [{ ...fields.code }],
      [{ ...fields.version, disabled: true }],
      [fields.expiryDays],
      // [fields.generalDesignation, fields.tpo]
      [fields.tpo, fields.ocrEnabled]
    ],
    [
      // [
      //   {
      //     ...fields.lang,
      //     addCallback: (lang: string) => {
      //       setCustomStates((prev) => ({ ...prev, editMode: true, currentLang: lang }));
      //     },
      //     addIconHidden: !statusIsDraft(forms) || editMode,
      //     clickCallback: (lang: string) => setCustomStates((prev: any) => ({ ...prev, currentLang: lang })),
      //     activeChipName: currentLang
      //   }
      // ],
      ...(editMode ? [[{ ...fields.upload, validation: editMode ? validations.file : fields.upload.validation }]] : []),
      // [{ ...fields.upload, validation: editMode ? validations.requiredFile : validations.file }],
      ...(editMode
        ? [[{ ...fields.schema, validation: editMode ? undefined : fields.schema.validation, disabled: !editMode }]]
        : [])
    ]
  ];
};

export const createDetailPostAmble: DC["createPostAmble"] = ({
  formik,
  actions: { update, delete: deleteFn, deprecate: deprecateFn, publish: publishFn },
  navigate,
  upload: { getUploadUrl, uploadFile, orgId },
  customStates,
  setCustomStates,
  initialQueryData,
  params: { code, version },
  permissions,
  closeWizard
}) => {
  const status = initialQueryData?.forms?.[0]?.status;
  const updateButton =
    // status === "draft"
    //   ?
    createButton({
      id: "btn-save",
      disabled: !formik.isValid,
      text: buttonText.save,
      actionId: "FORM_UPDATE",
      onPress: async () => {
        formik.handleSubmit();
        if (formik.isValid) {
          const values = formik.values;
          let formUrl;
          if (values?.upload && values.upload instanceof File) {
            const { url, fields: rawFields } = await getUploadUrl({ extension: "pdf", orgId }).unwrap();
            const fields = JSON.parse(rawFields);
            const uploadUrl = await uploadFile({ variables: { url, fields, file: values.upload } }).unwrap();
            formUrl = `${uploadUrl}/${fields.key}`;
          }

          await update!({
            // @ts-expect-error will be fixed later
            formId: values.formId,
            formInput: {
              code: String(values.code),
              version: String(values.version),
              generalDesignation: values?.generalDesignation,
              tpo: values?.tpo,
              // orgId: values?.orgId,
              // lang: customStates!.currentLang,
              ocrEnabled: values?.ocrEnabled,
              title: values?.name,
              description: values?.description,
              expiryDays: formik?.values?.expiryDays ? parseInt(formik?.values?.expiryDays, 10) : 0,
              schema: JSON.stringify(JSON.parse(values?.schema ?? '{"sections":[]}')),
              formType: values?.formType,
              formUrl,
              templates: values?.templates
            }
          }).unwrap();
          closeWizard();
          navigate(`${routes.ADMIN_APP}${routes.ADMIN}/forms/${String(values.code)}/${String(values.version)}`, {
            replace: true
          });

          setCustomStates((prev) => ({ ...prev, editMode: false }));
        }
      }
    });
  // : undefined;

  const publishButton = createButton({
    id: "btn-publish",
    text: buttonText.publish,
    actionId: "FORM_PUBLISH",
    onPress: async () => {
      const sure = window.confirm(confirmPublish);
      if (sure) {
        if (code && version) {
          await publishFn!({
            code,
            version
          });
          closeWizard();
        }
      }
    }
  });

  // const publishButton =
  //   status === "draft"
  //     ? createButton({
  //         id: "btn-publish",
  //         text: buttonText.publish,
  //         actionId: "FORM_PUBLISH",
  //         onPress: async () => {
  //           const sure = window.confirm(confirmPublish);
  //           if (sure) {
  //             if (code && version) {
  //               await publishFn!({
  //                 code,
  //                 version
  //               });
  //               closeWizard();
  //             }
  //           }
  //         }
  //       })
  //     : undefined;

  const deprecateButton =
    // status === "active"
    //   ?
    createButton({
      id: "btn-deactivate",
      text: buttonText.deprecate,
      actionId: "FORM_DEACTIVATE",
      onPress: async () => {
        if (code && version) {
          await deprecateFn!({
            code,
            version
          });
          closeWizard();
          navigate(routes.FORMS, { replace: true });
        }
      },
      separateButtonBlock: true
    });
  // : undefined;

  const deleteButton =
    // status === "draft"
    //   ?
    createButton({
      id: "btn-delete",
      text: buttonText.delete,
      color: "error",
      onPress: async () => {
        const sure = window.confirm(deleteConfirmation);
        if (sure) {
          if (code && version) {
            await deleteFn!({
              code,
              version
            }).unwrap();
            closeWizard();
            navigate(routes.FORMS, { replace: true });
          }
        }
      },
      variant: "outlined",
      actionId: "FORM_DELETE",
      separateButtonBlock: true
    });
  // : undefined;

  const cancelButton = createButton({
    actionId: "FORM_UPDATE",
    id: "btn-cancel",
    text: buttonText.cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  const detailsButtons = [updateButton, publishButton, cancelButton, deprecateButton, deleteButton].filter((b) =>
    b === undefined ? false : true
  );
  const schemaButtons = [updateButton].filter((b) => (b === undefined ? false : true));
  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: { details: detailsButtons, schema: schemaButtons }
  });
};

export const createDetailTabs: DC["createTabs"] = ({
  formik,
  rows,
  postAmbleInfo,
  customStates: { consentForms, currentLang }
}) => {
  const consentForm = consentForms.find((f) => f.lang === currentLang);
  // @ts-expect-error will be fixed later
  const { details, schema } = postAmbleInfo || {};
  return [
    {
      label: { text: tabs.details, disabled: false },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "form-detail",
        rows: rows[0],
        postAmble: details
          ? getReactElement({
              Component: details["PostAmble"],
              props: details["props"]
            })
          : null,
        isViewMode: true
        // wrapperSx: { maxWidth: 600 }
      }
    },
    {
      label: { text: tabs.schema, disabled: false },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "consentFormSchema",
        rows: rows[1],
        postAmble: schema
          ? getReactElement({
              Component: schema["PostAmble"],
              props: schema["props"]
            })
          : null,
        isViewMode: true
        // wrapperSx: { maxWidth: 800 }
      }
    },
    {
      label: { text: tabs.preview, disabled: false },
      TabComponent: FormBuilderPreview,
      props: {
        consentForm
      }
    },
    {
      label: { text: tabs.pdfTemplate, disabled: false },
      TabComponent: FormBuilderPreviewPdf,
      props: {
        formUrl: consentForm?.formUrl,
        downloadTemplatePDF: consentForm?.formUrl ? () => downloadFile(consentForm.formUrl) : undefined
      }
    }
  ];
};

export const createEditDetailTabs: DC["createTabs"] = ({
  formik,
  rows,
  postAmbleInfo,
  customStates: { consentForms, currentLang }
}) => {
  const consentForm = consentForms.find((f) => f.lang === currentLang);
  // @ts-expect-error will be fixed later
  const { details, schema } = postAmbleInfo || {};
  return [
    {
      label: { text: tabs.details, disabled: false },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "form-detail",
        rows: rows[0],
        postAmble: details
          ? getReactElement({
              Component: details["PostAmble"],
              props: details["props"]
            })
          : null
        //   wrapperSx: { maxWidth: 600 }
      }
    },
    {
      label: { text: tabs.schema, disabled: false },
      TabComponent: AddEditForm,
      props: {
        formik,
        itemType: "consentFormSchema",
        rows: rows[1],
        postAmble: schema
          ? getReactElement({
              Component: schema["PostAmble"],
              props: schema["props"]
            })
          : null
        // wrapperSx: { maxWidth: 800 }
      }
    },
    {
      label: { text: tabs.preview, disabled: false },
      TabComponent: FormBuilderPreview,
      props: {
        consentForm
      }
    },
    {
      label: { text: tabs.pdfTemplate, disabled: false },
      TabComponent: FormBuilderPreviewPdf,
      props: {
        formUrl: consentForm?.formUrl,
        downloadTemplatePDF: consentForm?.formUrl ? () => downloadFile(consentForm.formUrl) : undefined
      }
    }
  ];
};

export const createDetailInitialTab: DC["createInitialTab"] = ({ searchParams }) => {
  const tab = searchParams.get("tab");
  return tab === "details" || tab === "schema" || tab === "preview" || tab === "pdf template" ? tab : "details";
};

export const useDetailEffects: DC["useEffects"] = ({ setSearchParams, currentTab, formik, customStates }) => {
  useEffect(() => {
    currentTab && setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab]);

  useEffect(() => {
    formik.validateForm();
  }, [customStates.editMode]);
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({ permissions, wizardFunctions }) => [
  {
    allowed: userCan({ actionId: "FORM_UPDATE", userPermissions: permissions }),
    color: "primary",
    title: buttonText.edit,
    onClick: () => {
      wizardFunctions?.launchEditFormWizard();
    }
  }
];
