import { memo } from "react";
import * as React from "react";
import { FormControl, Select, Box, Typography, MenuItem } from "@mui/material";
import { fieldValueEqual, parseHTMLElements } from "../../../utils";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { appStyles } from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];

const USStateDropdownField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const dropdownContainerStyle = { width: "100%", marginTop: "36px" };
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <FormControl
        component="fieldset"
        key={fieldInfo.name}
        style={dropdownContainerStyle}
        disabled={fieldInfo.disabled}
      >
        <Select
          variant={fieldInfo.variant ?? "standard"}
          name={fieldInfo.name}
          inputProps={{
            "aria-label": fieldInfo.label
          }}
          aria-label={fieldInfo.label}
          aria-required={fieldRequired}
          aria-invalid={Boolean(error)}
          value={value}
          onChange={(e) => setFieldValue(fieldInfo.name, e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>
            State <span style={customAsteriskStyle}>{fieldRequired ? "*" : ""}</span>
          </MenuItem>
          {states.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
        {error && <Typography style={{ ...errorStyles, fontSize: "0.75rem" }}>{parseHTMLElements(error)}</Typography>}
      </FormControl>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(USStateDropdownField, fieldValueEqual);

// style
const {
  baseForm: { errorStyles },
  customAsteriskStyle
} = appStyles;
