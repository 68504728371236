import { lazy } from "react";
import type { ReactElement } from "react";
import { Box } from "@mui/material";
import BaseForm from "../BaseForm";
import SetupPostAmbleWrapper from "./SetupPostAmbleWrapper";
import SetupWrapper from "./SetupWrapper";
import { appConstants, appStyles } from "../../../assets";

const BaseButton = lazy(() => import("@midato-mono/common/src/components/BaseButton"));

export interface BaseButtonMeta {
  readonly text: string;
  readonly onPress: (data?: any) => void;
}

export interface WrapperText {
  readonly header?: string;
  readonly textArray?: ReadonlyArray<string>;
}

export interface SetupWrapperMeta {
  readonly buttons?: ReadonlyArray<BaseButtonMeta>;
  readonly wrapperText?: WrapperText;
  readonly showLoginLink?: boolean;
}

interface SetupFormProps {
  readonly header: string;
  readonly textArray: string[];
  readonly showLoginLink?: boolean;
  readonly postAmble?: ReactElement | null;
  readonly cancelButton: BaseButtonMeta | null;
  readonly submitButton: BaseButtonMeta | null;
  readonly rows: any[];
  readonly formik?: any;
  readonly launchScreenLogo: string;
  readonly isAdminApp?: boolean;
  readonly submitButtonDisabled?: boolean;
  readonly loginType?: string;
}

const { setupFormMinWidth } = appConstants;
const { flexCenter } = appStyles;

const SetupForm = ({
  loginType = "ALL",
  header,
  textArray,
  showLoginLink,
  postAmble,
  rows,
  formik,
  cancelButton,
  submitButton,
  launchScreenLogo,
  isAdminApp,
  submitButtonDisabled
}: SetupFormProps) => {
  const wrapperMeta = {
    wrapperText: {
      header,
      textArray
    },
    showLoginLink
  };

  // @ts-ignore
  const CLIENT_ID = import.meta.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID;
  // @ts-ignore
  const SAW_CLIENT_ID = import.meta.env.REACT_APP_ADMIN_USER_POOL_SAW_WEB_CLIENT_ID || CLIENT_ID;
  // @ts-ignore
  const REACT_APP_ADMIN_SAML_REDIRECT_URL = import.meta.env.REACT_APP_ADMIN_SAML_REDIRECT_URL;
  // @ts-ignore
  const REACT_APP_ADMIN_SAML_AUTHORIZE_URL = import.meta.env.REACT_APP_ADMIN_SAML_AUTHORIZE_URL;

  const wrapperredPostAmble = (
    <SetupPostAmbleWrapper
      submitButtonDisabled={submitButtonDisabled}
      cancelButton={cancelButton}
      submitButton={submitButton}
    >
      <>
        {isAdminApp && (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            {(loginType === "SAW" || loginType === "ALL") && (
              <BaseButton
                id="loginWithSaw"
                ariaLabel="SAW login"
                color="primary"
                onPress={(): void => {
                  window.localStorage.setItem("midato:loginType", loginType as string);
                  // window.location.href = `https://consentlink-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=1htr5e1v1ege8c56n37uomo6ig&redirect_uri=https%3A%2F%2Fadmin.dev.shareapprove.com%2Flogin&identity_provider=saw`;
                  window.location.href = `${REACT_APP_ADMIN_SAML_AUTHORIZE_URL}?response_type=code&client_id=${SAW_CLIENT_ID}&redirect_uri=${encodeURIComponent(
                    REACT_APP_ADMIN_SAML_REDIRECT_URL
                  )}&identity_provider=saw`;
                }}
              >
                Saw Login
              </BaseButton>
            )}
            {(loginType === "ALL" || loginType === "WATECH") && (
              <BaseButton
                style={{ marginLeft: "16px" }}
                id="loginWith"
                ariaLabel="Watech login"
                color="primary"
                onPress={(): void => {
                  window.localStorage.setItem("midato:loginType", loginType as string);
                  // window.location.href = `https://consentlink-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=1htr5e1v1ege8c56n37uomo6ig&redirect_uri=https%3A%2F%2Fadmin.dev.shareapprove.com%2Flogin&identity_provider=test`;
                  window.location.href = `${REACT_APP_ADMIN_SAML_AUTHORIZE_URL}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
                    REACT_APP_ADMIN_SAML_REDIRECT_URL
                  )}&identity_provider=watech`;
                }}
              >
                Watech Login
              </BaseButton>
            )}
          </Box>
        )}
        {postAmble}
      </>
    </SetupPostAmbleWrapper>
  );

  return (
    <Box>
      <SetupWrapper wrapperMeta={wrapperMeta} launchScreenLogo={launchScreenLogo}>
        <Box sx={{ ...flexCenter, minWidth: setupFormMinWidth, width: "100%", maxWidth: "500px" }}>
          <BaseForm loginType={loginType} rows={rows} formik={formik} postAmble={wrapperredPostAmble} preAmble={null} />
        </Box>
      </SetupWrapper>
    </Box>
  );
};

export default SetupForm;
