import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { useWindowDimensions, useAuth } from "@midato-mono/common/src/hooks";
import { dimensionSlice } from "@midato-mono/common/src/reduxSlices";
import roboto300woff from "../../ui/assets/fonts/roboto-v18-latin-300.woff";
import roboto400woff from "../../ui/assets/fonts/roboto-v18-latin-regular.woff";
import roboto500woff from "../../ui/assets/fonts/roboto-v18-latin-500.woff";
import roboto700woff from "../../ui/assets/fonts/roboto-v18-latin-700.woff";
import robotoMonoWoff from "../../ui/assets/fonts/roboto-mono-v6-latin-regular.woff";
import sourcesanspro from "../../ui/assets/fonts/SourceSansPro-Black.otf";
import nunitoregular from "../../ui/assets/fonts/nunito-regular.woff";
import assets from "../../ui/assets";

const {
  appColors: { primaryTheme, secondaryTheme, dangerTheme, warningTheme }
} = assets;

const { setDimensions } = dimensionSlice;

const useApp = () => {
  const { token: auth, permissions } = useAuth();
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: { main: primaryTheme },
          secondary: { main: secondaryTheme },
          warning: { main: warningTheme },
          error: { main: dangerTheme }
        },
        typography: {
          fontFamily: `Source-Sans-Pro, sans-serif !important;`,
          h1: { fontFamily: `Nunito, sans-serif !important;` },
          h2: { fontFamily: `Nunito, sans-serif !important;` },
          h3: { fontFamily: `Nunito, sans-serif !important;` },
          h4: { fontFamily: `Nunito, sans-serif !important;` },
          h5: { fontFamily: `Nunito, sans-serif !important;` },
          h6: { fontFamily: `Nunito, sans-serif !important;` }
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family:  "Source-Sans-Pro";
              font-style: normal;
              font-weight: 300;
              src: url(${sourcesanspro}) format('otf');
            }
            @font-face {
              font-family:  "Nunito";
              font-style: normal;
              font-weight: 300;
              src: url(${nunitoregular}) format('woff');
            }
            @font-face {
              font-family: Roboto;
              font-style: normal;
              font-weight: 300;
              src: url(${roboto300woff}) format('woff');
            }
            @font-face {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              src: url(${roboto400woff}) format('woff');
            }
            @font-face {
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              src: url(${roboto500woff}) format('woff');
            }
            @font-face {
              font-family: Roboto;
              font-style: normal;
              font-weight: 700;
              src: url(${roboto700woff}) format('woff');
            }
            @font-face {
              font-family: "Roboto Mono";
              font-style: normal;
              font-weight: normal;
              src: url(${robotoMonoWoff}) format('woff');
            }
            *, *::before, *::after {
              box-sizing: border-box;
            }
            body {
              background-color: #ffffff;
            }
            a {
              cursor: pointer;
            }
          `
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline"
                }
              }
            }
          }
        }
      }),
    []
  );

  useEffect(() => {
    dispatch(setDimensions(dimensions));
  }, [dimensions, dispatch]);

  return { auth, theme, permissions };
};

export default useApp;
