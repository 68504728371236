import * as React from "react";
import type { BaseFieldProps } from "../../types";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import {
  PDFField,
  ReadonlyTextField,
  SelectorField,
  ChipsField,
  SchemaField,
  InitialsSignatureField,
  SignatureField,
  DateField,
  DateTimeField,
  RadioField,
  CheckboxField,
  ReadonlyComponentField,
  PasswordField,
  NormalTextField,
  CheckboxGroupField,
  ReadonlySignatureField,
  USStateDropdownField,
  DobField,
  ZipTextField,
  TodayDateField,
  TodayDateTimeField,
  GenderField,
  USPhoneNumberField,
  SwitchField,
  CheckboxTextField,
  SwitchTextField,
  DividerField,
  ProgressBarField,
  FileUploadField,
  CheckboxWithPrePostTextField,
  MultiSelectorField,
  AutocompleteField,
  AsyncMultiSelectorField
} from "./fields";
import BaseButton from "../BaseButton";
import { appColors, appConstants } from "../../assets";
import { formatePhoneNumberAndRemoveCountryCode } from "../../utils";

const { primaryFontSize } = appConstants;
const { formText, primaryTheme, secondaryTheme, dangerTheme, warningTheme } = appColors;

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: { fontSize: primaryFontSize, color: formText }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {}
      }
    }
  },
  palette: {
    primary: { main: primaryTheme },
    secondary: { main: secondaryTheme },
    warning: { main: warningTheme },
    error: { main: dangerTheme }
  }
});

const BaseField = ({
  fieldInfo,
  value,
  error = "",
  setFieldValue,
  needConfirmation,
  oldFieldValues
}: BaseFieldProps) => {
  const { fieldType, hidden } = fieldInfo;

  let content = null;
  switch (fieldType) {
    case "computed":
      content = null;
      break;
    case "readonly_text":
    case "readonly_headertext":
      content = <ReadonlyTextField fieldInfo={fieldInfo} value={value} />;
      break;
    case "readonly_signature":
      content = <ReadonlySignatureField fieldInfo={fieldInfo} value={value} />;
      break;
    case "divider":
      content = <DividerField fieldInfo={fieldInfo} value={value} />;
      break;
    case "pdf":
      content = (
        <PDFField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "today-date":
      content = (
        <TodayDateField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "today-date-time":
      content = (
        <TodayDateTimeField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "gender":
      content = <GenderField fieldInfo={fieldInfo} value={value} setFieldValue={setFieldValue} error={error} />;
      break;
    case "select":
      content = (
        <SelectorField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "multi-select":
      content = (
        <MultiSelectorField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "chips":
      content = (
        <ChipsField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "progress-bar":
      content = <ProgressBarField fieldInfo={fieldInfo} value={value} />;
      break;
    case "signature":
      content = (
        <SignatureField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "initial":
      content = (
        <InitialsSignatureField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "date":
      content = (
        <DateField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "date-time":
      content = (
        <DateTimeField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "radio":
      content = (
        <RadioField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "checkbox":
      content = (
        <CheckboxField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "checkbox_group":
      content = <CheckboxGroupField fieldInfo={fieldInfo} value={value} setFieldValue={setFieldValue} error={error} />;
      break;
    case "readonly_component":
      content = <ReadonlyComponentField fieldInfo={fieldInfo} value={value} />;
      break;
    case "password":
      content = (
        <PasswordField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "dob":
      content = (
        <DobField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "us-state":
      content = (
        <USStateDropdownField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "zip":
      content = (
        <ZipTextField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "schema":
      content = (
        <SchemaField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "us-phone":
      content = (
        <USPhoneNumberField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={formatePhoneNumberAndRemoveCountryCode(value)}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "switch":
      content = (
        <SwitchField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "checkbox-text":
      content = (
        <CheckboxTextField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "checkbox-with-pre-post-text":
      content = (
        <CheckboxWithPrePostTextField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "switch-text":
      content = (
        <SwitchTextField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "file-upload":
      content = <FileUploadField fieldInfo={fieldInfo} value={value} setFieldValue={setFieldValue} error={error} />;
      break;
    case "button":
      content = (
        <BaseButton
          color={fieldInfo.color || "primary"}
          onPress={fieldInfo.onPress}
          id={fieldInfo.id || "btn-form"}
          style={fieldInfo.style || undefined}
          ariaLabel={fieldInfo.label}
        >
          {fieldInfo.label}
        </BaseButton>
      );
      break;
    case "icon-button":
      content = (
        <div onClick={fieldInfo.onPress} id={fieldInfo.id || "btn-form-button"} style={fieldInfo.style || undefined}>
          <IconButton aria-label="icon-button" id="btn-icon-button" size="medium">
            {fieldInfo.label}
          </IconButton>
        </div>
      );
      break;

    case "lookup":
      content = (
        <AutocompleteField
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
        />
      );
      break;
    case "async-multi-select":
      content = (
        <AsyncMultiSelectorField
          fieldInfo={fieldInfo}
          value={value || []}
          setFieldValue={setFieldValue}
          error={error}
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
        />
      );
      break;
    case "text":
      content = (
        <NormalTextField
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
        />
      );
      break;
    case "oldFieldValues":
    case "needConfirmation":
    case "hidden":
      content = null;
      break;
    default:
      content = (
        <NormalTextField
          fieldInfo={fieldInfo}
          value={value}
          setFieldValue={setFieldValue}
          error={error}
          needConfirmation={needConfirmation}
          oldFieldValues={oldFieldValues}
        />
      );
      break;
  }

  return !hidden ? <ThemeProvider theme={theme}>{content}</ThemeProvider> : null;
};

export default BaseField;
