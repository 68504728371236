import { BulkExportField } from "./types";
import assets from "../../../ui/assets";
import { validations } from "../../utils";

const {
  appStrings: {
    admin: {
      bulkExport: {
        item: { formFields }
      }
    }
  },
  appConstants: { gutterMargin, moderatePadding },
  appStyles: { consentStatusChip }
} = assets;

const fields: { [key in BulkExportField]: any } = {
  description: {
    name: "description",
    label: formFields.description,
    variant: "outlined",
    width: "70%",
    validation: validations.text
  },
  type: {
    name: "type",
    label: formFields.exportType,
    fieldType: "select",
    variant: "outlined",
    width: "70%",
    options: [{ name: formFields.options.consents, label: formFields.options.consents }],
    validation: validations.requiredText
  },
  format: {
    name: "format",
    label: formFields.exportFormat,
    fieldType: "select",
    variant: "outlined",
    width: "70%",
    options: [{ name: formFields.options.ndjson, label: formFields.options.ndjson }],
    validation: validations.requiredText
  },
  filterData: {
    name: "filterData",
    label: formFields.filterData,
    value: formFields.filterData,
    fieldType: "readonly_headertext",
    style: { marginTop: gutterMargin }
  },
  status: {
    name: "status",
    label: formFields.consentStatus,
    fieldType: "chips",
    chipStyles: consentStatusChip,
    width: "80%",
    options: [...Object.values(formFields.consentStatusOptions)],
    showIf: (formik: any) => formik.values["type"] === "CONSENTS"
  },
  orgId: {
    name: "orgId",
    label: formFields.organization,
    fieldType: "select",
    variant: "outlined",
    width: "70%",
    disabled: true // because currently, we only use callerOrganization
  },
  startDate: {
    name: "startDate",
    label: formFields.startDate,
    fieldType: "date",
    variant: "outlined",
    width: `calc(35% - ${moderatePadding / 2}px)`,
    validation: validations.date
  },
  endDate: {
    name: "endDate",
    label: formFields.endDate,
    fieldType: "date",
    variant: "outlined",
    width: `calc(35% - ${moderatePadding / 2}px)`,
    validation: validations.date
  },
  exportStatus: {
    name: "exportStatus",
    label: formFields.exportStatus,
    value: formFields.exportStatus,
    fieldType: "readonly_headertext",
    style: { marginTop: gutterMargin }
  },
  rowsProcessed: {
    name: "rowsProcessed",
    fieldType: "progress-bar",
    width: "70%"
  },
  results: {
    name: "results",
    label: formFields.results,
    value: formFields.results,
    fieldType: "readonly_headertext"
  }
};

export default fields;
