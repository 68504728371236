const colors = {
  white: "#FFFFFF",
  // gray: "#87868B",
  gray: "#535357",
  lightGray: "#A9A8AD",
  // midatoPrimaryLight: "#c6d8ee",
  midatoPrimaryLight: "#B4D7FF",
  midatoSecondaryDark: "#0f3e52",
  offWhite: "#fafafa",
  hoverBlue: "#EFE0C6",
  semiTransparent: "#B4D7FF",
  primaryBackground: "#003e51"
};

const styleGuideColors = {
  primary: "#0077C8",
  // primary: "#003e51",
  secondary: "#1b556f",
  warning: "#9d6d00",
  danger: "#D22630",
  lightGray: "#E9EFEF",
  darkGray: "#36393B"
};

export const themeColors = {
  primaryBlue: styleGuideColors.primary,
  secondaryBlue: colors.hoverBlue,
  secondaryGray: styleGuideColors.lightGray
};

export const buttonColors = {
  defaultBG: styleGuideColors.primary,
  defaultText: colors.white,
  primaryBG: styleGuideColors.secondary,
  primaryHoverBG: colors.midatoSecondaryDark,
  primaryText: colors.white,
  secondaryBG: colors.white,
  secondaryText: styleGuideColors.secondary,
  secondaryBorder: styleGuideColors.secondary,
  success: styleGuideColors.lightGray,
  error: styleGuideColors.danger,
  info: colors.white,
  warning: styleGuideColors.warning
};

export const appColors = {
  primaryTheme: styleGuideColors.primary,
  secondaryTheme: styleGuideColors.secondary,
  warningTheme: styleGuideColors.warning,
  dangerTheme: styleGuideColors.danger,
  // background colors
  defaultBG: colors.white,
  layoutBG: styleGuideColors.lightGray,
  consentHistoryBG: colors.offWhite,
  settingsBoxBG: styleGuideColors.lightGray,
  consentFieldBackground: styleGuideColors.lightGray,
  formToolbarBackground: styleGuideColors.lightGray,
  formNotificationBackground: styleGuideColors.lightGray,
  appBarBackground: styleGuideColors.primary,
  appBarLightBackground: colors.midatoPrimaryLight,
  appBarFilterBackground: styleGuideColors.primary,
  // text colors
  whiteText: colors.white,
  grayText: colors.gray,
  defaultGrayFont: colors.gray,
  emptyFillerText: styleGuideColors.lightGray,
  consentFieldDisabledText: styleGuideColors.lightGray,
  activeTabText: themeColors.primaryBlue,
  secondaryText: styleGuideColors.darkGray,
  formText: styleGuideColors.darkGray,
  titleText: styleGuideColors.darkGray,
  subTitleText: styleGuideColors.secondary,
  textLink: styleGuideColors.primary,
  defaultIcon: colors.gray,
  // error colors
  formError: styleGuideColors.danger,
  asteriskColor: styleGuideColors.danger,
  toasterErrorBackground: styleGuideColors.danger,
  expiringStatusText: styleGuideColors.danger,
  warningText: styleGuideColors.danger,
  // border colors
  defaultBorder: styleGuideColors.lightGray,
  darkBorder: styleGuideColors.darkGray,
  consentDetailBorder: styleGuideColors.lightGray,
  // other colors
  formLabelColor: colors.gray,
  NavigatorTabGray: styleGuideColors.lightGray,
  headerDropDown: colors.gray,
  // tab colors
  tabBarBackground: colors.primaryBackground,
  tabIndicator: colors.white,
  tabInactiveText: colors.white,
  // button colors
  setupButton: styleGuideColors.lightGray,
  addButton: styleGuideColors.lightGray,
  selectButtonBackground: colors.white,
  selectButtonText: themeColors.primaryBlue,
  // section title colors
  SectionTitleBackground: themeColors.primaryBlue,
  SectionTitleText: colors.white,
  // header colors
  headerBorder: styleGuideColors.lightGray,
  headerIcon: styleGuideColors.darkGray,
  // sidebar colors
  sidebarSelectedItemBG: colors.semiTransparent,
  sidebarBG: styleGuideColors.darkGray,
  sidebarDivider: styleGuideColors.lightGray,
  // breadcrumb colors
  breadCrumbActive: styleGuideColors.secondary,
  breadCrumb: styleGuideColors.primary,
  // table colors
  tableHeaderCellBG: colors.white,
  tableRowHoverColor: colors.hoverBlue,
  tableBodyActionMenuColor: styleGuideColors.darkGray,
  // action ribbon colors
  actionRibbonControlColor: colors.white,
  actionRibbonSearchIcon: styleGuideColors.darkGray,
  // chip colors
  lightChip: styleGuideColors.lightGray,
  darkChip: styleGuideColors.darkGray,

  filtersBackground: colors.midatoPrimaryLight,
  infoBackground: styleGuideColors.lightGray
};
