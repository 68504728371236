import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useAuth from "./useAuth";
import useToaster from "./useToaster";
import useSpinner from "./useSpinner";

interface UseMidatoQueryProps {
  queryFn: Function;
  queryArgs: any;
  queryOptions?: any;
  isSuccessCallback?: (data: any) => void;
  isErrorCallback?: (error: any) => void;
  isLoadingCallback?: () => void;
  isFetchingCallback?: () => void;
  auth?: string;
  otherQueriesAreSuccess?: boolean;
  errorHandlers?: {
    [key: string | number]: ({ error, refetch }: { error: Error; refetch: Function }) => void;
  };
}

const useMidatoQuery = ({
  queryFn,
  queryArgs,
  queryOptions,
  isSuccessCallback,
  isErrorCallback,
  isLoadingCallback,
  isFetchingCallback,
  auth,
  errorHandlers,
  otherQueriesAreSuccess = true
}: UseMidatoQueryProps) => {
  const { token: idToken } = useAuth();
  const dispatch = useDispatch();
  const { displayAnErrorMessage } = useToaster();
  const { closeSpinner, openSpinner } = useSpinner();

  const { isLoading, error, data, isSuccess, isError, isFetching, refetch } = queryFn(
    { variables: queryArgs, auth: auth || idToken },
    queryOptions
  );

  useEffect(() => {
    if (isSuccess && otherQueriesAreSuccess) {
      closeSpinner();
    }

    if (isSuccess && data) {
      if (isSuccessCallback) {
        isSuccessCallback(data);
      }
    }

    if (isError && error) {
      if (typeof errorHandlers?.[error?.response?.status] === "function") {
        errorHandlers?.[error?.response?.status]({ error, refetch });
      } else {
        closeSpinner();
        displayAnErrorMessage(error?.message ?? "error");
      }

      if (isErrorCallback) {
        isErrorCallback(error);
      }
    }

    if (isLoading) {
      openSpinner();
      if (isLoadingCallback) {
        isLoadingCallback();
      }
    }

    if (isFetching) {
      openSpinner();
      if (isFetchingCallback) {
        isFetchingCallback();
      }
    }
  }, [isLoading, error, data, isSuccess, isError, isFetching, otherQueriesAreSuccess, dispatch]);
  return { isLoading, error, data, isSuccess, isError, isFetching, refetch };
};

export default useMidatoQuery;
