import { memo, useState, useEffect } from "react";
import * as React from "react";
import { Checkbox, FormControl, Box, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { fieldValueEqual, extractFieldLabel } from "../../../utils";
import { appStyles, appConstants } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { formFieldVerticalMargin } = appConstants;

const {
  baseForm: { sharedStyles }
} = appStyles;

const CheckboxTextField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const [beingUsed, setBeingUsed] = useState(() => !!value);
  useEffect(() => {
    if (beingUsed === false) {
      setFieldValue(fieldInfo.name, "");
    }
  }, [beingUsed]);
  return (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <FormGroup row sx={{ minWidth: "max-content" }}>
        <FormControl
          style={{
            ...sharedStyles,
            marginTop: formFieldVerticalMargin,
            marginBottom: 0,
            ...(fieldInfo?.style ?? {})
          }}
          sx={{ maxWidth: "fit-content" }}
        >
          <FormControlLabel
            sx={{ marginRight: "5px" }}
            disabled={fieldInfo.disabled || fieldInfo.readOnly}
            control={
              <Checkbox
                disabled={fieldInfo.disabled || fieldInfo.readOnly}
                color="primary"
                checked={beingUsed}
                onChange={(e) => {
                  setBeingUsed((beingUsed) => !beingUsed);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
                id={`${fieldInfo.name}-checkbox`}
              />
            }
            aria-label={extractFieldLabel(fieldInfo.label, fieldInfo.componentLabel)}
            label={extractFieldLabel(fieldInfo.label, fieldInfo.componentLabel)}
          />
        </FormControl>
        {beingUsed ? (
          <TextField
            variant="standard"
            disabled={fieldInfo.disabled}
            sx={{ flex: "1 1 0%", marginTop: "20px" }}
            inputProps={{ style: { paddingBottom: 0 } }}
            error={Boolean(error)}
            helperText={error}
            onChange={(e) => {
              setFieldValue(fieldInfo.name, e.target.value);
            }}
            value={value}
            id={fieldInfo.name}
          />
        ) : null}
      </FormGroup>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(CheckboxTextField, fieldValueEqual);
