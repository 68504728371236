import { memo } from "react";
import * as React from "react";
import { FormControl, FormControlLabel, Box, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { fieldValueEqual, parseHTMLElements } from "../../../utils";
import { appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const {
  HTMLStyles,
  customAsteriskStyle,
  baseForm: { sharedStyles, errorStyles }
} = appStyles;

const RadioField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  oldFieldValues,
  needConfirmation
}: InteractiveFieldProps) => {
  const { options = [], disabled = false } = fieldInfo;
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel
      fieldInfo={fieldInfo}
      value={options?.find(({ name }) => name === value)?.label || ""}
    />
  ) : (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <FormControl
        component="fieldset"
        key={fieldInfo.name}
        sx={{
          ...sharedStyles,
          ...(fieldInfo?.style ?? {})
        }}
        disabled={disabled}
      >
        <FormLabel component="legend" style={sharedStyles}>
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: HTMLStyles.formViewer + fieldInfo.label
              }}
            />
            <span style={customAsteriskStyle}>{fieldRequired ? " *" : ""}</span>
          </div>
        </FormLabel>
        <RadioGroup
          row={fieldInfo.row}
          aria-label={fieldInfo.label}
          aria-required={fieldRequired}
          aria-invalid={Boolean(error)}
          name={fieldInfo.name}
          value={value}
          onChange={(e) => {
            setFieldValue(fieldInfo.name, e.target.checked ? e.target.value : "");
          }}
        >
          {options.map((option: any) => {
            const { label, value } =
              typeof option === "object"
                ? { label: option.label, value: option.name }
                : { label: option, value: option };
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio color="primary" />}
                label={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HTMLStyles.formViewer + label
                    }}
                  />
                }
              />
            );
          })}
        </RadioGroup>
        {error && <Typography style={errorStyles}>{parseHTMLElements(error)}</Typography>}
      </FormControl>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(RadioField, fieldValueEqual);
