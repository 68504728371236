import { memo } from "react";
import * as React from "react";
import { Box } from "@mui/material";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fieldValueEqual, formattedDate } from "../../../utils";
import { appColors, appStyles } from "../../../assets";
import type { InteractiveFieldProps } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const { formError } = appColors;
const {
  baseForm: { sharedStyles }
} = appStyles;

const DateField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isDisabledField = true;

  const sxProps = {
    ...sharedStyles,
    marginBottom: 0,
    ...(fieldInfo?.style ?? {})
  };

  if (fieldInfo?.readOnly) {
    return <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={formattedDate(value, "MM/dd/yyyy HH:mm")} />;
  } else {
    return (
      <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            sx={
              error
                ? { "& .MuiInputLabel-root": { color: formError }, "& .MuiFormHelperText-root": { color: formError } }
                : {}
            }
          >
            {/* <DateTimePicker
            disabled={isDisabledField}
            label={fieldInfo.label}
            value={value ? value : null}
            inputFormat="MM/dd/yyyy HH:mm"
            onChange={(date: any) => {
              try {
                setFieldValue(fieldInfo.name, format(date, "yyyy-MM-dd hh:mm"));
              } catch (err) {
                setFieldValue(fieldInfo.name, date);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, "aria-describedby": fieldInfo.label }}
                variant={fieldInfo.variant ?? "standard"}
                name={fieldInfo.name}
                error={Boolean(error)}
                helperText={error}
                InputLabelProps={{
                  shrink: true
                }}
                id={fieldInfo.name}
                sx={sxProps}
              />
            )}
          /> */}
          </Box>
        </LocalizationProvider>
        {needConfirmation?.includes(fieldInfo.name) && (
          <Box>
            <CheckIcon
              onClick={(e) => {
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
            />
            <CloseIcon
              onClick={(e) => {
                setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
                setFieldValue(
                  "needConfirmation",
                  needConfirmation.filter((item) => item !== fieldInfo.name)
                );
              }}
              style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default memo(DateField, fieldValueEqual);
