import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface FetchUserConfig {
  userId: string;
}

const fetchUser = async ({ auth, variables }: QueryConfig<FetchUserConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($userId: ID!) {
        getUser(userId: $userId) {
          ...cmsUserFields
        }
      }
    `,
    variables,
    auth
  );
};

const fetchCurrentUser = async ({ auth }: QueryConfig<null>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query {
        getCurrentUser {
          userId
          orgId
          orgIds
          username
          sub
          firstName
          lastName
          email
          phoneNumber
          createdAt
          lastModified
          isPolicyAccepted
          rolePermissions {
            role
            permissions
          }
          organizations {
            orgId
            name
          }
        }
      }
    `,
    {},
    auth
  );
};

export interface FetchUserListByOrgIdConfig {
  orgId: string;
  query?: string;
  limit?: number;
  nextToken?: string;
}

// TODO: Remove fetchUserListByOrgId after removing Users view from Organization
const fetchUserListByOrgId = async ({ auth, variables }: QueryConfig<FetchUserListByOrgIdConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($query: String, $orgId: ID!, $limit: Int, $nextToken: String) {
        getOrganization(orgId: $orgId) {
          userList(search: $query, nextToken: $nextToken, limit: $limit) {
            users {
              userId
              firstName
              lastName
              phoneNumber
              email
            }
            nextToken
          }
        }
      }
    `,
    variables,
    auth
  );
};

interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  permissions: string[];
  roles: string[];
  organizations: string[];
}

export interface AddNewUserConfig {
  orgId: string;
  user: User;
  tempPassword?: string;
  password?: string;
}

// const addNewUser = async ({ auth, variables }: QueryConfig<AddNewUserConfig>) => {
//   return await graphql(
//     GQL_ENDPOINT,
//     `
//       mutation ($user: UserInput!, $orgId: ID!, $tempPassword: String) {
//         addUser(orgId: $orgId, user: $user, tempPassword: $tempPassword) {
//           userId
//         }
//       }
//     `,
//     variables,
//     auth
//   );
// };

// TODO temporary solution for user create
const addNewUser = async ({ auth, variables }: QueryConfig<AddNewUserConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($user: UserInput!, $orgId: ID!, $password: String!) {
        signUp(user: $user, orgId: $orgId, password: $password) {
          userId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateUserConfig {
  userId: string;
  user: User;
}

const updateUser = async ({ auth, variables }: QueryConfig<UpdateUserConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($userId: ID!, $user: UserUpdateInput!) {
        updateUser(userId: $userId, user: $user) {
          ...cmsUserFields
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeleteUserConfig {
  userId: string;
}

const deleteUser = async ({ auth, variables }: QueryConfig<DeleteUserConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($userId: ID!) {
        deleteUser(userId: $userId) {
          firstName
          userId
        }
      }
    `,
    variables,
    auth
  );
};

export interface DeactivateUserConfig {
  userId: string;
}

const deactivateUser = async ({ auth, variables }: QueryConfig<DeactivateUserConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($userId: ID!) {
        deactivateUser(userId: $userId) {
          firstName
          userId
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdateProfileConfig {
  firstName: string;
  lastName: string;
  email: string;
}

const updateProfile = async ({ auth, variables }: QueryConfig<UpdateProfileConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($user: CurrentUserInput!) {
        updateCurrentUser(user: $user) {
          firstName
          lastName
          email
        }
      }
    `,
    variables,
    auth
  );
};

export interface UpdatePasswordConfig {
  oldPassword: string;
  newPassword: string;
}

const updatePassword = async ({ auth, variables }: QueryConfig<UpdatePasswordConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($oldPassword: String!, $newPassword: String!) {
        changeCurrentUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
      }
    `,
    variables,
    auth
  );
};

export {
  fetchCurrentUser,
  deactivateUser,
  fetchUser,
  addNewUser,
  updateUser,
  deleteUser,
  fetchUserListByOrgId,
  updateProfile,
  updatePassword
};
