import { CircularProgress, Backdrop } from "@mui/material";

const Spinner = ({ style }: { style?: {} }) => {
  const spinnerStyle = style ? { width: "80px", height: "80px", ...style } : { width: "80px", height: "80px" };

  return (
    <Backdrop invisible={true} sx={{ zIndex: 10000 }} open={true} id="page-loading-spinner">
      <CircularProgress style={spinnerStyle} color="inherit" />
    </Backdrop>
  );
};

export default Spinner;
