import { useMediaQuery } from "@mui/material";
import { appColors } from "./colors";

const { emptyFillerText } = appColors;
const responsiveFontSize = (minWidth: number, currWidth: number, fontSize: number, fontSizeSm?: number): number => {
  if (currWidth >= minWidth) {
    return fontSize;
  } else {
    if (fontSizeSm) {
      return fontSizeSm;
    } else {
      return fontSize - 4;
    }
  }
};
export const HTMLElement = /<\/?[a-z][\s\S]*>/;
// style constants
type GRID_SIZES = undefined | "auto" | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
// fonts
// Standard Fonts //
export const titleFontSize = 20;
export const subTitleFontSize = 16;
export const primaryFontSize = 14;
export const buttonFontSize = 14;
export const smButtonFontSize = 12.5;
export const descriptionFontSize = 12;
// ----------------//
export const defaultTextFontSize = 12;
export const defaultHeaderTextFontSize = 24;
export const font30 = 30;
export const searchBtnFont = 10.2;
export const setupPostAmbleFontSize = (width: number) =>
  responsiveFontSize(lgTabletSize, width, subTitleFontSize, primaryFontSize);
// responsive
export const tabletSize = 768;
export const lgTabletSize = 776;
export const mobileSize = 450;
export const smMobileSize = 353;
export const settingsCustomBreakpoint = 400;
export const isSmMobileDevice = () => useMediaQuery(`(max-width:${smMobileSize}px)`, { noSsr: true });
export const isMobileDevice = () => useMediaQuery(`(max-width:${mobileSize}px)`, { noSsr: true });
export const isDesktopDevice = () => useMediaQuery(`(min-width:${tabletSize}px)`, { noSsr: true });
export const isTabletDevice = () =>
  useMediaQuery(`(max-width:${tabletSize}px)`) && !useMediaQuery(`(max-width:${mobileSize}px)`);
// sizing
export const topPagePadding = 202;
export const minPageWidth = 1000;
export const iconHeightWidthValue = 28;
export const closingIconSize = 32;
export const wizardConfirmationIconSize = 50;
export const actionModalDefaultHeight = 650;
export const actionModalConsentFormListWidth = 1000;
export const wizardRecipientChoicePageWidth = 800;
export const wizardWidthLarge = 1000;
export const wizardWidthMedium = 800;
export const wizardWidthSmall = 650;
export const actionModalInviteHeight = 500;
export const actionModalInviteWidth = 700;
export const selectFieldMaxHeight = 180;
export const miniIconSize = 18;
export const smIconSize = 22;
export const mIconSize = 24;
export const largeIconSize = 45;
export const wizardRecipientChoiceHeight = 200;
export const wizardRecipientChoiceWidth = 280;
export const sidebarWidth = 260;
export const accountIconSize = 29;
export const careTeamAddIconSize = 26;
export const signatureFieldHeight = 48;
export const minNavBarHeight = 45;
export const stretchedButtonWidth = 170;
export const roleTitleWidth = 115;
export const filterControlMenuWidth = 150;
export const searchBarGrid = (isMobile: boolean, noActions: boolean): GRID_SIZES => (isMobile && noActions ? 12 : 10);
export const actionBtnGrid = (isMobile: boolean, noActions: boolean): GRID_SIZES =>
  isMobile && noActions ? undefined : 2;
export const standarButtonHeight = 40;
export const smButtonWidth = 58;
export const smButtonHeight = 27;
export const sidebarCloseBtn = 24;
export const minDrawerWidth = 240;
export const standardPostAmbleBtn = 84;
export const formWithNotifierHeight = "79%";
export const formHeight = "84%";
export const flexShrinkZero = 0;
export const zero = 0;
export const smFontSpace = 2;
export const fullContainerWidth = "100%";
export const chipTextFontSize = 10.2;

// margin
export const betweenBtnMargin = 8;
export const betweenIconBtnMargin = 8;
export const negativeAppContainer = -8;
export const margin10 = 10;
export const smallMargin = margin10;
export const margin20 = 20;
export const margin30 = 30;
export const margin40 = 40;
export const titleBoxPadding = 6;
// standard values
// we might want to be slightly more specific about these global const names.  ie...gutterPadding, buttonMarginHorizontal...etc.
// otherwise we're going to end up with small, smallest, and supersmall padding values.
export const tinyPadding = 4;
export const miniPadding = 5;
export const smallPadding = 12;
export const standardPadding = 20;
export const moderatePadding = 25;
export const gutterMargin = 24;
export const mobileGutterMargin = 16;
export const standardBorderWidth = 2;
export const pageTitleHeaderPaddingString = "10px 20px";

// header
export const headerHeight = 100;
export const headerBorderWidth = standardBorderWidth;

// page shell grid
export const fullGridWidth: GRID_SIZES = 12;
export const sideBarGridValue: GRID_SIZES = 3;
export const dashViewGridValue: GRID_SIZES = (fullGridWidth - sideBarGridValue) as GRID_SIZES;

// listControl
export const tableMinWidth = 750;
export const tableHeight = 500;
export const tableBorder = `1px solid ${emptyFillerText}`;
export const pagerContainerPadding = 10;
export const sortArrowPosition = `flex-start`; // flex-end = next to column name.  space-between = at end of column

// forms
export const formDescWordsLimit = 150;

// setup form
export const setupFormWidth = "45%"; // can be a string to specify %, rem, em and px values.  can be number to define a static px value
export const setupFormMinWidth = 260;

// settings form
export const settingsFormWidth = "45%"; // can be a string to specify %, rem, em and px values.  can be number to define a static px value
export const settingsFormMinWidth = 350;

// profile form
export const profileFormMinWidth = 300;
export const singleProfileFieldGroupWidth = "400px";
export const singleSettingsFieldGroupWidth = (width: number) => (width > 420 ? "400px" : undefined);

// form fields
export const formFieldHeight = 50;
export const sigFieldFillHeight = 38;
export const formFieldVerticalMargin = 15;
export const formFieldHorizontalMargin = 0;
export const formFieldHorizontalPadding = 20;
export const capturedSignatureWidth = 150;

// position values
export const offsetLabelValue = -10;

// wizards
export const formViewerWizardDimensions = 90;
export const manuallyRecordConsentWizardDimensions = 90;
export const formViewerScaleFactor = 0.9;
export const runReportModalCenterMargins = "24px auto";
export const runReportModalDimensions = 55;

// action ribbon
export const actionRibbonButtonHeight = 34;
export const actionRibbonSearchFieldHeight = 35;
