import { lazy, useMemo } from "react";
import { Box, IconButton, Typography, Paper } from "@mui/material";
import { useAuth, useForm } from "../../../../hooks";
import { graphql } from "../../../../aws";
import CloseIcon from "@mui/icons-material/Close";
import fields from "./fields";
import { ActionModalStyle, AddEditFormStyle } from "../../../../assets";

const BaseButton = lazy(() => import("../../../../components/BaseButton"));
const BaseForm = lazy(() => import("../../../../components/form/BaseForm"));

const {
  AddEditFormStyle: { FormBox },
  ActionModalStyle: { StyledModal, CloseButtonContainer, closingModalIcon }
} = { ActionModalStyle, AddEditFormStyle };

// @ts-ignore
const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;
const addNewOrganization = async ({ auth, variables }) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($organization: OrganizationInput!) {
        addOrganization(organization: $organization) {
          orgId
        }
      }
    `,
    variables,
    auth
  );
};

const CreateOrganizationModal = ({
  isOrganizationModalOpen,
  setIsOrganizationModalOpen,
  callback
}: {
  isOrganizationModalOpen: boolean;
  setIsOrganizationModalOpen: (value: boolean) => void;
  callback?: (name: string) => void;
}) => {
  const { token: auth } = useAuth();

  const createRows = () => [
    [fields.name, fields.email],
    [fields.phoneNumber, fields.fax],
    [fields.reviewAfterDays, fields.npi],
    [fields.address1, fields.address2],
    [fields.city, fields.state, fields.zip],
    [fields.allowRevocation, fields.allowGeneralDesignationConsents, fields.active]
  ];

  const rows = useMemo(() => createRows?.() ?? [], []).map((item) =>
    item.filter((field: { hidden?: boolean }) => (field.hidden ? null : field)).filter((item: unknown) => !!item)
  );

  const formik = useForm({
    rows,
    handleSubmit: async () => {
      if (formik.isValid) {
        const values = formik.values;
        const addParams = {
          organization: {
            name: values.name,
            npi: values.npi,
            phoneNumber: values.phoneNumber,
            fax: values.fax,
            email: values.email,
            allowRevocation: values.allowRevocation,
            allowGeneralDesignationConsents: values.allowGeneralDesignationConsents,
            active: values.active,
            reviewAfterDays: Number(values.reviewAfterDays),
            activeECMUser: true,
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip
            }
          }
        };

        const { data: newOrgId } = await addNewOrganization({
          auth,
          variables: addParams
        });

        callback?.(newOrgId);

        setIsOrganizationModalOpen(false);
      }
    },
    otherFormikOptions: {
      validateOnMount: false,
      validateOnChange: true
    }
  });

  const getPostAmble = () => {
    return (
      <Box mt={4}>
        <BaseButton
          color="primary"
          onPress={async () => {
            formik.handleSubmit();
          }}
          id="btn-save"
          style={{ marginRight: "20px" }}
          ariaLabel="Save"
        >
          Save
        </BaseButton>
        <BaseButton
          ariaLabel="Cancel"
          color="secondary"
          onPress={() => setIsOrganizationModalOpen(false)}
          id="btn-cancel"
          variant="outlined"
        >
          Cancel
        </BaseButton>
      </Box>
    );
  };

  const onClose = () => {
    setIsOrganizationModalOpen(false);
  };

  return (
    <StyledModal
      open={isOrganizationModalOpen}
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper style={{ maxHeight: `80vh`, maxWidth: `600px`, overflow: "auto", padding: "30px" }}>
        <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box>
            <CloseButtonContainer>
              <IconButton aria-label="close" id="btn-close-wizard" onClick={onClose} size="medium">
                <CloseIcon style={closingModalIcon} />
              </IconButton>
            </CloseButtonContainer>
          </Box>
          <Typography variant="h5">Create organization</Typography>
          <Box style={{ flex: 1, overflow: "auto" }}>
            <FormBox style={{ height: "100%" }}>
              <BaseForm
                isViewMode={false}
                id="organization-new"
                postAmble={getPostAmble()}
                rows={rows}
                formik={formik}
              />
            </FormBox>
          </Box>
        </Box>
      </Paper>
    </StyledModal>
  );
};

export default CreateOrganizationModal;
