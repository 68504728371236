import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export interface SpinnerState {
  extendedTimeout: number;
  spinner: boolean;
}

const initialState: SpinnerState = { extendedTimeout: 0, spinner: false };

const _spinnerSlice: Slice<SpinnerState> = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    setSpinner: (state, { payload: { extendedTimeout, spinner } }: PayloadAction<SpinnerState>) => {
      return { ...state, extendedTimeout, spinner };
    }
  }
});

export const { setSpinner } = _spinnerSlice.actions;
export const selectCurrentSpinner = <T extends { spinner: SpinnerState }>(state: T) => state.spinner;
const spinnerSlice = { ..._spinnerSlice, selectCurrentSpinner, setSpinner };

export default spinnerSlice;
