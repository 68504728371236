import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useAuth from "./useAuth";
import useToaster from "./useToaster";
import useSpinner from "./useSpinner";

interface UseMidatoMutationProps {
  mutationFn: Function;
  mutationOptions?: any;
  isSuccessCallback?: (data: any) => void;
  isErrorCallback?: (error: any) => void;
  isLoadingCallback?: () => void;
  auth?: string;
  errorHandlers?: {
    [key: string | number]: ({ error, refetch }: { error: Error; refetch: Function }) => void;
  };
}

const useMidatoMutation = ({
  mutationFn,
  mutationOptions,
  isSuccessCallback,
  isErrorCallback,
  isLoadingCallback,
  auth,
  errorHandlers
}: UseMidatoMutationProps) => {
  const { token: idToken } = useAuth();
  const dispatch = useDispatch();
  const { displayAnErrorMessage } = useToaster();
  const { closeSpinner, openSpinner } = useSpinner();

  const [rawTriggerFn, { isLoading, error, data, isSuccess, isError, originalArgs }] = mutationFn(mutationOptions);

  useEffect(() => {
    if (isSuccess && data) {
      closeSpinner();
      if (isSuccessCallback) {
        isSuccessCallback(data);
      }
    }

    if (isError && error) {
      if (typeof errorHandlers?.[error?.response?.status] === "function") {
        errorHandlers?.[error?.response?.status]({ error, refetch: () => rawTriggerFn(originalArgs) });
      } else {
        closeSpinner();
        displayAnErrorMessage(error?.message ?? "error");
      }

      if (isErrorCallback) {
        isErrorCallback(error);
      }
    }

    if (isLoading) {
      openSpinner();
      if (isLoadingCallback) {
        isLoadingCallback();
      }
    }
  }, [isLoading, error, data, isSuccess, isError, dispatch]);

  return {
    isLoading,
    error,
    data,
    isSuccess,
    isError,
    triggerFn: (options: object) => rawTriggerFn({ variables: options, auth: auth || idToken })
  };
};

export default useMidatoMutation;
