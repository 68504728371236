import { memo } from "react";
import * as React from "react";
import { Box } from "@mui/material";
import ReadonlyTextField from "./ReadonlyTextField";
import ReadonlyTextFieldValueLabel from "./ReadonlyTextFieldValueLabel";
import type { InteractiveFieldProps } from "../../../types";
import { fieldValueEqual, getIsDisabledField } from "../../../utils";
import { appStyles } from "../../../assets";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";

const {
  baseForm: { sharedStyles, inputLabelEllipsis, asteriskStyles },
  styledComponents: { DefaultTextField }
} = appStyles;

const USPhoneNumberField = ({
  fieldInfo,
  value,
  setFieldValue,
  error = "",
  needConfirmation,
  oldFieldValues
}: InteractiveFieldProps) => {
  const isFloatingLabel = !fieldInfo.labelStyle || fieldInfo.labelStyle === "floating";
  const isDisabledField = getIsDisabledField(fieldInfo.disabled, fieldInfo.readOnly);
  const fieldRequired = fieldInfo.validation?.spec?.presence === "required";

  return fieldInfo.readOnly ? (
    <ReadonlyTextFieldValueLabel fieldInfo={fieldInfo} value={value} />
  ) : (
    <Box style={needConfirmation?.includes(fieldInfo.name) ? { display: "flex", alignItems: "flex-end" } : {}}>
      <Box>
        <DefaultTextField
          style={{ ...sharedStyles, ...(fieldInfo?.style ?? {}) }}
          disabled={isDisabledField}
          InputLabelProps={{
            style: inputLabelEllipsis,
            sx: asteriskStyles
          }}
          inputProps={{ maxLength: fieldInfo.maxTextLength }}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">+1</InputAdornment>
          // }}
          required={fieldRequired}
          error={Boolean(error)}
          helperText={error}
          onKeyDown={(e) => {
            const key = e.key;

            if (
              !/[0-9+\-() ]/.test(key) &&
              key !== "Backspace" &&
              key !== "ArrowLeft" &&
              key !== "ArrowRight" &&
              key !== "Tab"
            ) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const valueFormatted = isValidPhoneNumber(e.target.value, "US")
              ? parsePhoneNumber(e.target.value, "US").nationalNumber
              : e.target.value;
            setFieldValue(fieldInfo.name, valueFormatted);
          }}
          type="text"
          aria-label={isFloatingLabel ? fieldInfo.label : ""}
          label={isFloatingLabel ? fieldInfo.label : ""}
          variant={fieldInfo.variant ?? "standard"}
          // value={value.startsWith("+1") ? value.substring(2) : value}
          value={
            isValidPhoneNumber(value, "US")
              ? parsePhoneNumber(value, "US").formatNational().replace(/[()]/g, "").replace(/\s/g, "-")
              : value
          }
          id={fieldInfo.name}
        />
        {!isFloatingLabel && fieldInfo.labelStyle === "below" && (
          <ReadonlyTextField
            fieldInfo={{ fieldType: "readonly_text", name: "", label: fieldInfo.label }}
            value={fieldInfo.label}
          />
        )}
      </Box>
      {needConfirmation?.includes(fieldInfo.name) && (
        <Box>
          <CheckIcon
            onClick={(e) => {
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#0077C8", marginLeft: "10px" }}
          />
          <CloseIcon
            onClick={(e) => {
              setFieldValue(fieldInfo.name, oldFieldValues?.[fieldInfo.name]);
              setFieldValue(
                "needConfirmation",
                needConfirmation.filter((item) => item !== fieldInfo.name)
              );
            }}
            style={{ cursor: "pointer", color: "#D22630", marginLeft: "10px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(USPhoneNumberField, fieldValueEqual);
