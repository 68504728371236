import React, { useEffect } from "react";
import { OrganizationItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  createButtonBoxPostAmble,
  createAction,
  createOrganizationPageCrumbMeta,
  formIsUntouchedOrHasError
} from "../../utils";
import {
  useActivateOrganizationsMutation,
  useAddNewOrganizationMutation,
  useDeleteOrganizationByIdMutation,
  useFetchOrganizationQuery,
  useUpdateOrganizationMutation
} from "../../redux/api";
import { userCan } from "../../utils";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { formatePhoneNumberAndRemoveCountryCode } from "@midato-mono/common/src";

const OrganizationSettingsForm = React.lazy(
  () => import("../../../ui/components/organization/OrganizationSettingsForm")
);
const OrganizationWebhooksForm = React.lazy(
  () => import("../../../ui/components/organization/OrganizationWebhooksForm")
);
const AddEditForm = React.lazy(() => import("../../../ui/components/common/form/AddEditForm"));

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, cancel, deactivate, edit },
        prompts: { deactivateConfirmation }
      }
    },
    admin: {
      organization: {
        item: { detailPageHeader, actionMenuOptions }
      }
    }
  }
} = assets;

// new
type NC = OrganizationItemPageConfiguration["new"];

export const useNewInitialQuery: NC["useInitialQuery"] = ({ searchParams }) => {
  const parentOrgId = searchParams?.get("parent") || "/";
  return useMidatoQuery({
    queryFn: useFetchOrganizationQuery,
    queryArgs: {
      orgId: parentOrgId
    },
    queryOptions: { skip: !parentOrgId }
  });
};

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useAddNewOrganizationMutation, type: "add" })
});

export const createNewEntityName: NC["createEntityName"] = ({
  formik: {
    values: { name }
  }
}) => (name ? name : "New");

export const createNewRows: NC["createRows"] = () => [
  [fields.name, fields.email],
  [fields.phoneNumber, fields.fax],
  [fields.reviewAfterDays, fields.npi],
  [fields.address1, fields.address2],
  [fields.city, fields.state, fields.zip],
  [fields.allowRevocation, fields.allowGeneralDesignationConsents, fields.active, fields.activeECMUser]
];

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  searchParams,
  permissions,
  closeWizard
}) => {
  const values = formik.values;
  const saveButton = createButton({
    id: "btn-save",
    actionId: "ORGANIZATION_ADD",
    text: save,
    onPress: async () => {
      // TODO will be implemented later
      // const parentOrgId = searchParams?.get("parent") || "/";
      // const parentOrgId = "/";
      formik.handleSubmit();
      if (formik.isValid) {
        const addParams = {
          // parentOrgId,
          organization: {
            name: values.name,
            npi: values.npi,
            phoneNumber: values.phoneNumber,
            fax: values.fax,
            email: values.email,
            allowRevocation: values.allowRevocation,
            allowGeneralDesignationConsents: values.allowGeneralDesignationConsents,
            active: values.active,
            reviewAfterDays: Number(values.reviewAfterDays),
            activeECMUser: values.activeECMUser,
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip
              // expressZip: values.expressZip
            }
          }
        };
        const rawOrgId = await add!(addParams).unwrap();
        const orgId = encodeURIComponent(rawOrgId);
        closeWizard();
        navigate(`${routes.ORGANIZATIONS}/${orgId}`, { replace: true });
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });
  const cancelButton = createButton({
    actionId: "ORGANIZATION_ADD",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });
  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton]
  });
};

export const createNewCrumbMeta: NC["createCrumbMeta"] = ({ initialQueryData: parentOrganization, navigate }) => {
  // @ts-ignore
  return createOrganizationPageCrumbMeta(parentOrganization, navigate, true);
};

// detail
type DC = OrganizationItemPageConfiguration["detail"];

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: rawOrgId } }) => {
  const orgId = decodeURIComponent(rawOrgId ?? "%2F");
  return useMidatoQuery({
    queryFn: useFetchOrganizationQuery,
    queryArgs: {
      orgId
    }
  });
};

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({ toaster, mutationFn: useUpdateOrganizationMutation, type: "update" }),
  deactivate: createAction({ toaster, mutationFn: useDeleteOrganizationByIdMutation, type: "deactivate" }),
  activate: createAction({ toaster, mutationFn: useActivateOrganizationsMutation, type: "active" })
});

export const createDetailActionMenuMeta: DC["createActionMenuMeta"] = ({
  initialQueryData: organization,
  actions: { deactivate: deactivateFn, activate }
}) => {
  return organization?.active && organization?.orgId
    ? [
        {
          label: actionMenuOptions.deleteButton,
          callback: async () => {
            await deactivateFn!({
              orgId: organization.orgId
            });
          },
          actionId: "ORGANIZATION_DELETE"
        }
      ]
    : !organization?.active && organization?.orgId
      ? [
          {
            label: actionMenuOptions.activateButton,
            callback: async () => {
              await activate!({
                orgIds: [organization.orgId]
              });
            },
            actionId: "ORGANIZATION_ACTIVATE"
          }
        ]
      : [];
};

export const createDetailDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData: organization }) =>
  organization?.name ? organization.name : detailPageHeader;

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData: organization }) =>
  organization?.name || "";

export const createDetailInitialValues: DC["createInitialValues"] = ({ initialQueryData: organization }) => ({
  name: organization?.name ?? "",
  npi: organization?.npi ?? "",
  email: organization?.email ?? "",
  phoneNumber: formatePhoneNumberAndRemoveCountryCode(organization?.phoneNumber),
  fax: organization?.fax ?? "",
  address1: organization?.address?.address1 ?? "",
  address2: organization?.address?.address2 ?? "",
  city: organization?.address?.city ?? "",
  state: organization?.address?.state ?? "",
  zip: organization?.address?.zip ?? "",
  expressZip: organization?.address?.expressZip ?? "",
  allowRevocation: organization?.allowRevocation ?? true,
  allowGeneralDesignationConsents: organization?.allowGeneralDesignationConsents ?? false,
  active: organization?.active ?? true,
  reviewAfterDays: organization?.reviewAfterDays ?? "",
  activeECMUser: organization?.activeECMUser ?? true
});

export const createDetailRows: DC["createRows"] = () => [
  [fields.name, fields.email],
  [fields.phoneNumber, fields.fax],
  [fields.reviewAfterDays, fields.npi],
  [fields.address1, fields.address2],
  [fields.city, fields.state, fields.zip],
  [
    fields.allowRevocation,
    fields.allowGeneralDesignationConsents,
    { ...fields.active, disabled: true },
    fields.activeECMUser
  ]
];

export const createDetailPostAmble: DC["createPostAmble"] = ({
  formik,
  actions: { update, deactivate: deactivateFn },
  navigate,
  params,
  permissions,
  closeWizard
}) => {
  const orgId = decodeURIComponent(params?.id ?? "%2F");
  const saveButton = createButton({
    actionId: "ORGANIZATION_UPDATE",
    id: "btn-save",
    text: save,
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const values = formik.values;
        await update!({
          orgId,
          organization: {
            name: values.name,
            npi: values.npi,
            phoneNumber: values.phoneNumber,
            fax: values.fax,
            email: values.email,
            allowRevocation: values.allowRevocation,
            allowGeneralDesignationConsents: values.allowGeneralDesignationConsents,
            active: values.active,
            reviewAfterDays: Number(values.reviewAfterDays),
            activeECMUser: values.activeECMUser,
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip
              // expressZip: values.expressZip
            }
          }
        });
        closeWizard();
        navigate(`${routes.ORGANIZATIONS}/${orgId.replace(/\//g, "%2F")}`);
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });
  const cancelButton = createButton({
    actionId: "ORGANIZATION_UPDATE",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });
  const deActivateButton = createButton({
    actionId: "ORGANIZATION_DELETE",
    id: "btn-deactivate",
    text: deactivate,
    color: "error",
    onPress: async () => {
      const sure = window.confirm(deactivateConfirmation);
      if (sure) {
        await deactivateFn!({
          orgId
        });
        closeWizard();
        navigate(routes.ORGANIZATIONS, { replace: true });
      }
    },
    variant: "outlined",
    separateButtonBlock: true
  });
  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton, deActivateButton]
  });
};

export const createDetailTabs: DC["createTabs"] = ({
  formik,
  rows,
  initialQueryData: organization,
  postAmbleInfo: postAmble
}) => {
  return [
    {
      label: { text: "profile", disabled: false },
      TabComponent: AddEditForm,
      props: {
        rows,
        formik,
        itemType: "organizationProfile",
        postAmble,
        isViewMode: true
      }
    },
    {
      label: { text: "settings", disabled: false },
      TabComponent: OrganizationSettingsForm,
      props: {
        orgId: organization?.orgId,
        settings: organization?.organizationSettings
      }
    },
    {
      label: { text: "webhooks", disabled: false },
      TabComponent: OrganizationWebhooksForm,
      props: {
        orgId: organization?.orgId,
        settings: organization?.organizationSettings
      }
    }
  ];
};

export const createDetailInitialTab: DC["createInitialTab"] = () => "profile";

export const useDetailEffects: DC["useEffects"] = ({ setSearchParams, currentTab }) => {
  useEffect(() => {
    currentTab && setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab]);
};

export const createDetailCrumbMeta: DC["createCrumbMeta"] = ({ initialQueryData: currentOrganization, navigate }) => {
  // @ts-ignore
  return createOrganizationPageCrumbMeta(currentOrganization, navigate);
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({ permissions, wizardFunctions }) => [
  {
    allowed: userCan({ actionId: "ORGANIZATION_UPDATE", userPermissions: permissions }),
    color: "primary",
    title: edit,
    onClick: () => {
      wizardFunctions?.launchEditOrganizationWizard();
    }
  }
];
