import {
  useMidatoMutation as useCommonMidatoMutation,
  useToaster,
  useSpinner,
  useAuth
} from "@midato-mono/common/src/hooks";
import { httpErrorHandlerCreators } from "@midato-mono/common/src/utils";
import { tokenToAuthMapCallback } from "./../utils";
import { DecodedToken } from "./../types";

interface UseMidatoMutationProps {
  mutationFn: Function;
  mutationOptions?: any;
  isSuccessCallback?: (data: any) => void;
  isErrorCallback?: (error: any) => void;
  isLoadingCallback?: () => void;
  auth?: string;
}

const useMidatoMutation = (args: UseMidatoMutationProps) => {
  const { setAuth } = useAuth();
  const { closeSpinner } = useSpinner();
  const { displayAnNotificationMessage } = useToaster();
  return useCommonMidatoMutation({
    ...args,
    errorHandlers: {
      401: httpErrorHandlerCreators[401]<DecodedToken>({
        closeSpinner,
        setAuth,
        displayAnNotificationMessage,
        ClientId: import.meta.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID,
        tokenToAuthMapCallback
      })
    }
  });
};

export default useMidatoMutation;
